import moment from 'moment'

export const createIsExpiredQuery = () => ({
    end: {
        $lt: {
            $date: moment().valueOf(),
        },
    },
})

export const createIsNotExpiredQuery = () => ({
    end: {
        $gt: {
            $date: moment().valueOf(),
        },
    },
})
