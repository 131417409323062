import ModelCustomer from '@/models/ModelCustomer'
import ModelOfferReservation from '@/models/ModelOfferReservation'
import moment from 'moment'

export default class ModelChatRoomMsg {
    constructor (model) {
        const {
            _id,
            id,
            stadiumId,
            attachment,
            productAttachment,
            fromUser,
            message,
            updatedOn,
        } = model
        Object.assign(this, {
            _id,
            id,
            stadiumId,
            attachment,
            productAttachment,
            fromUser,
            message,
            updatedOn,
        })
    }

    static fromGql (gql) {
        const {
            _id,
            id,
            stadiumId,
            // attachment,
            productAttachment,
            fromUser,
            message,
            updatedOn,
        } = gql

        return new ModelChatRoomMsg({
            _id,
            id,
            stadiumId,
            // attachment: ResolverAttachment.fromGqlUnion(attachment),
            productAttachment: productAttachment
                ? ModelOfferReservation.fromGql(productAttachment)
                : null,
            fromUser: ModelCustomer.fromGql(fromUser),
            message,
            updatedOn: updatedOn ? moment(updatedOn) : null,
        })
    }

    static toGql (model) {
        const {
            name,
            members,
        } = model

        return {
            name,
            members: members.map((member) => ModelCustomer.toGql(member)),
        }
    }
}
