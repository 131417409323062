import ModelStadiumUserRole from '@/models/ModelStadiumUserRole'
import ModelUser from '@/models/ModelUser'

export default class ModelUserStadium extends ModelUser {
    constructor (model) {
        super(model)
        Object.assign(this, {
            ...model,
        })
    }

    static fromGql (gql) {
        const { stadiumUserRoles } = gql
        return new ModelUserStadium({
            ...super.fromGql(gql),
            ...ModelStadiumUserRole.stadiumUserRolesFromGql(stadiumUserRoles),
        })
    }

    toGql () {
        const {
            stadiumUserRoles,
        } = this
        return {
            ...super.toGql(),
            ...ModelStadiumUserRole.stadiumUserRolesToGql(stadiumUserRoles),
        }
    }
}
