import { isEmptyString } from '@/utils/utils'

export default class ModelCustomer {
    constructor ({
        _id,
        id,
        displayName,
        firstName,
        lastName,
        phoneNumber,
        email,
        nickName,
        username,
        profilePhotoUrl,
    }) {
        Object.assign(this, {
            _id,
            id,
            displayName,
            firstName,
            lastName,
            phoneNumber,
            email,
            nickName,
            username,
            profilePhotoUrl,
        })
    }

    static fromGql (gql) {
        const {
            _id,
            id,
            firstName,
            lastName,
            displayName,
            phoneNumber,
            email,
            nickName,
            username,
            profilePhotoUrl,
        } = gql
        return new ModelCustomer({
            _id,
            id,
            firstName,
            lastName,
            displayName: isEmptyString(displayName) ? firstName : displayName,
            phoneNumber,
            email,
            nickName,
            username,
            profilePhotoUrl,
        })
    }
}
