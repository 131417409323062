import VApiResponse from '@/utils/VApiResponse'
import { ApiStadium } from '@/api'
import { updateEntityCallbacks } from '@/utils/UtilsApi'

export default {
    getHolidays (stadiumId) {
        return VApiResponse.CONVERT(
            ApiStadium.getHolidays(stadiumId),
        )
    },
    addHoliday (stadiumId, newHoliday) {
        return VApiResponse.CONVERT(
            ApiStadium.addHoliday(stadiumId, newHoliday),
        )
    },
    deleteHoliday (id) {
        return VApiResponse.CONVERT(
            ApiStadium.deleteHoliday(id),
        )
    },
    updateHoliday (holiday) {
        return VApiResponse.CONVERT(
            ApiStadium.updateHoliday(holiday),
        )
    },
    getGeneralInfo (stadiumId) {
        return VApiResponse.CONVERT(
            ApiStadium.loadStadiumById(stadiumId),
        )
    },
    async addField (stadiumId, fieldInfo) {
        const res = await ApiStadium.addField(stadiumId, fieldInfo)
        if (res.isSuccess()) {
            return this.updateFieldCallback(res, fieldInfo)
        }
        return res
    },
    deleteField (stadiumId, fieldId) {
        return ApiStadium.deleteField(stadiumId, fieldId)
    },
    async updateField (stadiumId, field) {
        const res = await ApiStadium.updateField(stadiumId, field)
        if (res.isSuccess()) {
            return this.updateFieldCallback(res, field)
        }
        return res
    },
    loadFields (stadiumId, page, size) {
        return VApiResponse.CONVERT(
            ApiStadium.loadFieldsByStadium(stadiumId, page, size),
        )
    },
    loadFieldsByIds (fieldIds) {
        return VApiResponse.CONVERT(
            ApiStadium.loadFieldsByIds(fieldIds),
        )
    },
    addBoost (stadiumId, fieldInfo) {
        return VApiResponse.CONVERT(
            ApiStadium.addBoost(stadiumId, fieldInfo),
        )
    },
    deleteBoost (stadiumId, fieldId) {
        return VApiResponse.CONVERT(
            ApiStadium.deleteBoost(stadiumId, fieldId),
        )
    },
    updateBoost (stadiumId, field) {
        return VApiResponse.CONVERT(
            ApiStadium.updateBoost(stadiumId, field),
        )
    },
    loadBoosts (stadiumId, isPast, page, size) {
        return VApiResponse.CONVERT(
            ApiStadium.loadBoosts(stadiumId, isPast, page, size),
        )
    },
    loadBoostCustomers (boostId) {
        return VApiResponse.CONVERT(
            ApiStadium.loadBoostCustomers(boostId),
        )
    },
    addBoostLocalCustomer (stadiumId, boostId, customer, numberOfPeople) {
        return VApiResponse.CONVERT(
            ApiStadium.addBoostLocalCustomer(stadiumId, boostId, customer, numberOfPeople),
        )
    },
    loadBoostLocalCustomers (boostId) {
        return VApiResponse.CONVERT(
            ApiStadium.loadBoostLocalCustomers(boostId),
        )
    },
    updateBoostLocalCustomer (boostId, customer) {
        return VApiResponse.CONVERT(
            ApiStadium.updateBoostLocalCustomer(boostId, customer),
        )
    },
    deleteProductLocalCustomers (boostId, customerId) {
        return VApiResponse.CONVERT(
            ApiStadium.deleteProductLocalCustomers(boostId, customerId),
        )
    },
    loadStadiums (page, size) {
        return VApiResponse.CONVERT(
            ApiStadium.loadStadiums(page, size),
        )
    },
    deleteStadium (stadiumId) {
        return VApiResponse.CONVERT(
            ApiStadium.deleteStadium(stadiumId),
        )
    },
    async updateStadium (stadium) {
        const res = await ApiStadium.updateStadium(stadium)
        if (res.isSuccess()) {
            return this.updateStadiumCallback(res, stadium)
        }
        return res
    },
    async addStadium (stadium) {
        const res = await ApiStadium.addStadium(stadium)
        if (res.isSuccess()) {
            return this.updateStadiumCallback(res, stadium)
        }
        return res
    },
    loadSports () {
        return VApiResponse.CONVERT(
            ApiStadium.loadSports(),
        )
    },
    async addSport (sport) {
        const res = await ApiStadium.addSport(sport)
        if (res.isSuccess()) {
            return this.updateSportCallback(res, sport)
        }
        return res
    },
    async updateSport (sport) {
        const res = await ApiStadium.updateSport(sport)
        if (res.isSuccess()) {
            return this.updateSportCallback(res, sport)
        }
        return res
    },
    deleteSport (sportCode) {
        return VApiResponse.CONVERT(
            ApiStadium.deleteSport(sportCode),
        )
    },
    payTx (txId, redeemCode) {
        return VApiResponse.CONVERT(
            ApiStadium.payTx(txId, redeemCode),
        )
    },
    useTx (txId, redeemCode) {
        return VApiResponse.CONVERT(
            ApiStadium.useTx(txId, redeemCode),
        )
    },
    loadStadiumReport (stadiumId, start, end) {
        return VApiResponse.CONVERT(
            ApiStadium.fetchStadiumReport(stadiumId, start, end),
        )
    },
    updateFieldCallback (res, field) {
        return updateEntityCallbacks(
            res,
            ApiStadium.loadFieldById,
            [
                async () => (field.coverPhoto
                    ? ApiStadium.updateCoverField({ ...res.data, ...field }) : null),
            ],
        )
    },
    updateSportCallback (res, sport) {
        return updateEntityCallbacks(
            res,
            ApiStadium.loadSportById,
            [
                () => (sport.icon ? ApiStadium.updateIconSport({ ...res.data, ...sport }) : null),
            ],
        )
    },
    updateStadiumCallback (res, stadium) {
        return updateEntityCallbacks(
            res,
            ApiStadium.loadStadiumById,
            [
                () => (stadium.coverPhoto
                    ? ApiStadium.updateCoverStadium({ ...res.data, ...stadium }) : null),
                () => (stadium.logoPhoto
                    ? ApiStadium.updateLogoStadium({ ...res.data, ...stadium }) : null),
            ],
        )
    },
}
