import { BoostCustomersGeneralInfo } from '@/api/graphql/fragments/boost'
import { CustomerTxItemGeneralInfo } from '@/api/graphql/fragments/tx'
import apolloClient from '@/apolloClient'
import ModelTxItem from '@/models/ModelTxItem'
import VApiResponse from '@/utils/VApiResponse'
import gql from 'graphql-tag'

export default {
    cancel (id) {
        return VApiResponse.convertGql(apolloClient.mutate({
            mutation: gql`
            mutation cancelTxItem($id:ObjectId){
                stadiumDoCancelCustomerTxItem(customerTxItemId:$id) {
                    customerTx{
                        customer{
                            ...BoostCustomersGeneralInfo
                        }
                        paymentMethod
                    }
                    ...CustomerTxItemGeneralInfo
                }
            }
            ${CustomerTxItemGeneralInfo}
            ${BoostCustomersGeneralInfo}
            `,
            variables: {
                id,
            },
        }),
        (data) => ModelTxItem.fromGql(data.stadiumDoCancelCustomerTxItem))
    },
}
