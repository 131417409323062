import ModelCode from '@/models/ModelCode'
import ModelTxItemMeta from '@/models/ModelTxItemMeta'

export default class ModelCodeRedeem extends ModelCode {
    constructor (model) {
        const {
            txItemMetas,
        } = model
        super(model)
        Object.assign(this, {
            txItemMetas,
        })
    }

    static fromGql (gql) {
        const {
            txItemMetas,
        } = gql
        return new ModelCodeRedeem({
            txItemMetas: txItemMetas.map((meta) => ModelTxItemMeta.fromGql(meta)),
            ...super.fromGql(gql),
        })
    }
}
