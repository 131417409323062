import ActionProcessor from '@/utils/ActionProcessor'
import { createActionsWithInterceptors, createMutationBaseEntities } from '@/utils/store-utils'
import { _Actions, _Mutations, _Getters } from '@/store/modules/shop/types'
import ServiceShop from '@/services/ServiceShop'
import { Entities } from '@/utils/enum'

class ActionProcessorImpl extends ActionProcessor {
    createInterceptors () {
        return []
    }
}

const Processors = new ActionProcessorImpl()
export default {
    namespaced: true,
    state: {
        couponForBundles: [],
        bundleCoupons: [],
    },
    getters: {
        [_Getters.GET_COUPON_FOR_BUNDLE]:
            (state) => (id) => state.couponForBundles.find(
                (couponForBundle) => couponForBundle.id === id,
            ),
        [_Getters.GET_BUNDLE_COUPON]:
            (state) => (id) => state.bundleCoupons.find(
                (bundleCoupon) => bundleCoupon.id === id,
            ),
    },
    actions: {
        ...createActionsWithInterceptors(Processors,
            {
                async [_Actions.LOAD_COUPON_FOR_BUNDLES] ({ commit }, { isPast, page, size }) {
                    const res = await ServiceShop.loadCouponForBundles(isPast, page, size)
                    if (res.isSuccess()) {
                        commit(_Mutations.LOAD_COUPON_FOR_BUNDLES, res.data.datas)
                    }
                    return res
                },
                async [_Actions.ADD_COUPON_FOR_BUNDLE] ({ commit }, couponForBundle) {
                    const res = await ServiceShop.addCouponForBundle(couponForBundle)
                    if (res.isSuccess()) {
                        commit(_Mutations.ADD_COUPON_FOR_BUNDLE, res.data)
                    }
                    return res
                },
                async [_Actions.UPDATE_COUPON_FOR_BUNDLE] ({ commit }, couponForBundle) {
                    const res = await ServiceShop.updateCouponForBundle(couponForBundle)
                    if (res.isSuccess()) {
                        commit(_Mutations.UPDATE_COUPON_FOR_BUNDLE, couponForBundle)
                    }
                    return res
                },
                async [_Actions.DELETE_COUPON_FOR_BUNDLE] ({ commit }, id) {
                    const res = await ServiceShop.deleteCouponForBundle(id)
                    if (res.isSuccess()) {
                        commit(_Mutations.DELETE_COUPON_FOR_BUNDLE, id)
                    }
                    return res
                },
                async [_Actions.LOAD_BUNDLE_COUPONS] ({ commit }, { isPast, page, size }) {
                    const res = await ServiceShop.loadBundleCoupons(isPast, page, size)
                    if (res.isSuccess()) {
                        commit(_Mutations.LOAD_BUNDLE_COUPONS, res.data.datas)
                    }
                    return res
                },
                async [_Actions.ADD_BUNDLE_COUPON] ({ commit }, couponForBundle) {
                    const res = await ServiceShop.addBundleCoupon(couponForBundle)
                    if (res.isSuccess()) {
                        commit(_Mutations.ADD_BUNDLE_COUPON, res.data)
                    }
                    return res
                },
                async [_Actions.UPDATE_BUNDLE_COUPON] ({ commit }, couponForBundle) {
                    const res = await ServiceShop.updateBundleCoupon(couponForBundle)
                    if (res.isSuccess()) {
                        commit(_Mutations.UPDATE_BUNDLE_COUPON, couponForBundle)
                    }
                    return res
                },
                async [_Actions.DELETE_BUNDLE_COUPON] ({ commit }, id) {
                    const res = await ServiceShop.deleteBundleCoupon(id)
                    if (res.isSuccess()) {
                        commit(_Mutations.DELETE_BUNDLE_COUPON, id)
                    }
                    return res
                },
            }),
    },
    mutations: {
        ...createMutationBaseEntities(Entities.COUPON_FOR_BUNDLES),
        ...createMutationBaseEntities(Entities.BUNDLE_COUPONS),
    },
}
