import ActionProcessor from '@/utils/ActionProcessor'
import { createActionsWithInterceptors, createMutationBaseEntities } from '@/utils/store-utils'
import ServiceField from '@/services/ServiceField'
import { _Mutations, _Actions } from './types'

class ActionProcessorImpl extends ActionProcessor {
    createInterceptors () {
        return []
    }
}

const Processor = new ActionProcessorImpl()

export default {
    namespaced: true,
    state: {
        id: undefined,
        /**
         * @typedef {Object} PriceRule
         * @prop {String} id
         * @prop {String} weekDay
         * @prop {MomentDateTime[]} rangeTime [startTime,endTime]
         * @prop {String} price price/timeslot(15min)
         */
        /**
        * @type {PriceRule[]}
         */
        priceRules: [],
    },
    getters: {

    },
    actions: {
        ...createActionsWithInterceptors(Processor,
            {
                async [_Actions.LOAD_PRICE_RULES] ({ state, commit }, fieldId) {
                    if (state.id !== fieldId) {
                        commit(_Mutations.RESET)
                        commit(_Mutations.UPDATE_ID, fieldId)
                    }
                    const res = await ServiceField.getPriceRules(fieldId)
                    if (res.isSuccess()) {
                        commit(_Mutations.RESET_PRICE_RULES)
                        commit(_Mutations.LOAD_PRICE_RULES, res.data.datas)
                    }
                    return res
                },
                async [_Actions.UPDATE_PRICE_RULES] ({ dispatch, state }, { insertingRules, disableRuleIds }) {
                    const res = await ServiceField.updatePriceRules({ insertingRules, disableRuleIds })
                    if (res.isSuccess()) {
                        await dispatch(_Actions.LOAD_PRICE_RULES, state.id)
                    }
                    return res
                },
                async [_Actions.LOAD_BASE_PRICE] ({ state, commit }, fieldId) {
                    if (state.id !== fieldId) {
                        commit(_Mutations.RESET)
                        commit(_Mutations.UPDATE_ID, fieldId)
                    }
                    const res = await ServiceField.getBasePrice(fieldId)
                    if (res.isSuccess()) {
                        commit(_Mutations.UPDATE_BASE_PRICE, res.data)
                    }
                    return res
                },
                async [_Actions.UPDATE_BASE_PRICE] ({ commit }, basePrice) {
                    const res = await ServiceField.updateBasePrice(basePrice)
                    if (res.isSuccess()) {
                        commit(_Mutations.UPDATE_BASE_PRICE, basePrice)
                    }
                    return res
                },
            }),
    },
    mutations: {
        [_Mutations.RESET] (state) {
            state.id = undefined
            state.priceRules = []
        },
        [_Mutations.RESET_PRICE_RULES] (state) {
            state.priceRules = []
        },
        [_Mutations.UPDATE_ID] (state, id) {
            state.id = id
        },
        [_Mutations.UPDATE_BASE_PRICE] (state, basePrice) {
            state.basePrice = basePrice
        },
        ...createMutationBaseEntities('priceRules'),
    },
}
