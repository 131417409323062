export default class ModelBoost {
    constructor ({
        eventType,
    }) {
        Object.assign(this, {
            eventType,
        })
    }

    static fromGql (gql) {
        const {
            eventType,
        } = gql
        return new ModelBoost({
            eventType,
        })
    }

    static toGql () {
        return {}
    }
}
