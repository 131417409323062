import ServiceProduct from '@/services/ServiceProduct'
import {
    Mutations,
    _Actions,
    _Getters,
    _Mutations,
} from '@/store/modules/products/types'
import { rootActions } from '@/store/types'
import {
    Entities, isStadiumUser,
} from '@/utils/enum'
import {
    createActionsWithInterceptors,
    createMutationBaseEntities,
} from '@/utils/store-utils'
import Vue from 'vue'
import ActionProcessor from '@/utils/ActionProcessor'
import ServiceTxItem from '@/services/ServiceTxItem'
import { Mutations as MutationsCustomer } from '@/store/modules/customers/types'

class ActionProcessorImpl extends ActionProcessor {
    createInterceptors () {
        return []
    }
}

const Processors = new ActionProcessorImpl()

export default {
    namespaced: true,
    state: {
        products: [],
    },
    getters: {
        [_Getters.GET_PRODUCT]: (state) => (id) => state.products.find((e) => e.id === id),
    },
    actions: {
        ...createActionsWithInterceptors(Processors,
            {
                async [_Actions.LOAD_MY_PRODUCTS] ({
                    commit,
                    dispatch,
                    rootState,
                }, {
                    isPast,
                    page,
                    size,
                    start, end,
                    query,
                }) {
                    const res = isStadiumUser(rootState.auth.me)
                        ? await ServiceProduct.loadStadiumProducts(
                            rootState.stadium.id,
                            isPast,
                            page,
                            size,
                            start, end,
                            query,
                        )
                        : await ServiceProduct.loadManagerProducts(
                            rootState.auth.me.partyId,
                            isPast,
                            page,
                            size,
                            start, end,
                            query,
                        )
                    if (res.isSuccess()) {
                        const resField = await dispatch(
                            rootActions.LOAD_FIELDS_BY_IDS,
                            res.data.datas.map((boost) => boost.fieldId),
                            { root: true },
                        )
                        if (!resField.isSuccess()) {
                            return resField
                        }
                        commit(_Mutations.LOAD_PRODUCTS, res.data.datas)
                        return res
                    }
                    return res
                },
                async [_Actions.ADD_PRODUCT] ({
                    commit,
                }, { localCustomers, type, ...values }) {
                    const res = await ServiceProduct.addProduct(type, values, localCustomers)
                    if (res.isSuccess()) {
                        commit(_Mutations.ADD_PRODUCT, res.data)
                    }
                    return res
                },
                async [_Actions.UPDATE_PRODUCT] ({
                    commit,
                }, { type, ...values }) {
                    const res = await ServiceProduct.updateProduct(type, values)
                    if (res.isSuccess()) {
                        commit(_Mutations.UPDATE_PRODUCT, res.data)
                    }
                    return res
                },
                async [_Actions.DELETE_PRODUCT] ({
                    commit,
                }, { type, id }) {
                    const res = await ServiceProduct.deleteProduct(type, id)
                    if (res.isSuccess()) {
                        commit(_Mutations.DELETE_PRODUCT, id)
                    }
                    return res
                },
                async [_Actions.LOAD_PRODUCT_LOCAL_CUSTOMERS] ({ commit }, boostId) {
                    const res = await ServiceProduct.loadBoostLocalCustomers(boostId)
                    if (res.isSuccess()) {
                        commit(
                            _Mutations.LOAD_PRODUCT_LOCAL_CUSTOMERS,
                            { id: boostId, data: res.data },
                        )
                    }
                    return res
                },
                async [_Actions.ADD_PRODUCT_LOCAL_CUSTOMER] ({ commit, state }, {
                    boostId,
                    customer,
                    numberOfPeople,
                }) {
                    const res = await ServiceProduct.addBoostLocalCustomer(
                        state.id, boostId, customer, numberOfPeople,
                    )
                    if (res.isSuccess()) {
                        commit(_Mutations.ADD_PRODUCT_LOCAL_CUSTOMER, {
                            boostId,
                            customer: res.data,
                        })
                    }
                    return res
                },
                async [_Actions.LOAD_PRODUCT_TX_ITEM] ({ commit, rootState }, {
                    productId, page, size, isActive,
                }) {
                    const res = await ServiceProduct
                        .loadProductCustomersByUser(rootState.auth.me, {
                            productId, page, size, isActive,
                        })
                    if (res.isSuccess()) {
                        commit(_Mutations.LOAD_PRODUCT_TX_ITEM, { boostId: productId, txItems: res.data.datas })
                    }
                    return res
                },
                async [_Actions.UPDATE_PRODUCT_LOCAL_CUSTOMER] ({ commit }, { boostId, customer }) {
                    const res = await ServiceProduct.updateBoostLocalCustomer(boostId, customer)
                    if (res.isSuccess()) {
                        commit(
                            _Mutations.UPDATE_PRODUCT_LOCAL_CUSTOMER,
                            { boostId, customer: res.data },
                        )
                    }
                    return res
                },
                async [_Actions.UPDATE_PRODUCT_LOCAL_CUSTOMER_IS_PAID] (
                    { dispatch },
                    { boostId, customer },
                ) {
                    const res = await dispatch(
                        _Actions.UPDATE_PRODUCT_LOCAL_CUSTOMER,
                        { boostId, customer },
                    )
                    return res
                },
                async [_Actions.DELETE_PRODUCT_LOCAL_CUSTOMER] ({ commit },
                    { boostId, customerId }) {
                    const res = await ServiceProduct.deleteProductLocalCustomers(
                        boostId,
                        customerId,
                    )
                    if (res.isSuccess()) {
                        commit(_Mutations.DELETE_PRODUCT_LOCAL_CUSTOMER, { boostId, customerId })
                    }
                    return res
                },
                async [_Actions.CANCEL_PRODUCT_OFFER] ({ commit }, offerId) {
                    const res = await ServiceProduct.cancelOffer(offerId)
                    if (res.isSuccess()) {
                        commit(_Mutations.UPDATE_PRODUCT, res.data)
                    }
                    return res
                },
                async [_Actions.CANCEL_PRODUCT_TX_ITEM] ({ commit }, { productId, txItemId }) {
                    const res = await ServiceTxItem.cancel(txItemId)
                    if (res.isSuccess()) {
                        commit(_Mutations.UPDATE_PRODUCT_TX_ITEM, { productId, txItem: res.data })
                    }
                    return res
                },
                async [_Actions.CANCEL_PRODUCT] ({
                    commit,
                }, { type, id }) {
                    const res = await ServiceProduct.cancelProduct(type, id)
                    if (res.isSuccess()) {
                        commit(_Mutations.UPDATE_PRODUCT, res.data)
                    }
                    return res
                },
            }),
    },
    mutations: {
        [_Mutations.LOAD_PRODUCT_TX_ITEM] (state, { boostId, txItems }) {
            const idx = state.products.findIndex((item) => item.id === boostId)
            const oldTxItems = state.products[idx].txItems
            Vue.set(state.products[idx], 'txItems', oldTxItems ? [...oldTxItems, ...txItems] : [...txItems])
        },
        [_Mutations.UPDATE_PRODUCT_TX_ITEM] (state, { productId, txItem }) {
            const idx = state.products.findIndex((item) => item.id === productId)
            const boost = state.products[idx]
            const txItemsIdx = boost.txItems.findIndex((item) => item.id === txItem.id)
            Vue.set(
                boost.txItems,
                txItemsIdx,
                { ...boost.txItems[txItemsIdx], ...txItem },
            )
        },
        [_Mutations.LOAD_PRODUCT_LOCAL_CUSTOMERS] (state, { id, data }) {
            const idx = state.products.findIndex((item) => item.id === id)
            Vue.set(state.products[idx], 'localCustomers', [...data])

            this.commit(
                MutationsCustomer.LOAD_CUSTOMERS, [...data.map((e) => e.getCustomer())],
            )
        },
        [_Mutations.ADD_PRODUCT_LOCAL_CUSTOMER] (state, { boostId, customer }) {
            const idx = state.products.findIndex((item) => item.id === boostId)
            const boost = state.products[idx]
            state.products[idx].localCustomers = [customer, ...boost.localCustomers]
            this.commit(
                Mutations.UPDATE_PRODUCT, {
                    ...boost,
                    noCheckedOutItem: boost.noCheckedOutItem + customer.numberOfPeople,
                    ...customer.isPaid ? { noSoldItem: boost.noSoldItem + customer.numberOfPeople } : {},
                },
            )
        },
        [_Mutations.UPDATE_PRODUCT_LOCAL_CUSTOMER] (state, { boostId, customer }) {
            const idx = state.products.findIndex((item) => item.id === boostId)
            const boost = state.products[idx]
            const customerIdx = boost.localCustomers.findIndex((item) => item.id === customer.id)
            const oldCustomer = boost.localCustomers[customerIdx]
            const diffNumberOfPeople = customer.numberOfPeople - oldCustomer.numberOfPeople
            const isChangeIsPaid = oldCustomer.isPaid !== customer.isPaid
            const checking = isChangeIsPaid && customer.isPaid
            const unChecking = isChangeIsPaid && !customer.isPaid
            const newProduct = {
                ...boost,
                noCheckedOutItem: boost.noCheckedOutItem + diffNumberOfPeople,
                ...checking ? { noSoldItem: boost.noSoldItem + customer.numberOfPeople } : {},
                ...unChecking ? { noSoldItem: boost.noSoldItem - oldCustomer.numberOfPeople } : {},
                ...!isChangeIsPaid && customer.isPaid ? { noSoldItem: boost.noSoldItem + diffNumberOfPeople } : {},
            }
            this.commit(
                Mutations.UPDATE_PRODUCT, newProduct,
            )

            Vue.set(
                boost.localCustomers,
                customerIdx,
                { ...boost.localCustomers[customerIdx], ...customer },
            )
        },
        [_Mutations.DELETE_PRODUCT_LOCAL_CUSTOMER] (state, { boostId, customerId }) {
            const idx = state.products.findIndex((item) => item.id === boostId)
            const boost = state.products[idx]
            const customer = {
                ...boost.localCustomers.find(
                    (item) => item.id === customerId,
                ),
            }
            state.products[idx].localCustomers = state.products[idx].localCustomers.filter(
                (item) => item.id !== customerId,
            )
            this.commit(
                Mutations.UPDATE_PRODUCT, {
                    ...boost,
                    noCheckedOutItem: boost.noCheckedOutItem - customer.numberOfPeople,
                    ...customer.isPaid ? { noSoldItem: boost.noSoldItem - customer.numberOfPeople } : {},
                },
            )
        },
        ...createMutationBaseEntities(Entities.PRODUCTS),
    },
}
