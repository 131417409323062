import gql from 'graphql-tag'

// eslint-disable-next-line import/prefer-default-export
export const UserGeneralInfo = gql`
    fragment UserGeneralInfo on User {
        id
        username
        email
        firstName
        lastName
        tel
        address
        postalCode
        nickName
        note
        requireChangePasswd
        roles
        requiredAgreements {
          id
        }
    }
`

export const ManagerGeneralInfo = gql`
    fragment ManagerGeneralInfo on ManagerUser {
        id
        username
        email
        firstName
        lastName
        tel
        address
        postalCode
        nickName
        note
        requireChangePasswd
        partyIds
        requiredAgreements {
          id
        }
    }
`

export const CustomerGeneralInfo = gql`
    fragment CustomerGeneralInfo on Customer {
        id
        displayName
        username
        email
        firstName
        lastName
        tel
        nickName
        note
        profilePhotoUrl
    }
`

export const LocalCustomerGeneralInfo = gql`
    fragment LocalCustomerGeneralInfo on LocalCustomer {
        id
        email
        firstName
        lastName
        tel
        nickName
        note
        isPaid
    }
`

export const StadiumMemberGeneralInfo = gql`
fragment StadiumMemberGeneralInfo on StadiumMember {
    id
    name
    color
}`

export const LocalStadiumCustomerGeneralInfo = gql`
fragment LocalStadiumCustomerGeneralInfo on LocalStadiumCustomer {
    localStadiumCustomerId
    stadiumMembers{
        ...StadiumMemberGeneralInfo
    }
}
${StadiumMemberGeneralInfo}`
