import ModelBoost from '@/models/ModelBoost'
import ModelProduct from '@/models/ModelProduct'
import ModelProductField from '@/models/ModelProductField'

export default class ModelBoostBuffet {
    constructor (boostBuffet) {
        Object.assign(this, {
            ...new ModelProduct(boostBuffet),
            ...new ModelProductField(boostBuffet),
            ...new ModelBoost(boostBuffet),
        })
    }

    static fromGql (gql) {
        return new ModelBoostBuffet({
            ...ModelProduct.fromGql(gql),
            ...ModelProductField.fromGql(gql),
            ...ModelBoost.fromGql(gql),
        })
    }

    static toGql (model) {
        return {
            ...ModelProduct.toGql(model),
            ...ModelProductField.toGql(model),
            ...ModelBoost.toGql(model),
        }
    }
}
