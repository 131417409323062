/* eslint-disable max-len */
import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store'
import { Getters, rootActions } from '@/store/types'
import { isAdminUser, isManagerUser, isStadiumUser } from '@/utils/enum'
import Home from './views/Home.vue'
import ManagerHome from './views/ManagerHome.vue'
import AdminHome from './views/AdminHome.vue'

const withPrefix = (prefix, routes) => routes.map((route) => {
    const routeWithPrefix = route
    routeWithPrefix.path = prefix + route.path
    if (route.alias && route.alias.startsWith('/')) {
        routeWithPrefix.alias = route.alias
    } else {
        routeWithPrefix.alias = prefix + route.alias
    }

    return routeWithPrefix
})

Vue.use(Router)

const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        {
            path: '/',
            meta: { requiresAuth: true, requiresStadiumUser: true },
            component: Home,
            children: [
                {
                    path: '/chats',
                    component: () => import(/* webpackChunkName: "stadium" */ './views/PageStadiumChats.vue'),
                },
                {
                    path: '/chats/:id',
                    component: () => import(/* webpackChunkName: "stadium" */ './views/PageStadiumChat.vue'),
                    props: (route) => ({ id: route.params.id }),
                },
                ...withPrefix('stadium', [
                    {
                        alias: '/',
                        path: '/dashboard',
                        component: () => import(/* webpackChunkName: "stadium" */ './views/StadiumDashboard.vue'),
                    },
                    {
                        path: '/info',
                        component: () => import(/* webpackChunkName: "stadium" */ './views/StadiumInfo.vue'),
                    },
                    // {
                    //     path: '/holiday',
                    //     component: () => import(/* webpackChunkName: "stadium" */ './views/StadiumHoliday.vue'),
                    // },
                    {
                        path: '/fields',
                        component: () => import(/* webpackChunkName: "stadium" */ './views/PageStadiumFields.vue'),
                    },
                    {
                        path: '/boosts',
                        component: () => import(/* webpackChunkName: "stadium" */ './views/PageStadiumBoosts.vue'),
                    },
                ]),
                ...withPrefix('code', [
                    {
                        alias: '',
                        path: '/use',
                        component: () => import(/* webpackChunkName: "code" */ './views/UseCode.vue'),
                        props: (route) => ({ code: route.query.code }),
                    },
                ]),
                ...withPrefix('report', [
                    {
                        alias: '',
                        path: '/sales',
                        component: () => import(/* webpackChunkName: "stadium" */ './views/ReportSales.vue'),
                    },
                    {
                        path: '/financial',
                        component: () => import(/* webpackChunkName: "report" */ './views/ReportFinancial.vue'),
                    },
                ]),
                ...withPrefix('customers', [
                    {
                        alias: '',
                        path: '/',
                        component: () => import(/* webpackChunkName: "stadium" */ './views/Customers.vue'),
                    },
                    {
                        path: '/member-types',
                        component: () => import(/* webpackChunkName: "stadium" */ './views/PageMemberTypes.vue'),
                    },
                ]),
                ...withPrefix('employees', [
                    {
                        alias: '',
                        path: '/',
                        component: () => import(/* webpackChunkName: "stadium" */ './views/EmployeesMy.vue'),
                    },
                    {
                        path: '/permission',
                        component: () => import(/* webpackChunkName: "stadium" */ './views/Permission.vue'),
                    },
                ]),
                ...withPrefix('me', [
                    {
                        alias: '',
                        path: '/',
                        component: () => import(/* webpackChunkName: "stadium" */ './views/PageMe.vue'),
                    },
                    {
                        path: '/change-password',
                        component: () => import(/* webpackChunkName: "stadium" */ './views/PasswordChange.vue'),
                    },
                ]),
            ],
        },
        {
            path: '/admin',
            meta: { requiresAuth: true, requiresAdmin: true },
            component: AdminHome,
            children: [
                ...withPrefix('stadium', [
                    {
                        path: '/',
                        alias: '/',
                        component: () => import(/* webpackChunkName: "admin" */ './views/AdminStadium.vue'),
                    },
                    {
                        path: '/sports',
                        component: () => import(/* webpackChunkName: "admin" */ './views/AdminSport.vue'),
                    },
                ]),
                ...withPrefix('manager', [
                    {
                        path: '/',
                        alias: '',
                        component: () => import(/* webpackChunkName: "admin" */ './views/AdminManager.vue'),
                    },
                ]),
                ...withPrefix('discount', [
                    {
                        path: '/discount-codes',
                        alias: '',
                        component: () => import(/* webpackChunkName: "admin" */ './views/AdminDiscountCodes.vue'),
                    },
                    {
                        path: '/coupons',
                        component: () => import(/* webpackChunkName: "admin" */ './views/AdminCoupons.vue'),
                    },
                ]),
                ...withPrefix('discount-for-sale', [
                    {
                        path: '/bundle-coupons',
                        alias: '',
                        component: () => import(/* webpackChunkName: "admin" */ './views/AdminBundleCoupons.vue'),
                    },
                    {
                        path: '/coupon-for-bundles',
                        component: () => import(/* webpackChunkName: "admin" */ './views/AdminCouponForBundles.vue'),
                    },
                ]),
                ...withPrefix('referral', [
                    {
                        path: '/referral-program',
                        alias: '',
                        component: () => import(/* webpackChunkName: "admin" */ './views/AdminReferralProgram.vue'),
                    },
                    {
                        path: '/referral-promotions',
                        component: () => import(/* webpackChunkName: "admin" */ './views/AdminReferralPromotions.vue'),
                    },
                ]),
                ...withPrefix('cashback', [
                    {
                        path: '/earning-cashback-programs',
                        alias: '',
                        component: () => import(/* webpackChunkName: "admin" */ './views/AdminCashbackPrograms.vue'),
                    },
                    {
                        path: '/applicable-cashback-programs',
                        component: () => import(/* webpackChunkName: "admin" */ './views/AdminApplicableCashbackPrograms.vue'),
                    },
                ]),
                ...withPrefix('banner', [
                    {
                        path: '/banner-lists',
                        alias: '',
                        component: () => import(/* webpackChunkName: "admin" */ './views/AdminBanners.vue'),
                    },
                    {
                        path: '/banner-list-items',
                        component: () => import(/* webpackChunkName: "admin" */ './views/PageAdminBannerMedias.vue'),
                    },
                ]),
            ],
        },
        {
            path: '/manager',
            meta: { requiresAuth: true, requiresManagerUser: true },
            component: ManagerHome,
            children: [
                ...withPrefix('party', [
                    {
                        path: '/',
                        alias: '',
                        component: () => import(/* webpackChunkName: "manager" */ './views/ManagerParty.vue'),
                    },
                    {
                        path: '/partyEvent',
                        component: () => import(/* webpackChunkName: "manager" */ './views/PageManagerPartyEvents.vue'),
                    },
                ]),
                ...withPrefix('me', [
                    {
                        path: '/',
                        component: () => import(/* webpackChunkName: "manager" */ './views/PageMe.vue'),
                    },
                    {
                        path: '/change-password',
                        component: () => import(/* webpackChunkName: "manager" */ './views/PasswordChange.vue'),
                    },
                ]),
                ...withPrefix('code', [
                    {
                        path: '/use',
                        alias: '',
                        component: () => import(/* webpackChunkName: "code" */ './views/UseCode.vue'),
                        props: (route) => ({ code: route.query.code }),
                    },
                ]),
            ],
        },
        {
            path: '/get-started-manager',
            name: 'get-started-manager',
            meta: { requiresAuth: true, requiresManagerUser: true },
            component: () => import(/* webpackChunkName: "manager" */ './views/ManagerGetStarted.vue'),
        },
        {
            path: '/about',
            name: 'about',
            // route level code-splitting
            // this generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () => import(/* webpackChunkName: "about" */ './views/About.vue'),
        },
        {
            path: '/login',
            name: 'login',
            meta: { requiresNotAuth: true },
            component: () => import(/* webpackChunkName: "login" */ './views/Login.vue'),
        },
        {
            path: '/forgot-password',
            name: 'forgot-password',
            meta: { requiresNotAuth: true },
            component: () => import(/* webpackChunkName: "forgot-password" */ './views/ForgotPassword.vue'),
        },
        // {
        //     path: '/reset-password',
        //     name: 'reset-password',
        //     component: () => import(/* webpackChunkName: "forgot-password" */ './views/ResetPassword.vue'),
        //     props: (route) => ({ token: route.query.token, userId: route.query.user_id }),
        // },
        {
            path: '/require-change-password',
            meta: { requiresAuth: true },
            name: 'require-change-password',
            component: () => import(/* webpackChunkName: "forgot-password" */ './views/RequireChangePassword.vue'),
        },
        {
            path: '*',
            redirect: '/',
        },
    ],
})

function isPassRequirement (user, pathToGo) {
    const isRequiresAdmin = pathToGo.matched.some((record) => record.meta.requiresAdmin)
    const isRequiresStadiumUser = pathToGo.matched.some((record) => record.meta.requiresStadiumUser)
    const isRequiresManagerUser = pathToGo.matched.some((record) => record.meta.requiresManagerUser)
    if (isRequiresAdmin && !isAdminUser(user)) return false
    if (isRequiresStadiumUser && !isStadiumUser(user)) return false
    if (isRequiresManagerUser && !isManagerUser(user)) return false
    return true
}

async function isLoginSuccess () {
    const res = await store.dispatch(rootActions.IS_LOGIN)
    return res.isSuccess() && res.data === true
}

function getDefaultPath (user) {
    if (isStadiumUser(user)) {
        return '/'
    }
    if (isAdminUser(user)) {
        return '/admin'
    }
    if (isManagerUser(user)) {
        return '/manager'
    }
    return '/'
}

async function handleManagerUser (me, to, next) {
    await store.dispatch(rootActions.LOAD_MY_PARTY)
    const myParty = store.getters[Getters.partys.GET_PARTY](me.partyId)
    if (!myParty && to.name !== 'get-started-manager') next('/get-started-manager')
    else if (myParty && to.name === 'get-started-manager') next('/')
    else next()
}

async function handleRequiredAuthRoute (to, next) {
    const isLogin = await isLoginSuccess()
    if (isLogin) {
        const { me } = store.state.auth
        if (me.requireChangePasswd) {
            if (to.name !== 'require-change-password') {
                next({ name: 'require-change-password' })
            } else {
                next()
            }
        } else if (!isPassRequirement(me, to)) {
            next(getDefaultPath(me))
        } else if (isManagerUser(me)) {
            handleManagerUser(me, to, next)
        } else {
            next()
        }
    } else {
        next({
            path: '/login',
            query: { redirect: to.fullPath },
        })
    }
}

router.beforeEach(async (to, _from, next) => {
    const isRequiresAuth = to.matched.some((record) => record.meta.requiresAuth)
    const isRequiresNotLogin = to.matched.some((record) => record.meta.requiresNotAuth)

    if (isRequiresAuth) {
        handleRequiredAuthRoute(to, next)
        return
    }
    if (isRequiresNotLogin) {
        if (await isLoginSuccess()) next('/')
        else next()
    } else {
        next()
    }
})

export default router
