import moment from 'moment'

export default class ModelBannerMedia {
    constructor (model) {
        Object.assign(this, model)
    }

    static fromGql (gql) {
        const {
            _id,
            startedOn,
            endedOn,
            imageUrl,
            gaAdPlatform,
            gaAdSource,
            gaAdFormat,
            gaAdUnitName,
            gaCurrency,
            gaValue,
            linkUrl,
        } = gql
        return new ModelBannerMedia({
            id: _id,
            imageUrl,
            start: startedOn ? moment(startedOn) : null,
            end: endedOn ? moment(endedOn) : null,
            gaAdPlatform,
            gaAdSource,
            gaAdFormat,
            gaAdUnitName,
            gaCurrency,
            gaValue,
            linkUrl,
        })
    }

    static toGql (model) {
        const {
            start,
            end,
            imageUrl,
            gaAdPlatform,
            gaAdSource,
            gaAdFormat,
            gaAdUnitName,
            gaCurrency,
            gaValue,
            linkUrl,
        } = model

        return {
            imageUrl,
            ...start ? { startedOn: start.format() } : {},
            ...end ? { endedOn: end.format() } : {},
            gaAdPlatform,
            gaAdSource,
            gaAdFormat,
            gaAdUnitName,
            gaCurrency,
            gaValue,
            linkUrl,
            ...gaAdUnitName ? {
                gaAdPlatform: 'Lenkila Admin',
                gaCurrency: 'THB',
            } : {},
        }
    }
}
