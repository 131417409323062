import ModelSport from '@/models/ModelSport'
import moment from 'moment'

export default class ModelField {
    constructor ({
        id,
        name,
        description,
        minMaxPeople,
        dividable,
        sportId,
        openTime,
        closeTime,
        coverPhotoUrl,
        stadiumId,
        isOfficial,
        /**
         * @typedef {Object} CreatedByUser
         * @prop {Sintrg} id
         */
        createdByUser,
        sport,
    }) {
        Object.assign(this, {
            id,
            name,
            description,
            minMaxPeople,
            dividable,
            sportId,
            openTime,
            closeTime,
            coverPhotoUrl,
            stadiumId,
            isOfficial,
            createdByUser,
            sport,
        })
    }

    static fromGql (gql) {
        const {
            id,
            name,
            desc,
            minPeople,
            maxPeople,
            devidable,
            sportId,
            openTime,
            closeTime,
            coverPhotoUrl,
            stadiumId,
            isOfficial,
            createdByUser,
            sport,
        } = gql
        return new ModelField({
            id,
            name,
            description: desc,
            minMaxPeople: minPeople && maxPeople ? [minPeople, maxPeople] : null,
            dividable: devidable,
            sportId,
            openTime: openTime ? moment(openTime, 'HH:mm') : null,
            closeTime: closeTime ? moment(closeTime, 'HH:mm') : null,
            coverPhotoUrl,
            stadiumId,
            isOfficial,
            createdByUser,
            sport: sport ? ModelSport.fromGql(sport) : null,
        })
    }

    toGql () {
        const {
            name,
            description,
            minMaxPeople,
            dividable,
            sportId,
            openTime,
            closeTime,
        } = this
        return {
            name,
            desc: description,
            minPeople: minMaxPeople ? minMaxPeople[0] : null,
            maxPeople: minMaxPeople ? minMaxPeople[1] : null,
            devidable: dividable,
            sportId,
            openTime: openTime ? openTime.format('HH:mm') : null,
            closeTime: closeTime ? closeTime.format('HH:mm') : null,
        }
    }
}
