import VApiResponse from '@/utils/VApiResponse'
import {
    ApiManager,
} from '@/api'
import {
    updateEntityCallbacks,
} from '@/utils/UtilsApi'
import ApiPartyEvent from '@/api/ApiPartyEvent'

export default {
    loadManagers (page, size) {
        return VApiResponse.CONVERT(
            ApiManager.loadManagers(page, size),
        )
    },
    deleteManager (id) {
        return VApiResponse.CONVERT(
            ApiManager.deleteManager(id),
        )
    },
    addManager (manager) {
        return VApiResponse.CONVERT(
            ApiManager.addManager(manager),
        )
    },
    updateManager (manager) {
        return VApiResponse.CONVERT(
            ApiManager.updateManager(manager),
        )
    },
    getPartyByUserId (userId) {
        return VApiResponse.CONVERT(
            ApiManager.getPartyByUserId(userId),
        )
    },
    loadPartyById (id) {
        if (id) return ApiManager.loadPartyById(id)
        return VApiResponse.SUCCESS(null)
    },
    async updateParty (party) {
        const res = await ApiManager.updateParty(party)
        if (res.isSuccess()) {
            return this.updatePartyCallback(res, party)
        }
        return res
    },
    async addParty (party) {
        const res = await ApiManager.addParty(party)
        if (res.isSuccess()) {
            return this.updatePartyCallback(res, party)
        }
        return res
    },
    loadPartyEvents (partyId, isPast, page, size, start, end) {
        return VApiResponse.CONVERT(
            ApiPartyEvent.loadManagerPartyEvents(partyId, isPast, page, size, start, end),
        )
    },
    addEvent (event) {
        return VApiResponse.CONVERT(
            ApiManager.addPartyEvent(event),
        )
    },
    deleteEvent (eventId) {
        return VApiResponse.CONVERT(
            ApiManager.deletePartyEvent(eventId),
        )
    },
    updateEvent (stadiumId, event) {
        return VApiResponse.CONVERT(
            ApiManager.updatePartyEvent(stadiumId, event),
        )
    },
    loadBoostCustomers (boostId) {
        return VApiResponse.CONVERT(
            ApiManager.loadBoostCustomers(boostId),
        )
    },
    addBoostLocalCustomer (stadiumId, boostId, customer, numberOfPeople) {
        return VApiResponse.CONVERT(
            ApiManager.addBoostLocalCustomer(stadiumId, boostId, customer, numberOfPeople),
        )
    },
    loadBoostLocalCustomers (boostId) {
        return VApiResponse.CONVERT(
            ApiManager.loadBoostLocalCustomers(boostId),
        )
    },
    updateBoostLocalCustomer (boostId, customer) {
        return VApiResponse.CONVERT(
            ApiManager.updateBoostLocalCustomer(boostId, customer),
        )
    },
    deleteProductLocalCustomers (boostId, customerId) {
        return VApiResponse.CONVERT(
            ApiManager.deleteProductLocalCustomers(boostId, customerId),
        )
    },
    async updatePartyCallback (res, party) {
        return updateEntityCallbacks(
            res,
            ApiManager.loadPartyById,
            [
                () => (party.coverPhoto
                    ? ApiManager.updateCoverParty({ ...res.data, ...party }) : null),
                () => (party.logoPhoto
                    ? ApiManager.updateLogoParty({ ...res.data, ...party }) : null),
            ],
        )
    },
}
