import VApiResponse from '@/utils/VApiResponse'
import axiosClient from '@/axiosClient'
import apolloClient from '@/apolloClient'
import gql from 'graphql-tag'
import ModelMe from '@/models/ModelMe'
import { isManagerUser, UserType } from '@/utils/enum'
import ApiManager from '@/api/ApiManager'
import { UserGeneralInfo } from '@/api/graphql/fragments/user'
import ApiEmployee from './ApiEmployee'

export default {
    async login (username, password) {
        try {
            const res = await axiosClient.post('/auth/signin', { username, password })
            return VApiResponse.SUCCESS(res.data)
        } catch (e) {
            const { error } = e.response.data
            return error.code === 'INVALID_LOGIN_DATA'
                ? VApiResponse.ERROR(error, 'ชื่อผู้ใช้ หรือ รหัสผ่านไม่ถูกต้อง')
                : VApiResponse.ERROR(error, error.message)
        }
    },
    async logout () {
        try {
            const res = await axiosClient.post('/auth/signout')
            return VApiResponse.SUCCESS(res.data)
        } catch (error) {
            return VApiResponse.ERROR(error)
        }
    },
    async isLogin () {
        const res = await this.getMe()
        const isLogin = res.isSuccess()
        return new VApiResponse({ ...res, data: isLogin })
    },
    resetPassword (user, newPassword) {
        const updatedInfo = { id: user.id, password: newPassword, requireChangePasswd: false }
        if (isManagerUser(user)) {
            return ApiManager.updateManager(
                updatedInfo,
            )
        }
        return ApiEmployee.updateEmployee(
            updatedInfo,
        )
    },
    async changePassword (user, currentPassword, newPassword) {
        const path = isManagerUser(user) ? '/managerUser/me/changePassword' : '/stadiumUser/me/changePassword'
        try {
            const res = await axiosClient.post(
                path, {
                    oldPassword: currentPassword,
                    newPassword,
                    newPasswordVerification: newPassword,
                },
            )
            return VApiResponse.SUCCESS(res.data)
        } catch (e) {
            const { error } = e.response.data
            return error.code === 'INCORRECT_OLD_PASSWORD'
                ? VApiResponse.ERROR(error, 'ใส่รหัสผ่านปัจจุบันไม่ถูกต้อง')
                : VApiResponse.ERROR(error, error.message)
        }
    },
    async getMe () {
        try {
            const { data, errors } = await apolloClient.query({
                query: gql`
                    query me{
                        me{
                            ...UserGeneralInfo
                            ... on StadiumUser{
                                stadiumUserRoles {
                                    stadiumId
                                    role
                                }
                            }
                            ... on AdminUser{
                                adminRole
                                username
                            }
                            ... on ManagerUser{
                                partyIds
                            }
                        }
                    }
                    ${UserGeneralInfo}
                `,
            })
            if (errors) return VApiResponse.ERROR(errors)
            const me = ModelMe.fromGql(data.me)
            const isValidUserType = me.roles.filter(
                (role) => [
                    UserType.ADMIN_USER,
                    UserType.STADIUM_USER,
                    UserType.MANAGER_USER]
                    .includes(role),
            ).length !== 0
            if (!isValidUserType) { return VApiResponse.ERROR(data, 'You cannot use lenkila mobile account with stadium system.') }
            return VApiResponse.SUCCESS(me)
        } catch (e) {
            return VApiResponse.ERROR(e)
        }
    },
    updateAgreementPermission (id, isAccepted) {
        return VApiResponse.convertGql(
            apolloClient.mutate({
                mutation: gql`
                mutation updateAgreementPermission($id: ObjectId!,$isAccepted:Boolean!){
                    meAckToAgreement(agreementAcceptanceId: $id,isAccepted: $isAccepted){
                        id
                    }
                }
                `,
                variables: {
                    id,
                    isAccepted,
                },
            }),
            (data) => data,
        )
    },
}
