import { ManagerPartyEventGeneralInfo } from '@/api/graphql/fragments/event'
import { PageGeneralInfo } from '@/api/graphql/fragments/page'
import apolloClient from '@/apolloClient'
import ModelPagination from '@/models/ModelPagination'
import ModelManagerPartyEvent from '@/models/ModelManagerPartyEvent'
import { createIsExpiredQuery, createIsNotExpiredQuery } from '@/utils/query'
import VApiResponse from '@/utils/VApiResponse'
import gql from 'graphql-tag'

export default {
    async addManagerPartyEvent (partyEvent) {
        return VApiResponse.convertGql(apolloClient.mutate({
            mutation: gql`
                mutation createManagerPartyEvent($partyEvent:ManagerPartyEventBuffetInput!){
                    insertManagerPartyEventBuffet(data:$partyEvent) {
                        ...ManagerPartyEventGeneralInfo
                    }
                }
                ${ManagerPartyEventGeneralInfo}
                `,
            variables: {
                partyEvent: { ...ModelManagerPartyEvent.toGql(partyEvent) },
            },
        }),
        (data) => ModelManagerPartyEvent.fromGql(data.insertManagerPartyEventBuffet))
    },
    async addManagerPartyEventTraining (partyEvent) {
        return VApiResponse.convertGql(apolloClient.mutate({
            mutation: gql`
                mutation createManagerPartyEventTraining($partyEvent:ManagerPartyEventTrainingInput!){
                    insertManagerPartyEventTraining(data:$partyEvent) {
                        ...ManagerPartyEventGeneralInfo
                    }
                }
                ${ManagerPartyEventGeneralInfo}
                `,
            variables: {
                partyEvent: { ...ModelManagerPartyEvent.toGql(partyEvent) },
            },
        }),
        (data) => ModelManagerPartyEvent.fromGql(data.insertManagerPartyEventTraining))
    },
    async addManagerPartyEventTournament (partyEvent) {
        return VApiResponse.convertGql(apolloClient.mutate({
            mutation: gql`
                mutation createManagerPartyEventTournament($partyEvent:ManagerPartyEventTournamentInput!){
                    insertManagerPartyEventTournament(data:$partyEvent) {
                        ...ManagerPartyEventGeneralInfo
                    }
                }
                ${ManagerPartyEventGeneralInfo}
                `,
            variables: {
                partyEvent: { ...ModelManagerPartyEvent.toGql(partyEvent) },
            },
        }),
        (data) => ModelManagerPartyEvent.fromGql(data.insertManagerPartyEventTournament))
    },
    async loadManagerPartyEvents (partyId, isPast, page, size, start, end) {
        let isPastQuery = {}
        if (isPast != null) {
            isPastQuery = isPast ? createIsExpiredQuery() : createIsNotExpiredQuery()
        }

        const sortQuery = {
            start: isPast ? -1 : 1,
        }

        return VApiResponse.convertGql(apolloClient.query({
            query: gql`
            query getManagerPartyEvents($query:QueryObject!,$page:Int,$size:Int,$sort:QueryObject!){
                managerPartyEventsPagination(query: $query,page:$page,size:$size,sort:$sort) {
                    datas{
                        ...ManagerPartyEventGeneralInfo
                    }
                    pageInfo{
                        ...PageGeneralInfo
                    }
                }
            }
            ${ManagerPartyEventGeneralInfo}
            ${PageGeneralInfo}
            `,
            variables: {
                query: {
                    partyId: { $oid: partyId },
                    ...isPastQuery,
                    ...start ? {
                        start: {
                            $gte: {
                                $date: start.valueOf(),
                            },
                            ...end ? {
                                $lt: {
                                    $date: end.valueOf(),
                                },
                            } : {},
                        },
                    } : {},
                },
                // page start at 0 on server
                page: page - 1,
                size,
                sort: { ...sortQuery },
            },
        }),
        (data) => ModelPagination.fromGql(data.managerPartyEventsPagination))
    },
    async updateManagerPartyEvent (partyEvent) {
        return VApiResponse.convertGql(apolloClient.mutate({
            mutation: gql`
            mutation updateManagerPartyEvent($id:ObjectId,$partyEvent: ManagerPartyEventBuffetInput!){
                updateManagerPartyEventBuffet(id:$id,data:$partyEvent) {
                    ...ManagerPartyEventGeneralInfo
                }
            }
            ${ManagerPartyEventGeneralInfo}
            `,
            variables: {
                id: partyEvent.id,
                partyEvent: ModelManagerPartyEvent.toGql(partyEvent),
            },
        }),
        (data) => ModelManagerPartyEvent.fromGql(data.updateManagerPartyEventBuffet))
    },
    async updateManagerPartyEventTraining (partyEvent) {
        return VApiResponse.convertGql(apolloClient.mutate({
            mutation: gql`
            mutation updateManagerPartyEventTraining($id:ObjectId,$partyEvent: ManagerPartyEventTrainingInput!){
                updateManagerPartyEventTraining(id:$id,data:$partyEvent) {
                    ...ManagerPartyEventGeneralInfo
                }
            }
            ${ManagerPartyEventGeneralInfo}
            `,
            variables: {
                id: partyEvent.id,
                partyEvent: ModelManagerPartyEvent.toGql(partyEvent),
            },
        }),
        (data) => ModelManagerPartyEvent.fromGql(data.updateManagerPartyEventTraining))
    },
    async updateManagerPartyEventTournament (partyEvent) {
        return VApiResponse.convertGql(apolloClient.mutate({
            mutation: gql`
            mutation updateManagerPartyEventTournament($id:ObjectId,$partyEvent: ManagerPartyEventTournamentInput!){
                updateManagerPartyEventTournament(id:$id,data:$partyEvent) {
                    ...ManagerPartyEventGeneralInfo
                }
            }
            ${ManagerPartyEventGeneralInfo}
            `,
            variables: {
                id: partyEvent.id,
                partyEvent: ModelManagerPartyEvent.toGql(partyEvent),
            },
        }),
        (data) => ModelManagerPartyEvent.fromGql(data.updateManagerPartyEventTournament))
    },
    async deleteManagerPartyEvent (partyEventId) {
        return VApiResponse.convertGql(apolloClient.mutate({
            mutation: gql`
            mutation deleteManagerPartyEvent($id:ObjectId){
                deleteManagerPartyEvent(id:$id)
            }
            `,
            variables: {
                id: partyEventId,
            },
        }))
    },
}
