import ActionProcessor from '@/utils/ActionProcessor'
import { createActionsWithInterceptors, createMutationBaseEntities } from '@/utils/store-utils'
import { _Actions, _Mutations, _Getters } from '@/store/modules/banner/types'
import ServiceBanner from '@/services/ServiceBanner'
import { Entities } from '@/utils/enum'

class ActionProcessorImpl extends ActionProcessor {
    createInterceptors () {
        return []
    }
}

const Processors = new ActionProcessorImpl()
export default {
    namespaced: true,
    state: {
        banners: [],
        bannerMedias: [],
    },
    getters: {
        [_Getters.GET_BANNER_MEDIA]:
            (state) => (id) => state.bannerMedias.find((discountCode) => discountCode.id === id),
        [_Getters.GET_BANNER]:
            (state) => (id) => state.banners.find((discountCode) => discountCode.id === id),
    },
    actions: {
        ...createActionsWithInterceptors(Processors,
            {
                async [_Actions.LOAD_BANNER_MEDIAS] ({ commit }, { isPast, page, size }) {
                    const res = await ServiceBanner.loadBannerMedias({ isPast, page, size })
                    if (res.isSuccess()) {
                        commit(_Mutations.LOAD_BANNER_MEDIAS, res.data.datas)
                    }
                    return res
                },
                async [_Actions.ADD_BANNER_MEDIA] ({ commit }, discountCode) {
                    const res = await ServiceBanner.addBannerMedia(discountCode)
                    if (res.isSuccess()) {
                        commit(_Mutations.ADD_BANNER_MEDIA, res.data)
                    }
                    return res
                },
                async [_Actions.UPDATE_BANNER_MEDIA] ({ commit }, discountCode) {
                    const res = await ServiceBanner.updateBannerMedia(discountCode)
                    if (res.isSuccess()) {
                        commit(_Mutations.UPDATE_BANNER_MEDIA, res.data)
                    }
                    return res
                },
                async [_Actions.DELETE_BANNER_MEDIA] ({ commit }, id) {
                    const res = await ServiceBanner.deleteBannerMedia(id)
                    if (res.isSuccess()) {
                        commit(_Mutations.DELETE_BANNER_MEDIA, id)
                    }
                    return res
                },
                async [_Actions.LOAD_MAIN_BANNER] ({ commit, dispatch }) {
                    const res = await ServiceBanner.loadMainBanner()
                    if (res.isSuccess()) {
                        commit(_Mutations.LOAD_BANNERS, [res.data])
                        dispatch(_Actions.LOAD_BANNER_MEDIAS, { page: 1, size: 10 })
                    }
                    return res
                },
                async [_Actions.UPDATE_BANNER] ({ commit }, discountCode) {
                    const res = await ServiceBanner.updateBanner(discountCode)
                    if (res.isSuccess()) {
                        commit(_Mutations.UPDATE_BANNER, res.data)
                    }
                    return res
                },
            }),
    },
    mutations: {
        ...createMutationBaseEntities(Entities.BANNER_MEDIAS),
        ...createMutationBaseEntities(Entities.BANNERS),
    },
}
