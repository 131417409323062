import { createTypes, createTypesWithNamespace } from '@/utils/store-utils'

const _namespace = 'shop'

const types = {
    /**
     * @typedef {Object} Actions
     * @prop {String} LOAD_COUPON_FOR_BUNDLES
     * @prop {String} ADD_COUPON_FOR_BUNDLE
     * @prop {String} UPDATE_COUPON_FOR_BUNDLE
     * @prop {String} DELETE_COUPON_FOR_BUNDLE
     * @prop {String} LOAD_BUNDLE_COUPONS
     * @prop {String} ADD_BUNDLE_COUPON
     * @prop {String} UPDATE_BUNDLE_COUPON
     * @prop {String} DELETE_BUNDLE_COUPON
     */
    Actions: [
        'LOAD_COUPON_FOR_BUNDLES',
        'ADD_COUPON_FOR_BUNDLE',
        'UPDATE_COUPON_FOR_BUNDLE',
        'DELETE_COUPON_FOR_BUNDLE',
        'LOAD_BUNDLE_COUPONS',
        'ADD_BUNDLE_COUPON',
        'UPDATE_BUNDLE_COUPON',
        'DELETE_BUNDLE_COUPON',
    ],
    /**
     * @typedef {Object} Mutations
     * @prop {String} LOAD_COUPON_FOR_BUNDLES
     * @prop {String} ADD_COUPON_FOR_BUNDLE
     * @prop {String} UPDATE_COUPON_FOR_BUNDLE
     * @prop {String} DELETE_COUPON_FOR_BUNDLE
     * @prop {String} LOAD_BUNDLE_COUPONS
     * @prop {String} ADD_BUNDLE_COUPON
     * @prop {String} UPDATE_BUNDLE_COUPON
     * @prop {String} DELETE_BUNDLE_COUPON
     */
    Mutations: [
        'LOAD_COUPON_FOR_BUNDLES',
        'ADD_COUPON_FOR_BUNDLE',
        'UPDATE_COUPON_FOR_BUNDLE',
        'DELETE_COUPON_FOR_BUNDLE',
        'LOAD_BUNDLE_COUPONS',
        'ADD_BUNDLE_COUPON',
        'UPDATE_BUNDLE_COUPON',
        'DELETE_BUNDLE_COUPON',
    ],
    /**
     * @typedef {Object} Getters
     * @prop {String} GET_COUPON_FOR_BUNDLE
     * @prop {String} GET_BUNDLE_COUPON
     */
    Getters: [
        'GET_COUPON_FOR_BUNDLE',
        'GET_BUNDLE_COUPON',
    ],
}
/**
 * @typedef {Object} Types
 * @prop {Actions} Actions
 * @prop {Mutations} Mutations
 * @prop {Getters} Getters
 */
/**
 * @type {Types} types
 */
const { Actions, Getters } = createTypesWithNamespace(_namespace, types)

/**
 * @type {Types} types
 */
const { Actions: _Actions, Mutations: _Mutations, Getters: _Getters } = createTypes(types)

const Entities = {
}

export {
    Actions, Getters, _Mutations, _Actions, _Getters, Entities,
}
