import {
    _Mutations,
} from '@/store/modules/stadium/types'
import { Entities, VStatus } from '@/utils/enum'
import ActionInterceptor from '@/utils/ActionInterceptor'

export default class UpdateStatusInterceptor extends ActionInterceptor {
    isMutateEntities () {
        let result = false
        Object.keys(Entities).forEach((entities) => {
            const entity = entities.substring(0, entities.length - 1)
            if (this.actionName.includes(entity)) result = true
        })
        return result
    }

    getMutatedEntity () {
        let result
        Object.keys(Entities).forEach((entities) => {
            const entity = entities.substring(0, entities.length - 1)
            if (this.actionName.includes(entity)) result = entities.toLowerCase()
        })
        return result
    }

    before () {
        if (this.isMutateEntities()) {
            this.commit(_Mutations.UPDATE_STATUS,
                { entity: this.getMutatedEntity(), status: VStatus.PROCESSING })
        }
    }

    after () {
        if (this.isMutateEntities()) {
            this.commit(_Mutations.UPDATE_STATUS,
                { entity: this.getMutatedEntity(), status: VStatus.NONE })
        }
    }
}
