import ActionProcessor from '@/utils/ActionProcessor'
import { createActionsWithInterceptors, createMutationBaseEntities } from '@/utils/store-utils'
import ServiceEmployee from '@/services/ServiceEmployee'
import { _Mutations, _Actions, _Getters } from './types'

const Entity = {
    EMPLOYEES: 'employees',
}

class ActionProcessorImpl extends ActionProcessor {
    createInterceptors () {
        return []
    }
}

const Processor = new ActionProcessorImpl()

export default {
    namespaced: true,
    state: {
        /**
         * @typedef {Object} Employee
         * @prop {Number} gender 1 : male, 2 : female
         * @prop {String} firstName
         * @prop {String} lastName
         * @prop {String} nickName
         * @prop {String} tel
         * @prop {String} email
         * @prop {MomentDate} dateOfBirth
         * @prop {String} carrier
         * @prop {String} address
         * @prop {String} postalCode
         * @prop {String} note
         * @prop {String} role
         * @prop {MomentDate} registerDate
         * @prop {String} stadiumId
         */
        /**
         * @type {Employee[]}
         */
        employees: [],
    },
    getters: {
        [_Getters.GET_EMPLOYEE]: (state) => (id) => state.employees.find((item) => item.id === id),
    },
    actions: {
        ...createActionsWithInterceptors(Processor,
            {
                async [_Actions.LOAD_EMPLOYEES] ({ commit }, { stadiumId, page, size }) {
                    const res = await ServiceEmployee.loadEmployees({ stadiumId, page, size })
                    if (res.isSuccess()) {
                        commit(_Mutations.LOAD_EMPLOYEES, res.data.datas)
                    }
                    return res
                },
                async [_Actions.DELETE_EMPLOYEE] ({ commit, rootState }, id) {
                    const res = await ServiceEmployee.deleteEmployee({ me: rootState.auth.me, id })
                    if (res.isSuccess()) {
                        commit(_Mutations.DELETE_EMPLOYEE, id)
                    }
                    return res
                },
                async [_Actions.ADD_EMPLOYEE] ({ commit, rootState }, employee) {
                    const res = await ServiceEmployee.addEmployee({ me: rootState.auth.me, employee })
                    if (res.isSuccess()) {
                        commit(_Mutations.ADD_EMPLOYEE, res.data)
                    }
                    return res
                },
                async [_Actions.UPDATE_EMPLOYEE] ({ commit, rootState }, employee) {
                    const res = await ServiceEmployee.updateEmployee({ me: rootState.auth.me, employee })
                    if (res.isSuccess()) {
                        commit(_Mutations.UPDATE_EMPLOYEE, employee)
                    }
                    return res
                },
            }),
    },
    mutations: {
        ...createMutationBaseEntities(Entity.EMPLOYEES),
    },
}
