import ActionInterceptor from '@/utils/ActionInterceptor'
import { _Mutations } from '@/store/modules/auth/types'

export default class StatusInterceptor extends ActionInterceptor {
    before () {
        this.commit(_Mutations.START_PROCESSING, {
            actionName: this.actionName,
            payload: this.payload,
        })
    }

    after () {
        this.commit(_Mutations.END_PROCESSING, {
            actionName: this.actionName,
            payload: this.payload,
        })
    }
}
