export default class ModelItem {
    constructor ({
        name,
        desc,
        totalImportToStock,
        isPrivate,
    }) {
        Object.assign(this, {
            name,
            desc,
            totalImportToStock,
            isPrivate,
        })
    }

    static fromGql (gql) {
        const {
            name,
            desc,
            totalImportToStock,
            isPrivate,
        } = gql
        return new ModelItem({
            name,
            desc,
            totalImportToStock,
            isPrivate,
        })
    }

    static toGql (model) {
        const {
            name,
            desc,
            totalImportToStock,
            isPrivate,
        } = model
        return {
            name,
            desc,
            totalImportToStock,
            isPrivate,
        }
    }
}
