import Vue from 'vue'
import moment from 'moment'
import 'moment/locale/th'
import {
    EventType, CodeType, ProductType, DiscountType, CashbackType, StadiumRole, WeekDayTH,
} from '@/utils/enum'
import { isSameDate, isSameYear } from '@/utils/utils'

moment.locale('th')

Vue.filter('prettyDate', (value) => {
    if (!value) return ''
    return isSameYear(moment(), value) ? value.format('D MMM (ddd)') : value.format('D MMM YYYY (ddd)')
})

Vue.filter('prettyDuration', (value) => {
    if (!value) return ''
    const durationDays = value.asDays()
    if (durationDays < 1) return 'วันนี้'
    if (durationDays < 2) return 'เมื่อวาน'
    if (durationDays < 7) return `${durationDays}วัน`
    const durationWeeks = value.asWeeks()
    if (durationWeeks < 4) return `${durationWeeks}สัปดาห์`
    const durationMonths = value.asMonths()
    if (durationMonths < 12) return `${durationMonths}เดือน`
    const durationYears = value.asYears()
    return `${durationYears}ปี`
})

Vue.filter('formatTxType', (value) => {
    if (!value) return '-'
    return value === EventType.BUFFET ? 'บุฟเฟ่ต์' : 'จองสนาม'
})

Vue.filter('formatDiscountTypeEnum', (value) => {
    if (!value) return '-'
    return value === DiscountType.PRICE ? 'เงินสด' : 'เปอร์เซ็น'
})

Vue.filter('formatCashbackTypeEnum', (value) => {
    if (value === CashbackType.PRICE) return 'ค่าคงที่'
    if (value === CashbackType.PERCENT) return 'เปอร์เซ็น'
    if (value === CashbackType.EVERY_X_FOR_1) return 'ได้ 1 coin ทุกๆ x บาท'
    return '-'
})

Vue.filter('formatEventType', (value) => {
    if (!value) return '-'
    switch (value) {
    case EventType.BUFFET:
        return 'บุฟเฟ่'
    case EventType.TRAINING:
        return 'คอร์สเรียน'
    case EventType.RESERVATION:
    default:
        return 'จองสนาม'
    }
})

Vue.filter('formatWeekdayEnum', (weekdayEnum) => WeekDayTH[weekdayEnum.toUpperCase()])

Vue.filter('getEventTypePrettyString', (product) => {
    if (!product) return '-'
    switch (product.eventType) {
    case EventType.BUFFET:
        return product.type === ProductType.BOOST ? 'บุฟเฟ่' : 'ก๊วน'
    case EventType.TRAINING:
        return 'คอร์สเรียน'
    case EventType.TOURNAMENT:
        return 'การแข่งขัน'
    case EventType.RESERVATION:
    default:
        return 'จองสนาม'
    }
})

Vue.filter('getStadiumRolePrettyString', (role) => {
    if (!role) return '-'
    switch (role) {
    case StadiumRole.EMPLOYEE:
        return 'พนักงาน'
    case StadiumRole.MANAGER:
        return 'ผู้จัดการ'
    case StadiumRole.OWNER:
        return 'เจ้าของ'
    case StadiumRole.MODERATOR:
        return 'เจ้าหน้าที่ lenkila'
    default:
        return '-'
    }
})

Vue.filter('getStartEndPrettyString', (product) => {
    if (!product) return ''
    const { start, end } = product
    let result = `${Vue.options.filters.prettyDate(start)}  ${Vue.options.filters.formatTime(start)}-`

    if (!isSameDate(start, end)) {
        result += `${Vue.options.filters.prettyDate(end)}  `
    }
    result += Vue.options.filters.formatTime(end)
    return result
})

Vue.filter('formatCodeType', (value) => {
    if (!value) return '-'
    return value === CodeType.PAYMENT ? 'จ่ายเงิน' : 'ใช้งานสินค้า'
})

Vue.filter('formatDate', (value) => {
    if (!value) return '-'
    return value.format('DD-MM-YYYY')
})

Vue.filter('formatTime', (value) => {
    if (!value) return ''
    return value.format('H:mm')
})

Vue.filter('formatCurrency', (value) => {
    if (typeof value !== 'number') {
        return value
    }
    const formatter = new Intl.NumberFormat('th-TH', {
        style: 'currency',
        currency: 'THB',
        minimumFractionDigits: 0,
    })
    return formatter.format(value)
})

Vue.filter('formatRedeemCode',
    (value) => (typeof value !== 'undefined'
        ? value.split('').reduce(
            (prev, current, index) => (index % 4 === 0 && index !== 0 ? `${prev }-${ current}` : prev + current),
        ) : ''))

Vue.filter('endTime', (value) => {
    if (value === '00:00') return '23:59'
    return value
})
