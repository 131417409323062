import gql from 'graphql-tag'

// eslint-disable-next-line import/prefer-default-export
export const FieldGeneralInfo = gql`
    fragment FieldGeneralInfo on Field {
        _id
        id
        name
        desc
        sportId
        devidable
        maxPeople
        minPeople
        openTime
        closeTime
        coverPhotoUrl
        stadiumId
        isOfficial
        createdByUser{
            id
        }
    }
`
export const SportGeneralInfo = gql`
    fragment SportGeneralInfo on Sport {
        _id
        id
        displayName
        iconUrl
    }
`

export const FieldPriceSettingGeneralInfo = gql`
    fragment FieldPriceSettingGeneralInfo on FieldPriceSettings{
        id
        pricePerHour
        rruleLocaleString
        minutesDuration
        startedOn
        zoneId
    }
`
