import { createTypes, createTypesWithNamespace } from '@/utils/store-utils'

const _namespace = 'auth'

const types = {
    /**
     * @typedef {Object} Actions
     * @prop {String} GET_ME
     * @prop {String} LOGIN
     * @prop {String} LOGOUT
     * @prop {String} REQUEST_TO_RESET_PASSWORD
     * @prop {String} RESET_PASSWORD
     * @prop {String} CHANGE_PASSWORD
     * @prop {String} IS_LOGIN
     * @prop {String} ACCEPT_POLICY
     * @prop {String} CHECK_IS_READ_POLICY
     */
    Actions: [
        'GET_ME',
        'LOGIN',
        'LOGOUT',
        'REQUEST_TO_RESET_PASSWORD',
        'RESET_PASSWORD',
        'CHANGE_PASSWORD',
        'IS_LOGIN',
        'ACCEPT_POLICY',
        'CHECK_IS_READ_POLICY',
    ],
    /**
     * @typedef {Object} Mutations
     * @prop {String} LOGIN_SUCCESS
     * @prop {String} LOGIN_ERROR
     * @prop {String} LOGIN_ERROR_ALREADY_LOGIN
     * @prop {String} LOGOUT
     * @prop {String} UPDATE_ME
     * @prop {String} CLEAR_ERROR_MSG
     * @prop {String} START_PROCESSING
     * @prop {String} END_PROCESSING
     * @prop {String} UPDATE_LOGIN_STATUS
     * @prop {String} UPDATE_IS_READ_POLICY
     * @prop {String} UPDATE_IS_READ_PRIVACY_POLICY
     */
    Mutations: [
        'LOGIN_SUCCESS',
        'LOGIN_ERROR',
        'LOGIN_ERROR_ALREADY_LOGIN',
        'LOGOUT',
        'UPDATE_ME',
        'CLEAR_ERROR_MSG',
        'START_PROCESSING',
        'END_PROCESSING',
        'UPDATE_LOGIN_STATUS',
        'UPDATE_IS_READ_POLICY',
        'UPDATE_IS_READ_PRIVACY_POLICY',
    ],
    /**
     * @typedef {Object} Getters
     */
    Getters: [
    ],
}
/**
 * @typedef {Object} Types
 * @prop {Actions} Actions
 * @prop {Mutations} Mutations
 * @prop {Getters} Getters
 */
/**
 * @type {Types} types
 */
const { Actions, Getters } = createTypesWithNamespace(_namespace, types)

/**
 * @type {Types} types
 */
const { Actions: _Actions, Mutations: _Mutations, Getters: _Getters } = createTypes(types)

export {
    Actions, Getters, _Mutations, _Actions, _Getters,
}
