export default class ModelStadiumTaxInfo {
    constructor ({
        taxNo,
        name,
        address,
        addressString,
        hasVAT,
        isIncVAT,
    }) {
        Object.assign(this, {
            taxNo,
            name,
            address,
            addressString,
            hasVAT,
            isIncVAT,
        })
    }

    static fromGql (gql) {
        const {
            taxNo,
            name,
            address,
            addressString,
            hasVAT,
            isIncVAT,
        } = gql
        return new ModelStadiumTaxInfo({
            taxNo,
            name,
            address,
            addressString,
            hasVAT,
            isIncVAT,
        })
    }

    toGql () {
        const {
            taxNo,
            name,
            address,
            addressString,
            hasVAT,
            isIncVAT,
        } = this
        return {
            taxNo,
            name,
            address,
            addressString,
            hasVAT,
            isIncVAT,
        }
    }
}
