import VApiResponse from '@/utils/VApiResponse'
import { ApiEmployee } from '@/api'
import { isStadiumUser } from '@/utils/enum'

export default {
    loadEmployees ({ stadiumId, page, size }) {
        return VApiResponse.CONVERT(
            ApiEmployee.loadEmployees({ stadiumId, page, size }),
        )
    },
    deleteEmployee ({ me, id }) {
        if (isStadiumUser(me)) {
            return VApiResponse.CONVERT(
                ApiEmployee.stadiumDeleteEmployee({ stadiumId: me.stadiumUserRole.stadiumId, id }),
            )
        }
        return VApiResponse.CONVERT(
            ApiEmployee.deleteEmployee(id),
        )
    },
    addEmployee ({ me, employee }) {
        if (isStadiumUser(me)) {
            return VApiResponse.CONVERT(
                ApiEmployee.stadiumAddEmployee(employee),
            )
        }
        return VApiResponse.CONVERT(
            ApiEmployee.addEmployee(employee),
        )
    },
    updateEmployee ({ me, employee }) {
        if (isStadiumUser(me)) {
            return VApiResponse.CONVERT(
                ApiEmployee.stadiumUpdateEmployee(employee),
            )
        }
        return VApiResponse.CONVERT(
            ApiEmployee.updateEmployee(employee),
        )
    },
}
