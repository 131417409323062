import { BoostBuffetGeneralInfo, BoostReservationGeneralInfo } from '@/api/graphql/fragments/boost'
import { ManagerPartyEventGeneralInfo } from '@/api/graphql/fragments/event'
import { FieldGeneralInfo } from '@/api/graphql/fragments/field'
import { VariantGeneralInfo } from '@/api/graphql/fragments/product'
import gql from 'graphql-tag'

export const CustomerTxItemGeneralInfo = gql`
    fragment CustomerTxItemGeneralInfo on CustomerTxItem {
        __typename
        _id
        price
        type
        stage:feStage
        amount
        mirrorProduct{
            ...BoostBuffetGeneralInfo
            ...BoostReservationGeneralInfo
            ... on ManagerPartyEvent{
                ...ManagerPartyEventGeneralInfo
            }
            ... on FieldProduct{
                field{
                    sport{
                        displayName
                        iconUrl
                    }
                    ...FieldGeneralInfo
                }
            }
        }
        mirrorVariant{
            ...VariantGeneralInfo
        }
    }
    ${BoostBuffetGeneralInfo}
    ${BoostReservationGeneralInfo}
    ${FieldGeneralInfo}
    ${VariantGeneralInfo}
    ${ManagerPartyEventGeneralInfo}
`

export const CustomerTxGeneralInfo = gql`
    fragment CustomerTxGeneralInfo on CustomerTx {
        _id
        price
        txItems{
            ...CustomerTxItemGeneralInfo
        }
    }
    ${CustomerTxItemGeneralInfo}
`
