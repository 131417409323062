import ApiBoost from '@/api/ApiBoost'
import ApiPartyEvent from '@/api/ApiPartyEvent'
import ApiProduct from '@/api/ApiProduct'
import ServiceManager from '@/services/ServiceManager'
import {
    EventType,
    isStadiumUser,
    ProductType,
} from '@/utils/enum'

export default {
    addProduct (type, product, localCustomers) {
        switch (type) {
        case ProductType.PARTY_EVENT:
            switch (product.eventType) {
            case EventType.TRAINING:
                return ApiPartyEvent.addManagerPartyEventTraining(product)
            case EventType.TOURNAMENT:
                return ApiPartyEvent.addManagerPartyEventTournament(product)
            case EventType.BUFFET:
            default:
                return ApiPartyEvent.addManagerPartyEvent(product)
            }
        case ProductType.BOOST:
        default:
            switch (product.eventType) {
            case EventType.TRAINING:
                return ApiBoost.addBoostTraining(product, localCustomers)
            case EventType.BUFFET:
                return ApiBoost.addBoostBuffet(product, localCustomers)
            case EventType.TOURNAMENT:
                return ApiBoost.addBoostTournament(product, localCustomers)
            case EventType.RESERVATION:
            default:
                return ApiBoost.addBoostReservation(product, localCustomers)
            }
        }
    },
    deleteProduct (type, productId) {
        switch (type) {
        case ProductType.PARTY_EVENT:
            return ApiPartyEvent.deleteManagerPartyEvent(productId)
        case ProductType.BOOST:
        default:
            return ApiBoost.deleteBoost(productId)
        }
    },
    cancelProduct (type, productId) {
        switch (type) {
        // case ProductType.PARTY_EVENT:
        //     return ApiPartyEvent.deleteManagerPartyEvent(productId)
        case ProductType.BOOST:
        default:
            return ApiBoost.cancelProduct(productId)
        }
    },
    updateProduct (type, product) {
        switch (type) {
        case ProductType.PARTY_EVENT:
            switch (product.eventType) {
            case EventType.TRAINING:
                return ApiPartyEvent.updateManagerPartyEventTraining(product)
            case EventType.TOURNAMENT:
                return ApiPartyEvent.updateManagerPartyEventTournament(product)
            case EventType.BUFFET:
            default:
                return ApiPartyEvent.updateManagerPartyEvent(product)
            }
        case ProductType.BOOST:
        default:
            switch (product.eventType) {
            case EventType.TRAINING:
                return ApiBoost.updateBoostTraining(product)
            case EventType.BUFFET:
                return ApiBoost.updateBoostBuffet(product)
            case EventType.TOURNAMENT:
                return ApiBoost.updateBoostTournament(product)
            case EventType.RESERVATION:
            default:
                return ApiBoost.updateBoostReservation(product)
            }
        }
    },
    loadStadiumProducts (stadiumId, isPast, page, size, start, end, query) {
        return ApiBoost.loadBoosts(stadiumId, isPast, page, size, start, end, query)
    },
    loadManagerProducts (partyId, isPast, page, size, start, end) {
        return ServiceManager.loadPartyEvents(partyId, isPast, page, size, start, end)
    },
    loadProductCustomersByUser (user, {
        productId, page, size, isActive = true,
    }) {
        if (isStadiumUser(user)) {
            return ApiProduct.loadProductCustomersByProductId({
                productId, page, size, isActive,
            })
        }
        return ApiProduct.loadProductCustomersByManager({
            productId, page, size, isActive,
        })
    },
    addBoostLocalCustomer (stadiumId, boostId, customer, numberOfPeople) {
        return ApiProduct.addBoostLocalCustomer(stadiumId, boostId, customer, numberOfPeople)
    },
    loadBoostLocalCustomers (boostId) {
        return ApiProduct.loadBoostLocalCustomers(boostId)
    },
    updateBoostLocalCustomer (boostId, customer) {
        return ApiProduct.updateBoostLocalCustomer(boostId, customer)
    },
    deleteProductLocalCustomers (boostId, customerId) {
        return ApiProduct.deleteProductLocalCustomers(boostId, customerId)
    },
    cancelOffer (offerId) {
        return ApiProduct.cancelOffer(offerId)
    },
    loadTxItemsByStadiumId (stadiumId) {
        return ApiProduct.loadTxItemsByStadiumId(stadiumId)
    },
}
