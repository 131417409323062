import { ItemGeneralInfo } from '@/api/graphql/fragments/item'
import gql from 'graphql-tag'

export const PricingGeneralInfo = gql`
    fragment PricingGeneralInfo on Pricing {
        paymentMethod
        price
    }
`
export const VariantGeneralInfo = gql`
    fragment VariantGeneralInfo on ProductVariant {
        variantId
        name
        pricings{
            ...PricingGeneralInfo
        }
        minPrice
        fullPrice
        isDefault
        amountPerUnit
    }
    ${PricingGeneralInfo}
`

export const ProductGeneralInfo = gql`
    fragment ProductGeneralInfo on Product {
        id
        type
        coverPhotoUrl
        noSoldItem
        noCheckedOutItem
        cancelStatus
        variants{
            ...VariantGeneralInfo
        }
        ...ItemGeneralInfo
    }
    ${ItemGeneralInfo}
    ${VariantGeneralInfo}
`

export const FieldProductGeneralInfo = gql`
    fragment FieldProductGeneralInfo on FieldProduct {
        fieldId
        start
        end
    }
`
