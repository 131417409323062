import {
    _Actions,
    _Getters,
    _Mutations,
} from '@/store/modules/txItem/types'
import {
    Entities,
} from '@/utils/enum'
import {
    createActionsWithInterceptors,
    createMutationBaseEntities,
} from '@/utils/store-utils'
import ActionProcessor from '@/utils/ActionProcessor'
import ServiceProduct from '@/services/ServiceProduct'
import { rootMutations } from '@/store/types'
import ServiceTxItem from '@/services/ServiceTxItem'

class ActionProcessorImpl extends ActionProcessor {
    createInterceptors () {
        return []
    }
}

const Processors = new ActionProcessorImpl()

export default {
    namespaced: true,
    state: {
        txItems: [],
    },
    getters: {
        [_Getters.GET_TX_ITEM]: (state) => (id) => state.txItems.find((e) => e.id === id),
    },
    actions: {
        ...createActionsWithInterceptors(Processors,
            {
                async [_Actions.LOAD_MY_TX_ITEMS] ({ commit, rootState }, { page, size }) {
                    const stadiumId = rootState.stadium.id
                    const res = await ServiceProduct.loadProductCustomersByUser(
                        rootState.auth.me, { stadiumId, page, size },
                    )
                    if (res.isSuccess()) {
                        commit(
                            _Mutations.LOAD_TX_ITEMS, res.data.datas,
                        )
                        commit(
                            rootMutations.products.LOAD_PRODUCTS,
                            res.data.datas.map((txItem) => txItem.mirrorProduct),
                            { root: true },
                        )
                    }
                    return res
                },
            }),
        async [_Actions.CANCEL_TX_ITEM] ({ commit }, id) {
            const res = await ServiceTxItem.cancel(id)
            if (res.isSuccess()) {
                // TODO: Update txItem from response Wait API to response with new txitem data
                commit(
                    _Mutations.UPDATE_TX_ITEM, res.data,
                )
            }
            return res
        },
    },
    mutations: {
        ...createMutationBaseEntities(Entities.TX_ITEMS),
    },
}
