import ModelCustomer from '@/models/ModelCustomer'
import ModelUserAdmin from '@/models/ModelUserAdmin'
import ModelUserManager from '@/models/ModelUserManager'
import ModelUserStadium from '@/models/ModelUserStadium'
import { UserType } from '@/utils/enum'

export default class ModelMe {
    constructor (model) {
        Object.assign(this, {
            ...model,
        })
    }

    static fromGql (gql) {
        const { roles } = gql
        const role = roles[0]
        switch (role) {
        case UserType.ADMIN_USER:
            return ModelUserAdmin.fromGql(gql)
        case UserType.STADIUM_USER:
            return ModelUserStadium.fromGql(gql)
        case UserType.MANAGER_USER:
            return ModelUserManager.fromGql(gql)
        case UserType.CUSTOMER:
            return ModelCustomer.fromGql(gql)
        default:
            return new ModelMe(gql)
        }
    }
}
