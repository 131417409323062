import ApiBanner from '@/api/ApiBanner'
import { updateEntityCallbacks } from '@/utils/UtilsApi'

function updateloadBannerMediaCallback (res, bannerMedia) {
    return updateEntityCallbacks(
        res,
        ApiBanner.loadBannerMedia,
        [
            () => (bannerMedia.image
                ? ApiBanner.updateBannerMediaImg({ ...res.data, ...bannerMedia }) : null),
        ],
    )
}

export default {
    loadBannerMedias ({ isPast, page, size }) {
        return ApiBanner.loadBannerMedias({ isPast, page, size })
    },
    loadBannerMedia (id) {
        return ApiBanner.loadBannerMedia(id)
    },
    async addBannerMedia (data) {
        const res = await ApiBanner.addBannerMedia(data)
        if (res.isSuccess()) {
            return updateloadBannerMediaCallback(res, data)
        }
        return res
    },
    async updateBannerMedia (data) {
        const res = await ApiBanner.updateBannerMedia(data)
        if (res.isSuccess()) {
            return updateloadBannerMediaCallback(res, data)
        }
        return res
    },
    deleteBannerMedia (data) {
        return ApiBanner.deleteBannerMedia(data)
    },
    loadMainBanner (page, size) {
        return ApiBanner.loadMainBanner(page, size)
    },
    updateBanner (data) {
        return ApiBanner.updateBanner(data)
    },
}
