import ModelChatRoomMsg from '@/models/ModelChatRoomMsg'
import ModelMe from '@/models/ModelMe'
import moment from 'moment'

export default class ModelChatRoom {
    constructor (model) {
        const {
            _id,
            id,
            name,
            updatedOn,
            members,
            recentlyMsgs,
        } = model
        Object.assign(this, {
            _id,
            id,
            name,
            updatedOn,
            members: members.map((member) => new ModelMe(member)),
            recentlyMsgs,
            recentlyMsg: recentlyMsgs[0],
        })
    }

    static fromGql (gql) {
        const {
            _id,
            id,
            name,
            updatedOn,
            members,
            recentlyMsgs,
        } = gql

        return new ModelChatRoom({
            _id,
            id,
            name,
            updatedOn: updatedOn ? moment(updatedOn) : null,
            members: members.map((member) => ModelMe.fromGql(member.user)),
            recentlyMsgs: recentlyMsgs.map((msg) => ModelChatRoomMsg.fromGql(msg)),
        })
    }

    static toGql (model) {
        const {
            name,
            members,
        } = model

        return {
            name,
            members: members.map((member) => ModelMe.toGql(member)),
        }
    }
}
