import { createTypes, createTypesWithNamespace } from '@/utils/store-utils'

const _namespace = 'cashback'

const types = {
    /**
     * @typedef {Object} Actions
     * @prop {String} LOAD_CASHBACK_PROGRAM
     * @prop {String} LOAD_CASHBACK_PROGRAMS
     * @prop {String} ADD_CASHBACK_PROGRAM
     * @prop {String} UPDATE_CASHBACK_PROGRAM
     * @prop {String} DELETE_CASHBACK_PROGRAM
     * @prop {Sting} ACTIVE_CASHBACK_PROGRAM
     * @prop {String} LOAD_ACTIVE_CASHBACK_PROGRAM
     * @prop {String} LOAD_APPLICABLE_CASHBACK_PROGRAMS
     * @prop {String} LOAD_APPLICABLE_CASHBACK_PROGRAM
     * @prop {String} ADD_APPLICABLE_CASHBACK_PROGRAM
     * @prop {String} UPDATE_APPLICABLE_CASHBACK_PROGRAM
     * @prop {String} DELETE_APPLICABLE_CASHBACK_PROGRAM
     * @prop {Sting} ACTIVE_APPLICABLE_CASHBACK_PROGRAM
     * @prop {String} LOAD_ACTIVE_APPLICABLE_CASHBACK_PROGRAM
     */
    Actions: [
        'LOAD_CASHBACK_PROGRAMS',
        'LOAD_CASHBACK_PROGRAM',
        'ADD_CASHBACK_PROGRAM',
        'UPDATE_CASHBACK_PROGRAM',
        'DELETE_CASHBACK_PROGRAM',
        'ACTIVE_CASHBACK_PROGRAM',
        'LOAD_ACTIVE_CASHBACK_PROGRAM',
        'LOAD_APPLICABLE_CASHBACK_PROGRAMS',
        'LOAD_APPLICABLE_CASHBACK_PROGRAM',
        'ADD_APPLICABLE_CASHBACK_PROGRAM',
        'UPDATE_APPLICABLE_CASHBACK_PROGRAM',
        'DELETE_APPLICABLE_CASHBACK_PROGRAM',
        'ACTIVE_APPLICABLE_CASHBACK_PROGRAM',
        'LOAD_ACTIVE_APPLICABLE_CASHBACK_PROGRAM',
    ],
    /**
     * @typedef {Object} Mutations
     * @prop {String} LOAD_CASHBACK_PROGRAMS
     * @prop {String} ADD_CASHBACK_PROGRAM
     * @prop {String} UPDATE_CASHBACK_PROGRAM
     * @prop {String} DELETE_CASHBACK_PROGRAM
     * @prop {Sting} ACTIVE_CASHBACK_PROGRAM
     * @prop {String} LOAD_APPLICABLE_CASHBACK_PROGRAMS
     * @prop {String} ADD_APPLICABLE_CASHBACK_PROGRAM
     * @prop {String} UPDATE_APPLICABLE_CASHBACK_PROGRAM
     * @prop {String} DELETE_APPLICABLE_CASHBACK_PROGRAM
     * @prop {Sting} ACTIVE_APPLICABLE_CASHBACK_PROGRAM
     */
    Mutations: [
        'LOAD_CASHBACK_PROGRAMS',
        'ADD_CASHBACK_PROGRAM',
        'UPDATE_CASHBACK_PROGRAM',
        'DELETE_CASHBACK_PROGRAM',
        'ACTIVE_CASHBACK_PROGRAM',
        'LOAD_APPLICABLE_CASHBACK_PROGRAMS',
        'ADD_APPLICABLE_CASHBACK_PROGRAM',
        'UPDATE_APPLICABLE_CASHBACK_PROGRAM',
        'DELETE_APPLICABLE_CASHBACK_PROGRAM',
        'ACTIVE_APPLICABLE_CASHBACK_PROGRAM',
    ],
    /**
     * @typedef {Object} Getters
     * @prop {String} GET_APPLICABLE_CASHBACK_PROGRAM
     * @prop {String} GET_CASHBACK_PROGRAM
     */
    Getters: [
        'GET_APPLICABLE_CASHBACK_PROGRAM',
        'GET_CASHBACK_PROGRAM',
    ],
}
/**
 * @typedef {Object} Types
 * @prop {Actions} Actions
 * @prop {Mutations} Mutations
 * @prop {Getters} Getters
 */
/**
 * @type {Types} types
 */
const { Actions, Getters } = createTypesWithNamespace(_namespace, types)

/**
 * @type {Types} types
 */
const { Actions: _Actions, Mutations: _Mutations, Getters: _Getters } = createTypes(types)

const Entities = {
}

export {
    Actions, Getters, _Mutations, _Actions, _Getters, Entities,
}
