import ApiCashback from '@/api/ApiCashback'

export default {
    // loadCashbackPrograms (isPast, page, size) {
    //     return ApiCashback.loadCashbackPrograms(isPast, page, size)
    // },
    // addCashbackProgram (program) {
    //     return ApiCashback.addCashbackProgram(program)
    // },
    loadCashbackProgram () {
        return ApiCashback.loadCashbackProgram()
    },
    updateCashbackProgram (program) {
        return ApiCashback.updateCashbackProgram(program)
    },
    // deleteCashbackProgram (program) {
    //     return ApiCashback.deleteCashbackProgram(program)
    // },
    // activeCashbackProgram (id) {
    //     return ApiCashback.activeCashbackProgram(id)
    // },
    // loadActiveCashbackProgram () {
    //     return ApiCashback.loadActiveCashbackProgram()
    // },
    // loadApplicableCashbackPrograms (isPast, page, size) {
    //     return ApiCashback.loadApplicableCashbackPrograms(isPast, page, size)
    // },
    // addApplicableCashbackProgram (program) {
    //     return ApiCashback.addApplicableCashbackProgram(program)
    // },
    loadApplicableCashbackProgram () {
        return ApiCashback.loadApplicableCashbackProgram()
    },
    updateApplicableCashbackProgram (program) {
        return ApiCashback.updateApplicableCashbackProgram(program)
    },
    // deleteApplicableCashbackProgram (program) {
    //     return ApiCashback.deleteApplicableCashbackProgram(program)
    // },
    // activeApplicableCashbackProgram (id) {
    //     return ApiCashback.activeApplicableCashbackProgram(id)
    // },
    // loadActiveApplicableCashbackProgram () {
    //     return ApiCashback.loadActiveApplicableCashbackProgram()
    // },
}
