import ActionProcessor from '@/utils/ActionProcessor'
import { createActionsWithInterceptors, createMutationBaseEntities } from '@/utils/store-utils'
import { _Actions, _Mutations, _Getters } from '@/store/modules/manager/types'
import ServiceManager from '@/services/ServiceManager'
import { Entities } from '@/utils/enum'

class ActionProcessorImpl extends ActionProcessor {
    createInterceptors (/* { commit }, actionName, action, payload */) {
        return []
    }
}

const Processors = new ActionProcessorImpl()

export default {
    namespaced: true,
    state: {
        /**
         * @typedef {Object} User
         * @prop {Number} id
         * @prop {String} firstName
         * @prop {String} lastName
         * @prop {String} nickName
         * @prop {String} tel
         * @prop {String} email
         */
        /**
         * @type {User[]}
         */
        managers: [],
    },
    getters: {
        [_Getters.GET_MANAGER]:
            (state) => (id) => state.managers.find((manager) => manager.id === id),
    },
    actions: {
        ...createActionsWithInterceptors(Processors,
            {
                async [_Actions.LOAD_MANAGERS] ({ commit }, { page, size }) {
                    const res = await ServiceManager.loadManagers(page, size)
                    if (res.isSuccess()) {
                        commit(_Mutations.LOAD_MANAGERS, res.data.datas)
                    } else {
                        commit(_Mutations.LOAD_MANAGERS, [])
                    }
                    return res
                },
                async [_Actions.ADD_MANAGER] ({ commit }, manager) {
                    const res = await ServiceManager.addManager(manager)
                    if (res.isSuccess()) {
                        commit(_Mutations.ADD_MANAGER, res.data)
                    }
                    return res
                },
                async [_Actions.DELETE_MANAGER] ({ commit }, id) {
                    const res = await ServiceManager.deleteManager(id)
                    if (res.isSuccess()) {
                        commit(_Mutations.DELETE_MANAGER, id)
                    }
                    return res
                },
                async [_Actions.UPDATE_MANAGER] ({ commit }, manager) {
                    const res = await ServiceManager.updateManager(manager)
                    if (res.isSuccess()) {
                        commit(_Mutations.UPDATE_MANAGER, res.data)
                    }
                    return res
                },
            }),
    },
    mutations: {
        ...createMutationBaseEntities(Entities.MANAGERS),
    },
}
