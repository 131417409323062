export default class ModelBanner {
    constructor (model) {
        Object.assign(this, model)
    }

    static fromGql (gql) {
        const {
            _id,
            bannerMediaIds,
        } = gql
        return new ModelBanner({
            id: _id,
            bannerMediaIds,
        })
    }

    static toGql (model) {
        const {
            bannerMediaIds,
        } = model

        return {
            bannerMediaIds,
        }
    }
}
