import ModelItem from '@/models/ModelItem'
import ModelVariants from '@/models/ModelVariants'

export default class ModelProduct {
    constructor ({
        id,
        type,
        coverPhotoUrl,
        noSoldItem,
        noCheckedOutItem,
        variants,
        cancelStatus,
        ...values
    }) {
        Object.assign(this, {
            id,
            type,
            coverPhotoUrl,
            noSoldItem,
            noCheckedOutItem,
            variants,
            cancelStatus,
            ...new ModelItem(values),
        })
    }

    static fromGql (gql) {
        const {
            id,
            type,
            coverPhotoUrl,
            noSoldItem,
            noCheckedOutItem,
            variants,
            cancelStatus,
        } = gql
        return new ModelProduct({
            id,
            type,
            coverPhotoUrl,
            noSoldItem,
            noCheckedOutItem,
            variants: variants ? variants.map(
                (variant) => ModelVariants.fromGql(variant),
            ) : undefined,
            cancelStatus,
            ...ModelItem.fromGql(gql),
        })
    }

    static toGql (model) {
        const {
            type,
            coverPhotoUrl,
            variants,
        } = model
        return {
            type,
            coverPhotoUrl,
            variants: variants ? variants.map(
                (variant, index) => (
                    index === 0
                        ? new ModelVariants({ ...variant, isDefault: true }).toGql()
                        : new ModelVariants({ ...variant, isDefault: false }).toGql()),
            ) : undefined,
            ...ModelItem.toGql(model),
        }
    }
}
