import ApiChat from '@/api/ApiChat'

export default {
    loadChatRooms (stadiumId, page, size) {
        return ApiChat.loadChatRooms(stadiumId, page, size)
    },
    loadChatRoom (chatId) {
        return ApiChat.loadChatRoom(chatId)
    },
    loadChatRoomMsgs (chatroomId, page, size) {
        return ApiChat.loadChatRoomMsgs(chatroomId, page, size)
    },
    sendMsg (chatroomId, msg, offer) {
        if (offer !== null && offer !== undefined) {
            return ApiChat.sendReservationOffer(chatroomId, msg, offer)
        }
        return ApiChat.sendMsg(chatroomId, msg)
    },
}
