import {
    IBundledItemGeneralInfo,
    ILimitedPublishTimeItemGeneralInfo,
    ItemConditionGeneralInfo,
    ItemGeneralInfo,
} from '@/api/graphql/fragments/item'
import { ProductGeneralInfo } from '@/api/graphql/fragments/product'
import gql from 'graphql-tag'

export const PromotionGeneralInfo = gql`
    fragment PromotionGeneralInfo on Promotion {
        noClaimed
        noUsed
        claimAmountLimitPerPerson
        disabled
        publishedOn
        unpublishedOn
        forcedUnpublished
        duration
        validOn
        invalidOn
        buyCondition{
            ...ItemConditionGeneralInfo
        }
        useCondition{
            ...ItemConditionGeneralInfo
        }
    }
    ${ItemConditionGeneralInfo}
`

export const PromotionCodeGeneralInfo = gql`
    fragment PromotionCodeGeneralInfo on IPromotionCode {
        code
    }
`

export const IDiscountPromotionGeneralInfo = gql`
    fragment IDiscountPromotionGeneralInfo on IDiscountPromotion {
        discountType
        discountValue
        maxDiscountAmount
    }
`

export const DiscountCodeGeneralInfo = gql`
    fragment DiscountCodeGeneralInfo on DiscountCode {
        _id
        noSaved
        ...PromotionGeneralInfo
        ...PromotionCodeGeneralInfo
        ...IDiscountPromotionGeneralInfo
        ...ItemGeneralInfo
    }
    ${PromotionGeneralInfo}
    ${PromotionCodeGeneralInfo}
    ${IDiscountPromotionGeneralInfo}
    ${ItemGeneralInfo}
`
export const DiscountCouponGeneralInfo = gql`
    fragment DiscountCouponGeneralInfo on CouponDiscountPromotion {
        _id
        ...PromotionGeneralInfo
        ...IDiscountPromotionGeneralInfo
        ...ItemGeneralInfo
    }
    ${PromotionGeneralInfo}
    ${IDiscountPromotionGeneralInfo}
    ${ItemGeneralInfo}
`

export const BundleCouponGeneralInfo = gql`
    fragment BundleCouponGeneralInfo on BundledProductPromotion{
        _id
        ...ItemGeneralInfo
        ...ProductGeneralInfo
        ...ILimitedPublishTimeItemGeneralInfo
        ...IBundledItemGeneralInfo
        forcedUnpublished
        buyAmountLimitPerPerson
        buyCondition{
            ...ItemConditionGeneralInfo
        }
    }
    ${ItemGeneralInfo}
    ${ProductGeneralInfo}
    ${ILimitedPublishTimeItemGeneralInfo}
    ${IBundledItemGeneralInfo}
    ${ItemConditionGeneralInfo}
`

export const CouponForBundleGeneralInfo = gql`
    fragment CouponForBundleGeneralInfo on CouponDiscountPromotionInBundledProduct{
        _id
        ...PromotionGeneralInfo
        ...IDiscountPromotionGeneralInfo
        ...ItemGeneralInfo
    }
    ${PromotionGeneralInfo}
    ${IDiscountPromotionGeneralInfo}
    ${ItemGeneralInfo}
`
