import gql from 'graphql-tag'

// eslint-disable-next-line import/prefer-default-export
export const PageGeneralInfo = gql`
    fragment PageGeneralInfo on PageInfo {
        count
        total
        currentPage
        lastPage
    }
`
