import moment from 'moment'

export default class ModelReportStadium {
    constructor ({
        start,
        end,
        numberOfCustomer,
        numberOfSoldProduct,
        total,
        paymentFee,
        netTotal,
    }) {
        Object.assign(this, {
            start,
            end,
            numberOfCustomer,
            numberOfSoldProduct,
            total,
            paymentFee,
            netTotal,
        })
    }

    static fromGql (gql) {
        const {
            start,
            end,
            numberOfCustomer,
            numberOfProduct,
            total,
            paymentFee,
            netTotal,
        } = gql
        return new ModelReportStadium({
            start: moment(start),
            end: moment(end),
            numberOfCustomer,
            numberOfSoldProduct: numberOfProduct,
            total,
            paymentFee,
            netTotal,
        })
    }

    toGql () {
        const {
            start,
            end,
            numberOfCustomer,
            numberOfSoldProduct,
            total,
            paymentFee,
            netTotal,
        } = this

        return {
            start: start ? moment(start) : null,
            end: end ? moment(end) : null,
            numberOfCustomer,
            numberOfProduct: numberOfSoldProduct,
            total,
            paymentFee,
            netTotal,
        }
    }
}
