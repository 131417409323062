<template>
    <div />
</template>
<script>
export default {
    data () {
        return {
            visible: false,
        }
    },
    computed: {
        isLogin () {
            return this.$store.state.auth.isLogin
        },
    },
    watch: {
        isLogin (newValue) {
            const router = this.$router
            const route = this.$route
            if (newValue === false && route.meta.requiresNotAuth) {
                this.$error({
                    title: 'คุณหลุดออกจากระบบ',
                    content: 'กรุณาทำการลงชื่อเข้าใช้ใหม่',
                    closeable: false,
                    centered: true,
                    okText: 'กลับไปหน้าลงชื่อเข้าใช้',
                    onOk () {
                        router.push({
                            path: '/login',
                            query: { redirect: route.fullPath },
                        })
                    },
                })
            }
        },
    },
}
</script>
