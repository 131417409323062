import ModelProduct from '@/models/ModelProduct'
import ModelProductField from '@/models/ModelProductField'

export default class ModelManagerPartyEvent {
    constructor (partyEvent) {
        const { partyId, eventType } = partyEvent
        Object.assign(this, {
            ...new ModelProduct(partyEvent),
            ...new ModelProductField(partyEvent),
            partyId,
            eventType,
        })
    }

    static fromGql (gql) {
        const { partyId, eventType } = gql
        return new ModelManagerPartyEvent({
            ...ModelProduct.fromGql(gql),
            ...ModelProductField.fromGql(gql),
            partyId,
            eventType,
        })
    }

    static toGql (model) {
        const { partyId } = model
        return {
            ...ModelProduct.toGql(model),
            ...ModelProductField.toGql(model),
            partyId,
        }
    }
}
