import { createTypes, createTypesWithNamespace } from '@/utils/store-utils'

const _namespace = 'referral'

const types = {
    /**
     * @typedef {Object} Actions
     * @prop {String} LOAD_REFERRAL_PROMOTION
     * @prop {String} LOAD_REFERRAL_PROMOTIONS
     * @prop {String} ADD_REFERRAL_PROMOTION
     * @prop {String} UPDATE_REFERRAL_PROMOTION
     * @prop {String} DELETE_REFERRAL_PROMOTION
     * @prop {String} LOAD_REFERRAL_PROGRAMS
     * @prop {String} ADD_REFERRAL_PROGRAM
     * @prop {String} UPDATE_REFERRAL_PROGRAM
     * @prop {String} DELETE_REFERRAL_PROGRAM
     * @prop {Sting} ACTIVE_REFERRAL_PROGRAM
     * @prop {String} LOAD_ACTIVE_REFERRAL_PROGRAM
     */
    Actions: [
        'LOAD_REFERRAL_PROMOTION',
        'LOAD_REFERRAL_PROMOTIONS',
        'ADD_REFERRAL_PROMOTION',
        'UPDATE_REFERRAL_PROMOTION',
        'DELETE_REFERRAL_PROMOTION',
        'LOAD_REFERRAL_PROGRAMS',
        'ADD_REFERRAL_PROGRAM',
        'UPDATE_REFERRAL_PROGRAM',
        'DELETE_REFERRAL_PROGRAM',
        'ACTIVE_REFERRAL_PROGRAM',
        'LOAD_ACTIVE_REFERRAL_PROGRAM',
    ],
    /**
     * @typedef {Object} Mutations
     * @prop {String} LOAD_REFERRAL_PROMOTIONS
     * @prop {String} ADD_REFERRAL_PROMOTION
     * @prop {String} UPDATE_REFERRAL_PROMOTION
     * @prop {String} DELETE_REFERRAL_PROMOTION
     * @prop {String} LOAD_REFERRAL_PROGRAMS
     * @prop {String} ADD_REFERRAL_PROGRAM
     * @prop {String} UPDATE_REFERRAL_PROGRAM
     * @prop {String} DELETE_REFERRAL_PROGRAM
     * @prop {Stinrg} ACTIVE_REFERRAL_PROGRAM
     */
    Mutations: [
        'LOAD_REFERRAL_PROMOTIONS',
        'ADD_REFERRAL_PROMOTION',
        'UPDATE_REFERRAL_PROMOTION',
        'DELETE_REFERRAL_PROMOTION',
        'LOAD_REFERRAL_PROGRAMS',
        'ADD_REFERRAL_PROGRAM',
        'UPDATE_REFERRAL_PROGRAM',
        'DELETE_REFERRAL_PROGRAM',
        'ACTIVE_REFERRAL_PROGRAM',
    ],
    /**
     * @typedef {Object} Getters
     * @prop {String} GET_REFERRAL_PROMOTION
     * @prop {String} GET_REFERRAL_PROGRAM
     */
    Getters: [
        'GET_REFERRAL_PROMOTION',
        'GET_REFERRAL_PROGRAM',
    ],
}
/**
 * @typedef {Object} Types
 * @prop {Actions} Actions
 * @prop {Mutations} Mutations
 * @prop {Getters} Getters
 */
/**
 * @type {Types} types
 */
const { Actions, Getters } = createTypesWithNamespace(_namespace, types)

/**
 * @type {Types} types
 */
const { Actions: _Actions, Mutations: _Mutations, Getters: _Getters } = createTypes(types)

const Entities = {
}

export {
    Actions, Getters, _Mutations, _Actions, _Getters, Entities,
}
