import { CashbackProgramGeneralInfo, ApplicableCashbackProgramGeneralInfo } from '@/api/graphql/fragments/cashback'
import apolloClient from '@/apolloClient'
import gql from 'graphql-tag'
import ModelCashbackProgram from '@/models/ModelCashbackProgram'
import VApiResponse from '@/utils/VApiResponse'
import ModelApplicableCashbackProgram from '@/models/ModelApplicableCashbackProgram'

export default {
    // async addCashbackProgram (cashbackProgram) {
    //     return VApiResponse.convertGql(
    //         apolloClient.mutate({
    //             mutation: gql`
    //             mutation addCashbackProgram($data: CashbackProgramInput!){
    //                 insertCashbackProgram(data:$data) {
    //                     ...CashbackProgramGeneralInfo
    //                 }
    //             }
    //             ${CashbackProgramGeneralInfo}
    //             `,
    //             variables: {
    //                 data: ModelCashbackProgram.toGql(cashbackProgram),
    //             },
    //         }),
    //         (data) => ModelCashbackProgram.fromGql(data.insertCashbackProgram),
    //     )
    // },
    // async loadCashbackPrograms (isPast, page, size) {
    //     return this._loadCashbackPrograms({
    //         ...getIsPastQuery(isPast),
    //     }, page, size, { ...getSort(isPast) })
    // },
    async loadCashbackProgram () {
        return VApiResponse.convertGql(
            apolloClient.mutate({
                mutation: gql`
                query cashbackProgram{
                    coinCashbackSettings{
                        ...CashbackProgramGeneralInfo
                    }
                }
                ${CashbackProgramGeneralInfo}
                `,
            }),
            (data) => ModelCashbackProgram.fromGql(data.coinCashbackSettings),
        )
    },
    async updateCashbackProgram (cashbackProgram) {
        return VApiResponse.convertGql(
            apolloClient.mutate({
                mutation: gql`
                mutation updateCashbackProgram($data: CoinCashbackSettingsInput!){
                    updateCoinCashbackSettings (data:$data){
                        ...CashbackProgramGeneralInfo
                    }
                }
                ${CashbackProgramGeneralInfo}
                `,
                variables: {
                    data: ModelCashbackProgram.toGql(cashbackProgram),
                },
            }),
            (data) => ModelCashbackProgram.fromGql(data.updateCoinCashbackSettings),
        )
    },
    // async deleteCashbackProgram (id) {
    //     return VApiResponse.convertGql(
    //         await apolloClient.mutate({
    //             mutation: gql`
    //             mutation deleteCashbackProgram($id:ObjectId){
    //                 deleteCashbackProgram(id:$id)
    //             }
    //             `,
    //             variables: {
    //                 id,
    //             },
    //         }),
    //     )
    // },
    // async activeCashbackProgram (id) {
    //     return VApiResponse.convertGql(
    //         await apolloClient.mutate({
    //             mutation: gql`
    //             mutation  ($id:ObjectId){
    //                 enableCashbackProgram(id:$id){
    //                     id
    //                 }
    //             }
    //             `,
    //             variables: {
    //                 id,
    //             },
    //         }),
    //     )
    // },
    // async _loadCashbackPrograms (query, page, size, sort) {
    //     return VApiResponse.convertGql(
    //         await apolloClient.query({
    //             query: gql`
    //             query getCashbackProgramsPagination(
    // $query:QueryObject!,$page:Int,$size:Int,$sort:QueryObject){
    //                 coinCashbackSettingsPagination(
    // query: $query, page: $page, size: $size,sort:$sort) {
    //                     datas{
    //                         ...CashbackProgramGeneralInfo
    //                     }
    //                     pageInfo{
    //                         ...PageGeneralInfo
    //                     }
    //                 }
    //             }
    //             ${CashbackProgramGeneralInfo}
    //             ${PageGeneralInfo}
    //             `,
    //             variables: {
    //                 query: {
    //                     ...query,
    //                 },
    //                 // page start at 0 on server
    //                 page: page - 1,
    //                 size,
    //                 sort: { ...sort },
    //             },
    //         }),
    //         (data) => ModelPagination.fromGql(data.coinCashbackSettingsPagination),
    //     )
    // },
    // async loadActiveCashbackProgram () {
    //     const res = await this._loadCashbackPrograms({ enabled: true }, 1, 1)
    //     const { status, msg, data } = res
    //     const result = new VApiResponse(
    //         { status, msg, data: res.isSuccess() ? data.datas[0] : data },
    //     )
    //     return result
    // },
    // async addApplicableCashbackProgram (applicableCashbackProgram) {
    //     return VApiResponse.convertGql(
    //         apolloClient.mutate({
    //             mutation: gql`
    //             mutation addApplicableCashbackProgram($data: ApplicableCashbackProgramInput!){
    //                 insertApplicableCashbackProgram(data:$data) {
    //                     ...ApplicableCashbackProgramGeneralInfo
    //                 }
    //             }
    //             ${ApplicableCashbackProgramGeneralInfo}
    //             `,
    //             variables: {
    //                 data: ModelApplicableCashbackProgram.toGql(applicableCashbackProgram),
    //             },
    //         }),
    //         (data) =>
    // ModelApplicableCashbackProgram.fromGql(data.insertApplicableCashbackProgram),
    //     )
    // },
    // async loadApplicableCashbackPrograms (isPast, page, size) {
    //     return this._loadApplicableCashbackPrograms({
    //         ...getIsPastQuery(isPast),
    //     }, page, size, { ...getSort(isPast) })
    // },
    async loadApplicableCashbackProgram () {
        return VApiResponse.convertGql(
            apolloClient.query({
                query: gql`
                query cashbackApplicableProgram{
                    coinApplicableSettings {
                        ...ApplicableCashbackProgramGeneralInfo
                    }
                }
                ${ApplicableCashbackProgramGeneralInfo}
                `,
            }),
            (data) => ModelApplicableCashbackProgram.fromGql(data.coinApplicableSettings),
        )
    },
    async updateApplicableCashbackProgram (applicableCashbackProgram) {
        return VApiResponse.convertGql(
            apolloClient.mutate({
                mutation: gql`
                mutation updateApplicableCashbackProgram($data: CoinApplicableSettingsInput!){
                    updateCoinApplicableSettings(data:$data) {
                        ...ApplicableCashbackProgramGeneralInfo
                    }
                }
                ${ApplicableCashbackProgramGeneralInfo}
                `,
                variables: {
                    id: applicableCashbackProgram.id,
                    data: ModelApplicableCashbackProgram.toGql(applicableCashbackProgram),
                },
            }),
            (data) => ModelApplicableCashbackProgram.fromGql(data.updateCoinApplicableSettings),
        )
    },
    // async deleteApplicableCashbackProgram (id) {
    //     return VApiResponse.convertGql(
    //         await apolloClient.mutate({
    //             mutation: gql`
    //             mutation deleteApplicableCashbackProgram($id:ObjectId){
    //                 deleteApplicableCashbackProgram(id:$id)
    //             }
    //             `,
    //             variables: {
    //                 id,
    //             },
    //         }),
    //     )
    // },
    // async activeApplicableCashbackProgram (id) {
    //     return VApiResponse.convertGql(
    //         await apolloClient.mutate({
    //             mutation: gql`
    //             mutation  ($id:ObjectId){
    //                 enableApplicableCashbackProgram(id:$id){
    //                     id
    //                 }
    //             }
    //             `,
    //             variables: {
    //                 id,
    //             },
    //         }),
    //     )
    // },
    // async _loadApplicableCashbackPrograms (query, page, size, sort) {
    //     return VApiResponse.convertGql(
    //         await apolloClient.query({
    //             query: gql`
    //             query
    // getApplicableCashbackProgramsPagination(
    // $query:QueryObject!,$page:Int,$size:Int,$sort:QueryObject){
    //                 applicableCashbackProgramsPagination(
    // query: $query, page: $page, size: $size,sort:$sort) {
    //                     datas{
    //                         ...ApplicableCashbackProgramGeneralInfo
    //                     }
    //                     pageInfo{
    //                         ...PageGeneralInfo
    //                     }
    //                 }
    //             }
    //             ${ApplicableCashbackProgramGeneralInfo}
    //             ${PageGeneralInfo}
    //             `,
    //             variables: {
    //                 query: {
    //                     ...query,
    //                 },
    //                 // page start at 0 on server
    //                 page: page - 1,
    //                 size,
    //                 sort: { ...sort },
    //             },
    //         }),
    //         (data) => ModelPagination.fromGql(data.applicableCashbackProgramsPagination),
    //     )
    // },
    // async loadActiveApplicableCashbackProgram () {
    //     const res = await this._loadApplicableCashbackPrograms({ enabled: true }, 1, 1)
    //     const { status, msg, data } = res
    //     const result = new VApiResponse(
    //         { status, msg, data: res.isSuccess() ? data.datas[0] : data },
    //     )
    //     return result
    // },
}
