export default class ModelReferralProgram {
    constructor (model) {
        Object.assign(this, model)
    }

    static fromGql (gql) {
        const {
            id,
            title,
            desc,
            enabled,
            referralPromotionId,
            referrerPromotionId,
        } = gql
        return new ModelReferralProgram({
            id,
            title,
            desc,
            enabled,
            referralPromotionId,
            referrerPromotionId,
        })
    }

    static toGql (model) {
        const {
            title,
            desc,
            enabled,
            referralPromotionId,
            referrerPromotionId,
        } = model

        return {
            title,
            desc,
            enabled,
            referralPromotionId,
            referrerPromotionId,
        }
    }
}
