import ApiShop from '@/api/ApiShop'

export default {
    loadCouponForBundles (isPast, page, size) {
        return ApiShop.loadCouponForBundles(isPast, page, size)
    },
    addCouponForBundle (discountCode) {
        return ApiShop.addCouponForBundle(discountCode)
    },
    updateCouponForBundle (discountCode) {
        return ApiShop.updateCouponForBundle(discountCode)
    },
    deleteCouponForBundle (discountCode) {
        return ApiShop.deleteCouponForBundle(discountCode)
    },
    loadBundleCoupons (isPast, page, size) {
        return ApiShop.loadBundleCoupons(isPast, page, size)
    },
    addBundleCoupon (discountCoupon) {
        return ApiShop.addBundleCoupon(discountCoupon)
    },
    updateBundleCoupon (discountCoupon) {
        return ApiShop.updateBundleCoupon(discountCoupon)
    },
    deleteBundleCoupon (discountCoupon) {
        return ApiShop.deleteBundleCoupon(discountCoupon)
    },
}
