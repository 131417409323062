import { BannerGeneralInfo, BannerMediaGeneralInfo } from '@/api/graphql/fragments/banner'
import { PageGeneralInfo } from '@/api/graphql/fragments/page'
import apolloClient from '@/apolloClient'
import axiosClient from '@/axiosClient'
import ModelBanner from '@/models/ModelBanner'
import ModelBannerMedia from '@/models/ModelBannerMedia'
import ModelPagination from '@/models/ModelPagination'
import VApiResponse from '@/utils/VApiResponse'
import gql from 'graphql-tag'
import moment from 'moment'

function getIsPastQuery (isPast) {
    if (isPast != null) {
        if (isPast) {
            return {
                endedOn: {
                    $lt: {
                        $date: moment().valueOf(),
                    },
                },
            }
        }
        return {
            endedOn: {
                $gt: {
                    $date: moment().valueOf(),
                },
            },
        }
    }

    return {}
}

function getSort (isPast) {
    return {
        startedOn: isPast ? -1 : 1,
    }
}

export default {
    async addBannerMedia (bannerMedia) {
        return VApiResponse.convertGql(
            apolloClient.mutate({
                mutation: gql`
                mutation addBannerMedia($data: BannerMediaInput!){
                    insertBannerMedia  (data:$data) {
                        ...BannerMediaGeneralInfo
                    }
                }
                ${BannerMediaGeneralInfo}
                `,
                variables: {
                    data: ModelBannerMedia.toGql(bannerMedia),
                },
            }),
            (data) => ModelBannerMedia.fromGql(data.insertBannerMedia),
        )
    },
    async loadBannerMedias ({ isPast, page, size }) {
        return VApiResponse.convertGql(
            await apolloClient.query({
                query: gql`
                query getBannerMediasPagination($query:QueryObject!,$page:Int,$size:Int,$sort:QueryObject){
                    bannerMediasPagination(query: $query, page: $page, size: $size,sort:$sort) {
                        datas{
                            ...BannerMediaGeneralInfo
                        }
                        pageInfo{
                            ...PageGeneralInfo
                        }
                    }
                }
                ${BannerMediaGeneralInfo}
                ${PageGeneralInfo}
                `,
                variables: {
                    query: {
                        ...getIsPastQuery(isPast),
                    },
                    // page start at 0 on server
                    page: page - 1,
                    size,
                    sort: { ...getSort(isPast) },
                },
            }),
            (data) => ModelPagination.fromGql(data.bannerMediasPagination),
        )
    },
    async loadBannerMedia (id) {
        return VApiResponse.convertGql(
            await apolloClient.query({
                query: gql`
                query getBannerMediasPagination($id:ObjectId!){
                    bannerMedia (id: $id) {
                        ...BannerMediaGeneralInfo
                    }
                }
                ${BannerMediaGeneralInfo}
                `,
                variables: {
                    id,
                },
            }),
            (data) => ModelBannerMedia.fromGql(data.bannerMedia),
        )
    },
    async updateBannerMedia (bannerMedia) {
        return VApiResponse.convertGql(
            apolloClient.mutate({
                mutation: gql`
                mutation updateBannerMedia($id:ObjectId,$data: BannerMediaInput!){
                    updateBannerMedia(id:$id,data:$data) {
                        ...BannerMediaGeneralInfo
                    }
                }
                ${BannerMediaGeneralInfo}
                `,
                variables: {
                    id: bannerMedia.id,
                    data: ModelBannerMedia.toGql(bannerMedia),
                },
            }),
            (data) => ModelBannerMedia.fromGql(data.updateBannerMedia),
        )
    },
    updateBannerMediaImg (bannerMedia) {
        const { id, image } = bannerMedia
        const formData = new FormData()
        formData.append('file', image)
        return axiosClient({
            method: 'post',
            url: `/bannerMedia/${id}/image`,
            data: formData,
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        })
    },
    async deleteBannerMedia (id) {
        return VApiResponse.convertGql(
            await apolloClient.mutate({
                mutation: gql`
                mutation deleteBannerMedia($id:ObjectId){
                    deleteBannerMedia(id:$id)
                }
                `,
                variables: {
                    id,
                },
            }),
        )
    },
    async loadMainBanner () {
        return VApiResponse.convertGql(
            await apolloClient.query({
                query: gql`
                query getBannerMain{
                    mainBanner {
                        ...BannerGeneralInfo
                    }
                }
                ${BannerGeneralInfo}
                `,
                variables: {
                },
            }),
            (data) => ModelBanner.fromGql(data.mainBanner),
        )
    },
    async updateBanner (banner) {
        return VApiResponse.convertGql(
            apolloClient.mutate({
                mutation: gql`
                mutation updateBanner($id:ObjectId,$data: BannerInput!){
                    updateBanner(id:$id,data:$data) {
                        ...BannerGeneralInfo
                    }
                }
                ${BannerGeneralInfo}
                `,
                variables: {
                    id: banner.id,
                    data: ModelBanner.toGql(banner),
                },
            }),
            (data) => ModelBanner.fromGql(data.updateBanner),
        )
    },
}
