import { Actions as messageActions } from '@/store/modules/message/types'
import { Actions as authAcitons } from '@/store/modules/auth/types'
import { Actions as stadiumActions, Getters as stadiumGetters } from '@/store/modules/stadium/types'
import { Actions as customersActions, Getters as customersGetters } from '@/store/modules/customers/types'
import { Actions as managerActions, Getters as managerGettters } from '@/store/modules/manager/types'
import { Actions as productActions, Getters as productGettters, Mutations as productMutations } from '@/store/modules/products/types'
import { Actions as discountActions, Getters as discountGetters } from '@/store/modules/discount/types'
import { Actions as shopActions, Getters as shopGetters } from '@/store/modules/shop/types'
import { Actions as referralActions, Getters as referralGetters } from '@/store/modules/referral/types'
import { Actions as employeesActions } from '@/store/modules/employees/types'
import { Actions as codeActions } from '@/store/modules/code/types'
import { Actions as partyActions, Getters as partyGetters } from '@/store/modules/partys/types'
import { Actions as chatActions, Getters as chatGetters } from '@/store/modules/chat/types'
import { Actions as cashbackActions, Getters as cashbackGetters } from '@/store/modules/cashback/types'
import { Actions as bannerActions, Getters as bannerGetters } from '@/store/modules/banner/types'
import { Actions as fieldActions } from '@/store/modules/field/types'
import { Actions as txItemActions, Getters as txItemGetters } from '@/store/modules/txItem/types'
import { createTypes } from '@/utils/store-utils'

/**
 * @typedef {Object} RootActions
 * @prop {String} SET_MY_STADIUM
 * @prop {String} LOAD_MY_PRODUCTS
 * @prop {String} ADD_PRODUCT
 * @prop {String} UPDATE_PRODUCT
 * @prop {String} DELETE_PRODUCT
 * @prop {String} LOAD_FIELDS
 * @prop {String} ADD_FIELD
 * @prop {String} UPDATE_STADIUM_FIELD
 * @prop {String} DELETE_STADIUM_FIELD
 * @prop {String} LOAD_STADIUMS_GENERAL_INFO
 * @prop {String} SAVE_STADIUM_GENERAL_INFO
 * @prop {String} LOAD_STADIUMS_HOLIDAY
 * @prop {String} UPDATE_STADIUM_HOLIDAY
 * @prop {String} ADD_STADIUM_HOLIDAY
 * @prop {String} DELETE_STADIUM_HOLIDAY
 * @prop {String} LOAD_CUSTOMERS
 * @prop {String} UPDATE_CUSTOMER
 * @prop {String} ADD_CUSTOMER
 * @prop {String} DELETE_CUSTOMER
 * @prop {String} SEARCH_CUSTOMER
 * @prop {String} LOAD_MEMBER_TYPES
 * @prop {String} ADD_MEMBER_TYPE
 * @prop {String} UPDATE_MEMBER_TYPE
 * @prop {String} DELETE_MEMBER_TYPE
 * @prop {String} UNREGISTOR_MEMBER_TYPE
 * @prop {String} REGISTOR_MEMBER_TYPE
 * @prop {String} LOAD_MEMBER_TYPE_HISTORY
 * @prop {String} LOAD_EMPLOYEES
 * @prop {String} DELETE_EMPLOYEE
 * @prop {String} ADD_EMPLOYEE
 * @prop {String} UPDATE_EMPLOYEE
 * @prop {String} LOGIN
 * @prop {String} LOGOUT
 * @prop {String} REQUEST_TO_RESET_PASSWORD
 * @prop {String} RESET_PASSWORD
 * @prop {String} CHANGE_PASSWORD
 * @prop {String} GET_ME
 * @prop {String} IS_LOGIN
 * @prop {String} ALERT_DONT_HAVE_PERMISSION
 * @prop {String} LOAD_PRODUCT_LOCAL_CUSTOMERS
 * @prop {String} ADD_PRODUCT_LOCAL_CUSTOMER
 * @prop {String} UPDATE_PRODUCT_LOCAL_CUSTOMER
 * @prop {String} UPDATE_PRODUCT_LOCAL_CUSTOMER_IS_PAID
 * @prop {String} DELETE_PRODUCT_LOCAL_CUSTOMER
 * @prop {String} LOAD_PRODUCT_TX_ITEM
 * @prop {String} LOAD_TX_ITEMS
 * @prop {String} CANCEL_PRODUCT_TX_ITEM
 * @prop {String} LOAD_STADIUMS
 * @prop {String} DELETE_STADIUM
 * @prop {String} UPDATE_STADIUM
 * @prop {String} ADD_STADIUM
 * @prop {String} LOAD_SPORTS
 * @prop {String} ADD_SPORT
 * @prop {String} UPDATE_SPORT
 * @prop {String} DELETE_SPORT
 * @prop {String} USE_TX
 * @prop {String} PAY_TX
 * @prop {String} USE_REDEEM_CODE
 * @prop {String} CLEAR_ACTIVE_TX
 * @prop {String} USE_PAYMENT_CODE
 * @prop {String} CLEAR_ACTIVE_CODE
 * @prop {String} LOAD_CODE
 * @prop {String} LOAD_STADIUM_REPORT
 * @prop {String} LOAD_MANAGERS
 * @prop {String} ADD_MANAGER
 * @prop {String} UPDATE_MANAGER
 * @prop {String} DELETE_MANAGER
 * @prop {String} LOAD_PARTYS
 * @prop {String} LOAD_MY_PARTY
 * @prop {String} UPDATE_MY_PARTY
 * @prop {String} ADD_MY_PARTY
 * @prop {String} LOAD_EVENTS
 * @prop {String} ADD_EVENT
 * @prop {String} UPDATE_EVENT
 * @prop {String} DELETE_EVENT
 * @prop {String} LOAD_STADIUMS
 * @prop {String} LOAD_FIELDS_BY_IDS
 * @prop {String} LOAD_DISCOUNT_CODES
 * @prop {String} ADD_DISCOUNT_CODE
 * @prop {String} UPDATE_DISCOUNT_CODE
 * @prop {String} DELETE_DISCOUNT_CODE
 * @prop {String} LOAD_DISCOUNT_COUPONS
 * @prop {String} ADD_DISCOUNT_COUPON
 * @prop {String} UPDATE_DISCOUNT_COUPON
 * @prop {String} DELETE_DISCOUNT_COUPON
 * @prop {String} LOAD_REFERRAL_PROMOTIONS
 * @prop {String} LOAD_REFERRAL_PROMOTION
 * @prop {String} ADD_REFERRAL_PROMOTION
 * @prop {String} UPDATE_REFERRAL_PROMOTION
 * @prop {String} DELETE_REFERRAL_PROMOTION
 * @prop {String} LOAD_REFERRAL_PROGRAMS
 * @prop {String} ADD_REFERRAL_PROGRAM
 * @prop {String} UPDATE_REFERRAL_PROGRAM
 * @prop {String} DELETE_REFERRAL_PROGRAM
 * @prop {String} ACTIVE_REFERRAL_PROGRAM
 * @prop {String} LOAD_CASHBACK_PROGRAMS
 * @prop {String} LOAD_CASHBACK_PROGRAM
 * @prop {String} ADD_CASHBACK_PROGRAM
 * @prop {String} UPDATE_CASHBACK_PROGRAM
 * @prop {String} DELETE_CASHBACK_PROGRAM
 * @prop {String} ACTIVE_CASHBACK_PROGRAM
 * @prop {String} LOAD_APPLICABLE_CASHBACK_PROGRAMS
 * @prop {String} LOAD_APPLICABLE_CASHBACK_PROGRAM
 * @prop {String} ADD_APPLICABLE_CASHBACK_PROGRAM
 * @prop {String} UPDATE_APPLICABLE_CASHBACK_PROGRAM
 * @prop {String} DELETE_APPLICABLE_CASHBACK_PROGRAM
 * @prop {String} ACTIVE_APPLICABLE_CASHBACK_PROGRAM
 * @prop {String} LOAD_ACTIVE_REFERRAL_PROGRAM
 * @prop {String} LOAD_BUNDLE_COUPONS
 * @prop {String} ADD_BUNDLE_COUPON
 * @prop {String} UPDATE_BUNDLE_COUPON
 * @prop {String} DELETE_BUNDLE_COUPON
 * @prop {String} LOAD_COUPON_FOR_BUNDLES
 * @prop {String} ADD_COUPON_FOR_BUNDLE
 * @prop {String} UPDATE_COUPON_FOR_BUNDLE
 * @prop {String} DELETE_COUPON_FOR_BUNDLE
 * @prop {String} LOAD_CHATROOMS
 * @prop {String} LOAD_CHATROOM
 * @prop {String} LOAD_CHATROOM_MSGS
 * @prop {String} SEND_CHATROOM_MSG
 * @prop {String} CANCEL_PRODUCT_OFFER
 * @prop {String} CANCEL_PRODUCT
 * @prop {String} ACCEPT_POLICY
 * @prop {String} CHECK_IS_READ_POLICY
 * @prop {String} LOAD_BANNER_MEDIAS
 * @prop {String} ADD_BANNER_MEDIA
 * @prop {String} UPDATE_BANNER_MEDIA
 * @prop {String} DELETE_BANNER_MEDIA
 * @prop {String} LOAD_BANNERS
 * @prop {String} LOAD_MAIN_BANNER
 * @prop {String} UPDATE_BANNER
 *
 * @prop {String} LOAD_FIELD_PRICE_RULES
 * @prop {String} UPDATE_PRICE_RULES
 * @prop {String} LOAD_BASE_PRICE
 * @prop {String} UPDATE_BASE_PRICE
 *
 * @prop {String} RESET_STORES
 */
/**
 * @typedef {Object} Types
 * @prop {RootActions} Actions
 */
/**
 * @type {Types}
 */
const { Actions: rootActions } = createTypes({
    Actions: [
        'SET_MY_STADIUM',
        'LOAD_MY_PRODUCTS',
        'ADD_PRODUCT',
        'UPDATE_PRODUCT',
        'DELETE_PRODUCT',
        'CANCEL_PRODUCT',
        'LOAD_FIELDS',
        'ADD_FIELD',
        'UPDATE_STADIUM_FIELD',
        'DELETE_STADIUM_FIELD',
        'LOAD_STADIUMS_GENERAL_INFO',
        'SAVE_STADIUM_GENERAL_INFO',
        'LOAD_STADIUMS_HOLIDAY',
        'UPDATE_STADIUM_HOLIDAY',
        'ADD_STADIUM_HOLIDAY',
        'DELETE_STADIUM_HOLIDAY',
        'LOAD_CUSTOMERS',
        'UPDATE_CUSTOMER',
        'ADD_CUSTOMER',
        'DELETE_CUSTOMER',
        'SEARCH_CUSTOMER',
        'LOAD_MEMBER_TYPES',
        'ADD_MEMBER_TYPE',
        'UPDATE_MEMBER_TYPE',
        'DELETE_MEMBER_TYPE',
        'UNREGISTOR_MEMBER_TYPE',
        'REGISTOR_MEMBER_TYPE',
        'LOAD_MEMBER_TYPE_HISTORY',
        'LOAD_EMPLOYEES',
        'DELETE_EMPLOYEE',
        'ADD_EMPLOYEE',
        'UPDATE_EMPLOYEE',
        'LOGIN',
        'LOGOUT',
        'REQUEST_TO_RESET_PASSWORD',
        'RESET_PASSWORD',
        'CHANGE_PASSWORD',
        'GET_ME',
        'IS_LOGIN',
        'ALERT_DONT_HAVE_PERMISSION',
        'LOAD_PRODUCT_LOCAL_CUSTOMERS',
        'ADD_PRODUCT_LOCAL_CUSTOMER',
        'LOAD_PRODUCT_TX_ITEM',
        'LOAD_TX_ITEMS',
        'CANCEL_PRODUCT_TX_ITEM',
        'UPDATE_PRODUCT_LOCAL_CUSTOMER',
        'UPDATE_PRODUCT_LOCAL_CUSTOMER_IS_PAID',
        'DELETE_PRODUCT_LOCAL_CUSTOMER',
        'LOAD_STADIUMS',
        'DELETE_STADIUM',
        'UPDATE_STADIUM',
        'ADD_STADIUM',
        'LOAD_SPORTS',
        'ADD_SPORT',
        'UPDATE_SPORT',
        'DELETE_SPORT',
        'USE_TX',
        'PAY_TX',
        'USE_REDEEM_CODE',
        'CLEAR_ACTIVE_TX',
        'USE_PAYMENT_CODE',
        'CLEAR_ACTIVE_CODE',
        'LOAD_CODE',
        'LOAD_STADIUM_REPORT',
        'LOAD_MANAGERS',
        'ADD_MANAGER',
        'UPDATE_MANAGER',
        'DELETE_MANAGER',
        'LOAD_PARTYS',
        'LOAD_MY_PARTY',
        'ADD_MY_PARTY',
        'UPDATE_MY_PARTY',
        'LOAD_EVENTS',
        'ADD_EVENT',
        'UPDATE_EVENT',
        'DELETE_EVENT',
        'LOAD_STADIUMS',
        'LOAD_FIELDS_BY_IDS',
        'LOAD_DISCOUNT_CODES',
        'ADD_DISCOUNT_CODE',
        'UPDATE_DISCOUNT_CODE',
        'DELETE_DISCOUNT_CODE',
        'LOAD_DISCOUNT_COUPONS',
        'ADD_DISCOUNT_COUPON',
        'UPDATE_DISCOUNT_COUPON',
        'DELETE_DISCOUNT_COUPON',
        'LOAD_BUNDLE_COUPONS',
        'ADD_BUNDLE_COUPON',
        'UPDATE_BUNDLE_COUPON',
        'DELETE_BUNDLE_COUPON',
        'LOAD_COUPON_FOR_BUNDLES',
        'ADD_COUPON_FOR_BUNDLE',
        'UPDATE_COUPON_FOR_BUNDLE',
        'DELETE_COUPON_FOR_BUNDLE',
        'LOAD_REFERRAL_PROMOTION',
        'LOAD_REFERRAL_PROMOTIONS',
        'ADD_REFERRAL_PROMOTION',
        'UPDATE_REFERRAL_PROMOTION',
        'DELETE_REFERRAL_PROMOTION',
        'LOAD_REFERRAL_PROGRAMS',
        'ADD_REFERRAL_PROGRAM',
        'UPDATE_REFERRAL_PROGRAM',
        'DELETE_REFERRAL_PROGRAM',
        'ACTIVE_REFERRAL_PROGRAM',
        'LOAD_ACTIVE_REFERRAL_PROGRAM',
        'LOAD_CHATROOMS',
        'LOAD_CHATROOM',
        'LOAD_CHATROOM_MSGS',
        'SEND_CHATROOM_MSG',
        'CANCEL_PRODUCT_OFFER',
        'ACCEPT_POLICY',
        'CHECK_IS_READ_POLICY',
        'LOAD_CASHBACK_PROGRAMS',
        'LOAD_CASHBACK_PROGRAM',
        'ADD_CASHBACK_PROGRAM',
        'UPDATE_CASHBACK_PROGRAM',
        'DELETE_CASHBACK_PROGRAM',
        'ACTIVE_CASHBACK_PROGRAM',
        'LOAD_APPLICABLE_CASHBACK_PROGRAMS',
        'LOAD_APPLICABLE_CASHBACK_PROGRAM',
        'ADD_APPLICABLE_CASHBACK_PROGRAM',
        'UPDATE_APPLICABLE_CASHBACK_PROGRAM',
        'DELETE_APPLICABLE_CASHBACK_PROGRAM',
        'ACTIVE_APPLICABLE_CASHBACK_PROGRAM',
        'LOAD_BANNER_MEDIAS',
        'ADD_BANNER_MEDIA',
        'UPDATE_BANNER_MEDIA',
        'DELETE_BANNER_MEDIA',
        'LOAD_MAIN_BANNER',
        'LOAD_BANNERS',
        'UPDATE_BANNER',
        'LOAD_FIELD_PRICE_RULES',
        'UPDATE_PRICE_RULES',
        'LOAD_BASE_PRICE',
        'UPDATE_BASE_PRICE',
        'RESET_STORES',
    ],
})

const Actions = {
    ...rootActions,
    message: messageActions,
    stadium: stadiumActions,
    customers: customersActions,
    employees: employeesActions,
    auth: authAcitons,
    code: codeActions,
    manager: managerActions,
    products: productActions,
    partys: partyActions,
    discount: discountActions,
    referral: referralActions,
    shop: shopActions,
    chat: chatActions,
    cashback: cashbackActions,
    banner: bannerActions,
    field: fieldActions,
    txItem: txItemActions,
}

const Getters = {
    stadium: stadiumGetters,
    customers: customersGetters,
    manager: managerGettters,
    products: productGettters,
    partys: partyGetters,
    discount: discountGetters,
    referral: referralGetters,
    shop: shopGetters,
    chat: chatGetters,
    cashback: cashbackGetters,
    banner: bannerGetters,
    txItem: txItemGetters,
}

const rootMutations = {
    products: productMutations,
}

export {
    Actions, Getters, rootActions, rootMutations,
}
