export default class ModelApplicableCashbackProgram {
    constructor (model) {
        Object.assign(this, model)
    }

    static fromGql (gql) {
        const {
            _id,
            amountType,
            applicableValue,
            maximumApplicableAmount,
            disabled,
        } = gql
        return new ModelApplicableCashbackProgram({
            id: _id,
            amountType,
            applicableValue,
            maximumApplicableAmount,
            disabled,
        })
    }

    static toGql (model) {
        const {
            amountType,
            applicableValue,
            maximumApplicableAmount,
            disabled,
        } = model

        return {
            amountType,
            applicableValue,
            maximumApplicableAmount,
            disabled,
        }
    }
}
