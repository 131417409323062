import ActionInterceptor from '@/utils/ActionInterceptor'
import { Actions, rootActions } from '@/store/types'
import VApiResponse from '@/utils/VApiResponse'

export default class MsgSuccessInterceptor extends ActionInterceptor {
    after (res) {
        if (res instanceof VApiResponse) {
            if (res.isSuccess()) {
                this.dispatch(
                    Actions.message.SHOW_SAVE_SUCCESS_MSG,
                    { caller: this.actionName, callerPayload: this.payload, data: res.data },
                )
            }
        }
    }

    isPassRequirement () {
        if (this.actionName.includes('LOAD')) return false
        return ![
            rootActions.ALERT_DONT_HAVE_PERMISSION,
            rootActions.GET_ME,
            rootActions.IS_LOGIN,
            rootActions.LOGIN,
            rootActions.LOGOUT,
            rootActions.RESET_PASSWORD,
            rootActions.LOAD_SPORTS,
        ].includes(this.actionName)
    }
}
