import ActionProcessor from '@/utils/ActionProcessor'
import { createActionsWithInterceptors, createMutationBaseEntities } from '@/utils/store-utils'
import { _Actions, _Mutations, _Getters } from '@/store/modules/cashback/types'
import ServiceCashback from '@/services/ServiceCashback'
import { Entities } from '@/utils/enum'

class ActionProcessorImpl extends ActionProcessor {
    createInterceptors () {
        return []
    }
}

const Processors = new ActionProcessorImpl()
export default {
    namespaced: true,
    state: {
        cashbackPrograms: [],
        applicableCashbackPrograms: [],
        /**
         * @type {String} id
        */
        activeCashbackProgram: null,
        /**
         * @type {String} id
        */
        activeApplicableCashbackProgram: null,
    },
    getters: {
        [_Getters.GET_CASHBACK_PROGRAM]:
            (state) => (id) => state.cashbackPrograms.find(
                (cashbackProgram) => cashbackProgram.id === id,
            ),
        [_Getters.GET_APPLICABLE_CASHBACK_PROGRAM]:
            (state) => (id) => state.applicableCashbackPrograms.find(
                (applicableCashbackProgram) => applicableCashbackProgram.id === id,
            ),
    },
    actions: {
        ...createActionsWithInterceptors(Processors,
            {
                // async [_Actions.LOAD_CASHBACK_PROGRAMS] ({ commit }, { isPast, page, size }) {
                //     const res = await ServiceCashback.loadCashbackPrograms(isPast, page, size)
                //     if (res.isSuccess()) {
                //         commit(_Mutations.LOAD_CASHBACK_PROGRAMS, res.data.datas)
                //     }
                //     return res
                // },
                // async [_Actions.ADD_CASHBACK_PROGRAM] ({ commit }, cashbackProgram) {
                //     const res = await ServiceCashback.addCashbackProgram(cashbackProgram)
                //     if (res.isSuccess()) {
                //         commit(_Mutations.ADD_CASHBACK_PROGRAM, res.data)
                //     }
                //     return res
                // },
                async [_Actions.LOAD_CASHBACK_PROGRAM] ({ commit }) {
                    const res = await ServiceCashback.loadCashbackProgram()
                    if (res.isSuccess()) {
                        commit(_Mutations.LOAD_CASHBACK_PROGRAMS, [res.data])
                        commit(_Mutations.ACTIVE_CASHBACK_PROGRAM, res.data.id)
                    }
                    return res
                },
                async [_Actions.UPDATE_CASHBACK_PROGRAM] ({ commit }, cashbackProgram) {
                    const res = await ServiceCashback.updateCashbackProgram(cashbackProgram)
                    if (res.isSuccess()) {
                        commit(_Mutations.UPDATE_CASHBACK_PROGRAM, cashbackProgram)
                    }
                    return res
                },
                // async [_Actions.DELETE_CASHBACK_PROGRAM] ({ commit }, id) {
                //     const res = await ServiceCashback.deleteCashbackProgram(id)
                //     if (res.isSuccess()) {
                //         commit(_Mutations.DELETE_CASHBACK_PROGRAM, id)
                //     }
                //     return res
                // },
                // async [_Actions.LOAD_APPLICABLE_CASHBACK_PROGRAMS] (
                //     { commit }, { isPast, page, size },
                // ) {
                //     const res = await ServiceCashback.loadCashbackPromotions(isPast, page, size)
                //     if (res.isSuccess()) {
                //         commit(_Mutations.LOAD_CASHBACK_PROMOTIONS, res.data.datas)
                //     }
                //     return res
                // },
                // async [_Actions.ADD_APPLICABLE_CASHBACK_PROGRAM] ({ commit }, cashbackProgram) {
                //     const res = await ServiceCashback.addCashbackPromotion(cashbackProgram)
                //     if (res.isSuccess()) {
                //         commit(_Mutations.ADD_CASHBACK_PROMOTION, res.data)
                //     }
                //     return res
                // },
                async [_Actions.LOAD_APPLICABLE_CASHBACK_PROGRAM] ({ commit }) {
                    const res = await ServiceCashback.loadApplicableCashbackProgram()
                    if (res.isSuccess()) {
                        commit(_Mutations.LOAD_APPLICABLE_CASHBACK_PROGRAMS, [res.data])
                        commit(_Mutations.ACTIVE_APPLICABLE_CASHBACK_PROGRAM, res.data.id)
                    }
                    return res
                },
                async [_Actions.UPDATE_APPLICABLE_CASHBACK_PROGRAM] ({ commit }, cashbackProgram) {
                    const res = await ServiceCashback.updateApplicableCashbackProgram(
                        cashbackProgram,
                    )
                    if (res.isSuccess()) {
                        commit(_Mutations.UPDATE_APPLICABLE_CASHBACK_PROGRAM, cashbackProgram)
                    }
                    return res
                },
                // async [_Actions.DELETE_APPLICABLE_CASHBACK_PROGRAM] ({ commit }, id) {
                //     const res = await ServiceCashback.deleteCashbackPromotion(id)
                //     if (res.isSuccess()) {
                //         commit(_Mutations.DELETE_CASHBACK_PROMOTION, id)
                //     }
                //     return res
                // },
                // async [_Actions.ACTIVE_APPLICABLE_CASHBACK_PROGRAM] ({ commit }, id) {
                //     const res = await ServiceCashback.activeCashbackProgram(id)
                //     if (res.isSuccess()) {
                //         commit(_Mutations.ACTIVE_CASHBACK_PROGRAM, id)
                //     }
                //     return res
                // },
                // async [_Actions.LOAD_ACTIVE_APPLICABLE_CASHBACK_PROGRAM] ({ commit, getters }) {
                //     const res = await ServiceCashback.loadActiveCashbackProgram()
                //     if (res.isSuccess()) {
                //         commit(_Mutations.ACTIVE_CASHBACK_PROGRAM, res.data.id)
                //         if (getters[_Getters.GET_CASHBACK_PROGRAM](res.data.id)) {
                //             commit(_Mutations.UPDATE_CASHBACK_PROGRAM, res.data)
                //         } else {
                //             commit(_Mutations.ADD_CASHBACK_PROGRAM, res.data)
                //         }
                //     }
                //     return res
                // },
            }),
    },
    mutations: {
        ...createMutationBaseEntities(Entities.CASHBACK_PROGRAMS),
        ...createMutationBaseEntities(Entities.APPLICABLE_CASHBACK_PROGRAMS),
        [_Mutations.ACTIVE_CASHBACK_PROGRAM] (state, value) {
            state.activeCashbackProgram = value
        },
        [_Mutations.ACTIVE_APPLICABLE_CASHBACK_PROGRAM] (state, value) {
            state.activeApplicableCashbackProgram = value
        },
    },
}
