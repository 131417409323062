import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/ant-design-vue'
import './vue-google-map'
import './filters'
// import { createProvider } from './vue-apollo'

Vue.config.productionTip = false
new Vue({
    router,
    store,
    // apolloProvider: createProvider(),
    render: (h) => h(App),
}).$mount('#app')
