<template>
    <a-config-provider :locale="locale">
        <div id="app">
            <MessagesAnnoucement />
            <router-view />
        </div>
    </a-config-provider>
</template>
<script>
import MessagesAnnoucement from '@/components/MessagesAnnoucement.vue'
import thTH from 'ant-design-vue/lib/locale-provider/th_TH'

export default {
    components: {
        MessagesAnnoucement,
    },
    data () {
        return {
            locale: thTH,
        }
    },
}
</script>
<style lang="stylus">
@import '~@/styles/global.styl'
</style>
