import { ProductType } from '@/utils/enum'

export default class ModelItemCondition {
    constructor (model) {
        Object.assign(this, model)
    }

    static fromGql (gql) {
        const {
            minPrice,
            isOnlyNewUser,
        } = gql
        return new ModelItemCondition({
            minPrice,
            isOnlyNewUser,
        })
    }

    static toGql (model) {
        const {
            minPrice,
            isOnlyNewUser,
        } = model

        return {
            minPrice,
            isOnlyNewUser,
            excludeProductTypes: [ProductType.BUNDLED_PROMOTION],
        }
    }
}
