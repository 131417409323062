import ModelTxItem from '@/models/ModelTxItem'

export default class ModelTx {
    constructor ({
        id,
        price,
        txItems,
    }) {
        Object.assign(this, {
            id,
            price,
            txItems,
        })
    }

    static fromGql (gql) {
        const {
            _id,
            price,
            txItems,
        } = gql
        return new ModelTx({
            id: _id,
            price,
            txItems: txItems ? txItems.map(
                (txItem) => ModelTxItem.fromGql(txItem),
            ) : undefined,
        })
    }
}
