import { IDiscountPromotionGeneralInfo, PromotionGeneralInfo } from '@/api/graphql/fragments/discount'
import { ItemGeneralInfo } from '@/api/graphql/fragments/item'
import gql from 'graphql-tag'

export const ReferralPromotionGeneralInfo = gql`
    fragment ReferralPromotionGeneralInfo on ReferralPromotion {
        id
        _id
        ...PromotionGeneralInfo
        ...IDiscountPromotionGeneralInfo
        ...ItemGeneralInfo
    }
    ${PromotionGeneralInfo}
    ${IDiscountPromotionGeneralInfo}
    ${ItemGeneralInfo}
`
export const ReferralProgramGeneralInfo = gql`
    fragment ReferralProgramGeneralInfo on ReferralProgram {
        id,
        _id,
        title,
        desc,
        enabled,
        referralPromotionId,
        referrerPromotionId
    }
`
