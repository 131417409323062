import ModelBoostBuffet from '@/models/ModelBoostBuffet'
import ModelBoostReservation from '@/models/ModelBoostReservation'
import ModelField from '@/models/ModelField'
import ModelDiscountCode from '@/models/ModelDiscountCode'
import ModelManagerPartyEvent from '@/models/ModelManagerPartyEvent'
import ModelStadium from '@/models/ModelStadium'
import ModelUserManager from '@/models/ModelUserManager'
import {
    EntitiesGql,
} from '@/utils/enum'
import ModelDiscount from '@/models/ModelDiscount'
import ModelBundleCoupon from '@/models/ModelBundleCounpon'
import ModelChatRoom from '@/models/ModelChatRoom'
import ModelChatRoomMsg from '@/models/ModelChatRoomMsg'
import ModelBannerMedia from '@/models/ModelBannerMedia'
import ModelPricingRule from '@/models/ModelPricingRule'
import ModelTxItemBoost from '@/models/ModelTxItemBoost'
import ModelMemberType from '@/models/ModelMemberType'
import ModelUserStadium from '@/models/ModelUserStadium'

export default class ResolverEntity {
    static fromGqlUnion (gql) {
        const {
            __typename,
        } = gql
        switch (__typename) {
        case EntitiesGql.BoostBuffet:
        case EntitiesGql.BoostTraining:
        case EntitiesGql.BoostTournament:
            return ModelBoostBuffet.fromGql(gql)
        case EntitiesGql.BoostReservation:
            return ModelBoostReservation.fromGql(gql)
        case EntitiesGql.ManagerPartyEventBuffet:
        case EntitiesGql.ManagerPartyEventTraining:
        case EntitiesGql.ManagerPartyEventTournament:
            return ModelManagerPartyEvent.fromGql(gql)
        case EntitiesGql.Stadium:
            return ModelStadium.fromGql(gql)
        case EntitiesGql.Field:
            return ModelField.fromGql(gql)
        case EntitiesGql.ManagerUser:
            return ModelUserManager.fromGql(gql)
        case EntitiesGql.DiscountCode:
            return ModelDiscountCode.fromGql(gql)
        case EntitiesGql.DiscountCoupon:
        case EntitiesGql.ReferralPromotion:
        case EntitiesGql.CouponForBundle:
            return ModelDiscount.fromGql(gql)
        case EntitiesGql.BundleCoupon:
            return ModelBundleCoupon.fromGql(gql)
        case EntitiesGql.ChatRoom:
            return ModelChatRoom.fromGql(gql)
        case EntitiesGql.ChatRoomMsg:
            return ModelChatRoomMsg.fromGql(gql)
        case EntitiesGql.BannerMedia:
            return ModelBannerMedia.fromGql(gql)
        case EntitiesGql.FieldPriceSettings:
            return ModelPricingRule.fromGql(gql)
        case EntitiesGql.CustomerReserveTxItem:
        case EntitiesGql.CustomerBoostTxItem:
            return ModelTxItemBoost.fromGql(gql)
        case EntitiesGql.StadiumMember:
            return ModelMemberType.fromGql(gql)
        case EntitiesGql.StadiumUser:
            return ModelUserStadium.fromGql(gql)
        default:
            return gql
        }
    }
}
