export default class ModelMemberType {
    constructor (model) {
        Object.assign(this, {
            ...model,
        })
    }

    static fromGql (gql) {
        const {
            id,
            name,
            color,
            stadiumId,
        } = gql
        return new ModelMemberType({
            id,
            name,
            color,
            stadiumId,
        })
    }

    static toGql (model) {
        const {
            name,
            color,
            stadiumId,
        } = model
        return {
            name,
            color,
            stadiumId,
        }
    }
}
