import ModelBoost from '@/models/ModelBoost'
import ModelProduct from '@/models/ModelProduct'
import ModelProductField from '@/models/ModelProductField'

export default class ModelBoostReservation {
    constructor (boostBuffet) {
        Object.assign(this, {
            ...new ModelProduct(boostBuffet),
            ...new ModelProductField(boostBuffet),
            ...new ModelBoost(boostBuffet),
        })
    }

    static fromGql (gql) {
        return new ModelBoostReservation({
            ...ModelProduct.fromGql(gql),
            ...ModelProductField.fromGql(gql),
            ...ModelBoost.fromGql(gql),
        })
    }

    static toGql (model) {
        const { totalImportToStock, ...values } = {
            ...ModelProduct.toGql(model),
            ...ModelProductField.toGql(model),
            ...ModelBoost.toGql(model),
        }
        return values
    }
}
