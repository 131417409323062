import ActionInterceptor from '@/utils/ActionInterceptor'
import { Actions, rootActions } from '@/store/types'
import VApiResponse from '@/utils/VApiResponse'
import { logError } from '@/utils/utils'

export default class MsgErrorInterceptor extends ActionInterceptor {
    after (res) {
        if (res instanceof VApiResponse) {
            if (!res.isSuccess()) {
                this.dispatch(
                    Actions.message.SHOW_ERROR_MSG,
                    {
                        caller: this.actionName,
                        callerPayload: this.payload,
                        data: res.data,
                        msg: res.msg,
                    },
                )
                logError(res.data, res.msg)
            }
        }
    }

    isPassRequirement () {
        return ![
            rootActions.ALERT_DONT_HAVE_PERMISSION,
            rootActions.GET_ME,
            rootActions.IS_LOGIN,
            rootActions.LOGIN,
            rootActions.LOGOUT,
        ].includes(this.actionName)
    }
}
