import ModelTxItem from '@/models/ModelTxItem'

export default class ModelTxItemMeta {
    constructor ({
        customerTxItemId,
        txItem,
        amount,
    }) {
        Object.assign(this, {
            customerTxItemId,
            txItem,
            amount,
        })
    }

    static fromGql (gql) {
        const {
            amount, customerTxItem, customerTxItemId,
        } = gql
        return new ModelTxItemMeta({
            customerTxItemId,
            txItem: ModelTxItem.fromGql(customerTxItem),
            amount,
        })
    }
}
