import gql from 'graphql-tag'

// eslint-disable-next-line import/prefer-default-export
export const ReportGeneralInfo = gql`
    fragment ReportGeneralInfo on StadiumReport {
        start
        end
        numberOfCustomer
        numberOfProduct
        total
        paymentFee
        netTotal
    }
`
