import { StadiumRole, getEnumByValue } from '@/utils/enum'

export default class ModelStadiumUserRole {
    constructor (model) {
        Object.assign(this, {
            ...model,
        })
    }

    static fromGql (gql) {
        const { __typename, ...values } = gql
        return new ModelStadiumUserRole({
            ...values,
            role: StadiumRole[gql.role],
            stadiumId: gql.stadiumId,
        })
    }

    toGql () {
        return {
            ...this,
            role: getEnumByValue(StadiumRole, this.role),
        }
    }

    static stadiumUserRolesToGql (stadiumUserRoles) {
        if (typeof stadiumUserRoles !== 'undefined' && stadiumUserRoles.length > 0) {
            return {
                stadiumUserRole: {
                    ...stadiumUserRoles[0],
                    role: getEnumByValue(StadiumRole, stadiumUserRoles[0].role),
                },
            }
        }
        return {}
    }

    static stadiumUserRolesFromGql (stadiumUserRoles) {
        if (typeof stadiumUserRoles !== 'undefined' && stadiumUserRoles.length > 0) {
            const modelRoles = stadiumUserRoles.map(
                (stadiumRole) => (ModelStadiumUserRole.fromGql(stadiumRole)),
            )
            return {
                stadiumUserRoles: modelRoles,
                stadiumUserRole: modelRoles[0],
            }
        }
        return {}
    }
}
