import VApiResponse from '@/utils/VApiResponse'
import ApiField from '@/api/ApiField'

/**
 * Rule Of Price Rule
 * 1. each rule can't have range Time which is overlape with others rule.
 * 2. Rule which has range time will ovewrite rule which has no range time.
 *  ex.
 * first rule: mon 300batth
 * seccond rule: mon 9-10:00 100bath
 * result: mon before 9:00 300bath 9-10:00 100bath after 10:00 300bath
 * 3.Rule which has no range time will apply to all time in that day.
 */

export default {
    getPriceRules (fieldId) {
        return VApiResponse.CONVERT(
            ApiField.getPriceRules(fieldId),
        )
    },
    getBasePrice (fieldId) {
        return VApiResponse.CONVERT(
            ApiField.getBasePrice(fieldId),
        )
    },
    updateBasePrice (newBasePrice) {
        return VApiResponse.CONVERT(
            ApiField.updateBasePrice(newBasePrice),
        )
    },
    updatePriceRules ({ insertingRules, disableRuleIds }) {
        return VApiResponse.CONVERT(
            ApiField.updatePriceRules({ insertingRules, disableRuleIds }),
        )
    },
}
