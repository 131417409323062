import {
    _Actions,
    _Getters,
    _Mutations,
} from '@/store/modules/chat/types'
import {
    Entities, isStadiumUser,
} from '@/utils/enum'
import {
    createActionsWithInterceptors,
    createMutationBaseEntities,
} from '@/utils/store-utils'
import ActionProcessor from '@/utils/ActionProcessor'
import ServiceChat from '@/services/ServiceChat'
import { rootActions, rootMutations } from '@/store/types'

class ActionProcessorImpl extends ActionProcessor {
    createInterceptors () {
        return []
    }
}

const Processors = new ActionProcessorImpl()

export default {
    namespaced: true,
    state: {
        chatrooms: [],
        chatroomMsgs: [],
    },
    getters: {
        [_Getters.GET_CHATROOM]:
            (state) => (id) => state.chatrooms.find((e) => e.id === id),
        [_Getters.GET_CHATROOM_MSGS]:
            (state) => (id) => state.chatroomMsgs.find((e) => e.id === id),
        [_Getters.GET_CHATROOM_MEMBERS_EXCLUDE_ME]:
            (state) => (id) => {
                const chatRoom = state.chatrooms.find((e) => e.id === id)
                if (chatRoom) {
                    return chatRoom
                        .members.filter((e) => !isStadiumUser(e))
                }
                return null
            },
        [_Getters.GET_CHATROOM_NAME]:
            (_state, getters) => (id) => {
                const chatRoom = getters[_Getters.GET_CHATROOM](id)
                if (chatRoom && chatRoom.name) return chatRoom.name
                const members = getters[_Getters.GET_CHATROOM_MEMBERS_EXCLUDE_ME](id)
                if (members) {
                    return getters[_Getters.GET_CHATROOM_MEMBERS_EXCLUDE_ME](id)
                        .map((member) => member.displayName).toString()
                }
                return null
            },
    },
    actions: {
        ...createActionsWithInterceptors(Processors,
            {
                async [_Actions.LOAD_CHATROOMS] ({ commit, rootState }, {
                    page,
                    size,
                }) {
                    const stadiumId = rootState.stadium.id
                    const res = await ServiceChat.loadChatRooms(
                        stadiumId,
                        page,
                        size,
                    )
                    if (res.isSuccess()) {
                        commit(
                            _Mutations.LOAD_CHATROOMS,
                            res.data.datas,
                        )
                    }
                    return res
                },
                async [_Actions.LOAD_CHATROOM] ({ commit }, id) {
                    const res = await ServiceChat.loadChatRoom(
                        id,
                    )
                    if (res.isSuccess()) {
                        commit(
                            _Mutations.LOAD_CHATROOMS,
                            [res.data],
                        )
                    }
                    return res
                },
                async [_Actions.LOAD_CHATROOM_MSGS] ({
                    commit,
                    dispatch,
                }, {
                    chatroomId,
                    page,
                    size,
                }) {
                    const res = await ServiceChat.loadChatRoomMsgs(
                        chatroomId,
                        page,
                        size,
                    )
                    if (res.isSuccess()) {
                        const attachmentMsgs = res.data.datas
                            .filter((msg) => msg.productAttachment != null)
                        if (attachmentMsgs != null && attachmentMsgs.length > 0) {
                            const resField = await dispatch(
                                _Actions.LOAD_PRODUCT_ATTACHMENTS,
                                attachmentMsgs,
                            )
                            if (!resField.isSuccess()) {
                                return resField
                            }
                        }
                        commit(
                            _Mutations.LOAD_CHATROOMS,
                            res.data.datas,
                        )
                        commit(_Mutations.LOAD_CHATROOM_MSGS, res.data.datas)
                    }
                    return res
                },
                async [_Actions.LOAD_PRODUCT_ATTACHMENTS] ({ commit, dispatch }, msgs) {
                    const productAttachments = msgs.filter((msg) => msg.productAttachment != null)
                        .map((msg) => msg.productAttachment)
                    const res = await dispatch(
                        rootActions.LOAD_FIELDS_BY_IDS,
                        productAttachments
                            .map((product) => product.fieldId),
                        { root: true },
                    )
                    if (res.isSuccess()) {
                        commit(
                            rootMutations.products.LOAD_PRODUCTS,
                            productAttachments,
                            { root: true },
                        )
                    }
                    return res
                },
                async [_Actions.SEND_MSG] ({
                    commit, dispatch,
                }, { chatroomId, msg, offer }) {
                    const res = await ServiceChat.sendMsg(chatroomId, msg, offer)
                    if (res.isSuccess()) {
                        if (res.data.productAttachment != null) {
                            const resField = await dispatch(
                                _Actions.LOAD_PRODUCT_ATTACHMENTS,
                                [res.data],
                            )
                            if (!resField.isSuccess()) {
                                return resField
                            }
                        }
                        commit(_Mutations.ADD_CHATROOM_MSG, res.data)
                    }
                    return res
                },
            }),
    },
    mutations: {
        ...createMutationBaseEntities(Entities.CHATROOMS),
        ...createMutationBaseEntities(Entities.CHATROOM_MSGS),
    },
}
