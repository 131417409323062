export default class ModelSport {
    constructor ({
        id,
        displayName,
        iconUrl,
    }) {
        Object.assign(this, {
            id,
            displayName,
            iconUrl,
        })
    }

    static fromGql (gql) {
        const {
            id,
            displayName,
            iconUrl,
        } = gql
        return new ModelSport({
            id,
            displayName,
            iconUrl,
        })
    }

    toGql () {
        const {
            displayName,
        } = this
        return {
            displayName,
        }
    }
}
