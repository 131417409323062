export const Msg = {
    SUCCESS: {
        type: 'success',
        message: 'ดำเนินการสำเร็จ',
        dismissAfter: 1.5,
    },
    ERROR: {
        type: 'error',
        message: 'เกิดข้อผิดพลาด กรุณาลองอีกครั้ง. ',
        dismissAfter: 3,
    },
    PROCESSING: {
        type: 'loading',
        message: 'กำลังดำเนินการ.....',
    },
}

export const VStatus = {
    PROCESSING: 'processing',
    NONE: '',
}

export const StadiumRole = {
    EMPLOYEE: 1,
    MANAGER: 2,
    OWNER: 3,
    MODERATOR: 4,
}

export const AdminRole = {
    SUPER_ADMIN: 13,
    ADMIN: 12,
    STAFF: 11,
}

export const getEnumByValue = (enumParent, value) => Object.keys(enumParent)
    . find((role) => enumParent[role] === value)

export const UserType = {
    CUSTOMER: 'CUSTOMER',
    STADIUM_USER: 'STADIUM_USER',
    MANAGER_USER: 'MANAGER_USER',
    ADMIN_USER: 'ADMIN_USER',
}

export function isAdminUser (user) {
    try {
        return user.roles.includes(UserType.ADMIN_USER)
    } catch (error) {
        return false
    }
}

export function isStadiumUser (user) {
    try {
        return user.roles.includes(UserType.STADIUM_USER)
    } catch (error) {
        return false
    }
}

export function isManagerUser (user) {
    try {
        return user.roles.includes(UserType.MANAGER_USER)
    } catch (error) {
        return false
    }
}

export const EventType = {
    BUFFET: 'BUFFET',
    RESERVATION: 'RESERVATION',
    TRAINING: 'TRAINING',
    TOURNAMENT: 'TOURNAMENT',
}

export const ProductType = {
    BOOST: 'BOOST',
    MEMBER: 'MEMBER',
    RESERVE: 'RESERVE',
    PARTY_EVENT: 'PARTY_EVENT',
    BUNDLED_PRODUCT: 'BUNDLED_PRODUCT',
    BUNDLED_PROMOTION: 'BUNDLED_PROMOTION',
}

export const CodeType = {
    PAYMENT: 'PAYMENT',
    REDEEM: 'REDEEM',
}

export const AttactmentType = {
    RESERVATION_ASK: 'RESERVATION_ASK',
    RESERVATION_OFFER: 'RESERVATION_OFFER',
}

export const Sport = {
    FUTSAL: 'FUTSAL',
    SOCCER: 'SOCCER',
    BADMINTON: 'BADMINTON',
    BOARDGAME: 'BOARDGAME',
}

export const RepeatType = {
    DAILY: 'DAILY',
    WEEKLY: 'WEEKLY',
    MONTHLY: 'MONTHLY',
    YEARLY: 'YAERLY',
}

export const Entities = {
    USERS: 'users',
    CUSTOMERS: 'customers',
    MEMBER_TYPES: 'memberTypes',
    HOLIDDAYS: 'holidays',
    FIELDS: 'fields',
    BOOSTS: 'boosts',
    STADIUMS: 'stadiums',
    SPORTS: 'sports',
    MANAGERS: 'managers',
    EVENTS: 'events',
    PRODUCTS: 'products',
    PARTYS: 'partys',
    DISCOUNT_COUPONS: 'discountCoupons',
    DISCOUNT_CODES: 'discountCodes',
    REFERRAL_PROGRAMS: 'referralPrograms',
    REFERRAL_PROMOTIONS: 'referralPromotions',
    COUPON_FOR_BUNDLES: 'couponForBundles',
    BUNDLE_COUPONS: 'bundleCoupons',
    CHATROOMS: 'chatrooms',
    CHATROOM_MSGS: 'chatroomMsgs',
    CASHBACK_PROGRAMS: 'cashbackPrograms',
    APPLICABLE_CASHBACK_PROGRAMS: 'applicableCashbackPrograms',
    BANNERS: 'banners',
    BANNER_MEDIAS: 'bannerMedias',
    TX_ITEMS: 'txItems',
}
export const EntitiesGql = {
    BoostReservation: 'BoostReservation',
    BoostBuffet: 'BoostBuffet',
    BoostTraining: 'BoostTraining',
    BoostTournament: 'BoostTournament',
    ManagerPartyEventBuffet: 'ManagerPartyEventBuffet',
    ManagerPartyEventTraining: 'ManagerPartyEventTraining',
    ManagerPartyEventTournament: 'ManagerPartyEventTournament',
    Stadium: 'Stadium',
    Field: 'Field',
    ManagerUser: 'ManagerUser',
    DiscountCode: 'DiscountCode',
    DiscountCoupon: 'CouponDiscountPromotion',
    ReferralPromotion: 'ReferralPromotion',
    BundleCoupon: 'BundledProductPromotion',
    ChatRoom: 'Conversation',
    ChatRoomMsg: 'ConversationPayload',
    CouponForBundle: 'CouponDiscountPromotionInBundledProduct',
    BannerMedia: 'BannerMedia',
    CustomerReserveTxItem: 'CustomerReserveTxItem',
    CustomerBoostTxItem: 'CustomerBoostTxItem',
    StadiumMember: 'StadiumMember',
    StadiumUser: 'StadiumUser',
    FieldPriceSettings: 'FieldPriceSettings',
}

export const TxItemType = {
    BOOST: 'BOOST',
}

export const ReportType = {
    DAILY: 'DAILY',
    WEEKLY: 'WEEKLY',
    MONTHLY: 'MONTHLY',
}

export const TxItemStage = {
    IN_CART: 'IN_CART',
    CHECKED_OUT_CART: 'CHECKED_OUT_CART',
    VALID: 'VALID',
    REDEEMED: 'REDEEMED',
    USED: 'USED',
    CANCELED: 'CANCELED',
    EXPIRED: 'EXPIRED',
    REFUNDED: 'REFUNDED',
    PENDING_REFUND: 'PENDING_REFUND',
}

export function getMsgFromTxItemStage (stage) {
    switch (stage) {
    case TxItemStage.IN_CART:
    case TxItemStage.CHECKED_OUT_CART:
        return 'รอชำระเงิน'
    case TxItemStage.VALID:
    case TxItemStage.REDEEMED:
    case TxItemStage.EXPIRED:
    case TxItemStage.USED:
        return 'จ่ายเงินแล้ว'
    case TxItemStage.CANCELED:
        return 'ยกเลิก'
    case TxItemStage.REFUNDED:
        return 'คืนเงินแล้ว'
    case TxItemStage.PENDING_REFUND:
        return 'กำลังคืนเงิน'
    default:
        return stage
    }
}

export function getColorFromEventType (eventType) {
    switch (eventType) {
    case EventType.RESERVATION:
        return 'green'
    case EventType.TRAINING:
        return 'blue'
    case EventType.BUFFET:
    default:
        return 'yellow'
    }
}

export const PaymentMethod = {
    PAY_AT_STADIUM: 'PAY_AT_STADIUM',
    CREDIT_DEBIT_CARD_FULL: 'CREDIT_DEBIT_CARD_FULL',
    QR_CODE_CASH: 'QR_CODE_CASH',
    // QR_CODE_CREDIT: 'QR_CODE_CREDIT',
    // BILL_PAYMENT: 'BILL_PAYMENT',
}

export function getOnlinePaymentMethod () {
    return Object.keys(PaymentMethod)
        .filter((method) => method !== PaymentMethod.PAY_AT_STADIUM)
}

export function getMsgFromPaymentMethod (method) {
    switch (method) {
    case PaymentMethod.PAY_AT_STADIUM:
        return 'จ่ายที่สนาม'
    case PaymentMethod.CREDIT_DEBIT_CARD_FULL:
        return 'เครดิต/เดบิต'
    case PaymentMethod.QR_CODE_CASH:
        return 'จ่ายด้วย Qr'
    default:
        return 'Lenkila App'
    }
}

export const DiscountType = {
    PRICE: 'FIXED',
    PERCENT: 'PERCENT',
}

export const CashbackType = {
    PRICE: 'FIXED',
    PERCENT: 'PERCENT',
    EVERY_X_FOR_1: 'EVERY_X_FOR_1',
}

export const PromotionCodeType = {
    DISCOUNT: 'DISCOUNT',
    COUPON: 'COUPON',
}

export const ReservationOfferStatus = {
    NONE: 'NONE',
    CANCELED_BY_USER: 'CANCELED_BY_USER',
    CANCELED_BY_STADIUM: 'CANCELED_BY_STADIUM',
    RESERVED: 'RESERVED',
}

export const CancelStatus = {
    CANCELING: 'CANCELING',
    CANCELED: 'CANCELED',
}

export const EntityImageSize = {
    BANNER: { h: 100, w: 320 },
    PRODUCT_COVER: { h: 1080, w: 1920 },
    STADIUM_LOGO: { h: 128, w: 128 },
}

export const WeekDay = {
    MON: 'MON',
    TUE: 'TUE',
    WED: 'WED',
    THU: 'THU',
    FRI: 'FRI',
    SAT: 'SAT',
    SUN: 'SUN',
}

export const WeekDayTH = {
    MON: 'จันทร์',
    TUE: 'อังคาร',
    WED: 'พุธ',
    THU: 'พฤหัส',
    FRI: 'ศุกร์',
    SAT: 'เสาร์',
    SUN: 'อาทิต',
}

export const ViewMode = {
    GRID: 'GRID',
    CALENDAR: 'CALENDAR',
}
