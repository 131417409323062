var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-layout",
    { staticClass: "home", style: { height: "100vh", overflow: "auto" } },
    [
      _c(
        "a-layout-header",
        {
          staticClass: "home__header",
          style: {
            height: "80px",
            lineHeight: "80px",
            position: "fixed",
            zIndex: 102,
            width: "100%"
          }
        },
        [
          _c("div", { staticClass: "home__logo-container" }, [
            _c("img", {
              staticClass: "home__logo",
              attrs: {
                alt: "Lenkila Stadium logo",
                src: require("@/assets/LogoSm.png")
              }
            })
          ]),
          _c(
            "div",
            { staticClass: "home__logout" },
            [
              _c(
                "a-button",
                {
                  style: { fontSize: "16px", color: "#fff" },
                  attrs: { icon: "logout", type: "link" },
                  on: { click: _vm.logout }
                },
                [
                  !_vm.isCollapsedEnable
                    ? [
                        _vm._v(
                          "\n                    ออกจากระบบ\n                "
                        )
                      ]
                    : [_vm._v("\n                    ออก\n                ")]
                ],
                2
              )
            ],
            1
          ),
          _c("div", { style: { clear: "both" } })
        ]
      ),
      _c(
        "a-layout",
        [
          _c(
            "a-layout-sider",
            {
              style: {
                backgroundColor: "#fff",
                margin: "80px 0 0 0"
              },
              attrs: { breakpoint: "lg", "collapsed-width": "0" },
              on: { breakpoint: _vm.onBreakpoint },
              model: {
                value: _vm.collapsed,
                callback: function($$v) {
                  _vm.collapsed = $$v
                },
                expression: "collapsed"
              }
            },
            [
              _c(
                "a-menu",
                {
                  style: {
                    height: "100%",
                    borderRight: 0,
                    overflowY: "auto",
                    overflowX: "hidden"
                  },
                  attrs: {
                    mode: "inline",
                    "default-selected-keys": [_vm.selectedSidebarItemKey],
                    "default-open-keys": _vm.groups
                  }
                },
                [
                  _vm._l(_vm.sidebar, function(group) {
                    return [
                      !group.items
                        ? _c(
                            "a-menu-item",
                            {
                              key: group.group,
                              on: { click: _vm.onSelectedMenu }
                            },
                            [
                              _c(
                                "router-link",
                                { attrs: { to: group.path } },
                                [
                                  group.icon
                                    ? _c("a-icon", {
                                        attrs: { type: group.icon }
                                      })
                                    : _vm._e(),
                                  _c("span", [_vm._v(_vm._s(group.label))])
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _c(
                            "a-sub-menu",
                            { key: group.group },
                            [
                              _c(
                                "span",
                                { attrs: { slot: "title" }, slot: "title" },
                                [
                                  group.icon
                                    ? _c("a-icon", {
                                        attrs: { type: group.icon }
                                      })
                                    : _vm._e(),
                                  _c("span", [_vm._v(_vm._s(group.label))])
                                ],
                                1
                              ),
                              _vm._l(group.items, function(item) {
                                return _c(
                                  "a-menu-item",
                                  {
                                    key: item.path,
                                    on: { click: _vm.onSelectedMenu }
                                  },
                                  [
                                    _c(
                                      "router-link",
                                      { attrs: { to: item.path } },
                                      [
                                        _vm._v(
                                          "\n                                " +
                                            _vm._s(item.label) +
                                            "\n                            "
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                )
                              })
                            ],
                            2
                          )
                    ]
                  })
                ],
                2
              )
            ],
            1
          ),
          _c(
            "a-layout",
            [
              _c(
                "a-layout-content",
                {
                  style: {
                    margin: "104px 16px 0",
                    minHeight: "280px"
                  }
                },
                [
                  _c("router-view"),
                  _c("a-layout-footer", { style: { textAlign: "center" } })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }