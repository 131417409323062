import { createTypes, createTypesWithNamespace } from '@/utils/store-utils'

const _namespace = 'discount'

const types = {
    /**
     * @typedef {Object} Actions
     * @prop {String} LOAD_DISCOUNT_CODES
     * @prop {String} ADD_DISCOUNT_CODE
     * @prop {String} UPDATE_DISCOUNT_CODE
     * @prop {String} DELETE_DISCOUNT_CODE
     * @prop {String} LOAD_DISCOUNT_COUPONS
     * @prop {String} ADD_DISCOUNT_COUPON
     * @prop {String} UPDATE_DISCOUNT_COUPON
     * @prop {String} DELETE_DISCOUNT_COUPON
     */
    Actions: [
        'LOAD_DISCOUNT_CODES',
        'ADD_DISCOUNT_CODE',
        'UPDATE_DISCOUNT_CODE',
        'DELETE_DISCOUNT_CODE',
        'LOAD_DISCOUNT_COUPONS',
        'ADD_DISCOUNT_COUPON',
        'UPDATE_DISCOUNT_COUPON',
        'DELETE_DISCOUNT_COUPON',
    ],
    /**
     * @typedef {Object} Mutations
     * @prop {String} LOAD_DISCOUNT_CODES
     * @prop {String} ADD_DISCOUNT_CODE
     * @prop {String} UPDATE_DISCOUNT_CODE
     * @prop {String} DELETE_DISCOUNT_CODE
     * @prop {String} LOAD_DISCOUNT_COUPONS
     * @prop {String} ADD_DISCOUNT_COUPON
     * @prop {String} UPDATE_DISCOUNT_COUPON
     * @prop {String} DELETE_DISCOUNT_COUPON
     */
    Mutations: [
        'LOAD_DISCOUNT_CODES',
        'ADD_DISCOUNT_CODE',
        'UPDATE_DISCOUNT_CODE',
        'DELETE_DISCOUNT_CODE',
        'LOAD_DISCOUNT_COUPONS',
        'ADD_DISCOUNT_COUPON',
        'UPDATE_DISCOUNT_COUPON',
        'DELETE_DISCOUNT_COUPON',
    ],
    /**
     * @typedef {Object} Getters
     * @prop {String} GET_DISCOUNT_CODE
     * @prop {String} GET_DISCOUNT_COUPON
     */
    Getters: [
        'GET_DISCOUNT_CODE',
        'GET_DISCOUNT_COUPON',
    ],
}
/**
 * @typedef {Object} Types
 * @prop {Actions} Actions
 * @prop {Mutations} Mutations
 * @prop {Getters} Getters
 */
/**
 * @type {Types} types
 */
const { Actions, Getters } = createTypesWithNamespace(_namespace, types)

/**
 * @type {Types} types
 */
const { Actions: _Actions, Mutations: _Mutations, Getters: _Getters } = createTypes(types)

const Entities = {
}

export {
    Actions, Getters, _Mutations, _Actions, _Getters, Entities,
}
