import ActionProcessor from '@/utils/ActionProcessor'
import { createActionsWithInterceptors, createMutationBaseEntities } from '@/utils/store-utils'
import { _Actions, _Mutations, _Getters } from '@/store/modules/referral/types'
import ServiceReferral from '@/services/ServiceReferral'
import { Entities } from '@/utils/enum'

class ActionProcessorImpl extends ActionProcessor {
    createInterceptors () {
        return []
    }
}

const Processors = new ActionProcessorImpl()
export default {
    namespaced: true,
    state: {
        referralPrograms: [],
        referralPromotions: [],
        /**
         * @type {String} id
        */
        activeReferralProgram: null,
    },
    getters: {
        [_Getters.GET_REFERRAL_PROGRAM]:
            (state) => (id) => state.referralPrograms.find(
                (referralProgram) => referralProgram.id === id,
            ),
        [_Getters.GET_REFERRAL_PROMOTION]:
            (state) => (id) => state.referralPromotions.find(
                (referralPromotion) => referralPromotion.id === id,
            ),
    },
    actions: {
        ...createActionsWithInterceptors(Processors,
            {
                async [_Actions.LOAD_REFERRAL_PROGRAMS] ({ commit }, { isPast, page, size }) {
                    const res = await ServiceReferral.loadReferralPrograms(isPast, page, size)
                    if (res.isSuccess()) {
                        commit(_Mutations.LOAD_REFERRAL_PROGRAMS, res.data.datas)
                    }
                    return res
                },
                async [_Actions.ADD_REFERRAL_PROGRAM] ({ commit }, referralProgram) {
                    const res = await ServiceReferral.addReferralProgram(referralProgram)
                    if (res.isSuccess()) {
                        commit(_Mutations.ADD_REFERRAL_PROGRAM, res.data)
                    }
                    return res
                },
                async [_Actions.UPDATE_REFERRAL_PROGRAM] ({ commit }, referralProgram) {
                    const res = await ServiceReferral.updateReferralProgram(referralProgram)
                    if (res.isSuccess()) {
                        commit(_Mutations.UPDATE_REFERRAL_PROGRAM, referralProgram)
                    }
                    return res
                },
                async [_Actions.DELETE_REFERRAL_PROGRAM] ({ commit }, id) {
                    const res = await ServiceReferral.deleteReferralProgram(id)
                    if (res.isSuccess()) {
                        commit(_Mutations.DELETE_REFERRAL_PROGRAM, id)
                    }
                    return res
                },
                async [_Actions.LOAD_REFERRAL_PROMOTIONS] ({ commit }, { isPast, page, size }) {
                    const res = await ServiceReferral.loadReferralPromotions(isPast, page, size)
                    if (res.isSuccess()) {
                        commit(_Mutations.LOAD_REFERRAL_PROMOTIONS, res.data.datas)
                    }
                    return res
                },
                async [_Actions.ADD_REFERRAL_PROMOTION] ({ commit }, referralProgram) {
                    const res = await ServiceReferral.addReferralPromotion(referralProgram)
                    if (res.isSuccess()) {
                        commit(_Mutations.ADD_REFERRAL_PROMOTION, res.data)
                    }
                    return res
                },
                async [_Actions.UPDATE_REFERRAL_PROMOTION] ({ commit }, referralProgram) {
                    const res = await ServiceReferral.updateReferralPromotion(referralProgram)
                    if (res.isSuccess()) {
                        commit(_Mutations.UPDATE_REFERRAL_PROMOTION, referralProgram)
                    }
                    return res
                },
                async [_Actions.DELETE_REFERRAL_PROMOTION] ({ commit }, id) {
                    const res = await ServiceReferral.deleteReferralPromotion(id)
                    if (res.isSuccess()) {
                        commit(_Mutations.DELETE_REFERRAL_PROMOTION, id)
                    }
                    return res
                },
                async [_Actions.ACTIVE_REFERRAL_PROGRAM] ({ commit }, id) {
                    const res = await ServiceReferral.activeReferralProgram(id)
                    if (res.isSuccess()) {
                        commit(_Mutations.ACTIVE_REFERRAL_PROGRAM, id)
                    }
                    return res
                },
                async [_Actions.LOAD_ACTIVE_REFERRAL_PROGRAM] ({ commit, getters }) {
                    const res = await ServiceReferral.loadActiveReferralProgram()
                    if (res.isSuccess()) {
                        commit(_Mutations.ACTIVE_REFERRAL_PROGRAM, res.data.id)
                        if (getters[_Getters.GET_REFERRAL_PROGRAM](res.data.id)) {
                            commit(_Mutations.UPDATE_REFERRAL_PROGRAM, res.data)
                        } else {
                            commit(_Mutations.ADD_REFERRAL_PROGRAM, res.data)
                        }
                    }
                    return res
                },
                async [_Actions.LOAD_REFERRAL_PROMOTION] ({ commit, getters }, id) {
                    const res = await ServiceReferral.loadReferralPromotion(id)
                    if (res.isSuccess()) {
                        if (getters[_Getters.GET_REFERRAL_PROMOTION](res.data.id)) {
                            commit(_Mutations.UPDATE_REFERRAL_PROMOTION, res.data)
                        } else {
                            commit(_Mutations.ADD_REFERRAL_PROMOTION, res.data)
                        }
                    }
                },
            }),
    },
    mutations: {
        ...createMutationBaseEntities(Entities.REFERRAL_PROGRAMS),
        ...createMutationBaseEntities(Entities.REFERRAL_PROMOTIONS),
        [_Mutations.ACTIVE_REFERRAL_PROGRAM] (state, value) {
            state.activeReferralProgram = value
        },
    },
}
