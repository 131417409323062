import ModelProduct from '@/models/ModelProduct'
import ModelProductField from '@/models/ModelProductField'

export default class ModelOfferReservation {
    constructor (data) {
        const { status } = data
        Object.assign(this, {
            status,
            ...new ModelProduct(data),
            ...new ModelProductField(data),
        })
    }

    static fromGql (gql) {
        const { status } = gql
        return new ModelOfferReservation({
            status,
            ...ModelProduct.fromGql(gql),
            ...ModelProductField.fromGql(gql),
        })
    }

    static toGql (model) {
        const {
            totalImportToStock, type, isPrivate, ...values
        } = {
            ...ModelProduct.toGql(model),
            ...ModelProductField.toGql(model),
        }
        return values
    }
}
