import {
    _Mutations, _Actions, _Getters,
} from '@/store/modules/stadium/types'
import ActionProcessor from '@/utils/ActionProcessor'
import { createActionsWithInterceptors, createMutationBaseEntities } from '@/utils/store-utils'
import UpdateStatusInterceptor from '@/store/modules/stadium/interceptors/UpdateStatusInterceptor'
import ServiceStadium from '@/services/ServiceStadium'
import Vue from 'vue'
import VApiResponse from '@/utils/VApiResponse'

import { Entities } from '@/utils/enum'

class ActionProcessorImpl extends ActionProcessor {
    createInterceptors ({ commit }, actionName, action, payload) {
        return [
            new UpdateStatusInterceptor({ commit }, actionName, action, payload),
        ]
    }
}

const Processors = new ActionProcessorImpl()

export default {
    namespaced: true,
    state: {
        id: null,
        stadiums: [],

        /**
        * @typedef {Object} Field
        * @prop {Number} id
        * @prop {String} name
        * @prop {String} coverPhoto cover photo image url
        * @prop {String} sport
        * @prop {String} description
        * @prop {Number[]} minMaxPeople [min,max]
        * @prop {MomentTime} openTime
        * @prop {MomentTime} closeTime
        * @prop {Boolean} dividable
        */
        /**
        * @type {Field[]}
        */
        fields: [],
        /**
         * @typedef {Object} Holiday
         * @prop {String} name
         * @prop {MomentDateTime[]} date [startDateTime,endDateTime]
         */
        /**
         * @type {Holiday[]}
         */
        holidays: [],
        /**
         * @typedef {Object} Sport
         * @prop {String} id
         * @prop {String} displayName
         * @prop {String} iconUrl
         */
        /**
         * @type {Sport[]}
         */
        sports: [],
        /**
         * @typedef {Object} GeneralInfo
         * @prop {String} name
         * @prop {String} logoPhotoUrl logo image url
         * @prop {String} coverPhoto cover photo image url
         * @prop {String} description
         * @prop {String} description2
         * @prop {[String]} phones phones number and additional infomation about phones
         * @prop {String} facilities
         * @prop {Moment} openTime
         * @prop {Moment} closeTime
         * @prop {Number[]} location [lng,lat]
         */
        /**
         * @type {GeneralInfo}
        */
        generalInfo: {},
        status: {
            fields: '',
        },
        /**
         * @typedef {Object} Customer
         * @prop {String} username
         * @prop {String} firstName
         * @prop {String} lastName
         * @prop {String} nickName
         * @prop {String} tel
         * @prop {Integer} numberOfPeople
         */
        /**
        * @typedef {Object} mirrorProduct
        * @prop {Number} id
        * @prop {String} fieldName
        * @prop {String} sportId
        * @prop {String} type {buffet,reservation}
        * @prop {String} description
        * @prop {Number} price
        * @prop {Numver} fullPrice
        * @prop {MomentTime} start
        * @prop {MomentTime} end
        * @prop {MomentDate} date
        */
        /**
         * @typedef {Object} Tx
         * @prop {String} id
         * @prop {String} redeemCode
         * @prop {Customer} customer
         * @prop {mirrorProduct} mirrorProduct
         */
        tx: null,
        /**
        * @typedef {Object} StadiumReport
        * @prop {Date} start
        * @prop {Date} end
        * @prop {Number} numberOfCustomer
        * @prop {Number} numberOfSoldProduct
        * @prop {Number} total total revenue (doesn't minus fee yet)
        */
        /**
        * @type {StadiumReport[]}
        */
        reports: [],
    },
    getters: {
        [_Getters.GET_FIELD]: (state) => (id) => state.fields.find((field) => field.id === id),
        [_Getters.GET_FIELDS_BY_STADIUM_ID]: (state) => (stadiumId) => state.fields.filter(
            (field) => field.stadiumId === stadiumId,
        ),
        [_Getters.GET_STADIUM]: (state) => (id) => state.stadiums.find(
            (stadium) => stadium.id === id,
        ),
        [_Getters.GET_SPORT]: (state) => (id) => state.sports.find(
            (sport) => sport.id === id,
        ),
        [_Getters.GET_REPORT]: (state) => (start, end) => state.reports.find(
            (report) => {
                const s = start.milliseconds(0) // Api doesn't store millisec
                const e = end.milliseconds(0) // Api doesn't store millisec
                return s.isSame(report.start) && e.isSame(report.end)
            },
        ),
    },
    actions: {
        ...createActionsWithInterceptors(Processors,
            {
                async [_Actions.USE_TX] (context, { txItemIds, redeemCode }) {
                    const res = await ServiceStadium.useTx(txItemIds, redeemCode)
                    return res
                },
                async [_Actions.PAY_TX] (context, { txId, redeemCode }) {
                    const res = await ServiceStadium.payTx(txId, redeemCode)
                    return res
                },
                async [_Actions.CLEAR_ACTIVE_TX] ({ commit }) {
                    commit(_Mutations.LOAD_TX, null)
                    return VApiResponse.SUCCESS()
                },
                async [_Actions.LOAD_TX] ({ state, commit }, redeemCode) {
                    const res = await ServiceStadium.loadTx(state.id, redeemCode)
                    if (res.isSuccess()) {
                        commit(_Mutations.LOAD_TX, res.data)
                    }
                    return res
                },
                async [_Actions.LOAD_SPORTS] ({ commit }) {
                    const res = await ServiceStadium.loadSports()
                    if (res.isSuccess()) {
                        commit(_Mutations.LOAD_SPORTS, res.data)
                    }
                    return res
                },
                async [_Actions.ADD_SPORT] ({ commit }, sport) {
                    const res = await ServiceStadium.addSport(sport)
                    if (res.isSuccess()) {
                        commit(_Mutations.ADD_SPORT, res.data)
                    }
                    return res
                },
                async [_Actions.UPDATE_SPORT] ({ commit }, sport) {
                    const res = await ServiceStadium.updateSport(sport)
                    if (res.isSuccess()) {
                        commit(_Mutations.UPDATE_SPORT, res.data)
                    }
                    return res
                },
                async [_Actions.DELETE_SPORT] ({ commit }, sportCode) {
                    const res = await ServiceStadium.deleteSport(sportCode)
                    if (res.isSuccess()) {
                        commit(_Mutations.DELETE_SPORT, sportCode)
                    }
                    return res
                },
                async [_Actions.SET_MY_STADIUM] ({ commit }, stadiumId) {
                    commit(_Mutations.SET_MY_STADIUM, stadiumId)
                },
                async [_Actions.LOAD_FIELDS_BY_IDS] ({ commit }, fieldIds) {
                    const res = await ServiceStadium.loadFieldsByIds(fieldIds)
                    if (res.isSuccess()) {
                        commit(_Mutations.LOAD_FIELDS, res.data.datas)
                    }
                    return res
                },
                async [_Actions.LOAD_FIELDS] ({ commit, state }, { page, size }) {
                    const res = await ServiceStadium.loadFields(state.id, page, size)
                    if (res.isSuccess()) {
                        commit(_Mutations.LOAD_FIELDS, res.data.datas)
                    }
                    return res
                },
                async [_Actions.ADD_FIELD] ({ state, commit }, fieldInfo) {
                    const res = await ServiceStadium.addField(state.id, fieldInfo)
                    if (res.isSuccess()) {
                        commit(_Mutations.ADD_FIELD, res.data)
                    }
                    return res
                },
                async [_Actions.UPDATE_FIELD] ({ commit, state }, field) {
                    const res = await ServiceStadium.updateField(state.id, field)
                    if (res.isSuccess()) {
                        commit(_Mutations.UPDATE_FIELD, res.data)
                    }
                    return res
                },
                async [_Actions.DELETE_FIELD] ({ state, commit }, fieldId) {
                    const res = await ServiceStadium.deleteField(state.id, fieldId)
                    if (res.isSuccess()) {
                        commit(_Mutations.DELETE_FIELD, fieldId)
                    }
                    return res
                },
                async [_Actions.LOAD_HOLIDAYS] ({ state, commit }) {
                    const res = await ServiceStadium.getHolidays(state.id)
                    if (res.isSuccess()) {
                        commit(_Mutations.LOAD_HOLIDAYS, res.data)
                    }
                    return res
                },
                async [_Actions.UPDATE_HOLIDAY] ({ commit }, holiday) {
                    const res = await ServiceStadium.updateHoliday(holiday)
                    if (res.isSuccess()) {
                        commit(_Mutations.UPDATE_HOLIDAY, holiday)
                    }
                    return res
                },
                async [_Actions.ADD_HOLIDAY] ({ state, commit }, holiday) {
                    const res = await ServiceStadium.addHoliday(state.id, holiday)
                    if (res.isSuccess()) {
                        commit(_Mutations.ADD_HOLIDAY, res.data)
                    }
                    return res
                },
                async [_Actions.DELETE_HOLIDAY] ({ commit }, id) {
                    const res = await ServiceStadium.deleteHoliday(id)
                    if (res.isSuccess()) {
                        commit(_Mutations.DELETE_HOLIDAY, id)
                    }
                    return res
                },
                async [_Actions.LOAD_GENERAL_INFO] ({ state, commit }) {
                    const res = await ServiceStadium.getGeneralInfo(state.id)
                    if (res.isSuccess()) {
                        commit(_Mutations.UPDATE_GENERAL_INFO, res.data)
                    }
                    return res
                },
                async [_Actions.UPDATE_GENERAL_INFO] ({ commit, state }, info) {
                    const res = await ServiceStadium.updateStadium({ id: state.id, ...info })
                    if (res.isSuccess()) {
                        commit(_Mutations.UPDATE_GENERAL_INFO, res.data)
                    }
                    return res
                },
                async [_Actions.LOAD_STADIUMS] ({ commit }, { page, size }) {
                    const res = await ServiceStadium.loadStadiums(page, size)
                    if (res.isSuccess()) {
                        commit(_Mutations.LOAD_STADIUMS, res.data.datas)
                    }
                    return res
                },
                async [_Actions.DELETE_STADIUM] ({ commit }, stadiumId) {
                    const res = await ServiceStadium.deleteStadium(stadiumId)
                    if (res.isSuccess()) {
                        commit(_Mutations.DELETE_STADIUM, stadiumId)
                    }
                    return res
                },
                async [_Actions.UPDATE_STADIUM] ({ commit }, stadium) {
                    const res = await ServiceStadium.updateStadium(stadium)
                    if (res.isSuccess()) {
                        commit(_Mutations.UPDATE_STADIUM, res.data)
                    }
                    return res
                },
                async [_Actions.ADD_STADIUM] ({ commit }, stadium) {
                    const res = await ServiceStadium.addStadium(stadium)
                    if (res.isSuccess()) {
                        commit(_Mutations.ADD_STADIUM, res.data)
                    }
                    return res
                },
                async [_Actions.LOAD_STADIUM_REPORT] ({ commit, state }, { start, end }) {
                    const res = await ServiceStadium.loadStadiumReport(state.id, start, end)
                    if (res.isSuccess()) {
                        commit(_Mutations.LOAD_STADIUM_REPORT, res.data)
                    }
                    return res
                },

            }),
    },
    mutations: {
        [_Mutations.SET_MY_STADIUM] (state, stadiumId) {
            state.id = stadiumId
        },
        [_Mutations.UPDATE_GENERAL_INFO] (state, info) {
            state.generalInfo = { ...info }
        },
        [_Mutations.UPDATE_STATUS] (state, { entity, status }) {
            state.status[entity] = status
        },
        [_Mutations.LOAD_TX] (state, tx) {
            state.tx = tx
        },
        [_Mutations.LOAD_STADIUM_REPORT] (state, report) {
            Vue.set(state, 'reports', [...state.reports, report])
        },
        ...createMutationBaseEntities(Entities.HOLIDDAYS),
        ...createMutationBaseEntities(Entities.FIELDS),
        ...createMutationBaseEntities(Entities.STADIUMS),
        ...createMutationBaseEntities(Entities.SPORTS),
    },
}
