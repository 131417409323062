import VApiResponse from '@/utils/VApiResponse'
import { ApiAuth } from '@/api'

export default {
    login (username, password) {
        return VApiResponse.CONVERT(
            ApiAuth.login(username, password),
        )
    },
    logout () {
        return VApiResponse.CONVERT(
            ApiAuth.logout(),
        )
    },
    isLogin () {
        return VApiResponse.CONVERT(ApiAuth.isLogin())
    },
    resetPassword (user, newPassword) {
        return VApiResponse.CONVERT(
            ApiAuth.resetPassword(user, newPassword),
        )
    },
    changePassword (user, currentPassword, newPassword) {
        return VApiResponse.CONVERT(
            ApiAuth.changePassword(user, currentPassword, newPassword),
        )
    },
    getMe () {
        return ApiAuth.getMe()
    },
    updateAgreementPermission (id, isAccepted) {
        return ApiAuth.updateAgreementPermission(id, isAccepted)
    },
}
