import gql from 'graphql-tag'
import apolloClient from '@/apolloClient'
import VApiResponse from '@/utils/VApiResponse'
import {
    StadiumGeneralInfo,
} from '@/api/graphql/fragments/stadium'
import {
    FieldGeneralInfo,
    SportGeneralInfo,
} from '@/api/graphql/fragments/field'
import axiosClient from '@/axiosClient'
import {
    converObjectId,
} from '@/utils/GqlUtils'
import {
    CustomerTxGeneralInfo,
    CustomerTxItemGeneralInfo,
} from '@/api/graphql/fragments/tx'
import ModelStadium from '@/models/ModelStadium'
import ModelField from '@/models/ModelField'
import ModelSport from '@/models/ModelSport'
import ResolverCode from '@/models/resolvers/ResolverCode'
import {
    ReportGeneralInfo,
} from '@/api/graphql/fragments/report'
import ModelReportStadium from '@/models/ModelReportStadium'
import {
    convertDateTimeToApiFormat,
} from '@/utils/utils'
import {
    PageGeneralInfo,
} from '@/api/graphql/fragments/page'
import ModelPagination from '@/models/ModelPagination'

class FiltersField {
    constructor ({
        stadiumId,
        ids,
    }) {
        this.stadiumId = stadiumId
        this.ids = ids
    }

    toMap () {
        const map = {}
        if (this.stadiumId) {
            map.stadiumId = {
                $oid: this.stadiumId,
            }
        }
        if (this.ids) {
            map.id = {
                $in: this.ids.map((oid) => converObjectId(oid)),
            }
        }
        return map
    }
}

export default {
    loadSportById (id) {
        return VApiResponse.convertGql(apolloClient.query({
            query: gql`
            query getSport($id:ObjectId!){
                sport(id: $id) {
                    ...SportGeneralInfo
                }
            }
            ${SportGeneralInfo}
            `,
            variables: {
                id,
            },
        }),
        (data) => ModelSport.fromGql(data.sport))
    },
    loadSports () {
        return VApiResponse.convertGql(apolloClient.query({
            query: gql`
            query getSports($query:QueryObject!){
                sports(query: $query) {
                    ...SportGeneralInfo
                }
            }
            ${SportGeneralInfo}
            `,
            variables: {
                query: {},
            },
        }),
        (data) => data.sports.map((sport) => ModelSport.fromGql(sport)))
    },
    async addSport (sport) {
        return VApiResponse.convertGql(apolloClient.mutate({
            mutation: gql`
            mutation createSport($sport:SportInput!){
                insertSport(data:$sport) {
                    ...SportGeneralInfo
                }
            }
            ${SportGeneralInfo}
            `,
            variables: {
                sport: new ModelSport(sport).toGql(),
            },
        }),
        async (data) => ModelSport.fromGql(data.insertSport))
    },
    async updateSport (sport) {
        return VApiResponse.convertGql(apolloClient.mutate({
            mutation: gql`
            mutation updateSport($sportId:ObjectId!,$sport:SportInput!){
                updateSport(id:$sportId,data:$sport) {
                    ...SportGeneralInfo
                }
            }
            ${SportGeneralInfo}
            `,
            variables: {
                sportId: sport.id,
                sport: new ModelSport(sport).toGql(),
            },
        }),
        async (data) => ModelSport.fromGql(data.updateSport))
    },
    async deleteSport (sportId) {
        return VApiResponse.convertGql(apolloClient.mutate({
            mutation: gql`
            mutation deleteSport($sportId:ObjectId!){
                deleteSport(id:$sportId)
            }
            `,
            variables: {
                sportId,
            },
        }),
        (data) => data.deleteStadium)
    },
    async loadStadiumById (id) {
        return VApiResponse.convertGql(apolloClient.query({
            query: gql`
            query getStadium($id:ObjectId!){
               stadium(id:$id){
                   ...StadiumGeneralInfo
               }
            }
            ${StadiumGeneralInfo}
            `,
            variables: {
                id,
            },
        }),
        (data) => ModelStadium.fromGql(data.stadium))
    },
    async loadStadiums (page, size) {
        return VApiResponse.convertGql(apolloClient.query({
            query: gql`
            query getStadiums(
                $query:QueryObject!,
                $page:Int,$size:Int,$sort:QueryObject){
                stadiumsPagination(query: $query,page:$page,size:$size,sort:$sort) {
                    datas{
                        ...StadiumGeneralInfo
                    }
                    pageInfo{
                        ...PageGeneralInfo
                    }
                }
            }
            ${StadiumGeneralInfo}
            ${PageGeneralInfo}
            `,
            variables: {
                query: {},
                // page start at 0 on server
                page: page - 1,
                size,
                sort: {
                    name: 1,
                },
            },
        }),
        (data) => ModelPagination.fromGql(data.stadiumsPagination))
    },
    async updateStadium (stadium) {
        return VApiResponse.convertGql(apolloClient.mutate({
            mutation: gql`
            mutation updateStadium($id:ObjectId,$stadium: StadiumInput!){
                updateStadium(id:$id,data:$stadium) {
                    ...StadiumGeneralInfo
                }
            }
            ${StadiumGeneralInfo}
            `,
            variables: {
                id: stadium.id,
                stadium: new ModelStadium(stadium).toGql(),
            },
        }),
        async (data) => ModelStadium.fromGql(data.updateStadium))
    },
    async addStadium (stadium) {
        return VApiResponse.convertGql(apolloClient.mutate({
            mutation: gql`
            mutation createStadium($stadium:StadiumInput!){
                insertStadium(data:$stadium) {
                    ...StadiumGeneralInfo
                }
            }
            ${StadiumGeneralInfo}
            `,
            variables: {
                stadium: new ModelStadium(stadium).toGql(),
            },
        }),
        async (data) => ModelStadium.fromGql(data.insertStadium))
    },
    async deleteStadium (stadiumId) {
        return VApiResponse.convertGql(apolloClient.mutate({
            mutation: gql`
            mutation deleteStadium($id:ObjectId){
                deleteStadium(id:$id)
            }
            `,
            variables: {
                id: stadiumId,
            },
        }),
        (data) => data.deleteStadium)
    },
    async getGeneralInfo (stadiumId) {
        return VApiResponse.convertGql(apolloClient.query({
            query: gql`
            query getStadium($id: ObjectId!){
                stadium(id:$id) {
                    ...StadiumGeneralInfo
                }
            }
            ${StadiumGeneralInfo}`,
            variables: {
                id: stadiumId,
            },
        }),
        (data) => ModelStadium.fromGql(data.stadium))
    },
    /**
     *
     * @param {FiltersField} filter
     */
    async loadFields (filter, page = 1, size = 100) {
        return VApiResponse.convertGql(apolloClient.query({
            query: gql`
            query getFields($query:QueryObject!,$page:Int,$size:Int){
                fieldsPagination(query: $query,page:$page,size:$size) {
                    datas{
                        ...FieldGeneralInfo
                    }
                    pageInfo{
                        ...PageGeneralInfo
                    }
                }
            }
            ${FieldGeneralInfo}
            ${PageGeneralInfo}
            `,
            variables: {
                query: filter.toMap(),
                page: page - 1,
                size,
            },
        }),
        (data) => ModelPagination.fromGql(data.fieldsPagination))
    },
    async loadFieldsByStadium (stadiumId, page, size) {
        const filter = new FiltersField({
            stadiumId,
        })
        return this.loadFields(filter, page, size)
    },
    async loadFieldsByIds (fieldIds) {
        const filter = new FiltersField({
            ids: fieldIds,
        })
        return this.loadFields(filter)
    },
    async loadFieldById (fieldId) {
        return VApiResponse.convertGql(apolloClient.query({
            query: gql`
            query getFields($id:ObjectId!){
                field(id: $id) {
                    ...FieldGeneralInfo
                }
            }
            ${FieldGeneralInfo}
            `,
            variables: {
                id: fieldId,
            },
        }),
        (data) => ModelField.fromGql(data.field))
    },
    async addField (stadiumId, fieldInfo) {
        return VApiResponse.convertGql(apolloClient.mutate({
            mutation: gql`
            mutation createField($fieldInfo:FieldInput!){
                insertField(data:$fieldInfo) {
                    ...FieldGeneralInfo
                }
            }
            ${FieldGeneralInfo}
            `,
            variables: {
                fieldInfo: {
                    stadiumId,
                    ...new ModelField(fieldInfo).toGql(),
                },
            },
        }),
        async (data) => ModelField.fromGql(data.insertField))
    },
    async updateField (stadiumId, field) {
        return VApiResponse.convertGql(apolloClient.mutate({
            mutation: gql`
            mutation updateField($id:ObjectId,$stadium: FieldInput!){
                updateField(id:$id,data:$stadium) {
                    ...FieldGeneralInfo
                }
            }
            ${FieldGeneralInfo}
            `,
            variables: {
                id: field.id,
                stadium: new ModelField(field).toGql(),
            },
        }),
        async (data) => ModelField.fromGql(data.updateField))
    },
    async deleteField (stadiumId, fieldId) {
        return VApiResponse.convertGql(apolloClient.mutate({
            mutation: gql`
            mutation deleteField($id:ObjectId){
                deleteField(id:$id)
            }
            `,
            variables: {
                id: fieldId,
            },
        }))
    },
    async loadCode (stadiumId, redeemCode) {
        return VApiResponse.convertGql(apolloClient.query({
            query: gql`
                query loadTx($stadiumId:ObjectId!,$redeemCode:String!){
                    stadiumFindCurrentCode(stadiumId:$stadiumId,code:$redeemCode){
                        _id
                        code
                        type
                        generatedByUser{
                            username
                            firstName
                            lastName
                            nickName
                            ... on Customer{
                                phoneNumber
                            }
                        }
                        ... on CustomerPaymentCode{
                            customerTx{
                                ...CustomerTxGeneralInfo
                            }
                        }
                        ... on CustomerRedeemCode{
                            txItemMetas{
                                customerTxItemId
                                customerTxItem{
                                    redeemCodeMetas {
                                        stage
                                        amount
                                    }
                                    ...CustomerTxItemGeneralInfo
                                }
                                amount
                            }
                        }
                    }
                }
                ${CustomerTxGeneralInfo}
                ${CustomerTxItemGeneralInfo}
            `,
            variables: {
                stadiumId,
                redeemCode,
            },
        }),
        (data) => {
            if (data.stadiumFindCurrentCode == null) {
                throw VApiResponse.ERROR(data, 'รหัสนี้ไม่ถูกต้องหรือหมดอายุแล้ว')
            }
            return ResolverCode.fromGqlUnion(data.stadiumFindCurrentCode)
        })
    },
    async loadCodeByManager (partyId, redeemCode) {
        return VApiResponse.convertGql(apolloClient.query({
            query: gql`
                query loadTxByManager($partyId:ObjectId!,$redeemCode:String!){
                    managerFindCurrentCode(partyId:$partyId,code:$redeemCode){
                        _id
                        code
                        type
                        generatedByUser{
                            username
                            firstName
                            lastName
                            nickName
                            ... on Customer{
                                phoneNumber
                            }
                        }
                        ... on CustomerPaymentCode{
                            customerTx{
                                ...CustomerTxGeneralInfo
                            }
                        }
                        ... on CustomerRedeemCode{
                            txItemMetas{
                                customerTxItemId
                                customerTxItem{
                                    redeemCodeMetas {
                                        stage
                                        amount
                                    }
                                    ...CustomerTxItemGeneralInfo
                                }
                                amount
                            }
                        }
                    }
                }
                ${CustomerTxGeneralInfo}
                ${CustomerTxItemGeneralInfo}
            `,
            variables: {
                partyId,
                redeemCode,
            },
        }),
        (data) => {
            if (data.managerFindCurrentCode == null) {
                throw new Error(
                    'รหัสนี้ไม่ถูกต้องหรือหมดอายุแล้ว',
                )
            }
            return ResolverCode.fromGqlUnion(data.managerFindCurrentCode)
        })
    },
    async usePaymentCode (txId, code) {
        return VApiResponse.convertGql(apolloClient.mutate({
            mutation: gql`
                mutation usePaymentCode($txId:ObjectId!,$code:String!){
                    stadiumPayCustomerTx(customerTxId:$txId,payAtStadiumCode:$code){
                        usedOn
                    }
                }
            `,
            variables: {
                txId,
                code,
            },
        }))
    },
    async usePaymentCodeByManager (txId, code) {
        return VApiResponse.convertGql(apolloClient.mutate({
            mutation: gql`
                mutation usePaymentCode($txId:ObjectId!,$code:String!){
                    managerPayCustomerTx(customerTxId:$txId,payAtStadiumCode:$code){
                        usedOn
                    }
                }
            `,
            variables: {
                txId,
                code,
            },
        }))
    },
    async useRedeemCode (txItemIds, code) {
        return VApiResponse.convertGql(apolloClient.mutate({
            mutation: gql`
                mutation useRedeemCode($txItemIds:[ObjectId]!,$code:String!){
                    stadiumUseRedeemCode(customerTxItemIds:$txItemIds,redeemCode:$code){
                        usedOn
                    }
                }
            `,
            variables: {
                txItemIds,
                code,
            },
        }))
    },
    async useRedeemCodeByManager (txItemIds, code) {
        return VApiResponse.convertGql(apolloClient.mutate({
            mutation: gql`
                mutation useRedeemCode($txItemIds:[ObjectId]!,$code:String!){
                    managerUseRedeemCode(customerTxItemIds:$txItemIds,redeemCode:$code){
                        usedOn
                    }
                }
            `,
            variables: {
                txItemIds,
                code,
            },
        }))
    },
    async fetchStadiumReport (stadiumId, start, end) {
        return VApiResponse.convertGql(apolloClient.query({
            query: gql`
                    query fetchStadiumReport($id:ObjectId!,$start:Date!,$end:Date!){
                        generateStadiumReport(stadiumId:$id,start:$start,end:$end){
                            ...ReportGeneralInfo
                        }
                    }
                    ${ReportGeneralInfo}
                `,
            variables: {
                id: stadiumId,
                start: convertDateTimeToApiFormat(start),
                end: convertDateTimeToApiFormat(end),
            },
        }),
        (data) => ModelReportStadium.fromGql(data.generateStadiumReport))
    },
    updateIconSport (sport) {
        const { id, icon } = sport
        const formData = new FormData()
        formData.append('file', icon)
        return axiosClient({
            method: 'post',
            url: `/sport/${id}/icon`,
            data: formData,
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        })
    },
    updateCoverStadium (stadium) {
        const { id, coverPhoto } = stadium
        const formDataCoverPhoto = new FormData()
        formDataCoverPhoto.append('file', coverPhoto)
        return axiosClient({
            method: 'post',
            url: `/stadium/${id}/cover`,
            data: formDataCoverPhoto,
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        })
    },
    updateLogoStadium (stadium) {
        const { id, logoPhoto } = stadium
        const formDataLogo = new FormData()
        formDataLogo.append('file', logoPhoto)
        return axiosClient({
            method: 'post',
            url: `/stadium/${id}/logo`,
            data: formDataLogo,
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        })
    },
    updateCoverField (field) {
        const { id, coverPhoto } = field
        const formData = new FormData()
        formData.append('file', coverPhoto)
        return axiosClient({
            method: 'post',
            url: `/field/${id}/cover`,
            data: formData,
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        })
    },
}
