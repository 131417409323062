import { createTypes, createTypesWithNamespace } from '@/utils/store-utils'

const _namespace = 'customers'

const types = {
    /**
     * @typedef {Object} Actions
     * @prop {String} LOAD_CUSTOMERS
     * @prop {String} UPDATE_CUSTOMER
     * @prop {String} ADD_CUSTOMER
     * @prop {String} DELETE_CUSTOMER
     * @prop {String} SEARCH_CUSTOMER
     * @prop {String} LOAD_MEMBER_TYPES
     * @prop {String} ADD_MEMBER_TYPE
     * @prop {String} UPDATE_MEMBER_TYPE
     * @prop {String} DELETE_MEMBER_TYPE
     * @prop {String} UNREGISTOR_MEMBER_TYPE
     * @prop {String} REGISTOR_MEMBER_TYPE
     * @prop {String} LOAD_MEMBER_TYPE_HISTORY commend
     */
    Actions: [
        'LOAD_CUSTOMERS',
        'UPDATE_CUSTOMER',
        'ADD_CUSTOMER',
        'DELETE_CUSTOMER',
        'SEARCH_CUSTOMER',
        'LOAD_MEMBER_TYPES',
        'ADD_MEMBER_TYPE',
        'UPDATE_MEMBER_TYPE',
        'DELETE_MEMBER_TYPE',
        'UNREGISTOR_MEMBER_TYPE',
        'REGISTOR_MEMBER_TYPE',
        'LOAD_MEMBER_TYPE_HISTORY',
    ],
    /**
     * @typedef {Object} Mutations
     * @prop {String} LOAD_CUSTOMERS
     * @prop {String} UPDATE_CUSTOMER
     * @prop {String} ADD_CUSTOMER
     * @prop {String} DELETE_CUSTOMER
     * @prop {String} LOAD_MEMBER_TYPES
     * @prop {String} ADD_MEMBER_TYPE
     * @prop {String} UPDATE_MEMBER_TYPE
     * @prop {String} DELETE_MEMBER_TYPE
     * @prop {String} LOAD_MEMBER_TYPE_HISTORY
     */
    Mutations: [
        'LOAD_CUSTOMERS',
        'UPDATE_CUSTOMER',
        'ADD_CUSTOMER',
        'DELETE_CUSTOMER',
        'LOAD_MEMBER_TYPES',
        'ADD_MEMBER_TYPE',
        'UPDATE_MEMBER_TYPE',
        'DELETE_MEMBER_TYPE',
        'LOAD_MEMBER_TYPE_HISTORY',
    ],
    /**
     * @typedef {Object} Getters
     * @prop {String} GET_CUSTOMER
     * @prop {String} GET_MEMBER_TYPE
     * @prop {String} GET_MEMBER_TYPE_HISTORY
     */
    Getters: [
        'GET_CUSTOMER',
        'GET_MEMBER_TYPE',
        'GET_MEMBER_TYPE_HISTORY',
    ],
}
/**
 * @typedef {Object} Types
 * @prop {Actions} Actions
 * @prop {Mutations} Mutations
 * @prop {Getters} Getters
 */
/**
 * @type {Types} types
 */
const { Actions, Getters, Mutations } = createTypesWithNamespace(_namespace, types)

/**
 * @type {Types} types
 */
const { Actions: _Actions, Mutations: _Mutations, Getters: _Getters } = createTypes(types)

export {
    Actions, Getters, Mutations, _Mutations, _Actions, _Getters,
}
