import ModelDiscount from '@/models/ModelDiscount'

export default class ModelDiscountCode {
    constructor (model) {
        Object.assign(this, model)
    }

    static fromGql (gql) {
        const {
            code,
            noSaved,
        } = gql
        return new ModelDiscountCode({
            code: code ? code.toUpperCase() : '',
            noSaved,
            ...ModelDiscount.fromGql(gql),
        })
    }

    static toGql (model) {
        const {
            code,
        } = model

        return {
            code: code ? code.toUpperCase() : '',
            ...ModelDiscount.toGql(model),
        }
    }
}
