import gql from 'graphql-tag'

export const ApplicableCashbackProgramGeneralInfo = gql`
    fragment ApplicableCashbackProgramGeneralInfo on CoinApplicableSettings {
        id
        _id
        amountType
        applicableValue
        maximumApplicableAmount
        disabled
    }
`
export const CashbackProgramGeneralInfo = gql`
    fragment CashbackProgramGeneralInfo on CoinCashbackSettings {
        id
        _id
        amountType
        cashbackValue
        maximumCashbackAmount
        disabled
    }
`
