import { createTypes, createTypesWithNamespace } from '@/utils/store-utils'

const _namespace = 'field'

const types = {
    /**
     * @typedef {Object} Actions
     * @prop {String} LOAD_PRICE_RULES
     * @prop {String} UPDATE_PRICE_RULES
     * @prop {String} LOAD_BASE_PRICE
     * @prop {String} UPDATE_BASE_PRICE
     */
    Actions: [
        'LOAD_PRICE_RULES',
        'UPDATE_PRICE_RULES',
        'LOAD_BASE_PRICE',
        'UPDATE_BASE_PRICE',
    ],
    /**
     * @typedef {Object} Mutations
     * @prop {String} LOAD_PRICE_RULES
     * @prop {String} RESET
     * @prop {String} RESET_PRICE_RULES
     * @prop {String} UPDATE_ID
     * @prop {String} UPDATE_BASE_PRICE
     */
    Mutations: [
        'LOAD_PRICE_RULES',
        'RESET',
        'RESET_PRICE_RULES',
        'UPDATE_ID',
        'UPDATE_BASE_PRICE',
    ],
    /**
     * @typedef {Object} Getters
     */
    Getters: [
    ],
}
/**
 * @typedef {Object} Types
 * @prop {Actions} Actions
 * @prop {Mutations} Mutations
 * @prop {Getters} Getters
 */
/**
 * @type {Types} types
 */
const { Actions, Getters } = createTypesWithNamespace(_namespace, types)

/**
 * @type {Types} types
 */
const { Actions: _Actions, Mutations: _Mutations, Getters: _Getters } = createTypes(types)

export {
    Actions, Getters, _Mutations, _Actions, _Getters,
}
