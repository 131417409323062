import ModelUser from '@/models/ModelUser'

export default class ModelUserManager extends ModelUser {
    constructor (model) {
        const { partyId } = model

        super(model)
        Object.assign(this, {
            partyId,
        })
    }

    static fromGql (gql) {
        const { partyIds } = gql
        return new ModelUserManager({
            ...super.fromGql(gql),
            ...partyIds ? { partyId: partyIds[0] } : {},
        })
    }

    toGql () {
        const {
            partyId,
        } = this
        const partyIds = ModelUserManager.toPartyIds(partyId)
        return {
            ...super.toGql(),
            partyIds,
        }
    }

    static toPartyIds (partyId) {
        switch (partyId) {
        case null:
            return []
        case undefined:
            return undefined
        default:
            return [partyId]
        }
    }
}
