import { createTypes, createTypesWithNamespace } from '@/utils/store-utils'

const _namespace = 'products'

const types = {
    /**
     * @typedef {Object} Actions
     * @prop {String} LOAD_MY_PRODUCTS
     * @prop {String} ADD_PRODUCT
     * @prop {String} UPDATE_PRODUCT
     * @prop {String} DELETE_PRODUCT
     * @prop {String} CANCEL_PRODUCT
     * @prop {String} LOAD_PRODUCT_LOCAL_CUSTOMERS
     * @prop {String} ADD_PRODUCT_LOCAL_CUSTOMER
     * @prop {String} LOAD_PRODUCT_TX_ITEM
     * @prop {String} CANCEL_PRODUCT_TX_ITEM
     * @prop {String} UPDATE_PRODUCT_LOCAL_CUSTOMER
     * @prop {String} UPDATE_PRODUCT_LOCAL_CUSTOMER_IS_PAID
     * @prop {String} DELETE_PRODUCT_LOCAL_CUSTOMER
     * @prop {String} CANCEL_PRODUCT_OFFER
     */
    Actions: [
        'LOAD_MY_PRODUCTS',
        'ADD_PRODUCT',
        'UPDATE_PRODUCT',
        'DELETE_PRODUCT',
        'CANCEL_PRODUCT',
        'LOAD_PRODUCT_LOCAL_CUSTOMERS',
        'ADD_PRODUCT_LOCAL_CUSTOMER',
        'LOAD_PRODUCT_TX_ITEM',
        'CANCEL_PRODUCT_TX_ITEM',
        'UPDATE_PRODUCT_LOCAL_CUSTOMER',
        'UPDATE_PRODUCT_LOCAL_CUSTOMER_IS_PAID',
        'DELETE_PRODUCT_LOCAL_CUSTOMER',
        'CANCEL_PRODUCT_OFFER',
    ],
    /**
     * @typedef {Object} Mutations
     * @prop {String} LOAD_PRODUCTS
     * @prop {String} ADD_PRODUCT
     * @prop {String} UPDATE_PRODUCT
     * @prop {String} DELETE_PRODUCT
     * @prop {String} LOAD_PRODUCT_LOCAL_CUSTOMERS
     * @prop {String} ADD_PRODUCT_LOCAL_CUSTOMER
     * @prop {String} LOAD_PRODUCT_TX_ITEM
     * @prop {String} UPDATE_PRODUCT_TX_ITEM
     * @prop {String} UPDATE_PRODUCT_LOCAL_CUSTOMER
     * @prop {String} DELETE_PRODUCT_LOCAL_CUSTOMER
     */
    Mutations: [
        'LOAD_PRODUCTS',
        'ADD_PRODUCT',
        'UPDATE_PRODUCT',
        'DELETE_PRODUCT',
        'LOAD_PRODUCT_LOCAL_CUSTOMERS',
        'ADD_PRODUCT_LOCAL_CUSTOMER',
        'LOAD_PRODUCT_TX_ITEM',
        'UPDATE_PRODUCT_TX_ITEM',
        'UPDATE_PRODUCT_LOCAL_CUSTOMER',
        'DELETE_PRODUCT_LOCAL_CUSTOMER',
    ],
    /**
     * @typedef {Object} Getters
     * @prop {String} GET_PRODUCT
     */
    Getters: [
        'GET_PRODUCT',
    ],
}
/**
 * @typedef {Object} Types
 * @prop {Actions} Actions
 * @prop {Mutations} Mutations
 * @prop {Getters} Getters
 */
/**
 * @type {Types} types
 */
const { Actions, Getters, Mutations } = createTypesWithNamespace(_namespace, types)

/**
 * @type {Types} types
 */
const { Actions: _Actions, Mutations: _Mutations, Getters: _Getters } = createTypes(types)

export {
    Actions, Getters, Mutations, _Mutations, _Actions, _Getters,
}
