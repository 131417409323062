import { createTypes, createTypesWithNamespace } from '@/utils/store-utils'

const _namespace = 'txItem'

const types = {
    /**
     * @typedef {Object} Actions
     * @prop {String} LOAD_MY_TX_ITEMS
     * @prop {String} ADD_TX_ITEM
     * @prop {String} UPDATE_TX_ITEM
     * @prop {String} DELETE_TX_ITEM
     * @prop {String} CANCEL_TX_ITEM
     */
    Actions: [
        'LOAD_MY_TX_ITEMS',
        'ADD_TX_ITEM',
        'UPDATE_TX_ITEM',
        'DELETE_TX_ITEM',
        'CANCEL_TX_ITEM',
    ],
    /**
     * @typedef {Object} Mutations
     * @prop {String} LOAD_TX_ITEMS
     * @prop {String} ADD_TX_ITEM
     * @prop {String} UPDATE_TX_ITEM
     * @prop {String} DELETE_TX_ITEM
     */
    Mutations: [
        'LOAD_TX_ITEMS',
        'ADD_TX_ITEM',
        'UPDATE_TX_ITEM',
        'DELETE_TX_ITEM',
    ],
    /**
     * @typedef {Object} Getters
     * @prop {String} GET_TX_ITEM
     */
    Getters: [
        'GET_TX_ITEM',
    ],
}
/**
 * @typedef {Object} Types
 * @prop {Actions} Actions
 * @prop {Mutations} Mutations
 * @prop {Getters} Getters
 */
/**
 * @type {Types} types
 */
const { Actions, Getters, Mutations } = createTypesWithNamespace(_namespace, types)

/**
 * @type {Types} types
 */
const { Actions: _Actions, Mutations: _Mutations, Getters: _Getters } = createTypes(types)

export {
    Actions, Getters, Mutations, _Mutations, _Actions, _Getters,
}
