/* eslint-disable class-methods-use-this */
export default class ActionProcessor {
    // eslint-disable-next-line no-unused-vars
    createInterceptors ({ dispatch, commit }, actionName, action, payload) {
        return []
    }

    async process (actionName, context, payload, action) {
        const interceptors = this.createInterceptors(context, actionName, action, payload)
        const activeInterceptors = interceptors.filter((item) => item.isPassRequirement())
        activeInterceptors.forEach((item) => {
            item.before()
        })
        const res = await action(context, payload)
        activeInterceptors.forEach((item) => {
            item.after(res)
        })
        return res
    }
}
