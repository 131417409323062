import { FieldProductGeneralInfo, ProductGeneralInfo } from '@/api/graphql/fragments/product'
import gql from 'graphql-tag'

export const BoostGeneralInfo = gql`
    fragment BoostGeneralInfo on Boost{
        eventType
        boostedOn
    }
`

export const BoostBuffetGeneralInfo = gql`
    fragment BoostBuffetGeneralInfo on BoostBuffet {
        ...ProductGeneralInfo
        ...FieldProductGeneralInfo
        ...BoostGeneralInfo
    }
    ${ProductGeneralInfo}
    ${FieldProductGeneralInfo}
    ${BoostGeneralInfo}
`

export const BoostTrainingGeneralInfo = gql`
    fragment BoostTrainingGeneralInfo on BoostTraining {
        ...ProductGeneralInfo
        ...FieldProductGeneralInfo
        ...BoostGeneralInfo
    }
    ${ProductGeneralInfo}
    ${FieldProductGeneralInfo}
    ${BoostGeneralInfo}
`
export const BoostTournamentGeneralInfo = gql`
    fragment BoostTournamentGeneralInfo on BoostTournament {
        ...ProductGeneralInfo
        ...FieldProductGeneralInfo
        ...BoostGeneralInfo
    }
    ${ProductGeneralInfo}
    ${FieldProductGeneralInfo}
    ${BoostGeneralInfo}
`

export const BoostLocalCustomersGeneralInfo = gql`
    fragment BoostLocalCustomersGeneralInfo on LocalCustomer{
        _id
        id
        firstName
        lastName
        tel
        email
        note
        numberOfPeople
        nickName
        isPaid
    }
`

export const BoostCustomersGeneralInfo = gql`
    fragment BoostCustomersGeneralInfo on Customer{
        _id
        id
        firstName
        lastName
        phoneNumber
        email
        nickName
        username
    }
`

export const BoostReservationGeneralInfo = gql`
    fragment BoostReservationGeneralInfo on Product{
        ...ProductGeneralInfo
        ...FieldProductGeneralInfo
        ...BoostGeneralInfo
    }
    ${ProductGeneralInfo}
    ${FieldProductGeneralInfo}
    ${BoostGeneralInfo}
`
