import { FieldProductGeneralInfo, ProductGeneralInfo } from '@/api/graphql/fragments/product'
import gql from 'graphql-tag'

// eslint-disable-next-line import/prefer-default-export
export const ManagerPartyEventGeneralInfo = gql`
    fragment ManagerPartyEventGeneralInfo on ManagerPartyEvent {
        partyId
        eventType
        ...ProductGeneralInfo
        ...FieldProductGeneralInfo
    }
    ${ProductGeneralInfo}
    ${FieldProductGeneralInfo}
`
