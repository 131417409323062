import VApiResponse from '@/utils/VApiResponse'

function isDuplicateId (list, newId) {
    return list.findIndex((item) => item.id === newId) !== -1
}

export function mockNewId (list) {
    let newId
    do {
        newId = Math.floor(Math.random() * 1000)
    } while (isDuplicateId(list, newId))
    return newId
}

/* eslint-disable no-param-reassign */
export function mockApiCall (
    successCallback,
    errorCallback = () => VApiResponse.ERROR(),
    successCondition = Math.random() >= 0.00,
    latency = 1000,
) {
    return new Promise((resolve, reject) => {
        setTimeout(() => {
            if (successCondition) {
                resolve(successCallback())
            } else {
                reject(errorCallback())
            }
        }, latency)
    })
}

export function mockApiCallLoadList (list) {
    return mockApiCall(
        () => VApiResponse.SUCCESS(list),
        () => VApiResponse.ERROR(),
    )
}

export function mockApiFunctionUpdateListItem (list, id, newValue) {
    const idx = list.findIndex((item) => item.id === id)
    if (list[idx]) {
        list[idx] = { ...list[idx], ...newValue }
        return VApiResponse.SUCCESS({ ...newValue })
    }
    return VApiResponse.ERROR()
}

export function mockApiFunctionAddListItem (list, newValue) {
    let newId
    do {
        newId = Math.floor(Math.random() * 1000)
    } while (isDuplicateId(list, newId))
    const newItem = {
        id: `${newId}ID`,
        ...newValue,
    }
    list.unshift(newItem)
    return VApiResponse.SUCCESS(newItem)
}

export function mockApiFunctionRemoveListItem (list, id) {
    const idx = list.findIndex(((item) => item.id === id))
    if (list[idx]) {
        list.splice(idx, 1)
        return VApiResponse.SUCCESS()
    }
    return VApiResponse.ERROR()
}

export function mockApiFunctionSeachListItem (list, query, unsensitive) {
    const matchedCustomers = list.filter(
        (item) => Object.keys(item).some((key) => {
            if (item[key]) {
                if (unsensitive) {
                    return item[key].toString().toLowerCase().includes(query.toLowerCase())
                }
                return item[key].toString().toLowerCase().includes(query)
            }
            return false
        }),
    )
    if (matchedCustomers) return VApiResponse.SUCCESS(matchedCustomers)
    return VApiResponse.ERROR()
}
