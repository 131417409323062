export default class ModelUser {
    constructor ({
        id,
        email,
        username,
        password,
        firstName,
        lastName,
        tel,
        address,
        postalCode,
        nickName,
        note,
        requireChangePasswd,
        requiredAgreements,
        roles,
    }) {
        Object.assign(this, {
            id,
            email,
            username,
            password,
            firstName,
            lastName,
            tel,
            address,
            postalCode,
            nickName,
            note,
            requireChangePasswd,
            requiredAgreements,
            roles,
        })
    }

    static fromGql (gql) {
        return new ModelUser({
            ...gql,
        })
    }

    toGql () {
        const {
            email,
            username,
            password,
            firstName,
            lastName,
            tel,
            address,
            postalCode,
            nickName,
            note,
            requireChangePasswd,
            roles,
        } = this
        return {
            email,
            username,
            password,
            firstName,
            lastName,
            tel,
            address,
            postalCode,
            nickName,
            note,
            requireChangePasswd,
            roles,
        }
    }
}
