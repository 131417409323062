import ModelCustomer from '@/models/ModelCustomer'
import ModelVariants from '@/models/ModelVariants'
import ResolverProduct from '@/models/resolvers/ResolverProduct'
import moment from 'moment'

export default class ModelTxItem {
    constructor ({
        id,
        type,
        amount,
        usedAmount,
        unusedAmount,
        price,
        stage,
        customer,
        paymentMethod,
        mirrorProduct,
        mirrorVariant,
        updatedOn,
    }) {
        Object.assign(this, {
            id,
            type,
            amount,
            usedAmount,
            unusedAmount,
            price,
            stage,
            customer,
            paymentMethod,
            mirrorProduct,
            mirrorVariant,
            updatedOn,
        })
    }

    static fromGql (gql) {
        const {
            _id,
            type,
            amount,
            price,
            redeemCodeMetas,
            customerTx,
            stage,
            mirrorProduct,
            mirrorVariant,
            updatedOn,
        } = gql
        let txParts = {}
        if (customerTx) {
            const {
                customer,
                paymentMethod,
            } = customerTx
            txParts = {
                customer: ModelCustomer.fromGql(customer),
                paymentMethod,
            }
        }

        const usedRedeemCodeMetas = redeemCodeMetas
            ? redeemCodeMetas.filter((item) => item.stage === 'USED') : null
        const usedAmount = usedRedeemCodeMetas
            ? usedRedeemCodeMetas
                .map((item) => item.amount)
                .reduce((prev, next) => prev + next, 0)
            : 0
        const unusedAmount = amount - usedAmount
        return new ModelTxItem({
            id: _id,
            type,
            amount,
            usedAmount,
            unusedAmount,
            price,
            stage,
            ...txParts,
            mirrorProduct: ResolverProduct.fromGqlUnion(mirrorProduct),
            mirrorVariant: ModelVariants.fromGql(mirrorVariant),
            updatedOn: moment(updatedOn),
        })
    }
}
