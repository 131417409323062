import gql from 'graphql-tag'

// eslint-disable-next-line import/prefer-default-export
export const EmployeeGeneralInfo = gql`
    fragment EmployeeGeneralInfo on StadiumUser {
        id
        username
        email
        firstName
        lastName
        tel
        roles
        stadiumUserRoles {
            stadiumId
            role
        }
        address
        postalCode
        nickName
        note
        requireChangePasswd
    }
`
