export default class ModelCashbackProgram {
    constructor (model) {
        Object.assign(this, model)
    }

    static fromGql (gql) {
        const {
            _id,
            amountType,
            cashbackValue,
            maximumCashbackAmount,
            disabled,
        } = gql
        return new ModelCashbackProgram({
            id: _id,
            amountType,
            cashbackValue,
            maximumCashbackAmount,
            disabled,
        })
    }

    static toGql (model) {
        const {
            amountType,
            cashbackValue,
            maximumCashbackAmount,
            disabled,
        } = model

        return {
            amountType,
            cashbackValue,
            maximumCashbackAmount,
            disabled,
        }
    }
}
