import moment from 'moment'
import {
    mockApiCall,
    mockApiFunctionSeachListItem,
    mockNewId,
} from '@/utils/mock'
import VApiResponse from '@/utils/VApiResponse'
import apolloClient from '@/apolloClient'
import { LocalCustomerGeneralInfo, LocalStadiumCustomerGeneralInfo, StadiumMemberGeneralInfo } from '@/api/graphql/fragments/user'
import gql from 'graphql-tag'
import ModelCustomerLocalStadium from '@/models/ModelCustomerLocalStadium'
import { PageGeneralInfo } from '@/api/graphql/fragments/page'
import ModelPagination from '@/models/ModelPagination'
import ModelMemberType from '@/models/ModelMemberType'

const customers = [
    {
        id: '0',
        gender: 1,
        firstName: 'Johny',
        lastName: 'Wick',
        nickName: 'John',
        tel: '088-888-8888',
        dateOfBirth: moment('31/12/2000 (00:00:00)', 'DD/MM/YYYY (HH:mm:ss)'),
        carrier: 'student',
        address: 'bangkok',
        postalCode: '10303',
        email: 'aaa@gmail.com',
        note: 'love dog',
        membership: {
            id: '101',
            memberType: {
                id: '1',
                name: 'Black',
                description: 'Highest Priority',
                color: '#000',
                type: 'yearly',
                price: '1000',
                expiredInDay: 365,
            },
            registerDate: moment('31/01/2019 (00:00:00)', 'DD/MM/YYYY (HH:mm:ss)'),
            registerDevice: 'mobileApp',
        },
        membershipHistory: [
            {
                id: '101',
                memberType: {
                    id: '1',
                    name: 'Black',
                    description: 'Highest Priority',
                    color: '#000',
                    type: 'yearly',
                    price: '1000',
                    expiredInDay: 365,
                },
                registerDate: moment('31/01/2019 (00:00:00)', 'DD/MM/YYYY (HH:mm:ss)'),
                registerDevice: 'mobileApp',
            },
        ],
    },
    {
        id: '1',
        gender: 2,
        firstName: 'Susan',
        lastName: 'Wick',
        nickName: 'su',
        tel: '089-999-9999',
        dateOfBirth: moment('31/12/1900 (00:00:00)', 'DD/MM/YYYY (HH:mm:ss)'),
        carrier: null,
        address: null,
        postalCode: '10303',
        email: null,
        note: '',
        membership: {
            id: '101',
            memberType: {
                id: '2',
                name: 'Siver',
                description: 'Second Priority',
                color: '#ddd',
                type: 'monthly',
                price: '500',
                expiredInDay: 30,
            },
            registerDate: moment('31/01/2019 (00:00:00)', 'DD/MM/YYYY (HH:mm:ss)'),
            registerDevice: 'mobileApp',
        },
        membershipHistory: [
            {
                id: '201',
                memberType: {
                    id: '1',
                    name: 'Black',
                    description: 'Highest Priority',
                    color: '#000',
                    type: 'yearly',
                    price: '1000',
                    expiredInDay: 365,
                },
                registerDate: moment('01/12/1900 (00:00:00)', 'DD/MM/YYYY (HH:mm:ss)'),
            },
            {
                id: '202',
                memberType: {
                    id: '1',
                    name: 'Black',
                    description: 'Highest Priority',
                    color: '#000',
                    type: 'yearly',
                    price: '1000',
                    expiredInDay: 365,
                },
                registerDate: moment('01/02/1901 (00:00:00)', 'DD/MM/YYYY (HH:mm:ss)'),
            },
        ],
    },
    {
        id: '2',
        gender: 2,
        firstName: 'Susan',
        lastName: 'Wick',
        nickName: 'su',
        tel: '089-999-9999',
        dateOfBirth: moment('31/12/1900 (00:00:00)', 'DD/MM/YYYY (HH:mm:ss)'),
        carrier: null,
        address: null,
        postalCode: '10303',
        email: null,
        note: '',
        membership: null,
        membershipHistory: [],
    },
]

const memberTypes = [
    {
        id: '1',
        name: 'Black',
        description: 'Highest Priority',
        color: '#000',
        type: 'yearly',
        price: '1000',
        expired_in_hour: null,
        expiredInDay: 365,
    },
    {
        id: '2',
        name: 'Siver',
        description: 'Second Priority',
        color: '#ddd',
        type: 'monthly',
        price: '500',
        expiredInDay: 30,
    },
    {
        id: '3',
        name: 'Royalty Member',
        description: 'For customer who continue using Black Member more than 3 years.',
        color: '#F7931E',
        type: 'permanant',
        price: '3000',
        expiredInDay: null,
    },
]

export default {
    loadCustomers ({ stadiumId, page, size }) {
        return VApiResponse.convertGql(apolloClient.query({
            query: gql`
            query getLocalCustomers(
                $query:QueryObject!,
                $page:Int,$size:Int,$sort:QueryObject){
                    stadiumDoLocalStadiumCustomersPagination(query: $query,page:$page,size:$size,sort:$sort){
                        datas{
                            ...LocalCustomerGeneralInfo
                            ...LocalStadiumCustomerGeneralInfo
                        }
                        pageInfo{
                            ...PageGeneralInfo
                        }
                    }
            }
            ${PageGeneralInfo}
            ${LocalCustomerGeneralInfo}
            ${LocalStadiumCustomerGeneralInfo}
            `,
            variables: {
                query: { stadiumId: { $oid: stadiumId } },
                // page start at 0 on server
                page: page - 1,
                size,
                sort: {
                    name: 1,
                },
            },
        }),
        (data) => ModelPagination.fromGql(data.stadiumDoLocalStadiumCustomersPagination))
    },
    updateCustomer (customer) {
        return VApiResponse.convertGql(apolloClient.mutate({
            mutation: gql`
            mutation updateLocalCustomer($id:ObjectId,$localCustomer: LocalStadiumCustomerInput!){
                stadiumDoUpdateLocalStadiumCustomer(id:$id,data:$localCustomer) {
                    ...LocalCustomerGeneralInfo
                    ...LocalStadiumCustomerGeneralInfo
                }
            }
            ${LocalCustomerGeneralInfo}
            ${LocalStadiumCustomerGeneralInfo}
            `,
            variables: {
                id: customer.id,
                localCustomer: ModelCustomerLocalStadium.toGql(customer),
            },
        }),
        async (data) => ModelCustomerLocalStadium.fromGql(data.stadiumDoUpdateLocalStadiumCustomer))
    },
    addCustomer (customer) {
        return VApiResponse.convertGql(apolloClient.mutate({
            mutation: gql`
            mutation insertLocalCustomer($localCustomer: LocalStadiumCustomerInput!){
                stadiumDoInsertLocalStadiumCustomer(data:$localCustomer) {
                    ...LocalCustomerGeneralInfo
                    ...LocalStadiumCustomerGeneralInfo
                }
            }
            ${LocalCustomerGeneralInfo}
            ${LocalStadiumCustomerGeneralInfo}
            `,
            variables: {
                localCustomer: ModelCustomerLocalStadium.toGql(customer),
            },
        }),
        async (data) => ModelCustomerLocalStadium.fromGql(data.stadiumDoInsertLocalStadiumCustomer))
    },
    deleteCustomer (id) {
        return VApiResponse.convertGql(apolloClient.mutate({
            mutation: gql`
            mutation deleteLocalCustomer($id:ObjectId){
                stadiumDoSoftDeleteLocalStadiumCustomer(id:$id)
            }
            `,
            variables: {
                id,
            },
        }),
        (data) => data.stadiumDoSoftDeleteLocalStadiumCustomer)
    },
    searchCustomer (query) {
        return mockApiCall(
            () => mockApiFunctionSeachListItem(customers, query, true),
        )
    },
    loadMemberTypes ({ stadiumId, page, size }) {
        return VApiResponse.convertGql(apolloClient.query({
            query: gql`
            query getMemberTypes(
                $query:QueryObject!,
                $page:Int,$size:Int,$sort:QueryObject){
                    stadiumDoStadiumMembersPagination(query: $query,page:$page,size:$size,sort:$sort){
                        datas{
                            ...StadiumMemberGeneralInfo
                        }
                        pageInfo{
                            ...PageGeneralInfo
                        }
                    }
            }
            ${PageGeneralInfo}
            ${StadiumMemberGeneralInfo}
            `,
            variables: {
                query: {
                    stadiumId: { $oid: stadiumId },
                },
                // page start at 0 on server
                page: page - 1,
                size,
                sort: {
                    name: 1,
                },
            },
        }),
        (data) => ModelPagination.fromGql(data.stadiumDoStadiumMembersPagination))
    },
    addMemberTypes (memberType) {
        return VApiResponse.convertGql(apolloClient.mutate({
            mutation: gql`
            mutation addMemberTypes($memberType: StadiumMemberInput!){
                stadiumDoInsertStadiumMember(data:$memberType) {
                    ...StadiumMemberGeneralInfo
                }
            }
            ${StadiumMemberGeneralInfo}
            `,
            variables: {
                memberType: ModelMemberType.toGql(memberType),
            },
        }),
        async (data) => ModelMemberType.fromGql(data.stadiumDoInsertStadiumMember))
    },
    updateMemberType (memberType) {
        return VApiResponse.convertGql(apolloClient.mutate({
            mutation: gql`
            mutation updateMemberTypes($id:ObjectId!,$memberType: StadiumMemberInput!){
                stadiumDoUpdateStadiumMember(id:$id,data:$memberType) {
                    ...StadiumMemberGeneralInfo
                }
            }
            ${StadiumMemberGeneralInfo}
            `,
            variables: {
                id: memberType.id,
                memberType: ModelMemberType.toGql(memberType),
            },
        }),
        async (data) => ModelMemberType.fromGql(data.stadiumDoUpdateStadiumMember))
    },
    deleteMemberType (id) {
        return VApiResponse.convertGql(apolloClient.mutate({
            mutation: gql`
            mutation deleteMemberType($id:ObjectId!){
                stadiumDoSoftDeleteStadiumMember(id:$id)
            }
            `,
            variables: {
                id,
            },
        }),
        (data) => data.stadiumDoSoftDeleteStadiumMember)
    },
    // eslint-disable-next-line no-unused-vars
    unregistorMemberType (id, reasonsToRemove) {
        return mockApiCall(
            () => {
                const cId = customers.findIndex((item) => item.id === id)
                if (customers[cId]) {
                    const mhId = customers[cId]
                        .membershipHistory
                        .findIndex((item) => item.id === customers[cId].membership.id)
                    customers[cId].membership = null
                    customers[cId].membershipHistory[mhId].reasonsToRemove = reasonsToRemove
                    customers[cId].membershipHistory[mhId].removeDate = moment()
                    return VApiResponse.SUCCESS()
                }
                return VApiResponse.ERROR()
            },
        )
    },
    registorMemberType (customerId, memberTypeId) {
        return mockApiCall(
            () => {
                const memberType = memberTypes.find((item) => item.id === memberTypeId)
                if (memberType) {
                    const targetId = customers.findIndex((item) => item.id === customerId)
                    const newId = mockNewId(customers[targetId].membershipHistory)
                    customers[targetId].membership = {
                        id: newId,
                        memberType,
                        registerDate: moment(),
                        registerDevice: 'webapp',
                    }
                    customers[targetId].membershipHistory.unshift({
                        id: newId,
                        memberType,
                        registerDate: moment(),
                        registerDevice: 'webapp',
                    })
                    return VApiResponse.SUCCESS(customers[targetId].membership)
                }
                return VApiResponse.ERROR()
            },
        )
    },
    loadMemberTypeHistory (customerId) {
        return mockApiCall(
            () => {
                const targetId = customers.findIndex((item) => item.id === customerId)
                if (customers[targetId]) {
                    return VApiResponse.SUCCESS(customers[targetId].membershipHistory)
                }
                return VApiResponse.ERROR()
            },
        )
    },
}
