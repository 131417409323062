import { createTypes, createTypesWithNamespace } from '@/utils/store-utils'

const _namespace = 'partys'

const types = {
    /**
     * @typedef {Object} Actions
     * @prop {String} LOAD_PARTYS
     * @prop {String} LOAD_PARTY
     * @prop {String} UPDATE_PARTY
     * @prop {String} ADD_PARTY
     * */
    Actions: [
        'LOAD_PARTYS',
        'LOAD_PARTY',
        'UPDATE_PARTY',
        'ADD_PARTY',
    ],
    /**
     * @typedef {Object} Mutations
     * @prop {String} LOAD_PARTYS
     * @prop {String} UPDATE_PARTY
     * @prop {String} ADD_PARTY
     */
    Mutations: [
        'LOAD_PARTYS',
        'UPDATE_PARTY',
        'ADD_PARTY',
    ],
    /**
     * @typedef {Object} Getters
     * @prop {String} GET_PARTY
     */
    Getters: [
        'GET_PARTY',
    ],
}
/**
 * @typedef {Object} Types
 * @prop {Actions} Actions
 * @prop {Mutations} Mutations
 * @prop {Getters} Getters
 */
/**
 * @type {Types} types
 */
const { Actions, Getters } = createTypesWithNamespace(_namespace, types)

/**
 * @type {Types} types
 */
const { Actions: _Actions, Mutations: _Mutations, Getters: _Getters } = createTypes(types)

export {
    Actions, Getters, _Mutations, _Actions, _Getters,
}
