import ModelBoost from '@/models/ModelBoost'
import ModelBoostBuffet from '@/models/ModelBoostBuffet'
import ModelBoostReservation from '@/models/ModelBoostReservation'
import {
    EventType,
} from '@/utils/enum'

export default class ResolverBoost {
    static fromGqlUnion (gql) {
        const {
            type,
        } = gql
        if (type === EventType.BUFFET) return ModelBoostBuffet.fromGql(gql)
        if (type === EventType.RESERVATION) return ModelBoostReservation.fromGql(gql)
        return ModelBoost.fromGql(gql)
    }
}
