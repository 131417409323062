import ModelItem from '@/models/ModelItem'
import ModelItemCondition from '@/models/ModelItemCondition'
import moment from 'moment'

export default class ModelDiscount {
    constructor (model) {
        Object.assign(this, model)
    }

    static fromGql (gql) {
        const {
            _id,
            discountType,
            discountValue,
            noClaimed,
            noUsed,
            maxDiscountAmount,
            claimAmountLimitPerPerson,
            duration,
            validOn,
            invalidOn,
            publishedOn,
            unpublishedOn,
            forcedUnpublished,
            disabled,
            buyCondition,
            useCondition,
        } = gql
        return new ModelDiscount({
            id: _id,
            discountValue,
            discountType,
            noClaimed,
            noUsed,
            maxDiscountAmount,
            claimAmountLimitPerPerson,
            date: validOn ? moment(validOn) : null,
            start: validOn ? moment(validOn) : null,
            end: invalidOn ? moment(invalidOn) : null,
            ...duration ? { duration: (duration / (60 * 24)) } : {},
            validOn: validOn ? moment(validOn) : null,
            invalidOn: invalidOn ? moment(invalidOn) : null,
            publishedOn: publishedOn ? moment(publishedOn) : null,
            unpublishedOn: unpublishedOn ? moment(unpublishedOn) : null,
            ...forcedUnpublished !== null && forcedUnpublished !== undefined
                ? { forcedUnpublished: !!forcedUnpublished } : {},
            disabled,
            buyCondition: buyCondition ? ModelItemCondition.fromGql(buyCondition) : null,
            useCondition: useCondition ? ModelItemCondition.fromGql(useCondition) : null,
            ...ModelItem.fromGql(gql),
        })
    }

    static toGql (model) {
        const {
            discountValue,
            discountType,
            maxDiscountAmount,
            claimAmountLimitPerPerson,
            start,
            end,
            publishedOn,
            forcedUnpublished,
            duration,
            disabled,
            buyCondition,
            useCondition,
        } = model

        return {
            discountValue,
            discountType,
            maxDiscountAmount,
            claimAmountLimitPerPerson,
            ...duration ? { duration: duration * 60 * 24 } : {},
            ...start ? { validOn: start.format() } : {},
            ...end ? { invalidOn: end.format() } : {},
            ...publishedOn ? { publishedOn: publishedOn.format() } : {},
            ...end ? { unpublishedOn: end.format() } : {},
            ...forcedUnpublished !== null && forcedUnpublished !== undefined
                ? { forcedUnpublished: !!forcedUnpublished } : {},
            disabled,
            ...buyCondition ? { buyCondition: ModelItemCondition.toGql(buyCondition) } : {},
            ...useCondition ? { useCondition: ModelItemCondition.toGql(useCondition) } : {},
            ...ModelItem.toGql(model),
        }
    }
}
