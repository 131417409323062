<template>
    <div>
        <ModalSessionTimeout />
        <HomeLayout
            :sidebar="sidebar"
            default-path="/admin/stadium"
            default-group="stadium" />
    </div>
</template>

<script>
import HomeLayout from '@/components/HomeLayout.vue'
import ModalSessionTimeout from '@/components/ModalSessionTimeout.vue'

export default {
    components: {
        HomeLayout,
        ModalSessionTimeout,
    },
    data () {
        return {
            sidebar: [
                {
                    label: 'สเตเดี้ยม',
                    group: 'stadium',
                    icon: 'home',
                    items: [
                        {
                            label: 'ทั่วไป',
                            path: '/admin/stadium',
                        },
                        {
                            label: 'กีฬา',
                            path: '/admin/stadium/sports',
                        },
                    ],
                },
                {
                    label: 'ผู้จัดการ',
                    group: 'manager',
                    icon: 'user',
                    items: [
                        {
                            label: 'ทั่วไป',
                            path: '/admin/manager',
                        },
                    ],
                },
                {
                    label: 'ส่วนลด',
                    group: 'discount',
                    icon: 'percentage',
                    items: [
                        {
                            label: 'โค้ดส่วนลด',
                            path: '/admin/discount/discount-codes',
                        },
                        {
                            label: 'คูปอง',
                            path: '/admin/discount/coupons',
                        },
                    ],
                },
                {
                    label: 'ขายส่วนลด',
                    group: 'discount-for-sale',
                    icon: 'shopping-cart',
                    items: [
                        {
                            label: 'แพ็กเกจส่วนลด',
                            path: '/admin/discount-for-sale/bundle-coupons',
                        },
                        {
                            label: 'ส่วนลดสำหรับแพ็กเกจ',
                            path: '/admin/discount-for-sale/coupon-for-bundles',
                        },
                    ],
                },
                {
                    label: 'กิจกรรมชวนเพื่อน',
                    group: 'referral',
                    icon: 'share-alt',
                    items: [
                        {
                            label: 'กิจกรรม',
                            path: '/admin/referral/referral-program',
                        },
                        {
                            label: 'รางวัล',
                            path: '/admin/referral/referral-promotions',
                        },
                    ],
                },
                {
                    label: 'คั้งค่า รับเงินคืน',
                    group: 'cashback',
                    icon: 'dollar',
                    items: [
                        {
                            label: 'รับเงินคืน',
                            path: '/admin/cashback/earning-cashback-programs',
                        },
                        {
                            label: 'เงื่อนไข ใช้เงินคืน',
                            path: '/admin/cashback/applicable-cashback-programs',
                        },
                    ],
                },
                {
                    label: 'Banner',
                    group: 'banner',
                    icon: 'notification',
                    items: [
                        {
                            label: 'สไลด์ Banner',
                            path: '/admin/banner/banner-lists',
                        },
                        {
                            label: 'รายการ Banner',
                            path: '/admin/banner/banner-list-items',
                        },
                    ],
                },
            ],
        }
    },
}
</script>
