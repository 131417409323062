import { createTypes, createTypesWithNamespace } from '@/utils/store-utils'

const _namespace = 'employees'

const types = {
    /**
     * @typedef {Object} Actions
     * @prop {String} LOAD_EMPLOYEES
     * @prop {String} DELETE_EMPLOYEE
     * @prop {String} ADD_EMPLOYEE
     * @prop {String} UPDATE_EMPLOYEE
     */
    Actions: [
        'LOAD_EMPLOYEES',
        'DELETE_EMPLOYEE',
        'ADD_EMPLOYEE',
        'UPDATE_EMPLOYEE',
    ],
    /**
     * @typedef {Object} Mutations
     * @prop {String} LOAD_EMPLOYEES
     * @prop {String} DELETE_EMPLOYEE
     * @prop {String} ADD_EMPLOYEE
     * @prop {String} UPDATE_EMPLOYEE
     */
    Mutations: [
        'LOAD_EMPLOYEES',
        'DELETE_EMPLOYEE',
        'ADD_EMPLOYEE',
        'UPDATE_EMPLOYEE',
    ],
    /**
     * @typedef {Object} Getters
     * @prop {String} GET_EMPLOYEE
     */
    Getters: [
        'GET_EMPLOYEE',
    ],
}
/**
 * @typedef {Object} Types
 * @prop {Actions} Actions
 * @prop {Mutations} Mutations
 * @prop {Getters} Getters
 */
/**
 * @type {Types} types
 */
const { Actions, Getters } = createTypesWithNamespace(_namespace, types)

/**
 * @type {Types} types
 */
const { Actions: _Actions, Mutations: _Mutations, Getters: _Getters } = createTypes(types)

export {
    Actions, Getters, _Mutations, _Actions, _Getters,
}
