import ModelUser from '@/models/ModelUser'
import { AdminRole } from '@/utils/enum'

export default class ModelUserAdmin extends ModelUser {
    constructor (model) {
        const { adminRole } = model

        super(model)
        Object.assign(this, {
            adminRole,
        })
    }

    static fromGql (gql) {
        const { adminRole } = gql
        return new ModelUserAdmin({
            ...super.fromGql(gql),
            ...adminRole ? { adminRole: AdminRole[adminRole] } : {},
        })
    }
}
