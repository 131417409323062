import gql from 'graphql-tag'

// eslint-disable-next-line import/prefer-default-export
export const PartyGeneralInfo = gql`
    fragment PartyGeneralInfo on ManagerParty {
        id
        name
        desc
        coverPhotoUrl
        logoPhotoUrl
    }
`
