import ActionInterceptor from '@/utils/ActionInterceptor'
import { Actions } from '@/store/types'

export default class MsgProcessingInterceptor extends ActionInterceptor {
    constructor ({ dispatch }, actionName, action, payload) {
        super({ dispatch }, actionName, action, payload)
        this.msgId = null
    }

    async before () {
        this.msgId = await this.dispatch(
            Actions.message.SHOW_PROCESS_MSG,
            { caller: this.actionName, callerPayload: this.payload },
        )
    }

    after () {
        this.dispatch(
            Actions.message.HIDE_MESSAGE,
            { id: this.msgId, caller: this.actionName, callerPayload: this.payload },
        )
    }

    isPassRequirement () {
        return [
            Actions.ADD_FIELD, Actions.UPDATE_STADIUM_FIELD,
        ].includes(this.actionName)
    }
}
