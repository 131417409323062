import { PaymentMethod } from '@/utils/enum'

export default class ModelVariants {
    constructor ({
        id,
        name,
        pricings,
        minPrice,
        fullPrice,
        payOfflinePrice,
        isDefault,
        amountPerUnit,
    }) {
        Object.assign(this, {
            id,
            name,
            pricings,
            minPrice,
            fullPrice,
            payOfflinePrice,
            isDefault,
            amountPerUnit,
        })
    }

    static fromGql (gql) {
        // if null/undefined return null/undefined
        if (!gql) return gql
        const {
            variantId,
            name,
            pricings,
            minPrice,
            fullPrice,
            isDefault,
            amountPerUnit,
        } = gql

        let payOfflinePrice
        if (pricings) {
            const pricing = pricings.find((p) => p.paymentMethod === PaymentMethod.PAY_AT_STADIUM)
            payOfflinePrice = pricing ? pricing.price : null
        }

        return new ModelVariants({
            id: variantId,
            name,
            pricings,
            minPrice,
            payOfflinePrice,
            fullPrice,
            isDefault,
            amountPerUnit,
        })
    }

    toGql () {
        const {
            id,
            name,
            pricings,
            fullPrice,
            isDefault,
            amountPerUnit,
        } = this
        return {
            ...id ? { variantId: id } : {},
            name,
            pricings,
            fullPrice,
            isDefault,
            amountPerUnit,
        }
    }
}
