import ApiReferral from '@/api/ApiReferral'

export default {
    loadReferralPrograms (isPast, page, size) {
        return ApiReferral.loadReferralPrograms(isPast, page, size)
    },
    addReferralProgram (referralProgram) {
        return ApiReferral.addReferralProgram(referralProgram)
    },
    updateReferralProgram (referralProgram) {
        return ApiReferral.updateReferralProgram(referralProgram)
    },
    deleteReferralProgram (referralProgram) {
        return ApiReferral.deleteReferralProgram(referralProgram)
    },
    loadReferralPromotion (id) {
        return ApiReferral.loadReferralPromotion(id)
    },
    loadReferralPromotions (isPast, page, size) {
        return ApiReferral.loadReferralPromotions(isPast, page, size)
    },
    addReferralPromotion (referralPromotion) {
        return ApiReferral.addReferralPromotion(referralPromotion)
    },
    updateReferralPromotion (referralPromotion) {
        return ApiReferral.updateReferralPromotion(referralPromotion)
    },
    deleteReferralPromotion (referralPromotion) {
        return ApiReferral.deleteReferralPromotion(referralPromotion)
    },
    activeReferralProgram (id) {
        return ApiReferral.activeReferralProgram(id)
    },
    loadActiveReferralProgram () {
        return ApiReferral.loadActiveReferralProgram()
    },
}
