import { ProductType, WeekDay } from '@/utils/enum'
import moment from 'moment'
import { RRule } from 'rrule'

// eslint-disable-next-line import/prefer-default-export
export const toTitleCase = (str) => str.replace(
    /\w\S*/g,
    (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase(),
)

export const includeText = (text, query) => {
    if (!text) return false
    if (!query) return true
    return text.toString().toLowerCase().indexOf(query.toString().toLowerCase()) !== -1
}

export const convertDateTimeToApiFormat = (datetime) => `${datetime.toISOString().split('.')[0]}Z`

export const percentChange = (from, to) => {
    if (to - from === 0) return 0
    return ((to - from) / from) * 100
}

export const isEqual = (...objects) => objects.every(
    (obj) => JSON.stringify(obj) === JSON.stringify(objects[0]),
)

export const isSameDateTime = (start, end) => start.valueOf() === end.valueOf()
export const isSameTime = (start, end) => start.format('HH:mm:ss') === end.format('HH:mm:ss')
export const isSameDate = (start, end) => start.format('DD-MM-YYYY') === end.format('DD-MM-YYYY')
export const isSameYear = (start, end) => start.format('YYYY') === end.format('YYYY')

export const range = (start, end) => {
    const result = []
    for (let i = start; i <= end; i += 1) {
        result.push(i)
    }
    return result
}

export const isReserveType = (product) => {
    if (product && product.type === ProductType.RESERVE) return true
    return false
}

export const isValidUrl = (string) => {
    let url
    try {
        // This construct will alway error if we remove {new}
        // eslint-disable-next-line no-new
        url = new URL(string)
    } catch (e) {
        return false
    }
    return url.protocol === 'http:' || url.protocol === 'https:'
}

export const isEmptyString = (string) => {
    if (string === null || string === undefined) return true
    return string.trim() === ''
}

export const isEmpty = (value) => {
    if (value === null || value === undefined) return true
    return false
}

export const isEmptyArray = (value) => {
    if (value === null || value === undefined) return true
    return value.length === 0
}
export const isObjectEmpty = (value) => (
    isEmpty(value) || (Object.prototype.toString.call(value) === '[object Object]'
      && JSON.stringify(value) === '{}')
)
// Draft for Advance Feature
// export const getComponentNameFromVNode = (vnode) => vnode.componentOptions.tag
// export const getChildrenComponentFromVNode = (vnode) => (vnode && vnode.context
//     ? vnode.componentOptions.children : [])
// export const getDeepChildrenComponentFromVNode = (vnode) => {
//     if (!vnode) return []
//     const children = getChildrenComponentFromVNode(vnode)
//     if (typeof children === 'undefined' || children.length === 0) return []
//     const deepChildren = []
//     for (let i = 0; i < children.length; i++) {
//         deepChildren.push(...getDeepChildrenComponentFromVNode(children[i]))
//     }
//     return [...children, ...deepChildren]
// }
// export const getDeepChildrenComponentFromVNodeByName = (vnode, name) => {
//     const deepChildren = getDeepChildrenComponentFromVNode(vnode)
//     const filtered = []
//     for (let i = 0; i < deepChildren.length; i++) {
//         const child = deepChildren[i]
//         if (getComponentNameFromVNode(child) === name) { filtered.push(deepChildren[i]) }
//     }
//     return filtered
// }

export const gcd = (a, b) => {
    if (!b) return a
    return gcd(b, a % b)
}

export const convertToRruleWeekDay = (weekDay) => {
    switch (weekDay) {
    case 'mon':
        return RRule.MO
    case 'tue':
        return RRule.TU
    case 'wed':
        return RRule.WE
    case 'thu':
        return RRule.TH
    case 'fri':
        return RRule.FR
    case 'sat':
        return RRule.SA
    case 'sun':
        return RRule.SU
    default:
        return null
    }
}

export const convertToOurWeekDay = (rruleWeekDay) => {
    switch (rruleWeekDay) {
    case RRule.MO:
        return 'mon'
    case RRule.TU:
        return 'tue'
    case RRule.WE:
        return 'wed'
    case RRule.TH:
        return 'thu'
    case RRule.FR:
        return 'fri'
    case RRule.SA:
        return 'sat'
    case RRule.SU:
        return 'sun'
    default:
        return null
    }
}

export const getAllWeekDays = () => Object.keys(WeekDay).map((key) => key.toLowerCase())

export const timer = (ms) => new Promise((res) => setTimeout(res, ms))
export const getFirstRangeTimesFromRrule = (rule, minutesDuration) => {
    const currentDate = moment().toDate()
    const start = moment(rule.after(currentDate)).utc()
    const end = moment(start).add(minutesDuration, 'minutes')
    return [start, end]
}

export const logError = (error, errorMsg) => {
    // only in dev env
    if (process.env.NODE_ENV !== 'production') {
        // eslint-disable-next-line no-console
        console.error(errorMsg)
        // eslint-disable-next-line no-console
        if (error && error.stack) console.error(error.stack)
        // eslint-disable-next-line no-console
        else if (error) console.error(error)
    }
}

export const fallbackCopyTextToClipboard = (text) => {
    const textArea = document.createElement('textarea')
    textArea.value = text

    // Avoid scrolling to bottom
    textArea.style.top = '0'
    textArea.style.left = '0'
    textArea.style.position = 'fixed'

    document.body.appendChild(textArea)
    textArea.focus()
    textArea.select()

    try {
        const successful = document.execCommand('copy')
        const msg = successful ? 'successful' : 'unsuccessful'
        // eslint-disable-next-line
        console.log(`Fallback: Copying text command was ${ msg}`)
    } catch (err) {
        // eslint-disable-next-line
        console.error('Fallback: Oops, unable to copy', err)
    }

    document.body.removeChild(textArea)
}
export const copyTextToClipboard = (text) => {
    if (!navigator.clipboard) {
        fallbackCopyTextToClipboard(text)
        return
    }
    navigator.clipboard.writeText(text).then(() => {
        // eslint-disable-next-line no-console
        console.log('Async: Copying to clipboard was successful!')
    }, (err) => {
        // eslint-disable-next-line no-console
        console.error('Async: Could not copy text: ', err)
    })
}

export const removeUndefinedKeyOnObj = (obj) => (obj ? Object.keys(obj).reduce((acc, key) => {
    const _acc = acc
    if (obj[key] !== undefined) _acc[key] = obj[key]
    return _acc
}, {}) : obj)
