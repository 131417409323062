import { PageGeneralInfo } from '@/api/graphql/fragments/page'
import { ReferralProgramGeneralInfo, ReferralPromotionGeneralInfo } from '@/api/graphql/fragments/referral'
import apolloClient from '@/apolloClient'
import gql from 'graphql-tag'
import ModelDiscount from '@/models/ModelDiscount'
import ModelPagination from '@/models/ModelPagination'
import ModelReferralProgram from '@/models/ModelReferralProgram'
import moment from 'moment'
import VApiResponse from '@/utils/VApiResponse'

function getIsPastQuery (isPast) {
    if (isPast != null) {
        if (isPast) {
            return {
                expiredOn: {
                    $lt: {
                        $date: moment().valueOf(),
                    },
                },
            }
        }
        return {
            expiredOn: {
                $gt: {
                    $date: moment().valueOf(),
                },
            },
        }
    }

    return {}
}

function getSort (isPast) {
    return {
        publishedOn: isPast ? -1 : 1,
    }
}

export default {
    async addReferralProgram (referralProgram) {
        return VApiResponse.convertGql(
            apolloClient.mutate({
                mutation: gql`
                mutation addReferralProgram($data: ReferralProgramInput!){
                    insertReferralProgram(data:$data) {
                        ...ReferralProgramGeneralInfo
                    }
                }
                ${ReferralProgramGeneralInfo}
                `,
                variables: {
                    data: ModelReferralProgram.toGql(referralProgram),
                },
            }),
            (data) => ModelReferralProgram.fromGql(data.insertReferralProgram),
        )
    },
    async loadReferralPrograms (isPast, page, size) {
        return this._loadReferralPrograms({
            ...getIsPastQuery(isPast),
        }, page, size, { ...getSort(isPast) })
    },
    async updateReferralProgram (referralProgram) {
        return VApiResponse.convertGql(
            apolloClient.mutate({
                mutation: gql`
                mutation updateReferralProgram($id:ObjectId,$data: ReferralProgramInput!){
                    updateReferralProgram(id:$id,data:$data) {
                        ...ReferralProgramGeneralInfo
                    }
                }
                ${ReferralProgramGeneralInfo}
                `,
                variables: {
                    id: referralProgram.id,
                    data: ModelReferralProgram.toGql(referralProgram),
                },
            }),
            (data) => ModelReferralProgram.fromGql(data.updateReferralProgram),
        )
    },
    async deleteReferralProgram (id) {
        return VApiResponse.convertGql(
            await apolloClient.mutate({
                mutation: gql`
                mutation deleteReferralProgram($id:ObjectId){
                    deleteReferralProgram(id:$id)
                }
                `,
                variables: {
                    id,
                },
            }),
        )
    },
    async addReferralPromotion (referralProgram) {
        return VApiResponse.convertGql(
            apolloClient.mutate({
                mutation: gql`
                mutation addReferralPromotion($data: ReferralPromotionInput!){
                    insertReferralPromotion(data:$data) {
                        ...ReferralPromotionGeneralInfo
                    }
                }
                ${ReferralPromotionGeneralInfo}
                `,
                variables: {
                    data: ModelDiscount.toGql(referralProgram),
                },
            }),
            (data) => ModelDiscount.fromGql(data.insertReferralPromotion),
        )
    },
    async loadReferralPromotions (isPast, page, size) {
        return VApiResponse.convertGql(
            await apolloClient.query({
                query: gql`
                query getReferralPromotionsPagination($query:QueryObject!,$page:Int,$size:Int,$sort:QueryObject){
                    referralPromotionsPagination (query: $query, page: $page, size: $size,sort:$sort) {
                        datas{
                            ...ReferralPromotionGeneralInfo
                        }
                        pageInfo{
                            ...PageGeneralInfo
                        }
                    }
                }
                ${ReferralPromotionGeneralInfo}
                ${PageGeneralInfo}
                `,
                variables: {
                    query: {
                        ...getIsPastQuery(isPast),
                    },
                    // page start at 0 on server
                    page: page - 1,
                    size,
                    sort: { ...getSort(isPast) },
                },
            }),
            (data) => ModelPagination.fromGql(data.referralPromotionsPagination),
        )
    },
    async updateReferralPromotion (referralPromotion) {
        return VApiResponse.convertGql(
            apolloClient.mutate({
                mutation: gql`
                mutation updateReferralPromotion($id:ObjectId,$data: ReferralPromotionInput!){
                    updateReferralPromotion(id:$id,data:$data) {
                        ...ReferralPromotionGeneralInfo
                    }
                }
                ${ReferralPromotionGeneralInfo}
                `,
                variables: {
                    id: referralPromotion.id,
                    data: ModelDiscount.toGql(referralPromotion),
                },
            }),
            (data) => ModelDiscount.fromGql(data.updateReferralPromotion),
        )
    },
    async deleteReferralPromotion (id) {
        return VApiResponse.convertGql(
            await apolloClient.mutate({
                mutation: gql`
                mutation deleteReferralProgram($id:ObjectId){
                    deleteReferralPromotion(id:$id)
                }
                `,
                variables: {
                    id,
                },
            }),
        )
    },
    async activeReferralProgram (id) {
        return VApiResponse.convertGql(
            await apolloClient.mutate({
                mutation: gql`
                mutation  ($id:ObjectId){
                    enableReferralProgram(id:$id){
                        id
                    }
                }
                `,
                variables: {
                    id,
                },
            }),
        )
    },
    async _loadReferralPrograms (query, page, size, sort) {
        return VApiResponse.convertGql(
            await apolloClient.query({
                query: gql`
                query getReferralProgramsPagination($query:QueryObject!,$page:Int,$size:Int,$sort:QueryObject){
                    referralProgramsPagination(query: $query, page: $page, size: $size,sort:$sort) {
                        datas{
                            ...ReferralProgramGeneralInfo
                        }
                        pageInfo{
                            ...PageGeneralInfo
                        }
                    }
                }
                ${ReferralProgramGeneralInfo}
                ${PageGeneralInfo}
                `,
                variables: {
                    query: {
                        ...query,
                    },
                    // page start at 0 on server
                    page: page - 1,
                    size,
                    sort: { ...sort },
                },
            }),
            (data) => ModelPagination.fromGql(data.referralProgramsPagination),
        )
    },
    async loadActiveReferralProgram () {
        const res = await this._loadReferralPrograms({ enabled: true }, 1, 1)
        const { status, msg, data } = res
        const result = new VApiResponse(
            { status, msg, data: res.isSuccess() ? data.datas[0] : data },
        )
        return result
    },
    async loadReferralPromotion (id) {
        return VApiResponse.convertGql(
            await apolloClient.query({
                query: gql`
                query getReferralPromotion($id:ObjectId!){
                    referralPromotion (id:$id) {
                        ...ReferralPromotionGeneralInfo
                    }
                }
                ${ReferralPromotionGeneralInfo}
                `,
                variables: {
                    id,
                },
            }),
            (data) => ModelDiscount.fromGql(data.referralPromotion),
        )
    },
}
