import { Msg } from '@/utils/enum'
import { _Actions, _Mutations } from './types'

export default {
    namespaced: true,
    state: {
        total: 0, // include deleted message
        /**
         * @typedef {Object} Message
         * @prop {String} id
         * @prop {String} type
         * @prop {String} message
         * @prop {String} caller reference to caller
         * @prop {String} callerPayload paylaod of caller
         * @prop {?Number} dismissAfter dismiss msg after X milisecond,null is mean no dismiss
         *
         */
        /**
         * @type {Message[]}
         */
        messages: [],
    },
    actions: {
        [_Actions.SHOW_MESSAGE] ({ commit, dispatch, state }, msg) {
            const id = state.total
            commit(_Mutations.ADD_MESSAGE, { id, ...msg })
            if (msg.dismissAfter) {
                setTimeout(() => {
                    dispatch(_Actions.HIDE_MESSAGE, { id, ...msg })
                }, msg.dismissAfter * 1000)
            }
            return id
        },
        [_Actions.HIDE_MESSAGE] ({ commit }, { id, caller, callerPayload }) {
            commit(_Mutations.DELETE_MESSAGE, { id, caller, callerPayload })
        },
        async [_Actions.SHOW_SAVE_SUCCESS_MSG] ({ dispatch }, { caller, callerPayload, data }) {
            const id = await dispatch(_Actions.SHOW_MESSAGE, {
                ...Msg.SUCCESS, caller, callerPayload, data,
            })
            return id
        },
        async [_Actions.SHOW_PROCESS_MSG] ({ dispatch }, { caller, callerPayload, data }) {
            const id = await dispatch(_Actions.SHOW_MESSAGE, {
                ...Msg.PROCESSING, caller, callerPayload, data,
            })
            return id
        },
        async [_Actions.SHOW_ERROR_MSG] ({ dispatch }, {
            caller, callerPayload, data, msg,
        }) {
            const id = await dispatch(_Actions.SHOW_MESSAGE, {
                ...Msg.ERROR, caller, callerPayload, data, message: msg || Msg.ERROR.message,
            })
            return id
        },
    },
    mutations: {
        [_Mutations.ADD_MESSAGE] (state, msg) {
            state.messages = [...state.messages, msg]
            state.total += 1
        },
        // caller,callerPayload is used for show in dev-tool.
        // eslint-disable-next-line no-unused-vars
        [_Mutations.DELETE_MESSAGE] (state, { id, caller, callerPayload }) {
            state.messages = state.messages.filter((item) => item.id !== id)
        },
    },
}
