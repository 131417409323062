import ModelCustomerLocalStadium from '@/models/ModelCustomerLocalStadium'

export default class ModelTxItemLocal {
    constructor (model) {
        const { numberOfPeople, isPaid, localStadiumCustomerId } = model
        Object.assign(this, {
            ...new ModelCustomerLocalStadium(model),
            numberOfPeople,
            isPaid,
            localStadiumCustomerId,
        })
    }

    static fromGql (gql) {
        const {
            isPaid,
            numberOfPeople,
            localStadiumCustomerId,
        } = gql
        return new ModelTxItemLocal({
            ...ModelCustomerLocalStadium.fromGql(gql),
            numberOfPeople,
            isPaid,
            localStadiumCustomerId,
        })
    }

    static toGql (model) {
        const {
            isPaid,
            numberOfPeople,
            localStadiumCustomerId,
            // stadiumMembers,
        } = model
        const { ...modelCustomerLocalStadium } = ModelCustomerLocalStadium.toGql(model)

        return {
            ...modelCustomerLocalStadium,
            ...numberOfPeople ? { numberOfPeople } : {},
            ...localStadiumCustomerId ? { localStadiumCustomerId } : {},
            // ...stadiumMembers ? { stadiumMembers } : {},
            isPaid,
        }
    }

    getCustomer () {
        return new ModelCustomerLocalStadium({ ...this, id: this.localStadiumCustomerId })
    }
}
