export default class ModelCustomerLocal {
    constructor ({
        id,
        firstName,
        lastName,
        tel,
        email,
        note,
        nickName,
    }) {
        Object.assign(this, {
            id,
            firstName,
            lastName,
            tel,
            email,
            note,
            nickName,
        })
    }

    static fromGql (gql) {
        const {
            id,
            firstName,
            lastName,
            tel,
            email,
            note,
            nickName,
        } = gql
        return new ModelCustomerLocal({
            id,
            firstName,
            lastName,
            tel,
            email,
            note,
            nickName,
        })
    }

    static toGql (model) {
        const {
            firstName,
            lastName,
            tel,
            email,
            note,
            nickName,
        } = model
        return {
            firstName,
            lastName,
            tel,
            email,
            note,
            nickName,
        }
    }
}
