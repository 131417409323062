import { createTypes, createTypesWithNamespace } from '@/utils/store-utils'

const _namespace = 'stadium'

const types = {
    /**
     * @typedef {Object} Actions
     * @prop {String} LOAD_MY_PRODUCTS
     * @prop {String} ADD_PRODUCT
     * @prop {String} UPDATE_PRODUCT
     * @prop {String} DELETE_PRODUCT
     * @prop {String} LOAD_FIELDS
     * @prop {String} LOAD_FIELDS_BY_IDS
     * @prop {String} ADD_FIELD
     * @prop {String} UPDATE_FIELD
     * @prop {String} DELETE_FIELD
     * @prop {String} LOAD_HOLIDAYS
     * @prop {String} ADD_HOLIDAY
     * @prop {String} UPDATE_HOLIDAY
     * @prop {String} DELETE_HOLIDAY
     * @prop {String} LOAD_GENERAL_INFO
     * @prop {String} UPDATE_GENERAL_INFO
     * @prop {String} LOAD_PRODUCT_LOCAL_CUSTOMERS
     * @prop {String} ADD_PRODUCT_LOCAL_CUSTOMER
     * @prop {String} LOAD_PRODUCT_TX_ITEM
     * @prop {String} UPDATE_PRODUCT_LOCAL_CUSTOMER
     * @prop {String} DELETE_PRODUCT_LOCAL_CUSTOMER
     * @prop {String} LOAD_STADIUMS
     * @prop {String} DELETE_STADIUM
     * @prop {String} UPDATE_STADIUM
     * @prop {String} ADD_STADIUM
     * @prop {String} SET_MY_STADIUM
     * @prop {String} LOAD_SPORTS
     * @prop {String} ADD_SPORT
     * @prop {String} UPDATE_SPORT
     * @prop {String} DELETE_SPORT
     * @prop {String} LOAD_TX
     * @prop {String} USE_TX
     * @prop {String} PAY_TX
     * @prop {String} CLEAR_ACTIVE_TX
     * @prop {String} LOAD_STADIUM_REPORT
     */
    Actions: [
        'LOAD_MY_PRODUCTS',
        'ADD_PRODUCT',
        'UPDATE_PRODUCT',
        'DELETE_PRODUCT',
        'LOAD_FIELDS',
        'ADD_FIELD',
        'LOAD_FIELDS_BY_IDS',
        'UPDATE_FIELD',
        'DELETE_FIELD',
        'LOAD_HOLIDAYS',
        'ADD_HOLIDAY',
        'UPDATE_HOLIDAY',
        'DELETE_HOLIDAY',
        'LOAD_GENERAL_INFO',
        'UPDATE_GENERAL_INFO',
        'LOAD_PRODUCT_LOCAL_CUSTOMERS',
        'ADD_PRODUCT_LOCAL_CUSTOMER',
        'LOAD_PRODUCT_TX_ITEM',
        'UPDATE_PRODUCT_LOCAL_CUSTOMER',
        'DELETE_PRODUCT_LOCAL_CUSTOMER',
        'LOAD_STADIUMS',
        'DELETE_STADIUM',
        'UPDATE_STADIUM',
        'ADD_STADIUM',
        'SET_MY_STADIUM',
        'LOAD_SPORTS',
        'ADD_SPORT',
        'UPDATE_SPORT',
        'DELETE_SPORT',
        'LOAD_TX',
        'USE_TX',
        'PAY_TX',
        'CLEAR_ACTIVE_TX',
        'LOAD_STADIUM_REPORT',
    ],
    /**
     * @typedef {Object} Mutations
     * @prop {String} UPDATE_GENERAL_INFO
     * @prop {String} LOAD_HOLIDAYS
     * @prop {String} UPDATE_HOLIDAY
     * @prop {String} ADD_HOLIDAY
     * @prop {String} DELETE_HOLIDAY
     * @prop {String} LOAD_FIELDS
     * @prop {String} UPDATE_FIELD
     * @prop {String} ADD_FIELD
     * @prop {String} DELETE_FIELD
     * @prop {String} LOAD_MY_PRODUCTS
     * @prop {String} UPDATE_PRODUCT
     * @prop {String} ADD_PRODUCT
     * @prop {String} DELETE_PRODUCT
     * @prop {String} UPDATE_STATUS
     * @prop {String} LOAD_PRODUCT_LOCAL_CUSTOMERS
     * @prop {String} ADD_PRODUCT_LOCAL_CUSTOMER
     * @prop {String} LOAD_PRODUCT_TX_ITEM
     * @prop {String} UPDATE_PRODUCT_LOCAL_CUSTOMER
     * @prop {String} DELETE_PRODUCT_LOCAL_CUSTOMER
     * @prop {String} LOAD_STADIUMS
     * @prop {String} DELETE_STADIUM
     * @prop {String} UPDATE_STADIUM
     * @prop {String} ADD_STADIUM
     * @prop {String} SET_MY_STADIUM
     * @prop {String} LOAD_SPORTS
     * @prop {String} ADD_SPORT
     * @prop {String} UPDATE_SPORT
     * @prop {String} DELETE_SPORT
     * @prop {String} LOAD_TX
     * @prop {String} LOAD_STADIUM_REPORT
     */
    Mutations: [
        'UPDATE_GENERAL_INFO',
        'LOAD_HOLIDAYS',
        'UPDATE_HOLIDAY',
        'ADD_HOLIDAY',
        'DELETE_HOLIDAY',
        'LOAD_FIELDS',
        'UPDATE_FIELD',
        'ADD_FIELD',
        'DELETE_FIELD',
        'LOAD_MY_PRODUCTS',
        'UPDATE_PRODUCT',
        'ADD_PRODUCT',
        'DELETE_PRODUCT',
        'UPDATE_STATUS',
        'LOAD_PRODUCT_LOCAL_CUSTOMERS',
        'ADD_PRODUCT_LOCAL_CUSTOMER',
        'LOAD_PRODUCT_TX_ITEM',
        'UPDATE_PRODUCT_LOCAL_CUSTOMER',
        'DELETE_PRODUCT_LOCAL_CUSTOMER',
        'LOAD_STADIUMS',
        'DELETE_STADIUM',
        'UPDATE_STADIUM',
        'ADD_STADIUM',
        'SET_MY_STADIUM',
        'LOAD_SPORTS',
        'ADD_SPORT',
        'UPDATE_SPORT',
        'DELETE_SPORT',
        'LOAD_TX',
        'LOAD_STADIUM_REPORT',
    ],
    /**
     * @typedef {Object} Getters
     * @prop {String} GET_FIELDS_BY_STADIUM_ID
     * @prop {String} GET_FIELD
     * @prop {String} GET_BOOST
     * @prop {String} GET_STADIUM
     * @prop {String} GET_SPORT
     * @prop {String} GET_REPORT
     */
    Getters: [
        'GET_FIELDS_BY_STADIUM_ID',
        'GET_FIELD',
        'GET_BOOST',
        'GET_STADIUM',
        'GET_SPORT',
        'GET_REPORT',
    ],
}
/**
 * @typedef {Object} Types
 * @prop {Actions} Actions
 * @prop {Mutations} Mutations
 * @prop {Getters} Getters
 */
/**
 * @type {Types} types
 */
const { Actions, Getters } = createTypesWithNamespace(_namespace, types)

/**
 * @type {Types} types
 */
const { Actions: _Actions, Mutations: _Mutations, Getters: _Getters } = createTypes(types)

export {
    Actions, Getters, _Mutations, _Actions, _Getters,
}
