import { createTypes, createTypesWithNamespace } from '@/utils/store-utils'

const _namespace = 'chat'

const types = {
    /**
     * @typedef {Object} Actions
     * @prop {String} LOAD_CHATROOMS
     * @prop {String} LOAD_CHATROOM
     * @prop {String} LOAD_CHATROOM_MSGS
     * @prop {String} SEND_MSG
     * @prop {String} LOAD_PRODUCT_ATTACHMENTS
     */
    Actions: [
        'LOAD_CHATROOMS',
        'LOAD_CHATROOM',
        'LOAD_CHATROOM_MSGS',
        'SEND_MSG',
        'LOAD_PRODUCT_ATTACHMENTS',
    ],
    /**
     * @typedef {Object} Mutations
     * @prop {String} LOAD_CHATROOMS
     * @prop {String} LOAD_CHATROOM_MSGS
     * @prop {String} ADD_CHATROOM_MSG
     */
    Mutations: [
        'LOAD_CHATROOMS',
        'LOAD_CHATROOM_MSGS',
        'ADD_CHATROOM_MSG',
    ],
    /**
     * @typedef {Object} Getters
     * @prop {String} GET_CHATROOM
     * @prop {String} GET_CHATROOM_MSGS
     * @prop {String} GET_CHATROOM_MEMBERS_EXCLUDE_ME
     * @prop {String} GET_CHATROOM_NAME
     */
    Getters: [
        'GET_CHATROOM',
        'GET_CHATROOM_MSGS',
        'GET_CHATROOM_MEMBERS_EXCLUDE_ME',
        'GET_CHATROOM_NAME',
    ],
}
/**
 * @typedef {Object} Types
 * @prop {Actions} Actions
 * @prop {Mutations} Mutations
 * @prop {Getters} Getters
 */
/**
 * @type {Types} types
 */
const { Actions, Getters } = createTypesWithNamespace(_namespace, types)

/**
 * @type {Types} types
 */
const { Actions: _Actions, Mutations: _Mutations, Getters: _Getters } = createTypes(types)

export {
    Actions, Getters, _Mutations, _Actions, _Getters,
}
