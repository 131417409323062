import { createTypes, createTypesWithNamespace } from '@/utils/store-utils'

const _namespace = 'banner'

const types = {
    /**
     * @typedef {Object} Actions
     * @prop {String} LOAD_BANNER_MEDIAS
     * @prop {String} ADD_BANNER_MEDIA
     * @prop {String} UPDATE_BANNER_MEDIA
     * @prop {String} DELETE_BANNER_MEDIA
     * @prop {String} LOAD_BANNERS
     * @prop {String} LOAD_MAIN_BANNER
     * @prop {String} UPDATE_BANNER
     */
    Actions: [
        'LOAD_BANNER_MEDIAS',
        'ADD_BANNER_MEDIA',
        'UPDATE_BANNER_MEDIA',
        'DELETE_BANNER_MEDIA',
        'LOAD_BANNERS',
        'LOAD_MAIN_BANNER',
        'UPDATE_BANNER',
    ],
    /**
     * @typedef {Object} Mutations
     * @prop {String} LOAD_BANNER_MEDIAS
     * @prop {String} ADD_BANNER_MEDIA
     * @prop {String} UPDATE_BANNER_MEDIA
     * @prop {String} DELETE_BANNER_MEDIA
     * @prop {String} LOAD_BANNERS
     * @prop {String} ADD_BANNER
     * @prop {String} UPDATE_BANNER
     * @prop {String} DELETE_BANNER
     */
    Mutations: [
        'LOAD_BANNER_MEDIAS',
        'ADD_BANNER_MEDIA',
        'UPDATE_BANNER_MEDIA',
        'DELETE_BANNER_MEDIA',
        'LOAD_BANNERS',
        'ADD_BANNER',
        'LOAD_BANNERS',
        'UPDATE_BANNER',
        'DELETE_BANNER',
    ],
    /**
     * @typedef {Object} Getters
     * @prop {String} GET_BANNER_MEDIA
     * @prop {String} GET_BANNER
     */
    Getters: [
        'GET_BANNER_MEDIA',
        'GET_BANNER',
    ],
}
/**
 * @typedef {Object} Types
 * @prop {Actions} Actions
 * @prop {Mutations} Mutations
 * @prop {Getters} Getters
 */
/**
 * @type {Types} types
 */
const { Actions, Getters } = createTypesWithNamespace(_namespace, types)

/**
 * @type {Types} types
 */
const { Actions: _Actions, Mutations: _Mutations, Getters: _Getters } = createTypes(types)

const Entities = {
}

export {
    Actions, Getters, _Mutations, _Actions, _Getters, Entities,
}
