// eslint-disable-next-line import/prefer-default-export
export const updateEntityCallbacks = async (resBeforeCallback, refershFunction, callbacks) => {
    let isDirty = false
    // eslint-disable-next-line no-restricted-syntax
    for (const func of callbacks) {
        // eslint-disable-next-line no-await-in-loop
        await func()
        isDirty = true
    }
    if (isDirty) {
        const {
            id,
        } = resBeforeCallback.data
        const res = await refershFunction(id)
        return res
    }
    return resBeforeCallback
}
