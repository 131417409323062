import { createActionsWithInterceptors } from '@/utils/store-utils'
import ActionProcessor from '@/utils/ActionProcessor'
import StatusInterceptor from '@/store/modules/auth/interceptors/StatusInterceptor'
import ServiceAuth from '@/services/ServiceAuth'
import VApiResponse from '@/utils/VApiResponse'
import { rootActions } from '@/store/types'
import {
    _Mutations, _Actions,
} from './types'

class ActionProcessorImpl extends ActionProcessor {
    createInterceptors ({ dispatch, commit }, actionName, action, payload) {
        return [new StatusInterceptor({ dispatch, commit }, actionName, action, payload)]
    }
}

const Processor = new ActionProcessorImpl()

export default {
    namespaced: true,
    state: {
        me: {},
        isLogin: false,
        isError: false,
        errorMsg: '',
        isProcessing: false,
        isReadCookiePolicy: false,
        isReadPrivacyPolicy: false,
        policyVersion: '1.0.0',
    },
    getters: {

    },
    actions: {
        ...createActionsWithInterceptors(Processor,
            {
                async [_Actions.GET_ME] ({ commit }) {
                    const res = await ServiceAuth.getMe()
                    if (!res.isSuccess()) {
                        commit(_Mutations.UPDATE_ME, {})
                        commit(_Mutations.UPDATE_LOGIN_STATUS, false)
                    } else {
                        commit(_Mutations.UPDATE_ME, res.data)
                        commit(
                            _Mutations.UPDATE_IS_READ_PRIVACY_POLICY,
                            res.data.requiredAgreements.length === 0,
                        )
                        commit(_Mutations.UPDATE_LOGIN_STATUS, true)
                    }
                    return res
                },
                async [_Actions.IS_LOGIN] ({ dispatch, state }) {
                    const res = await dispatch(_Actions.GET_ME)
                    return new VApiResponse({ ...res, data: state.isLogin })
                },
                async [_Actions.LOGOUT] ({ commit, dispatch }) {
                    const res = await ServiceAuth.logout()
                    if (res.isSuccess()) {
                        commit(_Mutations.LOGOUT)
                        dispatch(
                            rootActions.RESET_STORES,
                            null,
                            { root: true },
                        )
                    }
                    return res
                },
                async [_Actions.LOGIN] ({ commit, dispatch }, { username, password }) {
                    commit(_Mutations.CLEAR_ERROR_MSG)
                    const res = await ServiceAuth.login(username, password)
                    if (res.isSuccess()) {
                        commit(_Mutations.LOGIN_SUCCESS)
                        await dispatch(_Actions.GET_ME)
                    } else {
                        commit(_Mutations.LOGIN_ERROR, res.msg)
                    }
                    return res
                },
                async [_Actions.REQUEST_TO_RESET_PASSWORD] ({ state }, email) {
                    const res = await ServiceAuth.resetPassword(state.me, email)
                    if (res.isSuccess()) {
                        res.msg = 'Already sent reset password link to your email. Go check it !'
                    }
                    return res
                },
                async [_Actions.RESET_PASSWORD] ({ state }, newPassword) {
                    const res = await ServiceAuth.resetPassword(state.me, newPassword)
                    if (res.isSuccess()) {
                        res.msg = 'New password has been set succuessfully. Please login with your new password.'
                    }
                    return res
                },
                async [_Actions.CHANGE_PASSWORD] ({ state }, { currentPassword, newPassword }) {
                    const res = await ServiceAuth.changePassword(
                        state.me,
                        currentPassword,
                        newPassword,
                    )
                    if (res.isSuccess()) {
                        res.msg = 'New password has been set succuessfully. Please login with your new password.'
                    }
                    return res
                },
                async [_Actions.ACCEPT_POLICY] ({ commit, state, dispatch }) {
                    const results = state.me.requiredAgreements.map(
                        (agreement) => ServiceAuth.updateAgreementPermission(agreement.id, true),
                    )
                    const resList = await Promise.all(results)
                    const resError = resList.find((res) => res.isError())
                    if (resError) return resError
                    await dispatch(_Actions.GET_ME)
                    commit(_Mutations.UPDATE_IS_READ_POLICY, true)
                    localStorage.readPolicyVersion = state.policyVersion
                    return true
                },
                async [_Actions.CHECK_IS_READ_POLICY] ({ commit, state }) {
                    commit(
                        _Mutations.UPDATE_IS_READ_POLICY,
                        localStorage.readPolicyVersion === state.policyVersion,
                    )
                    return true
                },
            }),
    },
    mutations: {
        [_Mutations.LOGIN_SUCCESS] (state) {
            state.isLogin = true
            state.isError = false
            state.errorMsg = ''
        },
        [_Mutations.LOGIN_ERROR] (state, errorMsg) {
            state.isLogin = false
            state.isError = true
            state.errorMsg = errorMsg
        },
        [_Mutations.LOGOUT] (state) {
            state.isLogin = false
            state.isError = false
            state.errorMsg = ''
            state.me = {}
        },
        [_Mutations.UPDATE_ME] (state, me) {
            state.me = me
        },
        [_Mutations.CLEAR_ERROR_MSG] (state) {
            state.errorMsg = ''
        },
        [_Mutations.START_PROCESSING] (state) {
            state.isProcessing = true
        },
        [_Mutations.END_PROCESSING] (state) {
            state.isProcessing = false
        },
        [_Mutations.UPDATE_LOGIN_STATUS] (state, status) {
            state.isLogin = status
        },
        [_Mutations.UPDATE_IS_READ_POLICY] (state, status) {
            state.isReadCookiePolicy = status
        },
        [_Mutations.UPDATE_IS_READ_PRIVACY_POLICY] (state, status) {
            state.isReadPrivacyPolicy = status
        },
    },
}
