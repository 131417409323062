import Vue from 'vue'
import { objectMap } from '@/utils'

export function mapValues (obj, f) {
    const res = {}
    Object.keys(obj).forEach((key) => {
        res[key] = f(key, obj[key])
    })
    return res
}

/**
 * @typedef {Object} Types
 * @prop {Actions} Actions
 * @prop {Mutations} Mutations
 * @prop {Getters} Getters
 */
/**
 *
 * @param {String} namespace
 * @param {Types} types
 * @returns
 */
export function createTypesWithNamespace (namespace, types) {
    const newObj = {}

    mapValues(types, (type, names) => {
        newObj[type] = {}
        names.forEach((name) => {
            let newKey
            if (namespace && namespace !== '') {
                newKey = `${namespace}/${name}`
            } else {
                newKey = name
            }
            newObj[type][name] = newKey
        })
    })
    return newObj
}

export function createTypes (types) {
    return createTypesWithNamespace('', types)
}

export function createActionsWithInterceptors (processor, actions) {
    return objectMap(actions,
        (action, actionName) => (
            context, payload,
        ) => processor.process(actionName, context, payload, action))
}

function pascalCaseToUnderscoreCase (text) {
    return text.replace(/\.?([A-Z]+)/g, (_x, y) => `_${y.toLowerCase()}`).replace(/^_/, '')
}

export function createMutationBaseEntities (entities) {
    const entity = entities.substring(0, entities.length - 1)
    const entityMutationName = pascalCaseToUnderscoreCase(entity).toUpperCase()
    const entitiesMutationName = pascalCaseToUnderscoreCase(entities).toUpperCase()
    return {
        [`LOAD_${entitiesMutationName}`] (state, datas) {
            const notUpdatedEntities = state[entities].filter(
                (ele) => !datas.find((data) => data.id === ele.id),
            )
            Vue.set(state, entities, [...notUpdatedEntities, ...datas])
        },
        [`UPDATE_${entityMutationName}`] (state, value) {
            const result = state[entities].map(
                (item) => (
                    item.id !== value.id
                        ? item
                        : { ...item, ...value }),
            )
            Vue.set(state, entities, result)
        },
        [`ADD_${entityMutationName}`] (state, value) {
            state[entities] = [value, ...state[entities]]
        },
        [`DELETE_${entityMutationName}`] (state, id) {
            state[entities] = state[entities].filter((item) => item.id !== id)
        },
    }
}
