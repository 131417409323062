import gql from 'graphql-tag'

export const StadiumTaxGeneralInfo = gql`
    fragment StadiumTaxGeneralInfo on StadiumTaxInfo {
        taxNo,
        name,
        addressString,
        hasVAT,
        isIncVAT,
    }
`
export const StadiumGeneralInfo = gql`
    fragment StadiumGeneralInfo on Stadium {
        id
        name
        logoPhotoUrl
        coverPhotoUrl
        desc
        phones
        facilities
        openTime
        closeTime
        addressString
        location
        zoneId
        isOfficial
        generalCommissionRate
        adsCommissionRate
        mainTaxInfo {
            ...StadiumTaxGeneralInfo
        }
    }
    ${StadiumTaxGeneralInfo}
`
