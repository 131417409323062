<template>
    <div>
        <ModalSessionTimeout />
        <HomeLayout :sidebar="sidebar" />
    </div>
</template>
<script>
import HomeLayout from '@/components/HomeLayout.vue'
import ModalSessionTimeout from '@/components/ModalSessionTimeout.vue'

export default {
    components: {
        HomeLayout,
        ModalSessionTimeout,
    },
    data () {
        return {
            sidebar: [
                {
                    label: 'สเตเดียม',
                    group: 'stadium',
                    icon: 'home',
                    items: [
                        {
                            label: 'กระดานรายงาน',
                            path: '/stadium/dashboard',
                            alias: '/stadium',
                        },
                        {
                            label: 'ข้อมูลทั่วไป',
                            path: '/stadium/info',
                        },
                        // {
                        //     label: 'Holiday',
                        //     path: '/stadium/holiday',
                        // },
                        {
                            label: 'สนาม',
                            path: '/stadium/fields',
                        },
                        {
                            label: 'โปรโมชั่น สนาม',
                            path: '/stadium/boosts',
                        },
                    ],
                },
                {
                    label: 'กล่องข้อความ',
                    icon: 'message',
                    group: 'chats',
                    path: '/chats',
                },
                {
                    label: 'รหัส QR/Code',
                    group: 'code',
                    icon: 'qrcode',
                    items: [
                        {
                            label: 'ใช้งาน QR/Code',
                            path: '/code/use',
                            alias: '/code',
                        },
                    ],
                },
                {
                    label: 'รายงาน',
                    group: 'report',
                    icon: 'file-text',
                    items: [
                        {
                            label: 'ยอดขาย',
                            path: '/report/sales',
                            alias: '/report',
                        },
                        {
                            label: 'การเงิน',
                            path: '/report/financial',
                        },
                    ],
                },
                {
                    label: 'ลูกค้าออฟไลน์',
                    group: 'customers',
                    icon: 'user',
                    items: [
                        {
                            label: 'ทั่วไป',
                            path: '/customers',
                        },
                        {
                            label: 'สมาชิก',
                            path: '/customers/member-types',
                        },
                    ],
                },
                {
                    label: 'พนักงาน',
                    group: 'employees',
                    icon: 'team',
                    items: [
                        {
                            label: 'ทั่วไป',
                            path: '/employees',
                        },
                        {
                            label: 'สิทธิ์',
                            path: '/employees/permission',
                        },
                    ],
                },
                {
                    label: 'ฉัน',
                    group: 'me',
                    icon: 'user',
                    items: [
                        {
                            label: 'ข้อมูลทั่วไป',
                            path: '/me',
                        },
                        {
                            label: 'เปลี่ยนรหัสผ่าน',
                            path: '/me/change-password',
                        },
                    ],
                },
            ],
        }
    },
}
</script>
