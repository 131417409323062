import VApiResponse from '@/utils/VApiResponse'
import { ApiCustomer } from '@/api'

export default {
    loadCustomers ({ stadiumId, page, size }) {
        return VApiResponse.CONVERT(
            ApiCustomer.loadCustomers({ stadiumId, page, size }),
        )
    },
    updateCustomer (customer) {
        return VApiResponse.CONVERT(
            ApiCustomer.updateCustomer(customer),
        )
    },
    addCustomer (customer) {
        return VApiResponse.CONVERT(
            ApiCustomer.addCustomer(customer),
        )
    },
    deleteCustomer (id) {
        return VApiResponse.CONVERT(
            ApiCustomer.deleteCustomer(id),
        )
    },
    searchCustomer (query) {
        return VApiResponse.CONVERT(
            ApiCustomer.searchCustomer(query),
        )
    },
    loadMemberTypes ({ stadiumId, page, size }) {
        return VApiResponse.CONVERT(
            ApiCustomer.loadMemberTypes({ stadiumId, page, size }),
        )
    },
    addMemberTypes (memberType) {
        return VApiResponse.CONVERT(
            ApiCustomer.addMemberTypes(memberType),
        )
    },
    updateMemberType (memberType) {
        return VApiResponse.CONVERT(
            ApiCustomer.updateMemberType(memberType),
        )
    },
    deleteMemberType (id) {
        return VApiResponse.CONVERT(
            ApiCustomer.deleteMemberType(id),
        )
    },
    unregistorMemberType (id, removeNote) {
        return VApiResponse.CONVERT(
            ApiCustomer.unregistorMemberType(id, removeNote),
        )
    },
    registorMemberType (customerId, memberTypeId) {
        return VApiResponse.CONVERT(
            ApiCustomer.registorMemberType(customerId, memberTypeId),
        )
    },
    loadMemberTypeHistory (customerId) {
        return VApiResponse.CONVERT(
            ApiCustomer.loadMemberTypeHistory(customerId),
        )
    },
}
