import { CouponForBundleGeneralInfo, BundleCouponGeneralInfo } from '@/api/graphql/fragments/discount'
import { PageGeneralInfo } from '@/api/graphql/fragments/page'
import apolloClient from '@/apolloClient'
import ModelBundleCoupon from '@/models/ModelBundleCounpon'
import ModelDiscount from '@/models/ModelDiscount'
import ModelPagination from '@/models/ModelPagination'
import VApiResponse from '@/utils/VApiResponse'
import gql from 'graphql-tag'
import moment from 'moment'

function getIsPastQuery (isPast) {
    if (isPast != null) {
        if (isPast) {
            return {
                expiredOn: {
                    $lt: {
                        $date: moment().valueOf(),
                    },
                },
            }
        }
        return {
            expiredOn: {
                $gt: {
                    $date: moment().valueOf(),
                },
            },
        }
    }

    return {}
}

function getSort (isPast) {
    return {
        publishedOn: isPast ? -1 : 1,
    }
}

export default {
    async addCouponForBundle (discountCode) {
        return VApiResponse.convertGql(
            apolloClient.mutate({
                mutation: gql`
                mutation addCouponForBundle($data: CouponDiscountPromotionInBundledProductInput!){
                    insertCouponDiscountPromotionInBundledProduct(data:$data) {
                        ...CouponForBundleGeneralInfo
                    }
                }
                ${CouponForBundleGeneralInfo}
                `,
                variables: {
                    data: ModelDiscount.toGql(discountCode),
                },
            }),
            (data) => ModelDiscount.fromGql(data.insertCouponDiscountPromotionInBundledProduct),
        )
    },
    async loadCouponForBundles (isPast, page, size) {
        return VApiResponse.convertGql(
            await apolloClient.query({
                query: gql`
                query getCouponForBundlesPagination($query:QueryObject!,$page:Int,$size:Int,$sort:QueryObject){
                    couponDiscountPromotionInBundledProductsPagination(query: $query, page: $page, size: $size,sort:$sort) {
                        datas{
                            ...CouponForBundleGeneralInfo
                        }
                        pageInfo{
                            ...PageGeneralInfo
                        }
                    }
                }
                ${CouponForBundleGeneralInfo}
                ${PageGeneralInfo}
                `,
                variables: {
                    query: {
                        ...getIsPastQuery(isPast),
                    },
                    // page start at 0 on server
                    page: page - 1,
                    size,
                    sort: { ...getSort(isPast) },
                },
            }),
            (data) => ModelPagination.fromGql(
                data.couponDiscountPromotionInBundledProductsPagination,
            ),
        )
    },
    async updateCouponForBundle (discountCode) {
        return VApiResponse.convertGql(
            apolloClient.mutate({
                mutation: gql`
                mutation updateCouponForBundle($id:ObjectId,$data: CouponDiscountPromotionInBundledProductInput!){
                    updateCouponDiscountPromotionInBundledProduct(id:$id,data:$data) {
                        ...CouponForBundleGeneralInfo
                    }
                }
                ${CouponForBundleGeneralInfo}
                `,
                variables: {
                    id: discountCode.id,
                    data: ModelDiscount.toGql(discountCode),
                },
            }),
            (data) => ModelDiscount.fromGql(data.updateCouponDiscountPromotionInBundledProduct),
        )
    },
    async deleteCouponForBundle (id) {
        return VApiResponse.convertGql(
            await apolloClient.mutate({
                mutation: gql`
                mutation deleteDiscountCode($id:ObjectId){
                    deleteCouponDiscountPromotionInBundledProduct(id:$id)
                }
                `,
                variables: {
                    id,
                },
            }),
        )
    },
    async addBundleCoupon (discountCode) {
        return VApiResponse.convertGql(
            apolloClient.mutate({
                mutation: gql`
                mutation addBundleCoupon($data: BundledProductPromotionInput!){
                    insertBundledProductPromotion (data:$data) {
                        ...BundleCouponGeneralInfo
                    }
                }
                ${BundleCouponGeneralInfo}
                `,
                variables: {
                    data: ModelBundleCoupon.toGql(discountCode),
                },
            }),
            (data) => ModelBundleCoupon.fromGql(data.insertBundledProductPromotion),
        )
    },
    async loadBundleCoupons (isPast, page, size) {
        return VApiResponse.convertGql(
            await apolloClient.query({
                query: gql`
                query getBundleCouponsPagination($query:QueryObject!,$page:Int,$size:Int,$sort:QueryObject){
                    bundledProductPromotionsPagination(query: $query, page: $page, size: $size,sort:$sort) {
                        datas{
                            ...BundleCouponGeneralInfo
                        }
                        pageInfo{
                            ...PageGeneralInfo
                        }
                    }
                }
                ${BundleCouponGeneralInfo}
                ${PageGeneralInfo}
                `,
                variables: {
                    query: {
                        ...getIsPastQuery(isPast),
                    },
                    // page start at 0 on server
                    page: page - 1,
                    size,
                    sort: { ...getSort(isPast) },
                },
            }),
            (data) => ModelPagination.fromGql(data.bundledProductPromotionsPagination),
        )
    },
    async updateBundleCoupon (discountCoupon) {
        return VApiResponse.convertGql(
            apolloClient.mutate({
                mutation: gql`
                mutation updateBundleCoupon($id:ObjectId,$data: BundledProductPromotionInput!){
                    updateBundledProductPromotion(id:$id,data:$data) {
                        ...BundleCouponGeneralInfo
                    }
                }
                ${BundleCouponGeneralInfo}
                `,
                variables: {
                    id: discountCoupon.id,
                    data: ModelBundleCoupon.toGql(discountCoupon),
                },
            }),
            (data) => ModelBundleCoupon.fromGql(data.updateBundledProductPromotion),
        )
    },
    async deleteBundleCoupon (id) {
        return VApiResponse.convertGql(
            await apolloClient.mutate({
                mutation: gql`
                mutation deleteDiscountCode($id:ObjectId){
                    deleteBundledProductPromotion(id:$id)
                }
                `,
                variables: {
                    id,
                },
            }),
        )
    },
}
