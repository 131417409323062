import gql from 'graphql-tag'

export const ItemGeneralInfo = gql`
    fragment ItemGeneralInfo on Item {
        name
        desc
        totalImportToStock
        isPrivate
    }
`

export const ItemConditionGeneralInfo = gql`
    fragment ItemConditionGeneralInfo on ItemCondition {
        minPrice
        isOnlyNewUser
        excludeProductTypes
    }
`

export const ILimitedPublishTimeItemGeneralInfo = gql`
    fragment ILimitedPublishTimeItemGeneralInfo on ILimitedPublishTimeItem {
        publishedOn
        unpublishedOn
    }
`

export const IBundledItemGeneralInfo = gql`
    fragment IBundledItemGeneralInfo on IBundledItem {
        items {
          itemId
          amount
        }
    }
`
