import ResolverEntity from '@/models/resolvers/ResolverEntity'

export default class ModelPagination {
    constructor ({
        datas,
        pageInfo,
    }) {
        Object.assign(this, {
            datas,
            pageInfo,
        })
    }

    static fromGql (gql) {
        const {
            datas,
            pageInfo,
        } = gql
        return new ModelPagination({
            datas: datas.map((data) => ResolverEntity.fromGqlUnion(data)),
            pageInfo,
        })
    }

    toGql () {
        const {
            datas,
            pageInfo,
        } = this
        return {
            datas,
            pageInfo,
        }
    }
}
