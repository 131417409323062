import { ApiStadium } from '@/api'
import { isManagerUser } from '@/utils/enum'
import VApiResponse from '@/utils/VApiResponse'

export default {
    loadCode (stadiumId, code) {
        return VApiResponse.CONVERT(
            ApiStadium.loadCode(stadiumId, code),
        )
    },
    loadCodeByManager (partyId, code) {
        return VApiResponse.CONVERT(
            ApiStadium.loadCodeByManager(partyId, code),
        )
    },
    usePaymentCode (me, txId, code) {
        return VApiResponse.CONVERT(
            isManagerUser(me)
                ? ApiStadium.usePaymentCodeByManager(txId, code)
                : ApiStadium.usePaymentCode(txId, code),
            (res) => res,
            ({ graphQLErrors }) => {
                if (graphQLErrors) {
                    return graphQLErrors[0].extensions.code === 'INVALID_TX_STAGE'
                        ? VApiResponse.ERROR(graphQLErrors, 'โค้ดนี้อาจจะหมดอายุหรือถูกใช้ไปแล้ว')
                        : VApiResponse.ERROR(graphQLErrors)
                }
                return VApiResponse.ERROR()
            },
        )
    },
    useRedeemCode (me, txItemIds, code) {
        return VApiResponse.CONVERT(
            isManagerUser(me)
                ? ApiStadium.useRedeemCodeByManager(txItemIds, code)
                : ApiStadium.useRedeemCode(txItemIds, code),
        )
    },
}
