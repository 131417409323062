import ActionProcessor from '@/utils/ActionProcessor'
import { createActionsWithInterceptors, createMutationBaseEntities } from '@/utils/store-utils'
import ServiceCustomer from '@/services/ServiceCustomer'
import { _Getters, _Actions, _Mutations } from '@/store/modules/customers/types'
import { Entities } from '@/utils/enum'

class ActionProcessorImpl extends ActionProcessor {
    createInterceptors () {
        return []
    }
}

const Processor = new ActionProcessorImpl()

export default {
    namespaced: true,
    state: {
        /**
         * @typedef {Object} Membership
         * @prop {String} id
         * @prop {MemberType} memberType
         * @prop {MomentDate} registerDate
         * @prop {String} registerDevice
         */
        /**
         * @typedef {Object} Customer
         * @prop {Number} gender 1 : male, 2 : female
         * @prop {String} firstName
         * @prop {String} lastName
         * @prop {String} nickName
         * @prop {String} tel
         * @prop {MomentDate} dateOfBirth
         * @prop {String} carrier
         * @prop {String} address
         * @prop {String} postalCode
         * @prop {String} note
         * @prop {Membership} membership
         * @prop {Membership[]} membershipHistory:
         */
        /**
         * @type {Customer[]}
         */
        customers: [],
        /**
         * @typedef {Object} MemberType
         * @prop {String} id
         * @prop {String} name
         * @prop {String} description
         * @prop {String} color
         * @prop {String} type
         * @prop {Number} price
         * @prop {String} address
         * @prop {Number} expiredInDay
         */
        /**
         * @type {MemberType[]}
         */
        memberTypes: [],
    },
    getters: {
        [_Getters.GET_CUSTOMER]: (state) => (id) => state.customers.find((item) => item.id === id),
        [_Getters.GET_MEMBER_TYPE]: (state) => (id) => state.memberTypes.find(
            (item) => item.id === id,
        ),
    },
    actions: {
        ...createActionsWithInterceptors(Processor,
            {
                async [_Actions.LOAD_CUSTOMERS] ({ commit, rootState }, { page, size }) {
                    const stadiumId = rootState.stadium.id
                    const res = await ServiceCustomer.loadCustomers({ stadiumId, page, size })
                    if (res.isSuccess()) {
                        commit(_Mutations.LOAD_CUSTOMERS, res.data.datas)
                    }
                    return res
                },
                async [_Actions.UPDATE_CUSTOMER] ({ commit, rootState }, customer) {
                    const res = await ServiceCustomer.updateCustomer({ ...customer, stadiumId: rootState.stadium.id })
                    if (res.isSuccess()) {
                        commit(_Mutations.UPDATE_CUSTOMER, res.data)
                    }
                    return res
                },
                async [_Actions.ADD_CUSTOMER] ({ commit, rootState }, customer) {
                    const res = await ServiceCustomer.addCustomer({ ...customer, stadiumId: rootState.stadium.id })
                    if (res.isSuccess()) {
                        commit(_Mutations.ADD_CUSTOMER, res.data)
                    }
                    return res
                },
                async [_Actions.DELETE_CUSTOMER] ({ commit }, id) {
                    const res = await ServiceCustomer.deleteCustomer(id)
                    if (res.isSuccess()) {
                        commit(_Mutations.DELETE_CUSTOMER, id)
                    }
                    return res
                },
                async [_Actions.SEARCH_CUSTOMER] ({ commit }, query) {
                    const res = await ServiceCustomer.searchCustomer(query)
                    if (res.isSuccess()) {
                        commit(_Mutations.LOAD_CUSTOMERS, res.data)
                    }
                    return res
                },
                async [_Actions.LOAD_MEMBER_TYPES] ({ commit, rootState }, { page, size }) {
                    const stadiumId = rootState.stadium.id
                    const res = await ServiceCustomer.loadMemberTypes({ stadiumId, page, size })
                    if (res.isSuccess()) {
                        commit(_Mutations.LOAD_MEMBER_TYPES, res.data.datas)
                    }
                    return res
                },
                async [_Actions.ADD_MEMBER_TYPE] ({ commit, rootState }, memberType) {
                    const stadiumId = rootState.stadium.id
                    const res = await ServiceCustomer.addMemberTypes({ ...memberType, stadiumId })
                    if (res.isSuccess()) {
                        commit(_Mutations.ADD_MEMBER_TYPE, res.data)
                    }
                    return res
                },
                async [_Actions.UPDATE_MEMBER_TYPE] ({ commit }, memberType) {
                    const res = await ServiceCustomer.updateMemberType(memberType)
                    if (res.isSuccess()) {
                        commit(_Mutations.UPDATE_MEMBER_TYPE, memberType)
                    }
                    return res
                },
                async [_Actions.DELETE_MEMBER_TYPE] ({ commit }, id) {
                    const res = await ServiceCustomer.deleteMemberType(id)
                    if (res.isSuccess()) {
                        commit(_Mutations.DELETE_MEMBER_TYPE, id)
                    }
                    return res
                },
                async [_Actions.UNREGISTOR_MEMBER_TYPE] (
                    { dispatch, getters },
                    // eslint-disable-next-line no-unused-vars
                    { customerId, reasonsToRemove },
                ) {
                    const customer = getters[_Getters.GET_CUSTOMER](customerId)
                    const res = await dispatch(
                        _Actions.UPDATE_CUSTOMER,
                        { ...customer, stadiumMemberIds: [] },
                    )
                    return res
                },
                async [_Actions.REGISTOR_MEMBER_TYPE] (
                    { getters, dispatch },
                    { customerId, memberTypeId },
                ) {
                    const customer = getters[_Getters.GET_CUSTOMER](customerId)
                    const res = await dispatch(
                        _Actions.UPDATE_CUSTOMER,
                        { ...customer, stadiumMemberIds: [memberTypeId] },
                    )
                    return res
                },
                async [_Actions.LOAD_MEMBER_TYPE_HISTORY] ({ commit, getters }, customerId) {
                    const res = await ServiceCustomer.loadMemberTypeHistory(customerId)
                    if (res.isSuccess()) {
                        commit(_Mutations.UPDATE_CUSTOMER, {
                            ...getters[_Getters.GET_CUSTOMER](customerId),
                            membershipHistory: res.data,
                        })
                    }
                    return res
                },
            }),
    },
    mutations: {
        ...createMutationBaseEntities(Entities.CUSTOMERS),
        ...createMutationBaseEntities(Entities.MEMBER_TYPES),
    },
}
