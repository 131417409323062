<template>
    <a-layout class="home" :style="{ height: '100vh', overflow: 'auto' }">
        <a-layout-header
            class="home__header"
            :style="{
                height: '80px',
                lineHeight: '80px',
                position: 'fixed',
                zIndex: 102,
                width: '100%' }">
            <div class="home__logo-container">
                <img
                    class="home__logo"
                    alt="Lenkila Stadium logo"
                    src="@/assets/LogoSm.png">
            </div>
            <div class="home__logout">
                <a-button
                    icon="logout"
                    type="link"
                    :style="{ fontSize: '16px', color: '#fff' }"
                    @click="logout">
                    <template v-if="!isCollapsedEnable">
                        ออกจากระบบ
                    </template>
                    <template v-else>
                        ออก
                    </template>
                </a-button>
            </div>
            <div :style="{ clear: 'both' }" />
        </a-layout-header>
        <a-layout>
            <a-layout-sider
                v-model="collapsed"
                breakpoint="lg"
                collapsed-width="0"
                :style="{
                    backgroundColor: '#fff',
                    margin: '80px 0 0 0' }"
                @breakpoint="onBreakpoint">
                <a-menu
                    mode="inline"
                    :default-selected-keys="[ selectedSidebarItemKey ]"
                    :default-open-keys="groups"
                    :style="{
                        height: '100%',
                        borderRight: 0,
                        overflowY: 'auto',
                        overflowX: 'hidden',
                    }">
                    <template v-for="group in sidebar">
                        <a-menu-item
                            v-if="!group.items"
                            :key="group.group"
                            @click="onSelectedMenu">
                            <router-link :to="group.path">
                                <a-icon v-if="group.icon" :type="group.icon" />
                                <span>{{ group.label }}</span>
                            </router-link>
                        </a-menu-item>
                        <a-sub-menu
                            v-else
                            :key="group.group">
                            <span slot="title">
                                <a-icon v-if="group.icon" :type="group.icon" />
                                <span>{{ group.label }}</span>
                            </span>
                            <a-menu-item
                                v-for="item in group.items"
                                :key="item.path"
                                @click="onSelectedMenu">
                                <router-link :to="item.path">
                                    {{ item.label }}
                                </router-link>
                            </a-menu-item>
                        </a-sub-menu>
                    </template>

                </a-menu>
            </a-layout-sider>
            <a-layout>
                <a-layout-content
                    :style="{
                        margin: '104px 16px 0',
                        minHeight: '280px' }">
                    <router-view />
                    <a-layout-footer :style="{ textAlign: 'center' }" />
                </a-layout-content>
            </a-layout>
        </a-layout>
    </a-layout>
</template>
<script>
import { rootActions } from '@/store/types'

export default {
    props: {
        sidebar: {
            type: Array,
            required: true,
        },
        defaultPath: {
            type: String,
            default: '/stadium/dashboard',
        },
        defaultGroup: {
            type: String,
            default: 'stadium',
        },
    },
    data () {
        return {
            collapsed: false,
            isCollapsedEnable: false,
        }
    },
    computed: {
        selectedSidebarItemKey () {
            const target = this.findSidebarChild()
            if (target) {
                return target.path
            }
            return this.normalizeKey(this.defaultPath)
        },
        selectedSidebarItemGroup () {
            const target = this.findSidebarGroup()
            if (target) {
                return target
            }
            return this.defaultGroup
        },
        groups () {
            return this.sidebar.map((group) => group.group)
        },
    },
    methods: {
        normalizeKey (path) {
            return this.isNestedPath(path) ? path : path.substring(1)
        },
        isNestedPath (path) {
            return /\/.*\//.test(path)
        },
        findSidebarChild () {
            const group = this.findSidebar()
            if (group && group.items) {
                return group.items.find(
                    (item) => this.isSelectedItem(item),
                )
            }
            return null
        },
        findSidebar () {
            const target = this.sidebar.find(
                (group) => {
                    if (group.items === undefined) {
                        return this.isSelectedItem(group)
                    }
                    return group.items.findIndex((item) => this.isSelectedItem(item)) !== -1
                },
            )
            if (target) {
                return target
            }
            return null
        },
        findSidebarGroup () {
            const sidebar = this.findSidebar()
            return sidebar ? sidebar.group : null
        },
        isSelectedItem (item) {
            return item.path === this.$route.path || item.alias === this.$route.path
        },
        async logout () {
            const res = await this.$store.dispatch(
                rootActions.LOGOUT,
            )
            if (res.isSuccess()) {
                this.$router.replace('/login')
            }
        },
        onBreakpoint (broken) {
            this.isCollapsedEnable = broken
        },
        onSelectedMenu () {
            if (this.isCollapsedEnable) {
                this.collapsed = true
            }
        },
    },
}
</script>

<style lang="stylus">
.home__logo-container
    // margin-right: 48px
    float: left

.home__logo
    width: 100%
    max-width: 640px

.home .home__logout
    float right
    line-height 80px
</style>
