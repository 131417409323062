import { convertToOurWeekDay, convertToRruleWeekDay } from '@/utils/utils'
import moment from 'moment'
import { RRule, datetime } from 'rrule'

export default class ModelPricingRule {
    constructor ({
        id,
        fieldId,
        price,
        rangeTime,
        weekDay,
        fromServer,
        zoneId,
        rrule,
        rruleString,
        minutesDuration,
    }) {
        Object.assign(this, {
            id,
            fieldId,
            price,
            rangeTime,
            weekDay,
            fromServer,
            zoneId,
            rrule,
            rruleString,
            minutesDuration,
        })
    }

    static fromGql (gql) {
        const {
            pricePerHour, rruleLocaleString, id, minutesDuration, fieldId, zoneId, startedOn,
        } = gql
        const ruleWithOutTimeZone = RRule.fromString(rruleLocaleString)
        const {
            freq, byhour, byminute, byweekday,
        } = ruleWithOutTimeZone.origOptions
        const ruleStart = moment(startedOn)
        const rule = new RRule({
            freq,
            byhour,
            byminute,
            bysecond: 0,
            byweekday,
            dtstart: datetime(
                ruleStart.year(),
                ruleStart.month(),
                ruleStart.date(),
                ruleStart.hour(),
                ruleStart.minute(),
            ),
            tzid: zoneId,
        })
        const currentDate = moment().toDate()
        const start = moment(rule.after(currentDate)).utc()
        const end = moment(start).add(minutesDuration, 'minutes')
        return new ModelPricingRule({
            id,
            fieldId,
            price: pricePerHour,
            rangeTime: [start, end],
            rrule: rule,
            rruleString: rruleLocaleString,
            weekDay: rule.origOptions.byweekday.map((e) => convertToOurWeekDay(e))[0],
            fromServer: true,
            minutesDuration,
            zoneId,
        })
    }

    toGql () {
        const {
            rangeTime, price, weekDay, fieldId,
        } = this

        const duration = moment.duration(rangeTime[1].diff(rangeTime[0]))

        const rule = new RRule({
            freq: RRule.WEEKLY,
            byhour: rangeTime[0].hour(),
            byminute: rangeTime[0].minute(),
            byweekday: [convertToRruleWeekDay(weekDay)],
        })
        return {
            fieldId,
            pricePerHour: price,
            minutesDuration: Math.ceil(duration.asMinutes()),
            rruleLocaleString: rule.toString().split(':')[1],
        }
    }
}
