import ModelCustomerLocal from '@/models/ModelCustomerLocal'
import ModelMemberType from '@/models/ModelMemberType'

export default class ModelCustomerLocalStadium {
    constructor (customerLocalStadium) {
        const { stadiumId, stadiumMembers } = customerLocalStadium
        Object.assign(this, {
            ...new ModelCustomerLocal(customerLocalStadium),
            stadiumId,
            stadiumMembers,
        })
    }

    static fromGql (gql) {
        const {
            stadiumId, stadiumMembers,
        } = gql
        return new ModelCustomerLocalStadium({
            ...ModelCustomerLocal.fromGql(gql),
            stadiumId,
            stadiumMembers: stadiumMembers
                ? stadiumMembers.map((member) => ModelMemberType.fromGql(member)) : [],
        })
    }

    static toGql (model) {
        const {
            stadiumId,
            stadiumMemberIds,
        } = model
        const { ...modelCustomerLocal } = ModelCustomerLocal.toGql(model)
        return {
            ...modelCustomerLocal,
            stadiumId,
            stadiumMemberIds: stadiumMemberIds || [],
        }
    }
}
