import ModelTxItem from '@/models/ModelTxItem'
import ResolverBoost from '@/models/resolvers/ResolverBoost'

export default class ModelTxItemBoost extends ModelTxItem {
    constructor (txItemBoost) {
        const {
            mirrorProduct,
        } = txItemBoost
        super(txItemBoost)
        Object.assign(this, {
            mirrorProduct,
        })
    }

    static fromGql (gql) {
        const {
            mirrorProduct,
        } = gql

        return new ModelTxItemBoost({
            mirrorProduct: ResolverBoost.fromGqlUnion(mirrorProduct),
            ...super.fromGql(gql),
        })
    }
}
