import ApiDiscount from '@/api/ApiDiscount'

export default {
    loadDiscountCodes (isPast, page, size) {
        return ApiDiscount.loadDiscountCodes(isPast, page, size)
    },
    addDiscountCode (discountCode) {
        return ApiDiscount.addDiscountCode(discountCode)
    },
    updateDiscountCode (discountCode) {
        return ApiDiscount.updateDiscountCode(discountCode)
    },
    deleteDiscountCode (discountCode) {
        return ApiDiscount.deleteDiscountCode(discountCode)
    },
    loadDiscountCoupons (isPast, page, size) {
        return ApiDiscount.loadDiscountCoupons(isPast, page, size)
    },
    addDiscountCoupon (discountCoupon) {
        return ApiDiscount.addDiscountCoupon(discountCoupon)
    },
    updateDiscountCoupon (discountCoupon) {
        return ApiDiscount.updateDiscountCoupon(discountCoupon)
    },
    deleteDiscountCoupon (discountCoupon) {
        return ApiDiscount.deleteDiscountCoupon(discountCoupon)
    },
}
