import {
    BoostBuffetGeneralInfo,
    BoostReservationGeneralInfo,
    BoostTournamentGeneralInfo,
    BoostTrainingGeneralInfo,
} from '@/api/graphql/fragments/boost'
import { PageGeneralInfo } from '@/api/graphql/fragments/page'
import apolloClient from '@/apolloClient'
import ModelBoostBuffet from '@/models/ModelBoostBuffet'
import ModelBoostReservation from '@/models/ModelBoostReservation'
import ModelTxItemLocal from '@/models/ModelTxItemLocal'
import ModelPagination from '@/models/ModelPagination'
import ResolverEntity from '@/models/resolvers/ResolverEntity'
import VApiResponse from '@/utils/VApiResponse'
import gql from 'graphql-tag'
import moment from 'moment'

export default {
    async addBoostReservation (boostInfo, localCustomers) {
        const { type, ...values } = ModelBoostReservation.toGql(boostInfo)

        return VApiResponse.convertGql(apolloClient.mutate({
            mutation: gql`
                mutation createBoostReservation(
                    $boost:BoostReservationInput!,
                    $localCustomers:[LocalCustomerInput]!
                ){
                    insertBoostReservationWithLocalCustomers(
                        data:$boost,
                        localCustomers: $localCustomers
                    ) {
                        ...BoostReservationGeneralInfo
                    }
                }
                ${BoostReservationGeneralInfo}
                `,
            variables: {
                boost: values,
                ...localCustomers ? {
                    localCustomers: localCustomers.map(
                        (localCustomer) => ModelTxItemLocal.toGql(localCustomer),
                    ),
                } : {},
            },
        }),
        (data) => ModelBoostReservation.fromGql(data.insertBoostReservationWithLocalCustomers))
    },
    async addBoostBuffet (boostInfo, localCustomers) {
        return VApiResponse.convertGql(apolloClient.mutate({
            mutation: gql`
                mutation createBoostBuffet(
                    $boost:BoostBuffetInput!,
                    $localCustomers:[LocalCustomerInput]!
                ){
                    insertBoostBuffetWithLocalCustomers(
                        data:$boost,
                        localCustomers: $localCustomers
                    ) {
                        ...BoostBuffetGeneralInfo
                    }
                }
                ${BoostBuffetGeneralInfo}
                `,
            variables: {
                boost: { ...ModelBoostBuffet.toGql(boostInfo) },
                ...localCustomers ? {
                    localCustomers: localCustomers.map(
                        (localCustomer) => ModelTxItemLocal.toGql(localCustomer),
                    ),
                } : {},
            },
        }),
        (data) => ModelBoostBuffet.fromGql(data.insertBoostBuffetWithLocalCustomers))
    },
    async addBoostTraining (boostInfo, localCustomers) {
        return VApiResponse.convertGql(apolloClient.mutate({
            mutation: gql`
                mutation createBoostBuffet(
                    $boost:BoostTrainingInput!,
                    $localCustomers:[LocalCustomerInput]!
                ){
                    insertBoostTrainingWithLocalCustomers(
                        data:$boost,
                        localCustomers: $localCustomers
                    ) {
                        ...BoostTrainingGeneralInfo
                    }
                }
                ${BoostTrainingGeneralInfo}
                `,
            variables: {
                boost: { ...ModelBoostBuffet.toGql(boostInfo) },
                ...localCustomers ? {
                    localCustomers: localCustomers.map(
                        (localCustomer) => ModelTxItemLocal.toGql(localCustomer),
                    ),
                } : {},
            },
        }),
        (data) => ModelBoostBuffet.fromGql(data.insertBoostTrainingWithLocalCustomers))
    },
    async addBoostTournament (boostInfo, localCustomers) {
        return VApiResponse.convertGql(apolloClient.mutate({
            mutation: gql`
                mutation createBoostTournament(
                    $boost:BoostTournamentInput!,
                    $localCustomers:[LocalCustomerInput]!
                ){
                    insertBoostTournamentWithLocalCustomers(
                        data:$boost,
                        localCustomers: $localCustomers
                    ) {
                        ...BoostTournamentGeneralInfo
                    }
                }
                ${BoostTournamentGeneralInfo}
                `,
            variables: {
                boost: { ...ModelBoostBuffet.toGql(boostInfo) },
                ...localCustomers ? {
                    localCustomers: localCustomers.map(
                        (localCustomer) => ModelTxItemLocal.toGql(localCustomer),
                    ),
                } : {},
            },
        }),
        (data) => ModelBoostBuffet.fromGql(data.insertBoostTournamentWithLocalCustomers))
    },
    async loadBoosts (stadiumId, isPast, page, size, start, end, query) {
        let isPastQuery = {}
        if (isPast != null) {
            if (isPast) {
                isPastQuery = {
                    end: {
                        $lt: {
                            $date: moment().valueOf(),
                        },
                    },
                }
            } else {
                isPastQuery = {
                    end: {
                        $gt: {
                            $date: moment().valueOf(),
                        },
                    },
                }
            }
        }

        const sortQuery = {
            start: isPast ? -1 : 1,
        }

        return VApiResponse.convertGql(apolloClient.query({
            query: gql`
            query getBoosts($query:QueryObject!,$page:Int,$size:Int,$sort:QueryObject!){
                boostsAdvancedPagination(query: $query,page:$page,size:$size,sort:$sort) {
                    datas{
                        ...BoostBuffetGeneralInfo
                        ...BoostReservationGeneralInfo
                    }
                    pageInfo{
                        ...PageGeneralInfo
                    }
                }
            }
            ${BoostBuffetGeneralInfo}
            ${BoostReservationGeneralInfo}
            ${PageGeneralInfo}
            `,
            variables: {
                query: {
                    stadiumId: { $oid: stadiumId },
                    ...isPastQuery,
                    ...start ? {
                        start: {
                            $gte: {
                                $date: start.valueOf(),
                            },
                            ...end ? {
                                $lt: {
                                    $date: end.valueOf(),
                                },
                            } : {},
                        },
                    } : {},
                    ...query || {},
                },
                // page start at 0 on server
                page: page ? page - 1 : 0,
                size: size || 6,
                sort: { ...sortQuery },
            },
        }),
        (data) => ModelPagination.fromGql(data.boostsAdvancedPagination))
    },
    async updateBoostReservation (boost) {
        return VApiResponse.convertGql(apolloClient.mutate({
            mutation: gql`
            mutation updateBoostReservation($id:ObjectId,$boost: BoostReservationInput!){
                updateBoostReservation(id:$id,data:$boost) {
                    ...BoostReservationGeneralInfo
                }
            }
            ${BoostReservationGeneralInfo}
            `,
            variables: {
                id: boost.id,
                boost: ModelBoostReservation.toGql(boost),
            },
        }),
        (data) => ModelBoostReservation.fromGql(data.updateBoostReservation))
    },
    async updateBoostBuffet (boost) {
        return VApiResponse.convertGql(apolloClient.mutate({
            mutation: gql`
            mutation updateBoostBuffet($id:ObjectId,$boost: BoostBuffetInput!){
                updateBoostBuffet(id:$id,data:$boost) {
                    ...BoostBuffetGeneralInfo
                }
            }
            ${BoostBuffetGeneralInfo}
            `,
            variables: {
                id: boost.id,
                boost: ModelBoostBuffet.toGql(boost),
            },
        }),
        (data) => ModelBoostBuffet.fromGql(data.updateBoostBuffet))
    },
    async updateBoostTraining (boost) {
        return VApiResponse.convertGql(apolloClient.mutate({
            mutation: gql`
            mutation updateBoostTraining($id:ObjectId,$boost: BoostTrainingInput!){
                updateBoostTraining(id:$id,data:$boost) {
                    ...BoostTrainingGeneralInfo
                }
            }
            ${BoostTrainingGeneralInfo}
            `,
            variables: {
                id: boost.id,
                boost: ModelBoostBuffet.toGql(boost),
            },
        }),
        (data) => ModelBoostBuffet.fromGql(data.updateBoostTraining))
    },
    async updateBoostTournament (boost) {
        return VApiResponse.convertGql(apolloClient.mutate({
            mutation: gql`
            mutation updateBoostTournament($id:ObjectId,$boost: BoostTournamentInput!){
                updateBoostTournament(id:$id,data:$boost) {
                    ...BoostTournamentGeneralInfo
                }
            }
            ${BoostTournamentGeneralInfo}
            `,
            variables: {
                id: boost.id,
                boost: ModelBoostBuffet.toGql(boost),
            },
        }),
        (data) => ModelBoostBuffet.fromGql(data.updateBoostTournament))
    },
    async deleteBoost (boostId) {
        return VApiResponse.convertGql(apolloClient.mutate({
            mutation: gql`
            mutation deleteBoost($id:ObjectId){
                deleteBoost(id:$id)
            }
            `,
            variables: {
                id: boostId,
            },
        }))
    },
    async cancelProduct (productId) {
        return VApiResponse.convertGql(apolloClient.mutate({
            mutation: gql`
            mutation cancelProduct($id:ObjectId){
                cancelBoost(id:$id){
                    ...BoostBuffetGeneralInfo
                    ...BoostReservationGeneralInfo
                }
            }
            ${BoostBuffetGeneralInfo}
            ${BoostReservationGeneralInfo}
            `,
            variables: {
                id: productId,
            },
        }), (data) => ResolverEntity.fromGqlUnion(data.cancelBoost))
    },
}
