/* eslint-disable class-methods-use-this */
export default class ActionInterceptor {
    constructor ({ dispatch, commit }, actionName, action, payload) {
        this.dispatch = dispatch
        this.commit = commit
        this.action = action
        this.actionName = actionName
        this.payload = payload
    }

    before () {
    }

    /**
     * @param res // ApiResponse
     */
    after () {
    }

    isPassRequirement () {
        return true
    }
}
