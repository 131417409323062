import VApiResponse from '@/utils/VApiResponse'
import gql from 'graphql-tag'
import apolloClient from '@/apolloClient'
import ModelPricingRule from '@/models/ModelPricingRule'
import { FieldPriceSettingGeneralInfo } from '@/api/graphql/fragments/field'
import moment from 'moment'
import ModelPagination from '@/models/ModelPagination'
import { converObjectId } from '@/utils/GqlUtils'
// import { Field as MockField } from './mock'
// import { Field as DevField } from './dev'
export default {
    // ...(process.env.NODE_ENV === 'production') && MockField,
    // ...(process.env.NODE_ENV === 'development') && MockField,
    getPriceRules (fieldId) {
        return VApiResponse.convertGql(apolloClient.query({
            query: gql`
            query getPriceRules(
                $activeOn: Date,
                $query:QueryObject!,
                $page:Int,$size:Int,$sort:QueryObject){
                fieldPriceSettingsPagination(query: $query,page:$page,size:$size,sort:$sort,activeOn: $activeOn,){
                    datas{
                        ...FieldPriceSettingGeneralInfo
                    }
                }
            }
            ${FieldPriceSettingGeneralInfo}
            `,
            variables: {
                activeOn: moment().format(),
                query: { fieldId: converObjectId(fieldId) },
                // page start at 0 on server
                page: 0,
                size: 200,
                sort: {},
            },
        }),
        (data) => ModelPagination.fromGql(data.fieldPriceSettingsPagination))
    },
    updatePriceRules ({ insertingRules, disableRuleIds }) {
        return VApiResponse.convertGql(apolloClient.mutate({
            mutation: gql`
            mutation updatePriceRulesTableMode(
                $endingIds: [ObjectId],
                $insertingDatas: [FieldPriceSettingsInput]){
                stadiumDoEndAndInsertBulkFieldPriceSettingsInTableMode (
                    endingIds: $endingIds
                    insertingDatas: $insertingDatas
                )
            }
            `,
            variables: {
                endingIds: disableRuleIds,
                insertingDatas: insertingRules.map((rule) => new ModelPricingRule(rule).toGql()),
            },
        }))
        // return this.insertPriceRule(insertingRules[0])
        // rules.forEach((rule) => {
        //     await this.insertPriceRule(rule)
        // })
    },
    insertPriceRule (rule) {
        return VApiResponse.convertGql(apolloClient.query({
            query: gql`
            mutation insertPriceRule($data:FieldPriceSettingsInput){
                stadiumDoInsertFieldPriceSettings(data:$data ){
                    ...FieldPriceSettingGeneralInfo
                }
            }
            ${FieldPriceSettingGeneralInfo}
            `,
            variables: {
                data: new ModelPricingRule(rule).toGql(),
            },
        }),
        (data) => ModelPricingRule.fromGql(data.stadiumDoInsertFieldPriceSettings))
    },
    getCalculateFieldPrice ({
        fieldId,
        startedOn,
        endedOn,
    }) {
        return VApiResponse.convertGql(apolloClient.query({
            query: gql`
            query getCalculateFieldPrice($fieldId: ObjectId,$startedOn: Date,$endedOn: Date){
                calculateFieldPrice(fieldId:$fieldId,startedOn:$startedOn,endedOn:$endedOn)
            }
            `,
            variables: {
                fieldId,
                endedOn,
                startedOn,
            },
        }),
        (data) => data.calculateFieldPrice)
    },
}
