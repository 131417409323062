import ModelItem from '@/models/ModelItem'
import ModelItemCondition from '@/models/ModelItemCondition'
import ModelProduct from '@/models/ModelProduct'
import moment from 'moment'

export default class ModelBundleCoupon {
    constructor (model) {
        const {
            publishedOn,
            unpublishedOn,
            forcedUnpublished,
            items,
            buyCondition,
            buyAmountLimitPerPerson,
        } = model
        Object.assign(this, {
            ...new ModelProduct(model),
            ...new ModelItem(model),
            publishedOn,
            unpublishedOn,
            forcedUnpublished,
            items,
            buyCondition,
            buyAmountLimitPerPerson,
        })
    }

    static fromGql (gql) {
        const {
            publishedOn,
            unpublishedOn,
            forcedUnpublished,
            buyCondition,
            items,
            buyAmountLimitPerPerson,
        } = gql

        return new ModelBundleCoupon({
            ...ModelProduct.fromGql(gql),
            ...ModelItem.fromGql(gql),
            publishedOn: publishedOn ? moment(publishedOn) : null,
            unpublishedOn: unpublishedOn ? moment(unpublishedOn) : null,
            buyCondition: buyCondition ? ModelItemCondition.fromGql(buyCondition) : null,
            ...forcedUnpublished !== null && forcedUnpublished !== undefined
                ? { forcedUnpublished: !!forcedUnpublished } : {},
            items,
            buyAmountLimitPerPerson,
        })
    }

    static toGql (model) {
        const {
            publishedOn,
            unpublishedOn,
            forcedUnpublished,
            items,
            buyCondition,
            buyAmountLimitPerPerson,
        } = model

        const publishedOnValue = publishedOn ? publishedOn.format() : publishedOn

        return {
            ...ModelProduct.toGql(model),
            ...ModelItem.toGql(model),
            ...publishedOnValue === undefined
                ? {}
                : { publishedOn: publishedOn ? publishedOn.format() : publishedOn },
            ...unpublishedOn === undefined
                ? {}
                : { unpublishedOn: unpublishedOn ? unpublishedOn.format() : unpublishedOn },
            ...buyCondition ? { buyCondition: ModelItemCondition.toGql(buyCondition) } : {},
            ...forcedUnpublished !== null && forcedUnpublished !== undefined
                ? { forcedUnpublished: !!forcedUnpublished } : {},
            items,
            buyAmountLimitPerPerson,
        }
    }
}
