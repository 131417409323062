var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("a-config-provider", { attrs: { locale: _vm.locale } }, [
    _c(
      "div",
      { attrs: { id: "app" } },
      [_c("MessagesAnnoucement"), _c("router-view")],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }