import ModelBoostBuffet from '@/models/ModelBoostBuffet'
import ModelBoostReservation from '@/models/ModelBoostReservation'
import ModelManagerPartyEvent from '@/models/ModelManagerPartyEvent'
import {
    EntitiesGql,
} from '@/utils/enum'
import ModelBundleCoupon from '@/models/ModelBundleCounpon'

export default class ResolverProduct {
    static fromGqlUnion (gql) {
        const {
            __typename,
        } = gql
        switch (__typename) {
        case EntitiesGql.BoostBuffet:
        case EntitiesGql.BoostTraining:
        case EntitiesGql.BoostTournament:
            return ModelBoostBuffet.fromGql(gql)
        case EntitiesGql.BoostReservation:
            return ModelBoostReservation.fromGql(gql)
        case EntitiesGql.ManagerPartyEventBuffet:
        case EntitiesGql.ManagerPartyEventTraining:
        case EntitiesGql.ManagerPartyEventTournament:
            return ModelManagerPartyEvent.fromGql(gql)
        case EntitiesGql.BundleCoupon:
            return ModelBundleCoupon.fromGql(gql)
        default:
            return gql
        }
    }
}
