import { createTypes, createTypesWithNamespace } from '@/utils/store-utils'

const _namespace = 'manager'

const types = {
    /**
     * @typedef {Object} Actions
     * @prop {String} LOAD_MANAGERS
     * @prop {String} ADD_MANAGER
     * @prop {String} UPDATE_MANAGER
     * @prop {String} DELETE_MANAGER
     */
    Actions: [
        'LOAD_MANAGERS',
        'ADD_MANAGER',
        'UPDATE_MANAGER',
        'DELETE_MANAGER',
    ],
    /**
     * @typedef {Object} Mutations
     * @prop {String} LOAD_MANAGERS
     * @prop {String} UPDATE_MANAGER
     * @prop {String} ADD_MANAGER
     * @prop {String} DELETE_MANAGER
     */
    Mutations: [
        'LOAD_MANAGERS',
        'UPDATE_MANAGER',
        'ADD_MANAGER',
        'DELETE_MANAGER',
    ],
    /**
     * @typedef {Object} Getters
     * @prop {String} GET_MANAGER
     */
    Getters: [
        'GET_MANAGER',
    ],
}
/**
 * @typedef {Object} Types
 * @prop {Actions} Actions
 * @prop {Mutations} Mutations
 * @prop {Getters} Getters
 */
/**
 * @type {Types} types
 */
const { Actions, Getters } = createTypesWithNamespace(_namespace, types)

/**
 * @type {Types} types
 */
const { Actions: _Actions, Mutations: _Mutations, Getters: _Getters } = createTypes(types)

export {
    Actions, Getters, _Mutations, _Actions, _Getters,
}
