import { BoostReservationGeneralInfo } from '@/api/graphql/fragments/boost'
import { CustomerGeneralInfo, UserGeneralInfo } from '@/api/graphql/fragments/user'
import gql from 'graphql-tag'

export const ReservationOfferGeneralInfo = gql`
   fragment ReservationOfferGeneralInfo on ReservationOffer{
        _id
        status
        ...BoostReservationGeneralInfo
    }
    ${BoostReservationGeneralInfo}
`

export const ChatRoomMsgGeneralInfo = gql`
    fragment ChatRoomMsgGeneralInfo on ConversationPayload {
        _id
        id
        stadiumId
        productAttachment {
            ...ReservationOfferGeneralInfo
        }
        # attachment {
        #     type
            # ... on ReservationAsk{
            #     ...ReservationAskGeneralInfo
            # }
            # ... on ReservationOffer{
            #     ...ReservationOfferGeneralInfo
            # }
        # }
        fromUser {
            ...UserGeneralInfo
            ... on Customer{
                ...CustomerGeneralInfo
            }
            ... on StadiumUser{
                stadiumUserRoles {
                    stadiumId
                    role
                }
            }
            ... on AdminUser{
                adminRole
                username
            }
            ... on ManagerUser{
                partyIds
            }
        }
        message
        updatedOn
    }
    ${CustomerGeneralInfo}
    ${UserGeneralInfo}
    ${ReservationOfferGeneralInfo}
`

export const ChatRoomGeneralInfo = gql`
    fragment ChatRoomGeneralInfo on Conversation {
        _id
        id
        name
        updatedOn
        recentlyMsgs:lastPayloads(size:1) {
          ...ChatRoomMsgGeneralInfo
        }
        members {
            user {
                ...UserGeneralInfo
                ... on Customer{
                    ...CustomerGeneralInfo
                }
            }
        }
    }
    ${CustomerGeneralInfo}
    ${UserGeneralInfo}
    ${ChatRoomMsgGeneralInfo}
`
