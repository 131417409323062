<template>
    <div />
</template>
<script>
export default {

}
</script>

<style>

</style>
<script>
import { createNamespacedHelpers } from 'vuex'

const { mapState } = createNamespacedHelpers('message')

export default {
    data () {
        return {
            annoucements: [],
        }
    },
    computed: {
        ...mapState([
            'messages',
        ]),
    },
    created(){
        this.$message.config({
            maxCount: 3,
        })
    },
    watch: {
        messages (newValue, oldValue) {
            const lostMessages = oldValue.filter(ov => !newValue.includes(ov))
            lostMessages.forEach(((lostMsg) => {
                const target = this.annoucements.find(item => item.id === lostMsg.id)
                setTimeout(target.value)
                this.annoucements = this.annoucements.filter(item => item.id !== lostMsg.id)
            }))
            const newMessages = newValue.filter(nv => !oldValue.includes(nv))
            newMessages.forEach((nm) => {
                this.annoucements.push({
                    ...nm,
                    value: this.createAnnoucement(nm),
                })
            })
        },
    },
    methods: {
        createAnnoucement (msg) {
            if (msg.type === 'loading') {
                return this.$message.loading(msg.message, 0)
            }
            if (msg.type === 'success') {
                return this.$message.success(msg.message, 0)
            } if (msg.type === 'error') {
                return this.$message.error(msg.message, 0)
            }
            return this.$message.info(msg.message, 0)
        },
    },
}
</script>
