import moment from 'moment'

export default class ModelCode {
    constructor ({
        id,
        type,
        code,
        generatedByUser,
        expiredOn,
    }) {
        Object.assign(this, {
            id,
            type,
            code,
            generatedByUser,
            expiredOn,
        })
    }

    static fromGql (gql) {
        const {
            _id, type, code, generatedByUser: user, expiredOn,
        } = gql
        const codeModel = {
            id: _id,
            type,
            code,
            generatedByUser: {
                username: user.username,
                firstName: user.firstName,
                lastName: user.lastName,
                nickName: user.nickName,
                phoneNumber: user.phoneNumber,
            },
            expiredOn: moment(expiredOn),
        }
        return new ModelCode({
            ...codeModel,
        })
    }
}
