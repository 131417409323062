import { EmployeeGeneralInfo } from '@/api/graphql/fragments/employee'
import VApiResponse from '@/utils/VApiResponse'
import apolloClient from '@/apolloClient'
import gql from 'graphql-tag'
import ModelUserStadium from '@/models/ModelUserStadium'
import ModelPagination from '@/models/ModelPagination'
import { PageGeneralInfo } from '@/api/graphql/fragments/page'

export default {
    async loadEmployees ({ stadiumId, page, size }) {
        return VApiResponse.convertGql(apolloClient.query({
            query: gql`
            query getEmployees($query:QueryObject!,$page:Int,$size:Int){
                stadiumUsersPagination(query:$query, page:$page, size:$size  ) {
                    datas{
                        ...EmployeeGeneralInfo
                    }
                    pageInfo{
                        ...PageGeneralInfo
                    }
                }
            }
            ${EmployeeGeneralInfo}
            ${PageGeneralInfo}
            `,
            variables: {
                query: {
                    stadiumId: {
                        $oid: stadiumId,
                    },
                },
                // page start at 0 on server
                page: page - 1,
                size,
            },
        }),
        (data) => ModelPagination.fromGql(data.stadiumUsersPagination))
    },
    async deleteEmployee (id) {
        return VApiResponse.convertGql(apolloClient.mutate({
            mutation: gql`
            mutation deleteEmployee($id:ObjectId!){
                deleteStadiumUser(id:$id)
            }
            `,
            variables: {
                id,
            },
        }))
    },
    async stadiumDeleteEmployee ({ id, stadiumId }) {
        return VApiResponse.convertGql(apolloClient.mutate({
            mutation: gql`
            mutation stadiumDeleteEmployee($id:ObjectId!,$stadiumId:ObjectId!){
                stadiumDoDeleteStadiumUser(id:$id,stadiumId: $stadiumId)
            }
            `,
            variables: {
                id,
                stadiumId,
            },
        }))
    },
    async addEmployee (employee) {
        return VApiResponse.convertGql(apolloClient.mutate({
            mutation: gql`
            mutation createEmployee($employee:StadiumUserInput!){
                insertStadiumUser(data:$employee) {
                    ...EmployeeGeneralInfo
                }
            }
            ${EmployeeGeneralInfo}
            `,
            variables: {
                employee: new ModelUserStadium(employee).toGql(),
            },
        }),
        (data) => ModelUserStadium.fromGql(data.insertStadiumUser))
    },
    async stadiumAddEmployee (employee) {
        return VApiResponse.convertGql(apolloClient.mutate({
            mutation: gql`
            mutation stadiumCreateEmployee($employee:StadiumUserInput!){
                stadiumDoInsertStadiumUser(data:$employee) {
                    ...EmployeeGeneralInfo
                }
            }
            ${EmployeeGeneralInfo}
            `,
            variables: {
                employee: new ModelUserStadium(employee).toGql(),
            },
        }),
        (data) => ModelUserStadium.fromGql(data.stadiumDoInsertStadiumUser))
    },
    async updateEmployee (employee) {
        return VApiResponse.convertGql(apolloClient.mutate({
            mutation: gql`
            mutation updateEmployee($id:ObjectId,$employee:StadiumUserInput!){
                updateStadiumUser(id:$id,data:$employee) {
                    ...EmployeeGeneralInfo
                }
            }
            ${EmployeeGeneralInfo}
            `,
            variables: {
                id: employee.id,
                employee: new ModelUserStadium(employee).toGql(),
            },
        }),
        (data) => ModelUserStadium.fromGql(data.updateStadiumUser))
    },
    async stadiumUpdateEmployee (employee) {
        return VApiResponse.convertGql(apolloClient.mutate({
            mutation: gql`
            mutation stadiumUpdateEmployee($id:ObjectId,$employee:StadiumUserInput!){
                stadiumDoUpdateStadiumUser(id:$id,data:$employee) {
                    ...EmployeeGeneralInfo
                }
            }
            ${EmployeeGeneralInfo}
            `,
            variables: {
                id: employee.id,
                employee: new ModelUserStadium(employee).toGql(),
            },
        }),
        (data) => ModelUserStadium.fromGql(data.stadiumDoUpdateStadiumUser))
    },
}
