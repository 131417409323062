import { PageGeneralInfo } from '@/api/graphql/fragments/page'
import apolloClient from '@/apolloClient'
import ModelPagination from '@/models/ModelPagination'
import VApiResponse from '@/utils/VApiResponse'
import gql from 'graphql-tag'
import { ChatRoomGeneralInfo, ChatRoomMsgGeneralInfo } from '@/api/graphql/fragments/chat'
import ModelChatRoomMsg from '@/models/ModelChatRoomMsg'
import ModelChatRoom from '@/models/ModelChatRoom'
import ModelOfferReservation from '@/models/ModelOfferReservation'

function getSort () {
    return {
        updatedOn: -1,
    }
}

export default {
    async sendMsg (conversationId, msg) {
        return VApiResponse.convertGql(
            apolloClient.mutate({
                mutation: gql`
                    mutation sendMsg($conversationId:ObjectId!,$message:String!){
                        sendConversationPayload(conversationId: $conversationId,message: $message) {
                            ...ChatRoomMsgGeneralInfo
                        }}
                        ${ChatRoomMsgGeneralInfo}
                    `,
                variables: {
                    conversationId,
                    message: msg,
                },
            }),
            (data) => ModelChatRoomMsg.fromGql(data.sendConversationPayload),
        )
    },
    async sendReservationOffer (conversationId, msg, offer) {
        return VApiResponse.convertGql(
            apolloClient.mutate({
                mutation: gql`
                    mutation sendReservationOffer(
                        $conversationId:ObjectId!,
                        $message:String!,
                        $data:ReservationOfferInput!
                    ){
                        sendReservationOffer (
                            conversationId: $conversationId,
                            data: $data,
                            message: $message
                        ) {
                            ...ChatRoomMsgGeneralInfo
                        }}
                        ${ChatRoomMsgGeneralInfo}
                    `,
                variables: {
                    conversationId,
                    message: msg,
                    data: ModelOfferReservation.toGql(offer),
                },
            }),
            (data) => ModelChatRoomMsg.fromGql(data.sendReservationOffer),
        )
    },
    async loadChatRoomMsgs (conversationId, page, size) {
        return VApiResponse.convertGql(
            await apolloClient.query({
                query: gql`
                query getChatRoomMsgsPagination(
                    $conversationId:ObjectId!,
                    $query:QueryObject!,$page:Int,$size:Int,$sort:QueryObject
                ){
                    conversationPayloadsPagination (
                        conversationId: $conversationId,
                        query: $query, page: $page, size: $size,sort:$sort
                    ) {
                        datas{
                            ...ChatRoomMsgGeneralInfo
                        }
                        pageInfo{
                            ...PageGeneralInfo
                        }
                    }
                }
                ${ChatRoomMsgGeneralInfo}
                ${PageGeneralInfo}
                `,
                variables: {
                    conversationId,
                    query: {},
                    // page start at 0 on server
                    page: page - 1,
                    size,
                    sort: { ...getSort() },
                },
            }),
            (data) => ModelPagination.fromGql(data.conversationPayloadsPagination),
        )
    },
    async loadChatRooms (stadiumId, page, size) {
        return VApiResponse.convertGql(
            await apolloClient.query({
                query: gql`
                query getChatRoomsPagination(
                    $stadiumId:ObjectId!,
                    $query:QueryObject!,$page:Int,$size:Int,$sort:QueryObject
                ){
                    stadiumConversationsPagination(
                        stadiumId: $stadiumId,
                        query: $query, page: $page, size: $size,sort:$sort) {
                        datas{
                            ...ChatRoomGeneralInfo
                        }
                        pageInfo{
                            ...PageGeneralInfo
                        }
                    }
                }
                ${ChatRoomGeneralInfo}
                ${PageGeneralInfo}
                `,
                variables: {
                    stadiumId,
                    query: {},
                    // page start at 0 on server
                    page: page - 1,
                    size,
                    sort: { ...getSort() },
                },
            }),
            (data) => ModelPagination.fromGql(data.stadiumConversationsPagination),
        )
    },
    async loadChatRoom (id) {
        return VApiResponse.convertGql(
            await apolloClient.query({
                query: gql`
                query getChatRoom($id:ObjectId!){
                    stadiumConversation(id: $id) {
                        ...ChatRoomGeneralInfo
                    }
                }
                ${ChatRoomGeneralInfo}
                `,
                variables: {
                    id,
                },
            }),
            (data) => ModelChatRoom.fromGql(data.stadiumConversation),
        )
    },
}
