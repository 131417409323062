import ModelField from '@/models/ModelField'
import moment from 'moment'

export default class ModelProductField {
    constructor ({
        fieldId,
        start,
        end,
        field,
    }) {
        Object.assign(this, {
            fieldId,
            start,
            end,
            field,
        })
    }

    static fromGql (gql) {
        const {
            fieldId,
            start,
            end,
            field,
        } = gql
        return new ModelProductField({
            fieldId,
            start: start ? moment(start) : null,
            end: end ? moment(end) : null,
            ...field ? { field: ModelField.fromGql(field) } : {},
        })
    }

    static toGql (model) {
        const {
            fieldId,
            start,
            end,
        } = model

        return {
            fieldId,
            ...start ? { start: start.format() } : {},
            ...end ? { end: end.format() } : {},
        }
    }
}
