import ModelStadiumTaxInfo from '@/models/ModelStadiumTaxInfo'
import moment from 'moment'

export default class ModelStadium {
    constructor ({
        id,
        name,
        phones,
        facilities,
        description,
        address,
        coverPhotoUrl,
        logoPhotoUrl,
        location,
        isOfficial,
        openTime,
        closeTime,
        zoneId,
        generalCommissionRate,
        adsCommissionRate,
        mainTaxInfo,
    }) {
        Object.assign(this, {
            id,
            name,
            phones,
            facilities,
            description,
            address,
            coverPhotoUrl,
            logoPhotoUrl,
            location,
            isOfficial,
            openTime,
            closeTime,
            zoneId,
            generalCommissionRate,
            adsCommissionRate,
            mainTaxInfo,
        })
    }

    static fromGql (gql) {
        const {
            id,
            name,
            phones,
            facilities,
            desc,
            coverPhotoUrl,
            logoPhotoUrl,
            addressString,
            location,
            isOfficial,
            openTime,
            closeTime,
            zoneId,
            generalCommissionRate,
            adsCommissionRate,
            mainTaxInfo,
        } = gql
        return new ModelStadium({
            id,
            name,
            phones,
            facilities,
            description: desc,
            coverPhotoUrl,
            logoPhotoUrl,
            location,
            address: addressString,
            isOfficial,
            openTime: openTime ? moment(openTime, 'HH:mm') : null,
            closeTime: closeTime ? moment(closeTime, 'HH:mm') : null,
            zoneId,
            generalCommissionRate,
            adsCommissionRate,
            ...mainTaxInfo ? { mainTaxInfo: ModelStadiumTaxInfo.fromGql(mainTaxInfo) } : {},
        })
    }

    toGql () {
        const { mainTaxInfo, isOfficial } = this
        return {
            name: this.name,
            phones: this.phones,
            facilities: this.facilities,
            desc: this.description,
            addressString: this.address,
            location: this.location,
            isOfficial,
            openTime: this.openTime ? this.openTime.format('HH:mm') : null,
            closeTime: this.closeTime ? this.closeTime.format('HH:mm') : null,
            zoneId: 'Asia/Bangkok',
            generalCommissionRate: this.generalCommissionRate,
            adsCommissionRate: this.adsCommissionRate,
            ...mainTaxInfo ? { mainTaxInfo: new ModelStadiumTaxInfo(mainTaxInfo).toGql() } : {},
        }
    }
}
