import ActionProcessor from '@/utils/ActionProcessor'
import { createActionsWithInterceptors } from '@/utils/store-utils'
import UpdateStatusInterceptor from '@/store/modules/stadium/interceptors/UpdateStatusInterceptor'
import { _Actions, _Mutations } from '@/store/modules/code/types'
import ServiceCode from '@/services/ServiceCode'
import { isManagerUser } from '@/utils/enum'

class ActionProcessorImpl extends ActionProcessor {
    createInterceptors ({ commit }, actionName, action, payload) {
        return [
            new UpdateStatusInterceptor({ commit }, actionName, action, payload),
        ]
    }
}

const Processors = new ActionProcessorImpl()

export default {
    namespaced: true,
    state: {
        /**
         * @typedef {Object} Code
         * @prop {String} id
         * @prop {String} type
         * @prop {String} code
         * @prop {User} generatedByUser
         * @prop {DateTime} expiredOn
         * @prop {Tx} tx
         */
        /**
         * @typedef {Object} User
         * @prop {Number} id
         * @prop {String} firstName
         * @prop {String} lastName
         * @prop {String} nickName
         * @prop {String} tel
         * @prop {String} email
         */
        /**
         * @typedef {Object} Tx
         * @prop {String} id
         * @prop {Number} price
         * @prop {TxItem[]} txItems
         */
        /**
         * @typedef {Object} TxItem
         * @prop {String} id
         * @prop {String} type
         * @prop {Number} amount
         * @prop {Number} price
         * @prop {Boost} mirrorProduct
         */
        /**
         * @type {Code}
         */
        data: null,
    },
    actions: {
        ...createActionsWithInterceptors(Processors,
            {
                async [_Actions.LOAD_CODE] ({ rootState, commit }, redeemCode) {
                    const res = isManagerUser(rootState.auth.me)
                        ? await ServiceCode.loadCodeByManager(
                            rootState.auth.me.partyId, redeemCode,
                        )
                        : await ServiceCode.loadCode(rootState.stadium.id, redeemCode)
                    if (res.isSuccess()) {
                        commit(_Mutations.LOAD_CODE, res.data)
                    } else {
                        commit(_Mutations.LOAD_CODE, null)
                    }
                    return res
                },
                async [_Actions.USE_PAYMENT_CODE] ({ rootState }, { txId, redeemCode }) {
                    const res = await ServiceCode.usePaymentCode(
                        rootState.auth.me,
                        txId,
                        redeemCode,
                    )
                    return res
                },
                async [_Actions.USE_REDEEM_CODE] ({ rootState }, { txItemIds, redeemCode }) {
                    const res = await ServiceCode.useRedeemCode(
                        rootState.auth.me,
                        txItemIds,
                        redeemCode,
                    )
                    return res
                },
                [_Actions.CLEAR_ACTIVE_CODE] ({ commit }) {
                    commit(_Mutations.LOAD_CODE, null)
                },
            }),
    },
    mutations: {
        [_Mutations.LOAD_CODE] (state, code) {
            state.data = code
        },
    },
}
