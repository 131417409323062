<template>
    <div>
        <ModalSessionTimeout />
        <HomeLayout
            :sidebar="sidebar"
            default-path="/manager/party"
            default-group="manager" />
    </div>
</template>

<script>
import HomeLayout from '@/components/HomeLayout.vue'
import ModalSessionTimeout from '@/components/ModalSessionTimeout.vue'

export default {
    components: {
        HomeLayout,
        ModalSessionTimeout,
    },
    data () {
        return {
            sidebar: [
                {
                    label: 'ก๊วน',
                    group: 'party',
                    icon: 'team',
                    items: [
                        {
                            label: 'ทั่วไป',
                            path: '/manager/party',
                        },
                        {
                            label: 'กิจกรรม',
                            path: '/manager/party/partyEvent',
                        },
                    ],
                },
                {
                    label: 'รหัส QR/Code',
                    group: 'code',
                    icon: 'qrcode',
                    items: [
                        {
                            label: 'ใช้งาน QR/Code',
                            path: '/manager/code/use',
                        },
                    ],
                },
                {
                    label: 'ฉัน',
                    group: 'me',
                    icon: 'user',
                    items: [
                        {
                            label: 'ข้อมูลทั่วไป',
                            path: '/manager/me',
                        },
                        {
                            label: 'เปลี่ยนรหัสผ่าน',
                            path: '/manager/me/change-password',
                        },
                    ],
                },
            ],
        }
    },
}
</script>
