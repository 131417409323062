import { createTypes, createTypesWithNamespace } from '@/utils/store-utils'

const _namespace = 'code'

const types = {
    /**
     * @typedef {Object} Actions
     * @prop {String} LOAD_CODE
     * @prop {String} USE_PAYMENT_CODE
     * @prop {String} CLEAR_ACTIVE_CODE
     * @prop {String} USE_REDEEM_CODE
     */
    Actions: [
        'LOAD_CODE',
        'USE_PAYMENT_CODE',
        'CLEAR_ACTIVE_CODE',
        'USE_REDEEM_CODE',
    ],
    /**
     * @typedef {Object} Mutations
     * @prop {String} LOAD_CODE
     */
    Mutations: [
        'LOAD_CODE',
    ],
    /**
     * @typedef {Object} Getters
     */
    Getters: [
    ],
}
/**
 * @typedef {Object} Types
 * @prop {Actions} Actions
 * @prop {Mutations} Mutations
 * @prop {Getters} Getters
 */
/**
 * @type {Types} types
 */
const { Actions, Getters } = createTypesWithNamespace(_namespace, types)

/**
 * @type {Types} types
 */
const { Actions: _Actions, Mutations: _Mutations, Getters: _Getters } = createTypes(types)

const Entities = {
}

export {
    Actions, Getters, _Mutations, _Actions, _Getters, Entities,
}
