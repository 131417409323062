import VApiResponse from '@/utils/VApiResponse'
import apolloClient from '@/apolloClient'
import axiosClient from '@/axiosClient'
import gql from 'graphql-tag'
import { ManagerGeneralInfo } from '@/api/graphql/fragments/user'
import ModelParty from '@/models/ModelParty'
import { PartyGeneralInfo } from '@/api/graphql/fragments/party'
import { converObjectId } from '@/utils/GqlUtils'
import ModelUserManager from '@/models/ModelUserManager'
import ModelPagination from '@/models/ModelPagination'
import { PageGeneralInfo } from '@/api/graphql/fragments/page'

export default {
    async loadManagers (page, size) {
        return VApiResponse.convertGql(apolloClient.query({
            query: gql`
            query getManagers($query:QueryObject!,$page:Int,$size:Int,$sort:QueryObject){
                managerUsersPagination(query:$query ,page:$page,size:$size,sort:$sort) {
                    datas{
                    ...ManagerGeneralInfo
                    }
                    pageInfo{
                        ...PageGeneralInfo
                    }
                }
            }
            ${ManagerGeneralInfo}
            ${PageGeneralInfo}
            `,
            variables: {
                query: {},
                // page start at 0 on server
                page: page - 1,
                size,
                sort: {
                    firstName: 1,
                },
            },
        }),
        (data) => ModelPagination.fromGql(data.managerUsersPagination))
    },
    async deleteManager (id) {
        return VApiResponse.convertGql(apolloClient.mutate({
            mutation: gql`
            mutation deleteManager($id:ObjectId!){
                deleteManagerUser(id:$id)
            }
            `,
            variables: {
                id,
            },
        }))
    },
    async addManager (manager) {
        return VApiResponse.convertGql(apolloClient.mutate({
            mutation: gql`
            mutation createManager($manager:ManagerUserInput!){
                insertManagerUser(data:$manager) {
                    ...ManagerGeneralInfo
                }
            }
            ${ManagerGeneralInfo}
            `,
            variables: {
                manager: new ModelUserManager(manager).toGql(),
            },
        }),
        (data) => ModelUserManager.fromGql(data.insertManagerUser))
    },
    async updateManager (manager) {
        return VApiResponse.convertGql(apolloClient.mutate({
            mutation: gql`
            mutation updateManager($id:ObjectId,$manager:ManagerUserInput!){
                updateManagerUser(id:$id,data:$manager) {
                    ...ManagerGeneralInfo
                }
            }
            ${ManagerGeneralInfo}
            `,
            variables: {
                id: manager.id,
                manager: new ModelUserManager(manager).toGql(),
            },
        }),
        (data) => ModelUserManager.fromGql(data.updateManagerUser))
    },
    async getPartyByUserId (userId) {
        return VApiResponse.convertGql(apolloClient.query({
            query: gql`
                query managerPartys($query:QueryObject!){
                    managerPartys(query:$query){
                        ...PartyGeneralInfo
                    }
                }
                ${PartyGeneralInfo}
            `,
            variables: {
                query: {
                    managerId: converObjectId(userId),
                },
            },
        }),
        (data) => (data.managerPartys == null || data.managerPartys.length === 0
            ? null : ModelParty.fromGql(data.managerPartys[0])))
    },
    loadPartyById (id) {
        return VApiResponse.convertGql(apolloClient.query({
            query: gql`
                query getParty($id:ObjectId!){
                    managerParty(id:$id){
                        ...PartyGeneralInfo
                    }
                }
                ${PartyGeneralInfo}
            `,
            variables: {
                id,
            },
        }),
        (data) => ModelParty.fromGql(data.managerParty))
    },
    async addParty (party) {
        return VApiResponse.convertGql(apolloClient.mutate({
            mutation: gql`
            mutation createParty($party:ManagerPartyInput){
                insertMeManagerParty(data:$party) {
                    ...PartyGeneralInfo
                }
            }
            ${PartyGeneralInfo}
            `,
            variables: {
                party: new ModelParty(party).toGql(),
            },
        }),
        (data) => ModelParty.fromGql(data.insertMeManagerParty))
    },
    async updateParty (party) {
        return VApiResponse.convertGql(apolloClient.mutate({
            mutation: gql`
            mutation updateParty($id:ObjectId,$party:ManagerPartyInput!){
                updateMeManagerParty(id:$id,data:$party) {
                    ...PartyGeneralInfo
                }
            }
            ${PartyGeneralInfo}
            `,
            variables: {
                id: party.id,
                party: new ModelParty(party).toGql(),
            },
        }),
        (data) => ModelParty.fromGql(data.updateMeManagerParty))
    },
    async updateCoverParty (party) {
        const formData = new FormData()
        formData.append('file', party.coverPhoto)
        await axiosClient({
            method: 'post',
            url: `/managerParty/${party.id}/cover`,
            data: formData,
            headers: { 'Content-Type': 'multipart/form-data' },
        })
        return `/managerParty/${party.id}/cover`
    },
    async updateLogoParty (party) {
        const formData = new FormData()
        formData.append('file', party.logoPhoto)
        await axiosClient({
            method: 'post',
            url: `/managerParty/${party.id}/logo`,
            data: formData,
            headers: { 'Content-Type': 'multipart/form-data' },
        })
        return `/managerParty/${party.id}/logo`
    },
}
