import { Msg } from '@/utils/enum'

export default class VApiResponse {
    constructor ({
        status, msg, data,
    }) {
        this.status = status
        this.msg = msg
        this.data = data
    }

    isSuccess () {
        return this.status === 'success'
    }

    isError () {
        return !this.isSuccess()
    }

    getErrorGqlCode () {
        return VApiResponse.getErrorGqlCodeFromError(this.data)
    }

    static getErrorGqlCodeFromError (error) {
        let errorCode
        try {
            errorCode = error.extensions.code
        } catch (e) {
            errorCode = null
        }
        return errorCode
    }

    static SUCCESS (data) {
        return new VApiResponse({
            status: 'success',
            msg: Msg.SUCCESS.message,
            data,
        })
    }

    static ERROR (data, msg = Msg.ERROR.message) {
        return new VApiResponse({
            status: 'error',
            msg,
            data,
        })
    }

    static GQL_ERROR (errors) {
        return new VApiResponse({
            status: 'error',
            msg: VApiResponse.getErrorMsgGql(errors[0]),
            data: errors[0],
        })
    }

    static getErrorMsgGql (error) {
        const errorCode = VApiResponse.getErrorGqlCodeFromError(error)
        switch (errorCode) {
        case 'NOT_ENOUGH_ITEM':
            return 'มีสินค้าไม่เพียงพอ'
        case 'INVALID_TX_STAGE':
            return 'โค้ดนี้ไม่อยู่ในสถานะที่ใช้งานได้แล้ว เช่นอาจจะหมดอายุหรือถูกใช้ไปแล้ว'
        case 'UNDELETABLE':
            return 'ไม่สามารถลบได้ เนื่องจากสินค้าถูกซื้อไปแล้ว'
        default:
            try {
                return error.extensions.localizedMessage
            } catch (e) {
                return Msg.ERROR.message
            }
        }
    }

    /**
     * Covert Api Response to our app VApiResponse format.
     * Benefit :
     * 1. Check error from isSuccess() instead of .catch() or try{}/catch{}. Make out code cleaner.
     * 2. Ensure our app receive api response in expected format.
    * */
    static CONVERT (apiCall,
        resSuccessConvertFn = (res) => res,
        resErrorConvertFn = (res) => (res instanceof VApiResponse
            ? VApiResponse.ERROR(res, res.msg)
            : VApiResponse.ERROR(res))) {
        return new Promise((resolve) => {
            apiCall.then(
                (res) => resolve(resSuccessConvertFn(res)),
            ).catch(
                (res) => resolve(resErrorConvertFn(res)),
            )
        })
    }

    /**
     * Covert Api Response to our app VApiResponse format.
     * Benefit :
     * 1. Check error from isSuccess() instead of .catch() or try{}/catch{}. Make out code cleaner.
     * 2. Ensure our app receive api response in expected format.
    * */
    static async convertGql (apiCall,
        resSuccessConvertFn = (data) => data,
        resErrorConvertFn = (e) => e) {
        try {
            const { data, errors } = await apiCall
            if (errors) throw errors
            const normailizedData = await resSuccessConvertFn(data)
            return VApiResponse.SUCCESS(normailizedData)
        } catch (e) {
            const exception = resErrorConvertFn(e)
            try {
                return VApiResponse.GQL_ERROR(
                    exception.graphQLErrors,
                )
            } catch (e1) {
                if (exception instanceof VApiResponse) {
                    return VApiResponse.ERROR(exception, exception.msg)
                }
                return VApiResponse.ERROR(exception)
            }
        }
    }
}
