import { PartyGeneralInfo } from '@/api/graphql/fragments/party'
import apolloClient from '@/apolloClient'
import ModelParty from '@/models/ModelParty'
import VApiResponse from '@/utils/VApiResponse'
import gql from 'graphql-tag'
import moment from 'moment'

export default {
    async loadPartys (isPast, page, size) {
        let isPastQuery = {}
        if (isPast != null) {
            if (isPast) {
                isPastQuery = {
                    end: {
                        $lt: {
                            $date: moment().valueOf(),
                        },
                    },
                }
            } else {
                isPastQuery = {
                    end: {
                        $gt: {
                            $date: moment().valueOf(),
                        },
                    },
                }
            }
        }

        const sortQuery = {
            start: isPast ? -1 : 1,
        }

        return VApiResponse.convertGql(apolloClient.query({
            query: gql`
            query getPartys($query:QueryObject!,$page:Int,$size:Int,$sort:QueryObject!){
                managerPartys(query: $query,page:$page,size:$size,sort:$sort) {
                    ...PartyGeneralInfo
                }
            }
            ${PartyGeneralInfo}
            `,
            variables: {
                query: {
                    ...isPastQuery,
                },
                // page start at 0 on server
                page: page - 1,
                size,
                sort: { ...sortQuery },
            },
        }),
        (data) => data.managerPartys.map((party) => ModelParty.fromGql(party)))
    },
}
