import { createTypes, createTypesWithNamespace } from '@/utils/store-utils'

const _namespace = 'message'

const types = {
    /**
     * @typedef {Object} Actions
     * @prop {String} SHOW_MESSAGE
     * @prop {String} HIDE_MESSAGE
     * @prop {String} SHOW_SAVE_SUCCESS_MSG
     * @prop {String} SHOW_PROCESS_MSG
     * @prop {String} SHOW_ERROR_MSG
     */
    Actions: [
        'SHOW_MESSAGE',
        'HIDE_MESSAGE',
        'SHOW_SAVE_SUCCESS_MSG',
        'SHOW_PROCESS_MSG',
        'SHOW_ERROR_MSG',
    ],
    /**
     * @typedef {Object} Mutations
     * @prop {String} ADD_MESSAGE
     * @prop {String} DELETE_MESSAGE
     */
    Mutations: [
        'ADD_MESSAGE',
        'DELETE_MESSAGE',
    ],
}
/**
 * @typedef {Object} Types
 * @prop {Actions} Actions
 * @prop {Mutations} Mutations
 */
/**
 * @type {Types} types
 */
const { Actions } = createTypesWithNamespace(_namespace, types)

/**
 * @type {Types} types
 */
const { Actions: _Actions, Mutations: _Mutations } = createTypes(types)

export { Actions, _Mutations, _Actions }
