import {
    _Actions,
    _Getters,
    _Mutations,
} from '@/store/modules/partys/types'
import {
    Entities,
} from '@/utils/enum'
import {
    createActionsWithInterceptors,
    createMutationBaseEntities,
} from '@/utils/store-utils'
import ActionProcessor from '@/utils/ActionProcessor'
import ServiceParty from '@/services/ServiceParty'
import ServiceManager from '@/services/ServiceManager'

class ActionProcessorImpl extends ActionProcessor {
    createInterceptors () {
        return []
    }
}

const Processors = new ActionProcessorImpl()

export default {
    namespaced: true,
    state: {
        partys: [],
    },
    getters: {
        [_Getters.GET_PARTY]: (state) => (id) => state.partys.find((e) => e.id === id),
    },
    actions: {
        ...createActionsWithInterceptors(Processors,
            {
                async [_Actions.LOAD_PARTYS] ({
                    commit,
                }, {
                    isPast,
                    page,
                    size,
                }) {
                    const res = await ServiceParty.loadPartys(
                        isPast,
                        page,
                        size,
                    )

                    if (res.isSuccess()) {
                        commit(_Mutations.LOAD_PARTYS, res.data)
                        return res
                    }
                    return res
                },
                async [_Actions.ADD_PARTY] ({ commit }, event) {
                    const res = await ServiceManager
                        .addParty({ ...event })
                    if (res.isSuccess()) {
                        commit(_Mutations.LOAD_PARTYS, [res.data])
                    }
                    return res
                },
                async [_Actions.UPDATE_PARTY] ({ commit }, party) {
                    const res = await ServiceManager.updateParty({ ...party })
                    if (res.isSuccess()) {
                        commit(_Mutations.UPDATE_PARTY, res.data)
                    }
                    return res
                },
                async [_Actions.LOAD_PARTY] ({ commit }, partyId) {
                    const res = await ServiceManager.loadPartyById(partyId)
                    if (res.isSuccess() && res.data !== null && res.data !== undefined) {
                        commit(_Mutations.LOAD_PARTYS, [res.data])
                    }
                    return res
                },
            }),
    },
    mutations: {
        ...createMutationBaseEntities(Entities.PARTYS),
    },
}
