import ActionProcessor from '@/utils/ActionProcessor'
import { createActionsWithInterceptors, createMutationBaseEntities } from '@/utils/store-utils'
import { _Actions, _Mutations, _Getters } from '@/store/modules/discount/types'
import ServiceDiscount from '@/services/ServiceDiscount'
import { Entities } from '@/utils/enum'

class ActionProcessorImpl extends ActionProcessor {
    createInterceptors () {
        return []
    }
}

const Processors = new ActionProcessorImpl()
export default {
    namespaced: true,
    state: {
        discountCodes: [],
        discountCoupons: [],
    },
    getters: {
        [_Getters.GET_DISCOUNT_CODE]:
            (state) => (id) => state.discountCodes.find((discountCode) => discountCode.id === id),
        [_Getters.GET_DISCOUNT_COUPON]:
            (state) => (id) => state.discountCoupons.find(
                (discountCoupon) => discountCoupon.id === id,
            ),
    },
    actions: {
        ...createActionsWithInterceptors(Processors,
            {
                async [_Actions.LOAD_DISCOUNT_CODES] ({ commit }, { isPast, page, size }) {
                    const res = await ServiceDiscount.loadDiscountCodes(isPast, page, size)
                    if (res.isSuccess()) {
                        commit(_Mutations.LOAD_DISCOUNT_CODES, res.data.datas)
                    }
                    return res
                },
                async [_Actions.ADD_DISCOUNT_CODE] ({ commit }, discountCode) {
                    const res = await ServiceDiscount.addDiscountCode(discountCode)
                    if (res.isSuccess()) {
                        commit(_Mutations.ADD_DISCOUNT_CODE, res.data)
                    }
                    return res
                },
                async [_Actions.UPDATE_DISCOUNT_CODE] ({ commit }, discountCode) {
                    const res = await ServiceDiscount.updateDiscountCode(discountCode)
                    if (res.isSuccess()) {
                        commit(_Mutations.UPDATE_DISCOUNT_CODE, res.data)
                    }
                    return res
                },
                async [_Actions.DELETE_DISCOUNT_CODE] ({ commit }, id) {
                    const res = await ServiceDiscount.deleteDiscountCode(id)
                    if (res.isSuccess()) {
                        commit(_Mutations.DELETE_DISCOUNT_CODE, id)
                    }
                    return res
                },
                async [_Actions.LOAD_DISCOUNT_COUPONS] ({ commit }, { isPast, page, size }) {
                    const res = await ServiceDiscount.loadDiscountCoupons(isPast, page, size)
                    if (res.isSuccess()) {
                        commit(_Mutations.LOAD_DISCOUNT_COUPONS, res.data.datas)
                    }
                    return res
                },
                async [_Actions.ADD_DISCOUNT_COUPON] ({ commit }, discountCode) {
                    const res = await ServiceDiscount.addDiscountCoupon(discountCode)
                    if (res.isSuccess()) {
                        commit(_Mutations.ADD_DISCOUNT_COUPON, res.data)
                    }
                    return res
                },
                async [_Actions.UPDATE_DISCOUNT_COUPON] ({ commit }, discountCode) {
                    const res = await ServiceDiscount.updateDiscountCoupon(discountCode)
                    if (res.isSuccess()) {
                        commit(_Mutations.UPDATE_DISCOUNT_COUPON, discountCode)
                    }
                    return res
                },
                async [_Actions.DELETE_DISCOUNT_COUPON] ({ commit }, id) {
                    const res = await ServiceDiscount.deleteDiscountCoupon(id)
                    if (res.isSuccess()) {
                        commit(_Mutations.DELETE_DISCOUNT_COUPON, id)
                    }
                    return res
                },
            }),
    },
    mutations: {
        ...createMutationBaseEntities(Entities.DISCOUNT_CODES),
        ...createMutationBaseEntities(Entities.DISCOUNT_COUPONS),
    },
}
