import Vue from 'vue'
import Vuex from 'vuex'
import auth from '@/store/modules/auth'
import stadium from '@/store/modules/stadium'
import message from '@/store/modules/message'
import customers from '@/store/modules/customers'
import employees from '@/store/modules/employees'
import code from '@/store/modules/code'
import manager from '@/store/modules/manager'
import products from '@/store/modules/products'
import partys from '@/store/modules/partys'
import discount from '@/store/modules/discount'
import referral from '@/store/modules/referral'
import shop from '@/store/modules/shop'
import chat from '@/store/modules/chat'
import cashback from '@/store/modules/cashback'
import banner from '@/store/modules/banner'
import txItem from '@/store/modules/txItem'
import { rootActions, Actions } from '@/store/types'
import fieldModule from '@/store/modules/field'
import ActionProcessor from '@/utils/ActionProcessor'
import { createActionsWithInterceptors } from '@/utils/store-utils'
import MsgErrorInterceptor from '@/store/interceptors/MsgErrorInterceptor'
import MsgProcessingInterceptor from '@/store/interceptors/MsgProcessingInterceptor'
import MsgSuccessInterceptor from '@/store/interceptors/MsgSuccessInterceptor'
import { isManagerUser, isStadiumUser } from '@/utils/enum'
import { createStore } from 'vuex-extensions'

Vue.use(Vuex)

class ActionProcessorImpl extends ActionProcessor {
    createInterceptors ({ dispatch }, actionName, action, payload) {
        return [
            new MsgProcessingInterceptor({ dispatch }, actionName, action, payload),
            new MsgErrorInterceptor({ dispatch }, actionName, action, payload),
            new MsgSuccessInterceptor({ dispatch }, actionName, action, payload),
        ]
    }
}

const Processor = new ActionProcessorImpl()

export default createStore(Vuex.Store, {
    plugins: [],
    actions: {
        ...createActionsWithInterceptors(Processor, {
            [rootActions.SET_MY_STADIUM] ({ dispatch }, id) {
                return dispatch(Actions.stadium.SET_MY_STADIUM, id)
            },
            [rootActions.REQUEST_TO_RESET_PASSWORD] ({ dispatch }, email) {
                return dispatch(Actions.auth.REQUEST_TO_RESET_PASSWORD, email)
            },
            [rootActions.RESET_PASSWORD] ({ dispatch }, newPassword) {
                return dispatch(Actions.auth.RESET_PASSWORD, newPassword)
            },
            [rootActions.LOAD_STADIUMS_GENERAL_INFO] ({ dispatch }) {
                return dispatch(Actions.stadium.LOAD_GENERAL_INFO)
            },
            [rootActions.SAVE_STADIUM_GENERAL_INFO] ({ dispatch }, info) {
                return dispatch(Actions.stadium.UPDATE_GENERAL_INFO, info)
            },
            [rootActions.LOAD_STADIUMS_HOLIDAY] ({ dispatch }) {
                return dispatch(Actions.stadium.LOAD_HOLIDAYS)
            },
            [rootActions.UPDATE_STADIUM_HOLIDAY] ({ dispatch }, holiday) {
                return dispatch(Actions.stadium.UPDATE_HOLIDAY, holiday)
            },
            [rootActions.ADD_STADIUM_HOLIDAY] ({ dispatch }, holiday) {
                return dispatch(Actions.stadium.ADD_HOLIDAY, holiday)
            },
            [rootActions.DELETE_STADIUM_HOLIDAY] ({ dispatch }, id) {
                return dispatch(Actions.stadium.DELETE_HOLIDAY, id)
            },
            [rootActions.LOAD_FIELDS] ({ dispatch }, { page, size }) {
                return dispatch(Actions.stadium.LOAD_FIELDS, { page, size })
            },
            [rootActions.ADD_FIELD] ({ dispatch }, field) {
                return dispatch(Actions.stadium.ADD_FIELD, field)
            },
            [rootActions.UPDATE_STADIUM_FIELD] ({ dispatch }, field) {
                return dispatch(Actions.stadium.UPDATE_FIELD, field)
            },
            [rootActions.DELETE_STADIUM_FIELD] ({ dispatch }, fieldId) {
                return dispatch(Actions.stadium.DELETE_FIELD, fieldId)
            },
            [rootActions.LOAD_MY_PRODUCTS] ({ dispatch }, {
                isPast, page, size, start, end,
            }) {
                return dispatch(Actions.products.LOAD_MY_PRODUCTS, {
                    isPast, page, size, start, end,
                })
            },
            [rootActions.ADD_PRODUCT] ({ dispatch }, product) {
                return dispatch(Actions.products.ADD_PRODUCT, product)
            },
            [rootActions.UPDATE_PRODUCT] ({ dispatch }, field) {
                return dispatch(Actions.products.UPDATE_PRODUCT, field)
            },
            [rootActions.DELETE_PRODUCT] ({ dispatch }, { type, id }) {
                return dispatch(Actions.products.DELETE_PRODUCT, { type, id })
            },
            [rootActions.CANCEL_PRODUCT] ({ dispatch }, { type, id }) {
                return dispatch(Actions.products.CANCEL_PRODUCT, { type, id })
            },
            [rootActions.UPDATE_CUSTOMER] ({ dispatch }, customer) {
                return dispatch(Actions.customers.UPDATE_CUSTOMER, customer)
            },
            [rootActions.ADD_CUSTOMER] ({ dispatch }, customer) {
                return dispatch(Actions.customers.ADD_CUSTOMER, customer)
            },
            [rootActions.DELETE_CUSTOMER] ({ dispatch }, id) {
                return dispatch(Actions.customers.DELETE_CUSTOMER, id)
            },
            [rootActions.SEARCH_CUSTOMER] ({ dispatch }, query) {
                return dispatch(Actions.customers.SEARCH_CUSTOMER, query)
            },
            [rootActions.LOAD_CUSTOMERS] ({ dispatch }, { page, size }) {
                return dispatch(Actions.customers.LOAD_CUSTOMERS, { page, size })
            },
            [rootActions.LOAD_MEMBER_TYPES] ({ dispatch }, { page, size }) {
                return dispatch(Actions.customers.LOAD_MEMBER_TYPES, { page, size })
            },
            [rootActions.ADD_MEMBER_TYPE] ({ dispatch }, memberType) {
                return dispatch(Actions.customers.ADD_MEMBER_TYPE, memberType)
            },
            [rootActions.UPDATE_MEMBER_TYPE] ({ dispatch }, memberType) {
                return dispatch(Actions.customers.UPDATE_MEMBER_TYPE, memberType)
            },
            [rootActions.DELETE_MEMBER_TYPE] ({ dispatch }, id) {
                return dispatch(Actions.customers.DELETE_MEMBER_TYPE, id)
            },
            [rootActions.UNREGISTOR_MEMBER_TYPE] ({ dispatch }, { customerId, reasonsToRemove }) {
                return dispatch(
                    Actions.customers.UNREGISTOR_MEMBER_TYPE,
                    { customerId, reasonsToRemove },
                )
            },
            [rootActions.REGISTOR_MEMBER_TYPE] ({ dispatch }, { customerId, memberTypeId }) {
                return dispatch(
                    Actions.customers.REGISTOR_MEMBER_TYPE,
                    { customerId, memberTypeId },
                )
            },
            [rootActions.LOAD_MEMBER_TYPE_HISTORY] ({ dispatch }, customerId) {
                return dispatch(Actions.customers.LOAD_MEMBER_TYPE_HISTORY, customerId)
            },
            [rootActions.LOAD_EMPLOYEES] ({ dispatch }, { stadiumId, page, size }) {
                return dispatch(Actions.employees.LOAD_EMPLOYEES, { stadiumId, page, size })
            },
            [rootActions.DELETE_EMPLOYEE] ({ dispatch }, id) {
                return dispatch(Actions.employees.DELETE_EMPLOYEE, id)
            },
            [rootActions.ADD_EMPLOYEE] ({ dispatch }, employee) {
                return dispatch(Actions.employees.ADD_EMPLOYEE, employee)
            },
            [rootActions.UPDATE_EMPLOYEE] ({ dispatch }, employee) {
                return dispatch(Actions.employees.UPDATE_EMPLOYEE, employee)
            },
            [rootActions.LOGIN] ({ dispatch }, { username, password }) {
                return dispatch(Actions.auth.LOGIN, { username, password })
            },
            [rootActions.LOGOUT] ({ dispatch }) {
                return dispatch(Actions.auth.LOGOUT)
            },
            async [rootActions.GET_ME] ({ dispatch }) {
                return dispatch(Actions.auth.GET_ME)
            },
            async [rootActions.IS_LOGIN] ({ dispatch, state }) {
                const res = await dispatch(Actions.auth.IS_LOGIN)
                if (res.isSuccess()) {
                    if (isStadiumUser(state.auth.me)) {
                        dispatch(
                            Actions.stadium.SET_MY_STADIUM,
                            state.auth.me.stadiumUserRoles[0].stadiumId,
                        )
                    } else if (isManagerUser(state.auth.me)) {
                        dispatch(
                            rootActions.LOAD_MY_PARTY,
                        )
                    }
                }
                return res
            },
            [rootActions.ALERT_DONT_HAVE_PERMISSION] ({ dispatch }) {
                return dispatch(Actions.message.SHOW_MESSAGE,
                    {
                        type: 'error',
                        message: 'You must have higher role to do this operation.',
                        dismissAfter: 3,
                    })
            },
            [rootActions.LOAD_PRODUCT_LOCAL_CUSTOMERS] ({ dispatch }, boostId) {
                return dispatch(Actions.products.LOAD_PRODUCT_LOCAL_CUSTOMERS, boostId)
            },
            [rootActions.ADD_PRODUCT_LOCAL_CUSTOMER] (
                { dispatch },
                { boostId, customer, numberOfPeople },
            ) {
                return dispatch(Actions.products.ADD_PRODUCT_LOCAL_CUSTOMER,
                    { boostId, customer, numberOfPeople })
            },
            [rootActions.LOAD_PRODUCT_TX_ITEM] ({ dispatch }, {
                productId, page, size, isActive,
            }) {
                return dispatch(Actions.products.LOAD_PRODUCT_TX_ITEM, {
                    productId, page, size, isActive,
                })
            },
            [rootActions.LOAD_TX_ITEMS] ({ dispatch }, { page, size }) {
                return dispatch(Actions.txItem.LOAD_MY_TX_ITEMS, { page, size })
            },
            [rootActions.CANCEL_PRODUCT_TX_ITEM] ({ dispatch }, { productId, txItemId }) {
                return dispatch(Actions.products.CANCEL_PRODUCT_TX_ITEM, { productId, txItemId })
            },
            [rootActions.UPDATE_PRODUCT_LOCAL_CUSTOMER] ({ dispatch }, { boostId, customer }) {
                return dispatch(
                    Actions.products.UPDATE_PRODUCT_LOCAL_CUSTOMER,
                    { boostId, customer },
                )
            },
            [rootActions.UPDATE_PRODUCT_LOCAL_CUSTOMER_IS_PAID] ({ dispatch }, { boostId, customer }) {
                return dispatch(
                    Actions.products.UPDATE_PRODUCT_LOCAL_CUSTOMER_IS_PAID,
                    { boostId, customer },
                )
            },
            [rootActions.DELETE_PRODUCT_LOCAL_CUSTOMER] (
                { dispatch },
                { boostId, customerId },
            ) {
                return dispatch(
                    Actions.products.DELETE_PRODUCT_LOCAL_CUSTOMER,
                    { boostId, customerId },
                )
            },
            [rootActions.DELETE_STADIUM] ({ dispatch }, stadiumId) {
                return dispatch(Actions.stadium.DELETE_STADIUM, stadiumId)
            },
            [rootActions.UPDATE_STADIUM] ({ dispatch }, stadiumEntity) {
                return dispatch(Actions.stadium.UPDATE_STADIUM, stadiumEntity)
            },
            [rootActions.ADD_STADIUM] ({ dispatch }, stadiumEntity) {
                return dispatch(Actions.stadium.ADD_STADIUM, stadiumEntity)
            },
            [rootActions.LOAD_SPORTS] ({ dispatch }) {
                return dispatch(Actions.stadium.LOAD_SPORTS)
            },
            [rootActions.ADD_SPORT] ({ dispatch }, sport) {
                return dispatch(Actions.stadium.ADD_SPORT, sport)
            },
            [rootActions.UPDATE_SPORT] ({ dispatch }, sport) {
                return dispatch(Actions.stadium.UPDATE_SPORT, sport)
            },
            [rootActions.DELETE_SPORT] ({ dispatch }, sportCode) {
                return dispatch(Actions.stadium.DELETE_SPORT, sportCode)
            },
            [rootActions.LOAD_CODE] ({ dispatch }, redeemCode) {
                return dispatch(Actions.code.LOAD_CODE, redeemCode)
            },
            [rootActions.USE_PAYMENT_CODE] ({ dispatch }, { txId, redeemCode }) {
                return dispatch(Actions.code.USE_PAYMENT_CODE, { txId, redeemCode })
            },
            [rootActions.CLEAR_ACTIVE_CODE] ({ dispatch }) {
                return dispatch(Actions.code.CLEAR_ACTIVE_CODE)
            },
            [rootActions.USE_REDEEM_CODE] ({ dispatch }, { txItemIds, redeemCode }) {
                return dispatch(Actions.code.USE_REDEEM_CODE, { txItemIds, redeemCode })
            },
            [rootActions.LOAD_STADIUM_REPORT] ({ dispatch }, { start, end }) {
                return dispatch(Actions.stadium.LOAD_STADIUM_REPORT, { start, end })
            },
            [rootActions.LOAD_MANAGERS] ({ dispatch }, { page, size }) {
                return dispatch(Actions.manager.LOAD_MANAGERS, { page, size })
            },
            [rootActions.ADD_MANAGER] ({ dispatch }, managerUser) {
                return dispatch(Actions.manager.ADD_MANAGER, managerUser)
            },
            [rootActions.DELETE_MANAGER] ({ dispatch }, id) {
                return dispatch(Actions.manager.DELETE_MANAGER, id)
            },
            [rootActions.UPDATE_MANAGER] ({ dispatch }, managerUser) {
                return dispatch(Actions.manager.UPDATE_MANAGER, managerUser)
            },
            [rootActions.ADD_MY_PARTY] ({ dispatch }, party) {
                return dispatch(Actions.partys.ADD_PARTY, party)
            },
            [rootActions.UPDATE_MY_PARTY] ({ rootState, dispatch }, party) {
                return dispatch(Actions.partys.UPDATE_PARTY,
                    { ...party, id: rootState.auth.me.partyId })
            },
            [rootActions.LOAD_PARTYS] ({ dispatch }, { isPast, page, size }) {
                return dispatch(Actions.partys.LOAD_PARTYS, { isPast, page, size })
            },
            [rootActions.LOAD_MY_PARTY] ({ rootState, dispatch }) {
                return dispatch(Actions.partys.LOAD_PARTY, rootState.auth.me.partyId)
            },
            [rootActions.CHANGE_PASSWORD] ({ dispatch }, { currentPassword, newPassword }) {
                return dispatch(Actions.auth.CHANGE_PASSWORD, { currentPassword, newPassword })
            },
            [rootActions.LOAD_EVENTS] ({ dispatch }, { isPast, page, size }) {
                return dispatch(Actions.manager.LOAD_EVENTS, { isPast, page, size })
            },
            [rootActions.ADD_EVENT] ({ dispatch }, event) {
                return dispatch(Actions.manager.ADD_EVENT, event)
            },
            [rootActions.UPDATE_EVENT] ({ dispatch }, event) {
                return dispatch(Actions.manager.UPDATE_EVENT, event)
            },
            [rootActions.DELETE_EVENT] ({ dispatch }, event) {
                return dispatch(Actions.manager.DELETE_EVENT, event)
            },
            [rootActions.LOAD_STADIUMS] ({ dispatch }, { page, size }) {
                return dispatch(Actions.stadium.LOAD_STADIUMS, { page, size })
            },
            [rootActions.LOAD_FIELDS_BY_IDS] ({ dispatch }, ids) {
                return dispatch(Actions.stadium.LOAD_FIELDS_BY_IDS, ids)
            },
            [rootActions.LOAD_DISCOUNT_CODES] ({ dispatch }, { isPast, page, size }) {
                return dispatch(Actions.discount.LOAD_DISCOUNT_CODES, { isPast, page, size })
            },
            [rootActions.ADD_DISCOUNT_CODE] ({ dispatch }, discountCode) {
                return dispatch(Actions.discount.ADD_DISCOUNT_CODE, discountCode)
            },
            [rootActions.UPDATE_DISCOUNT_CODE] ({ dispatch }, discountCode) {
                return dispatch(Actions.discount.UPDATE_DISCOUNT_CODE, discountCode)
            },
            [rootActions.DELETE_DISCOUNT_CODE] ({ dispatch }, id) {
                return dispatch(Actions.discount.DELETE_DISCOUNT_CODE, id)
            },
            [rootActions.LOAD_DISCOUNT_COUPONS] ({ dispatch }, { isPast, page, size }) {
                return dispatch(Actions.discount.LOAD_DISCOUNT_COUPONS, { isPast, page, size })
            },
            [rootActions.ADD_DISCOUNT_COUPON] ({ dispatch }, discountCode) {
                return dispatch(Actions.discount.ADD_DISCOUNT_COUPON, discountCode)
            },
            [rootActions.UPDATE_DISCOUNT_COUPON] ({ dispatch }, discountCode) {
                return dispatch(Actions.discount.UPDATE_DISCOUNT_COUPON, discountCode)
            },
            [rootActions.DELETE_DISCOUNT_COUPON] ({ dispatch }, id) {
                return dispatch(Actions.discount.DELETE_DISCOUNT_COUPON, id)
            },
            [rootActions.LOAD_BUNDLE_COUPONS] ({ dispatch }, { isPast, page, size }) {
                return dispatch(Actions.shop.LOAD_BUNDLE_COUPONS, { isPast, page, size })
            },
            [rootActions.ADD_BUNDLE_COUPON] ({ dispatch }, discountCode) {
                return dispatch(Actions.shop.ADD_BUNDLE_COUPON, discountCode)
            },
            [rootActions.UPDATE_BUNDLE_COUPON] ({ dispatch }, discountCode) {
                return dispatch(Actions.shop.UPDATE_BUNDLE_COUPON, discountCode)
            },
            [rootActions.DELETE_BUNDLE_COUPON] ({ dispatch }, id) {
                return dispatch(Actions.shop.DELETE_BUNDLE_COUPON, id)
            },
            [rootActions.LOAD_COUPON_FOR_BUNDLES] ({ dispatch }, { isPast, page, size }) {
                return dispatch(Actions.shop.LOAD_COUPON_FOR_BUNDLES, { isPast, page, size })
            },
            [rootActions.ADD_COUPON_FOR_BUNDLE] ({ dispatch }, discountCode) {
                return dispatch(Actions.shop.ADD_COUPON_FOR_BUNDLE, discountCode)
            },
            [rootActions.UPDATE_COUPON_FOR_BUNDLE] ({ dispatch }, discountCode) {
                return dispatch(Actions.shop.UPDATE_COUPON_FOR_BUNDLE, discountCode)
            },
            [rootActions.DELETE_COUPON_FOR_BUNDLE] ({ dispatch }, id) {
                return dispatch(Actions.shop.DELETE_COUPON_FOR_BUNDLE, id)
            },
            [rootActions.LOAD_REFERRAL_PROGRAMS] ({ dispatch }, { isPast, page, size }) {
                return dispatch(Actions.referral.LOAD_REFERRAL_PROGRAMS, { isPast, page, size })
            },
            [rootActions.ADD_REFERRAL_PROGRAM] ({ dispatch }, referralProgram) {
                return dispatch(Actions.referral.ADD_REFERRAL_PROGRAM, referralProgram)
            },
            [rootActions.UPDATE_REFERRAL_PROGRAM] ({ dispatch }, referralProgram) {
                return dispatch(Actions.referral.UPDATE_REFERRAL_PROGRAM, referralProgram)
            },
            [rootActions.DELETE_REFERRAL_PROGRAM] ({ dispatch }, id) {
                return dispatch(Actions.referral.DELETE_REFERRAL_PROGRAM, id)
            },
            [rootActions.LOAD_REFERRAL_PROMOTION] ({ dispatch }, id) {
                return dispatch(Actions.referral.LOAD_REFERRAL_PROMOTION, id)
            },
            [rootActions.LOAD_REFERRAL_PROMOTIONS] ({ dispatch }, { isPast, page, size }) {
                return dispatch(Actions.referral.LOAD_REFERRAL_PROMOTIONS, { isPast, page, size })
            },
            [rootActions.ADD_REFERRAL_PROMOTION] ({ dispatch }, referralPromotion) {
                return dispatch(Actions.referral.ADD_REFERRAL_PROMOTION, referralPromotion)
            },
            [rootActions.UPDATE_REFERRAL_PROMOTION] ({ dispatch }, referralPromotion) {
                return dispatch(Actions.referral.UPDATE_REFERRAL_PROMOTION, referralPromotion)
            },
            [rootActions.DELETE_REFERRAL_PROMOTION] ({ dispatch }, id) {
                return dispatch(Actions.referral.DELETE_REFERRAL_PROMOTION, id)
            },
            [rootActions.ACTIVE_REFERRAL_PROGRAM] ({ dispatch }, id) {
                return dispatch(Actions.referral.ACTIVE_REFERRAL_PROGRAM, id)
            },
            [rootActions.LOAD_ACTIVE_REFERRAL_PROGRAM] ({ dispatch }) {
                return dispatch(Actions.referral.LOAD_ACTIVE_REFERRAL_PROGRAM)
            },
            [rootActions.LOAD_CHATROOMS] ({ dispatch }, { page, size }) {
                return dispatch(Actions.chat.LOAD_CHATROOMS, { page, size })
            },
            [rootActions.LOAD_CHATROOM] ({ dispatch }, id) {
                return dispatch(Actions.chat.LOAD_CHATROOM, id)
            },
            [rootActions.LOAD_CHATROOM_MSGS] ({ dispatch }, { chatroomId, page, size }) {
                return dispatch(Actions.chat.LOAD_CHATROOM_MSGS, { chatroomId, page, size })
            },
            [rootActions.SEND_CHATROOM_MSG] ({ dispatch }, { chatroomId, msg, offer }) {
                return dispatch(Actions.chat.SEND_MSG, { chatroomId, msg, offer })
            },
            [rootActions.CANCEL_PRODUCT_OFFER] ({ dispatch }, offerId) {
                return dispatch(Actions.products.CANCEL_PRODUCT_OFFER, offerId)
            },
            [rootActions.ACCEPT_POLICY] ({ dispatch }) {
                return dispatch(Actions.auth.ACCEPT_POLICY)
            },
            [rootActions.CHECK_IS_READ_POLICY] ({ dispatch }) {
                return dispatch(Actions.auth.CHECK_IS_READ_POLICY)
            },
            [rootActions.LOAD_CASHBACK_PROGRAM] ({ dispatch }) {
                return dispatch(Actions.cashback.LOAD_CASHBACK_PROGRAM)
            },
            [rootActions.ADD_CASHBACK_PROGRAM] ({ dispatch }, data) {
                return dispatch(Actions.cashback.ADD_CASHBACK_PROGRAM, data)
            },
            [rootActions.UPDATE_CASHBACK_PROGRAM] ({ dispatch }, data) {
                return dispatch(Actions.cashback.UPDATE_CASHBACK_PROGRAM, data)
            },
            [rootActions.DELETE_CASHBACK_PROGRAM] ({ dispatch }, id) {
                return dispatch(Actions.cashback.DELETE_CASHBACK_PROGRAM, id)
            },
            [rootActions.ACTIVE_CASHBACK_PROGRAM] ({ dispatch }, id) {
                return dispatch(Actions.cashback.ACTIVE_CASHBACK_PROGRAM, id)
            },
            [rootActions.LOAD_APPLICABLE_CASHBACK_PROGRAM] ({ dispatch }) {
                return dispatch(Actions.cashback.LOAD_APPLICABLE_CASHBACK_PROGRAM)
            },
            [rootActions.ADD_APPLICABLE_CASHBACK_PROGRAM] ({ dispatch }, data) {
                return dispatch(Actions.cashback.ADD_CASHBACK_PROGRAM, data)
            },
            [rootActions.UPDATE_APPLICABLE_CASHBACK_PROGRAM] ({ dispatch }, data) {
                return dispatch(Actions.cashback.UPDATE_APPLICABLE_CASHBACK_PROGRAM, data)
            },
            [rootActions.DELETE_APPLICABLE_CASHBACK_PROGRAM] ({ dispatch }, id) {
                return dispatch(Actions.cashback.DELETE_APPLICABLE_CASHBACK_PROGRAM, id)
            },
            [rootActions.ACTIVE_APPLICABLE_CASHBACK_PROGRAM] ({ dispatch }, id) {
                return dispatch(Actions.cashback.ACTIVE_APPLICABLE_CASHBACK_PROGRAM, id)
            },
            [rootActions.LOAD_BANNER_MEDIAS] ({ dispatch }, { isPast, page, size }) {
                return dispatch(Actions.banner.LOAD_BANNER_MEDIAS, { isPast, page, size })
            },
            [rootActions.ADD_BANNER_MEDIA] ({ dispatch }, data) {
                return dispatch(Actions.banner.ADD_BANNER_MEDIA, data)
            },
            [rootActions.UPDATE_BANNER_MEDIA] ({ dispatch }, data) {
                return dispatch(Actions.banner.UPDATE_BANNER_MEDIA, data)
            },
            [rootActions.DELETE_BANNER_MEDIA] ({ dispatch }, id) {
                return dispatch(Actions.banner.DELETE_BANNER_MEDIA, id)
            },
            [rootActions.LOAD_MAIN_BANNER] ({ dispatch }) {
                return dispatch(Actions.banner.LOAD_MAIN_BANNER)
            },
            [rootActions.UPDATE_BANNER] ({ dispatch }, data) {
                return dispatch(Actions.banner.UPDATE_BANNER, data)
            },
            [rootActions.UPDATE_BANNER] ({ dispatch }, data) {
                return dispatch(Actions.banner.UPDATE_BANNER, data)
            },
            [rootActions.LOAD_FIELD_PRICE_RULES] ({ dispatch }, fieldId) {
                return dispatch(Actions.field.LOAD_PRICE_RULES, fieldId)
            },
            [rootActions.UPDATE_PRICE_RULES] ({ dispatch }, { insertingRules, disableRuleIds }) {
                return dispatch(Actions.field.UPDATE_PRICE_RULES, { insertingRules, disableRuleIds })
            },
            [rootActions.LOAD_BASE_PRICE] ({ dispatch }, fieldId) {
                return dispatch(Actions.field.LOAD_BASE_PRICE, fieldId)
            },
            [rootActions.UPDATE_BASE_PRICE] ({ dispatch }, basePrice) {
                return dispatch(Actions.field.UPDATE_BASE_PRICE, basePrice)
            },
        }),
        [rootActions.RESET_STORES] () {
            this.reset()
        },
    },
    modules: {
        auth,
        stadium,
        message,
        customers,
        employees,
        code,
        manager,
        products,
        partys,
        discount,
        referral,
        shop,
        chat,
        cashback,
        banner,
        field: fieldModule,
        txItem,
    },
})
