export default class ModelParty {
    constructor ({
        id,
        name,
        desc,
        coverPhotoUrl,
        logoPhotoUrl,
    }) {
        Object.assign(this, {
            id,
            name,
            desc,
            coverPhotoUrl,
            logoPhotoUrl,
        })
    }

    static fromGql (gql) {
        const {
            id,
            name,
            desc,
            coverPhotoUrl,
            logoPhotoUrl,
        } = gql
        return new ModelParty({
            id,
            name,
            desc,
            coverPhotoUrl,
            logoPhotoUrl,
        })
    }

    toGql () {
        const { name, desc } = this
        return {
            name,
            desc,
        }
    }
}
