var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("ModalSessionTimeout"),
      _c("HomeLayout", {
        attrs: {
          sidebar: _vm.sidebar,
          "default-path": "/manager/party",
          "default-group": "manager"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }