import ModelCode from '@/models/ModelCode'
import ModelTx from '@/models/ModelTx'

export default class ModelCodePayment extends ModelCode {
    constructor (model) {
        const { tx } = model
        super(model)
        Object.assign(this, {
            tx,
        })
    }

    static fromGql (gql) {
        const {
            customerTx,
        } = gql
        return new ModelCodePayment({
            tx: ModelTx.fromGql(customerTx),
            ...super.fromGql(gql),
        })
    }
}
