import ModelCode from '@/models/ModelCode'
import ModelCodePayment from '@/models/ModelCodePayment'
import ModelCodeRedeem from '@/models/ModelCodeRedeem'
import { CodeType } from '@/utils/enum'

export default class ResolverCode {
    static fromGqlUnion (gql) {
        const { type } = gql
        if (type === CodeType.PAYMENT) return ModelCodePayment.fromGql(gql)
        if (type === CodeType.REDEEM) return ModelCodeRedeem.fromGql(gql)
        return ModelCode.fromGql(gql)
    }
}
