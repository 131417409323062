import { BaseDocumentGeneralInfo } from '@/api/graphql/fragments/base'
import { BoostCustomersGeneralInfo, BoostLocalCustomersGeneralInfo } from '@/api/graphql/fragments/boost'
import { ReservationOfferGeneralInfo } from '@/api/graphql/fragments/chat'
import { PageGeneralInfo } from '@/api/graphql/fragments/page'
import { CustomerTxItemGeneralInfo } from '@/api/graphql/fragments/tx'
import { LocalStadiumCustomerGeneralInfo } from '@/api/graphql/fragments/user'
import apolloClient from '@/apolloClient'
import ModelTxItemLocal from '@/models/ModelTxItemLocal'
import ModelOfferReservation from '@/models/ModelOfferReservation'
import ModelPagination from '@/models/ModelPagination'
import ModelTxItem from '@/models/ModelTxItem'
import VApiResponse from '@/utils/VApiResponse'
import gql from 'graphql-tag'

function convertModelTxItems (txItems) {
    return txItems.map(
        (txItem) => ModelTxItem.fromGql(
            txItem,
        ),
    )
}

export default {
    async addBoostLocalCustomer (stadiumId, productId, customer, numberOfPeople) {
        return VApiResponse.convertGql(apolloClient.mutate({
            mutation: gql`
            mutation createBoostLocalCustomers($customer:LocalCustomerInput!){
                insertLocalCustomer(data:$customer) {
                    ...BoostLocalCustomersGeneralInfo
                    ...LocalStadiumCustomerGeneralInfo
                }
            }
            ${BoostLocalCustomersGeneralInfo}
            ${LocalStadiumCustomerGeneralInfo}
            `,
            variables: {
                customer: {
                    stadiumId,
                    productId,
                    ...ModelTxItemLocal.toGql({ ...customer, numberOfPeople }),
                },
            },
        }),
        (data) => ModelTxItemLocal.fromGql(data.insertLocalCustomer))
    },
    async loadBoostLocalCustomers (productId) {
        return VApiResponse.convertGql(apolloClient.query({
            query: gql`
            query getBoostLocalCustomers($query:QueryObject!){
                localCustomers(query: $query) {
                    ...BoostLocalCustomersGeneralInfo
                    ...LocalStadiumCustomerGeneralInfo
                }
            }
            ${BoostLocalCustomersGeneralInfo}
            ${LocalStadiumCustomerGeneralInfo}
            `,
            variables: {
                query: { productId: { $oid: productId } },
            },
        }),
        (data) => data.localCustomers.map((localUser) => ModelTxItemLocal.fromGql(localUser)))
    },
    async updateBoostLocalCustomer (boostId, customer) {
        return VApiResponse.convertGql(apolloClient.mutate({
            mutation: gql`
            mutation updateBoostLocalCustomer($id:ObjectId,$customer: LocalCustomerInput!){
                updateLocalCustomer(id:$id,data:$customer) {
                    ...BoostLocalCustomersGeneralInfo
                    ...LocalStadiumCustomerGeneralInfo
                }
            }
            ${BoostLocalCustomersGeneralInfo}
            ${LocalStadiumCustomerGeneralInfo}
            `,
            variables: {
                id: customer.id,
                customer: ModelTxItemLocal.toGql(customer),
            },
        }),
        (data) => ModelTxItemLocal.fromGql(data.updateLocalCustomer))
    },
    async deleteProductLocalCustomers (boostId, customerId) {
        return VApiResponse.convertGql(apolloClient.mutate({
            mutation: gql`
            mutation deleteBoostLocalCustomers($id:ObjectId){
                deleteLocalCustomer(id:$id)
            }
            `,
            variables: {
                id: customerId,
            },
        }))
    },
    async loadProductCustomersByProductId ({
        stadiumId, productId, page, size, isActive,
    }) {
        const refundStages = ['REFUNDED', 'PENDING_REFUND']
        return VApiResponse.convertGql(apolloClient.query({
            query: gql`
            query getBoostCustomers($query:QueryObject!,$page:Int,$size:Int,$sort:QueryObject!){
                stadiumFindCustomerTxItems(query: $query,page:$page,size:$size,sort:$sort) {
                    datas{
                        customerTx{
                            customer{
                                ...BoostCustomersGeneralInfo
                            }
                            paymentMethod
                        }
                        ...CustomerTxItemGeneralInfo
                        ...BaseDocumentGeneralInfo
                    }
                    pageInfo{
                        ...PageGeneralInfo
                    }
                }
            }
            ${CustomerTxItemGeneralInfo}
            ${BoostCustomersGeneralInfo}
            ${BaseDocumentGeneralInfo}
            ${PageGeneralInfo}
            `,
            variables: {
                query: {
                    ...stadiumId ? { 'mirrorProduct.stadiumId': { $oid: stadiumId } } : {},
                    ...productId ? { productId: { $oid: productId } } : {},
                    stage: isActive ? {
                        $nin: ['IN_CART', 'CANCELED', ...refundStages],
                    } : { $in: refundStages },
                    type: {
                        $nin: ['PARTY_EVENT'],
                    },
                },
                page: page - 1,
                size,
                sort: {
                    updatedOn: -1,
                },
            },
        }),
        (data) => ModelPagination.fromGql(data.stadiumFindCustomerTxItems))
    },
    async loadTxItemsByStadiumId (stadiumId, page, size) {
        return VApiResponse.convertGql(apolloClient.query({
            query: gql`
            query getTxItems($query:QueryObject!,$page:Int,$size:Int,$sort:QueryObject){
                stadiumFindCustomerTxItems(query: $query, page: $page, size: $size,sort:$sort) {
                    datas{
                        customerTx{
                            customer{
                                ...BoostCustomersGeneralInfo
                            }
                            paymentMethod
                        }
                        ...CustomerTxItemGeneralInfo
                    }
                }
            }
            ${CustomerTxItemGeneralInfo}
            ${BoostCustomersGeneralInfo}
            `,
            variables: {
                query: {
                    'mirrorProduct.stadiumId': { $oid: stadiumId },
                    stage: {
                        $nin: ['IN_CART'],
                    },
                },
                // page start at 0 on server
                page: page - 1,
                size,
                sort: {
                    updatedOn: -1,
                },
            },
        }),
        (data) => convertModelTxItems(data.stadiumFindCustomerTxItems.datas))
    },
    async loadProductCustomersByManager (productId) {
        return VApiResponse.convertGql(apolloClient.query({
            query: gql`
            query getProductCustomersByManager($query:QueryObject!){
                managerFindCustomerTxItems(query: $query) {
                    datas{
                        customerTx{
                            customer{
                                ...BoostCustomersGeneralInfo
                            }
                            paymentMethod
                        }
                        ...CustomerTxItemGeneralInfo
                        ...BaseDocumentGeneralInfo
                    }
                }
            }
            ${CustomerTxItemGeneralInfo}
            ${BoostCustomersGeneralInfo}
            ${BaseDocumentGeneralInfo}
            `,
            variables: {
                query: {
                    productId: { $oid: productId },
                    stage: {
                        $nin: ['IN_CART', 'CANCELED'],
                    },
                },
            },
        }),
        (data) => convertModelTxItems(data.managerFindCustomerTxItems.datas))
    },
    async cancelOffer (offerId) {
        return VApiResponse.convertGql(apolloClient.mutate({
            mutation: gql`
            mutation cancelOffer($id:ObjectId!){
                cancelReservationOffer(reservationOfferId:$id){
                    ...ReservationOfferGeneralInfo
                }
            }
            ${ReservationOfferGeneralInfo}
            `,
            variables: {
                id: offerId,
            },
        }), (data) => ModelOfferReservation.fromGql(data.cancelReservationOffer))
    },
}
