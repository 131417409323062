import gql from 'graphql-tag'

export const BannerMediaGeneralInfo = gql`
    fragment BannerMediaGeneralInfo on BannerMedia {
        _id
        startedOn
        endedOn
        imageUrl
        gaAdPlatform
        gaAdSource
        gaAdFormat
        gaAdUnitName
        gaCurrency
        gaValue
        linkUrl
    }
`
export const BannerGeneralInfo = gql`
    fragment BannerGeneralInfo on Banner{
        _id
        bannerMediaIds
    }
`
