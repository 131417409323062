import { DiscountCodeGeneralInfo, DiscountCouponGeneralInfo } from '@/api/graphql/fragments/discount'
import { PageGeneralInfo } from '@/api/graphql/fragments/page'
import apolloClient from '@/apolloClient'
import ModelDiscount from '@/models/ModelDiscount'
import ModelDiscountCode from '@/models/ModelDiscountCode'
import ModelPagination from '@/models/ModelPagination'
import VApiResponse from '@/utils/VApiResponse'
import gql from 'graphql-tag'
import moment from 'moment'

function getIsPastQuery (isPast) {
    if (isPast != null) {
        if (isPast) {
            return {
                invalidOn: {
                    $lt: {
                        $date: moment().valueOf(),
                    },
                },
            }
        }
        return {
            invalidOn: {
                $gt: {
                    $date: moment().valueOf(),
                },
            },
        }
    }

    return {}
}

function getSort (isPast) {
    if (isPast) {
        return {
            invalidOn: -1,
        }
    }
    return {
        publishedOn: 1,
    }
}

export default {
    async addDiscountCode (discountCode) {
        return VApiResponse.convertGql(
            apolloClient.mutate({
                mutation: gql`
                mutation addDiscountCode($data: DiscountCodeInput!){
                    insertDiscountCode(data:$data) {
                        ...DiscountCodeGeneralInfo
                    }
                }
                ${DiscountCodeGeneralInfo}
                `,
                variables: {
                    data: ModelDiscountCode.toGql(discountCode),
                },
            }),
            (data) => ModelDiscountCode.fromGql(data.insertDiscountCode),
        )
    },
    async loadDiscountCodes (isPast, page, size) {
        return VApiResponse.convertGql(
            await apolloClient.query({
                query: gql`
                query getDiscountCodesPagination($query:QueryObject!,$page:Int,$size:Int,$sort:QueryObject){
                    discountCodesPagination(query: $query, page: $page, size: $size,sort:$sort) {
                        datas{
                            ...DiscountCodeGeneralInfo
                        }
                        pageInfo{
                            ...PageGeneralInfo
                        }
                    }
                }
                ${DiscountCodeGeneralInfo}
                ${PageGeneralInfo}
                `,
                variables: {
                    query: {
                        ...getIsPastQuery(isPast),
                    },
                    // page start at 0 on server
                    page: page - 1,
                    size,
                    sort: { ...getSort(isPast) },
                },
            }),
            (data) => ModelPagination.fromGql(data.discountCodesPagination),
        )
    },
    async updateDiscountCode (discountCode) {
        return VApiResponse.convertGql(
            apolloClient.mutate({
                mutation: gql`
                mutation updateDiscountCode($id:ObjectId,$data: DiscountCodeInput!){
                    updateDiscountCode(id:$id,data:$data) {
                        ...DiscountCodeGeneralInfo
                    }
                }
                ${DiscountCodeGeneralInfo}
                `,
                variables: {
                    id: discountCode.id,
                    data: ModelDiscountCode.toGql(discountCode),
                },
            }),
            (data) => ModelDiscountCode.fromGql(data.updateDiscountCode),
        )
    },
    async deleteDiscountCode (id) {
        return VApiResponse.convertGql(
            await apolloClient.mutate({
                mutation: gql`
                mutation deleteDiscountCode($id:ObjectId){
                    deleteDiscountCode(id:$id)
                }
                `,
                variables: {
                    id,
                },
            }),
        )
    },
    async addDiscountCoupon (discountCode) {
        return VApiResponse.convertGql(
            apolloClient.mutate({
                mutation: gql`
                mutation addDiscountCoupon($data: CouponDiscountPromotionInput!){
                    insertCouponDiscountPromotion(data:$data) {
                        ...DiscountCouponGeneralInfo
                    }
                }
                ${DiscountCouponGeneralInfo}
                `,
                variables: {
                    data: ModelDiscount.toGql(discountCode),
                },
            }),
            (data) => ModelDiscount.fromGql(data.insertCouponDiscountPromotion),
        )
    },
    async loadDiscountCoupons (isPast, page, size) {
        return VApiResponse.convertGql(
            await apolloClient.query({
                query: gql`
                query getDiscountCouponsPagination($query:QueryObject!,$page:Int,$size:Int,$sort:QueryObject){
                    couponDiscountPromotionsPagination(query: $query, page: $page, size: $size,sort:$sort) {
                        datas{
                            ...DiscountCouponGeneralInfo
                        }
                        pageInfo{
                            ...PageGeneralInfo
                        }
                    }
                }
                ${DiscountCouponGeneralInfo}
                ${PageGeneralInfo}
                `,
                variables: {
                    query: {
                        ...getIsPastQuery(isPast),
                    },
                    // page start at 0 on server
                    page: page - 1,
                    size,
                    sort: { ...getSort(isPast) },
                },
            }),
            (data) => ModelPagination.fromGql(data.couponDiscountPromotionsPagination),
        )
    },
    async updateDiscountCoupon (discountCoupon) {
        return VApiResponse.convertGql(
            apolloClient.mutate({
                mutation: gql`
                mutation updateDiscountCoupon($id:ObjectId,$data: CouponDiscountPromotionInput!){
                    updateCouponDiscountPromotion(id:$id,data:$data) {
                        ...DiscountCouponGeneralInfo
                    }
                }
                ${DiscountCouponGeneralInfo}
                `,
                variables: {
                    id: discountCoupon.id,
                    data: ModelDiscount.toGql(discountCoupon),
                },
            }),
            (data) => ModelDiscount.fromGql(data.updateCouponDiscountPromotion),
        )
    },
    async deleteDiscountCoupon (id) {
        return VApiResponse.convertGql(
            await apolloClient.mutate({
                mutation: gql`
                mutation deleteDiscountCode($id:ObjectId){
                    deleteCouponDiscountPromotion(id:$id)
                }
                `,
                variables: {
                    id,
                },
            }),
        )
    },
}
